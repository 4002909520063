<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <span>
    <div class="bg-white cursor-pointer">
      <editor v-model:editorText="replyText" ref="editorRef" />
    </div>
    <div class="flex text-sm pb-5">
      <div class="text-gray-500 text-sm p-1 flex-1">
        {{ replyTextLength }} / {{ commentCharacterLength }}
      </div>
      <button
        @click="closeReplyModal"
        :disabled="posting"
        class="border rounded ml-2 p-1 cursor-pointer w-20 inline-block text-center justify-center bg-gray-100"
        :class="posting ? 'text-gray-500' : 'text-gray-700'"
      >
        Cancel
      </button>
      <button
        :disabled="postButtonDisabled || posting"
        @click="replytoComment"
        class="flex items-center border rounded ml-2 p-1 cursor-pointer w-20 text-center justify-center"
        :class="
          postButtonDisabled || posting
            ? 'bg-gray-100 text-gray-500'
            : 'bg-blue-100 text-blue-500'
        "
      >
        <div v-if="!posting" class="transform rotate-90 inline-block mr-1">
          <BaseIcon name="paperairplane" />
        </div>
        <div class="mr-1 pt-px" v-else>
          <UISpinner size="extra-small" />
        </div>
        <div class="pl-1">Reply</div>
      </button>
    </div>
  </span>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import BaseIcon from '../svgicons/BaseIcon.vue'
import { CommentService } from '@/services/'
import { editorConfig } from '@/helper/constants'
import { COMMENT_MAX_LENGTH } from '@/helper/constants'
import UISpinner from '../common/UISpinner.vue'
import { hideTinyMceWarning } from '@/helper/editorHelper'
import Editor from '../common/Editor.vue'
const editorRef = ref(null)

export default defineComponent({
  data() {
    return {
      replyText: '',
      posting: false,
    }
  },
  props: {
    parentCommentId: {
      type: String,
    },
    repliedToComment: {
      type: String,
    },
    repliedToUserId: {
      type: String,
    },
    pendoPayload: {
      type: Object,
    },
    parentVisibility: String,
  },
  computed: {
    commentCharacterLength() {
      return COMMENT_MAX_LENGTH || 140
    },
    postButtonDisabled() {
      return (
        this.replyTextLength > this.commentCharacterLength ||
        this.replyTextLength === 0
      )
    },
    editorConfig() {
      return {
        ...editorConfig,
        setup: function (editor: any) {
          editor.on('init', () => {
            hideTinyMceWarning(0)
            for (const timeS of [100, 500, 1000, 2000, 5000]) {
              setTimeout(() => hideTinyMceWarning(timeS), timeS)
            }
          })
        },
      }
    },
    postId() {
      return this.$route.params.post_id
    },
    productId() {
      return this.$route.params.id
    },
    replyTextLength() {
      return this.replyText
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/g, ' ').length
    },
  },
  components: {
    editor: Editor,
    BaseIcon,
    UISpinner,
  },
  methods: {
    closeReplyModal() {
      this.$emit('closeReplyModal')
    },
    async replytoComment() {
      try {
        this.posting = true
        const { data: repliedComment } = await CommentService.createComment({
          postId: this.postId,
          productId: this.productId,
          content: this.replyText,
          parentCommentId: this.parentCommentId,
          repliedToComment: this.repliedToComment,
          repliedToUserId: this.repliedToUserId,
          pendoPayload: {
            postTitle: this.pendoPayload.post.title,
            productTitle: this.pendoPayload.product.title,
            categoryTitle: this.pendoPayload.category.title,
          },
          visibility: this.parentVisibility,
        })
        this.posting = false
        this.emitter.emit('addNewReplyToState', repliedComment.comment)
        this.$emit('closeReplyModal')
      } catch (error) {
        console.error('Something went wrong', error)
      }
    },
  },
})
</script>
