export function setLocalStorageItem(key, value, expiryInMinutes) {
  const now = new Date()
  const item = {
    value: value,
    expiry: now.getTime() + expiryInMinutes * 60 * 1000,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getLocalStorageItem(key) {
  try {
    const itemStr = localStorage.getItem(key)
    // If the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()

    // Compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, remove it from storage and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  } catch (error) {
    console.error('error in getLocalStorageItem', error)
  }
}

export function removeLocalStorageItem(key) {
  localStorage.removeItem(key)
}

export function utf8ToBase64(str) {
  const utf8Bytes = new TextEncoder().encode(str)
  return btoa(String.fromCharCode.apply(null, utf8Bytes))
}

// Decode a Base64 string back to a regular string
export function base64ToUtf8(base64) {
  const binaryString = atob(base64)
  const binaryBytes = Uint8Array.from(binaryString, (char) =>
    char.charCodeAt(0)
  )
  return new TextDecoder().decode(binaryBytes)
}
