import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Transition as _Transition } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "w-full grid grid-cols-12 gap-4" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-1 flex items-center justify-center"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "category-status-indicator col-span-3 xl:col-span-2 flex justify-center items-center" }

import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import ImageLoader from '@/components/neo-classic-theme/loaders/ImageLoader.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { getTextContentFromHTML } from '@/helper'
import {
  DESCRIPTION_LIMIT,
  defaultEmptyImage,
  defaultAssessmentImage,
} from '@/helper/constants'
import { computed, ref, PropType } from 'vue'
import { Lock01Icon, CheckCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import { UIProgress } from '@gohighlevel/ghl-ui'
import { PostContentType } from '@/models/Post'


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryListItem',
  props: {
  showDescription: {
    type: Boolean,
    default: true,
  },
  isSubCategoryItem: {
    type: Boolean,
    default: false,
  },
  showBottomBorder: {
    type: Boolean,
    default: true,
  },
  listItemData: {
    type: Object as PropType<CategoryTreeNode>,
    default: () => ({}),
  },
  shrinkSize: {
    type: Boolean,
    default: false,
  },
  isPostActive: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: 0,
  },
  progress: {
    type: Number,
    default: 0,
  },
  isPostCompleted: {
    type: Boolean,
    default: false,
  },
  isItemLocked: {
    type: Boolean,
    default: false,
  },
  isCategoryAvailable: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props

const route = useRoute()
const router = useRouter()
const isImageLoading = ref(true)
const imageUrl = ref(defaultEmptyImage)
const categoryItemContainer = ref(null)

const contentType = computed(() => {
  return props.listItemData.contentType
})

const isAssessment = computed(() => {
  return (
    contentType.value === PostContentType.assignment ||
    contentType.value === PostContentType.quiz
  )
})

const limitedDescription = computed(() => {
  if (!props.listItemData.node.description) return ''

  if (props.listItemData.node.description.length > DESCRIPTION_LIMIT) {
    const limitedContent = getTextContentFromHTML(
      props.listItemData.node.description
    ).slice(0, DESCRIPTION_LIMIT)
    return `${limitedContent}...`
  }

  return props.listItemData.node.description
})

const loading = ref(false)

function redirectToPost() {
  if (
    route.query.post_id === props.listItemData.node.id ||
    !props.isCategoryAvailable
  ) {
    return
  }

  loading.value = true
  router.push({
    name: 'post-overview',
    params: {
      id: props.listItemData.node.productId,
      category_id: props.listItemData.parent || props.listItemData.categoryId,
      post_id: props.listItemData.node.id,
    },
    query: {
      ...route.query,
    },
    hash: `#${props.listItemData.node.id}`,
  })
}

onMounted(() => {
  try {
    let defaultImage = defaultEmptyImage

    if (isAssessment.value) {
      defaultImage = defaultAssessmentImage
    }

    const newImage = new Image()
    newImage.src = props.listItemData.node.posterImage
      ? replaceBucketUrlWithCdnUrl(props.listItemData.node.posterImage)
      : defaultImage
    isImageLoading.value = true
    newImage.onload = () => {
      imageUrl.value = newImage.src
      isImageLoading.value = false
    }

    newImage.onerror = (error) => {
      imageUrl.value = defaultImage
      isImageLoading.value = false
      console.error('Error while loading image: ', error)
    }

    if (props.isPostActive) {
      // focus on the container to make show active post
      categoryItemContainer.value.focus()
      categoryItemContainer.value.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  } catch (error) {
    console.error('Error while loading image: ', error)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        tabindex: "0",
        role: "button",
        "aria-label": __props.listItemData.node.title,
        class: _normalizeClass(["category-item-container p-4 hover:bg-neo-classic-hover-bg relative", {
        'border-b': __props.showBottomBorder,
        'bg-neo-classic-active-bg': __props.isPostActive,
        'cursor-pointer': __props.isCategoryAvailable,
        'cursor-not-allowed': !__props.isCategoryAvailable,
      }]),
        onClick: redirectToPost,
        ref_key: "categoryItemContainer",
        ref: categoryItemContainer
      }, [
        _createElementVNode("div", _hoisted_2, [
          (__props.count)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.count), 1))
            : _createCommentVNode("", true),
          (isImageLoading.value)
            ? (_openBlock(), _createBlock(ImageLoader, {
                key: 1,
                class: _normalizeClass({ 'col-span-2': __props.shrinkSize, 'col-span-3': !__props.shrinkSize })
              }, null, 8, ["class"]))
            : (_openBlock(), _createElementBlock("img", {
                key: 2,
                src: imageUrl.value,
                class: _normalizeClass(["w-full rounded-lg aspect-video", {
            'col-span-2': __props.shrinkSize,
            'col-span-3': !__props.shrinkSize,
            border: isAssessment.value,
            'object-cover': !isAssessment.value,
          }]),
                alt: "category-item-image"
              }, null, 10, _hoisted_4)),
          _createElementVNode("div", {
            class: _normalizeClass(["category-item-content", {
            'col-span-6 xl:col-span-7': __props.shrinkSize && __props.count,
            'col-span-7 xl:col-span-6': !__props.shrinkSize && __props.count,
            'col-span-7 xl:col-span-8': __props.shrinkSize && !__props.count,
            'col-span-8 xl:col-span-7': !__props.shrinkSize && !__props.count,
            'flex w-full items-center': !__props.showDescription,
          }])
          }, [
            _createVNode(Title, {
              tag: __props.shrinkSize ? 'h4' : 'h5',
              class: _normalizeClass([{ 'mb-2': __props.shrinkSize, 'mb-0': !__props.showDescription }, "font-medium mb-2"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.listItemData.node.title), 1)
              ]),
              _: 1
            }, 8, ["tag", "class"]),
            (__props.showDescription)
              ? (_openBlock(), _createBlock(Paragraph, {
                  key: 0,
                  class: _normalizeClass(["text-sm", {
              'blur-sm select-none': __props.isItemLocked,
            }])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(limitedDescription.value), 1)
                  ]),
                  _: 1
                }, 8, ["class"]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            (__props.isItemLocked)
              ? (_openBlock(), _createBlock(_unref(Lock01Icon), {
                  key: 0,
                  class: _normalizeClass(__props.shrinkSize ? 'h-5 w-5' : 'h-6 w-6')
                }, null, 8, ["class"]))
              : (__props.isPostCompleted)
                ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                    key: 1,
                    class: _normalizeClass(["bg-neo-classic-success text-white rounded-full", __props.shrinkSize ? 'h-5 w-5' : 'h-6 w-6'])
                  }, null, 8, ["class"]))
                : (__props.progress > 0)
                  ? (_openBlock(), _createBlock(_unref(UIProgress), {
                      key: 2,
                      class: "w-full",
                      type: "line",
                      percentage: __props.progress,
                      status: __props.progress === 100 ? 'success' : 'default',
                      showProgressText: false,
                      showProgressPercentage: false,
                      indicatorPlacement: "outside",
                      strokeWidth: 8
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [])),
                      _: 1
                    }, 8, ["percentage", "status"]))
                  : _createCommentVNode("", true)
          ])
        ])
      ], 10, _hoisted_1)
    ]),
    _: 1
  }))
}
}

})