import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-xl text-center lg:text-left py-4 extrabold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }

import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'LockedView',
  props: {
    isCategoryLocked: { type: Boolean },
    parentCategoryLocked: { type: Boolean },
    isActivePostLocked: { type: Boolean },
    lockedOverlayContentText: {},
    lockedPost: {},
    lockedCategory: {},
    showLockedButton: { type: Boolean },
    lockIcon: {},
    productId: {}
  },
  setup(__props: any) {

const router = useRouter()

const props = __props

function redirectToPost(categoryId: string, postId?: string) {
  const params: { [key: string]: string } = {
    id: props.productId,
    category_id: categoryId,
  }

  if (postId) {
    params.post_id = postId
  }

  router.push({
    name: postId ? 'post-overview' : 'category-overview',
    params: params,
  })
}

return (_ctx: any,_cache: any) => {
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "h-20 py-4",
      src: _ctx.lockIcon,
      alt: "Lock Category"
    }, null, 8, _hoisted_2),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-4xl extrabold text-center" }, "This Lesson is Locked!", -1)),
    _createElementVNode("div", _hoisted_3, " To gain access to this " + _toDisplayString(_ctx.lockedOverlayContentText) + " please complete. ", 1),
    (_ctx.lockedPost)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.showLockedButton && _ctx.lockedPost && _ctx.lockedPost.title)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (redirectToPost(_ctx.lockedPost.categoryId, _ctx.lockedPost.id))),
                class: "my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
              }, _toDisplayString(`Post Name` && `Post: ${_ctx.lockedPost.title}`), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.lockedCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.showLockedButton && _ctx.lockedCategory && _ctx.lockedCategory.title)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (redirectToPost(_ctx.lockedCategory.id))),
                class: "my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
              }, _toDisplayString(`Category Name` && `Category: ${_ctx.lockedCategory.title}`), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.showLockedButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_UISpinner, { size: "small" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})