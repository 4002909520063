import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentModal = _resolveComponent("PaymentModal")!
  const _component_PaymentFailedModal = _resolveComponent("PaymentFailedModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showPaymentModal)
      ? (_openBlock(), _createBlock(_component_PaymentModal, {
          key: 0,
          contact: _ctx.paymentProps.contact,
          showThankYouMsg: _ctx.paymentProps.showThankYouMsg,
          freeProduct: _ctx.paymentProps.freeProduct,
          "show-modal": _ctx.showPaymentModal,
          couponData: _ctx.paymentProps.couponData,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPaymentModal = false)),
          location: _ctx.paymentProps.location,
          locationId: _ctx.paymentProps.locationId,
          offerId: _ctx.paymentProps.offerId,
          offer: _ctx.paymentProps?.offer?._data,
          checkoutSource: _ctx.paymentProps.checkoutSource,
          onOrderSuccessful: _ctx.orderConfirmation,
          onGoToPreview: _ctx.goToPreview
        }, null, 8, ["contact", "showThankYouMsg", "freeProduct", "show-modal", "couponData", "location", "locationId", "offerId", "offer", "checkoutSource", "onOrderSuccessful", "onGoToPreview"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PaymentFailedModal, {
      show: _ctx.showFailedModal,
      onRedirectToCurrent: _ctx.redirectToCurrent
    }, null, 8, ["show", "onRedirectToCurrent"])
  ]))
}