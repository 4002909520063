import * as Sentry from '@sentry/vue'

import { BrowserTracing } from '@sentry/tracing'
import { getValidUrl } from '@/helper'

const ignoredErrorStrings = ['user_not_logged_in']
/**
 * We filter all the invalid errors here
 * Cases
 *  - when the user is not logged in, we should not capture the error on the login screen.
 *  - Ideally these should be handled on the respective logics, this change is complimentary till then
 * @param event
 * @param hint
 * @returns Boolean
 */
const isInvalidError = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const { request } = event
  const originUrl = getValidUrl(request?.url) // fallback to avoid the URL exception
  const urlDetails = new URL(originUrl)

  if (
    urlDetails.pathname === '/' /* Login path */ &&
    hint.originalException?.toString().includes(ignoredErrorStrings.join(' '))
  ) {
    return true
  }

  return false
}

export const setupSentry = (Vue: any, router: any, config: any) => {
  Sentry.init({
    Vue,
    dsn: 'https://0702936b00ef478da35d23063b467302@o176457.ingest.sentry.io/4504604626321408',
    environment: config.mode,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    beforeSend(event, hint) {
      if (isInvalidError(event, hint)) {
        return null
      }

      return event
    },
    sampleRate: 1, // for errors
    tracesSampleRate: 0,
    logErrors: true,
    trackComponents: true,
  })
}
