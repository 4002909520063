import http from '../../http-common'

export default class UserPostCompletionService {
  protected subdomain = 'user-post-completion'
  private readonly locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  private get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  findAll(params?: any): Promise<[]> {
    return http
      .get(this.endpoint, {
        params,
      })
      .then((res) => res.data)
  }

  markPostAsCompleted(payload: {
    productId: string
    postId: string
    percentage?: number
  }) {
    return http.post(this.endpoint, payload).then((resp) => resp.data)
  }

  markPostAsIncomplete(postCompletedRef: string) {
    return http.delete(`${this.endpoint}/${postCompletedRef}`)
  }

  checkIfPostCompleted(postId: string) {
    return http
      .get(this.endpoint, { params: { post_id: postId } })
      .then((resp) => resp.data)
  }

  noOfCompletedPosts(params?: any) {
    return http
      .get(`${this.endpoint}/no-posts-completed`, {
        params,
      })
      .then((resp) => resp.data)
  }
}
