<template>
  <div class="z-20 font-semibold">
    <div class="app-launcher-trigger" :class="[triggerColor, allAppsDisabled && 'cursor-not-allowed']">
      <UIButton
        @click="toggleAppLauncher"
        id="app-launcher"
        class="h-10 w-10"
        :quaternary="true"
        :circle="true"
      >
        <DotsGridIcon
          class="h-5 w-5"
          :class="newMobileScreens ? 'text-white' : 'text-gray-500'"
        />
      </UIButton>
    </div>
    <Transition enter-from-class="opacity-0 translate-y-1" enter-active-class="transition ease-out duration-200"
      enter-to-class="opacity-100 translate-y-0" leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1" leave-active-class="transition ease-in duration-150">
      <div class="absolute right-0 z-10 mt-5 flex px-4" v-if="appLauncherIsOpen" @mouseleave="toggleAppLauncher">
        <div
          class="w-screen max-w-xs flex-auto overflow-hidden rounded-2xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-xs">
          <div class="grid grid-cols-2 gap-x-1 gap-y-1 p-4 lg:grid-cols-2 text-gray-900">
            <div class="group relative flex justify-center gap-x-1 rounded-lg p-4 hover:bg-gray-50" v-if="clientPortalDomainData?.locationPermissions
              ?.communitiesEnabled === true">
              <div id="pg-afcp-navbar__navigation-page-communities-btn"
                class="flex cursor-pointer flex-col items-center justify-center" @click="redirectTo('communities/')">
                <Users01Icon @click="toggleAppLauncher" class="h-5 w-5" />

                <div class="flex flex-col items-center justify-center">
                  Communities
                </div>
              </div>
            </div>
            <div class="group relative flex items-start justify-center gap-x-1 rounded-lg p-4 hover:bg-gray-50" v-if="clientPortalDomainData?.locationPermissions
              ?.membershipEnabled === true">
              <div id="pg-afcp-navbar__navigation-page-courses-btn"
                class="flex cursor-pointer flex-col items-center justify-center"
                @click="redirectTo('courses/library-v2')">
                <BookOpen01Icon @click="toggleAppLauncher" class="h-5 w-5" />
                <div>Courses</div>
              </div>
            </div>
            <div class="group relative flex items-start justify-center gap-x-1 rounded-lg p-4 hover:bg-gray-50" v-if="clientPortalDomainData?.locationPermissions
              ?.affiliateManagerEnabled === true">
              <div id="pg-afcp-navbar__navigation-page-affiliate-btn"
                class="flex cursor-pointer flex-col items-center justify-center" @click="redirectTo('affiliate')">
                <Briefcase02Icon @click="toggleAppLauncher" class="h-5 w-5 cursor-pointer" />
                <div>Affiliates</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import {
  DotsGridIcon,
  Users01Icon,
  BookOpen01Icon,
  Briefcase02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { redirectTo } from '../../helper'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { UIButton } from '@gohighlevel/ghl-ui'

const props = defineProps({
  appLauncherIsOpen: {
    type: Boolean,
    default: false,
  },
  triggerColor: {
    type: String,
    default: '',
  },
})

const store = useStore()
const emit = defineEmits(['toggleAppLauncher'])

const newMobileScreens = computed(() => {
  return store.getters.newMobileScreens
})
const clientPortalDomainData = computed(() => {
  return store.state.clientPortalDomainData
})
//computed property if all apps are disabled
const allAppsDisabled = computed(() => {
  return (clientPortalDomainData?.value?.locationPermissions?.communitiesEnabled !==
    true &&
    clientPortalDomainData?.value?.locationPermissions?.membershipEnabled !==
    true &&
    clientPortalDomainData?.value?.locationPermissions?.affiliateManagerEnabled !==
    true)
})

function toggleAppLauncher() {
  //disable app launcher if all permissions are disabled
  if (allAppsDisabled.value) {
    return
  }
  emit('toggleAppLauncher')
}
</script>
