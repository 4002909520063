<script lang="ts" setup>
import {
  ref,
  computed,
  onBeforeMount,
  onMounted,
  watch,
  getCurrentInstance,
  inject,
} from 'vue'
import { defaultPosterImage } from '@/helper/constants'
import CheckoutService from '@/services/CheckoutService'
import NewProductCard from '@/components/product/NewProductCard.vue'
import { getContactId } from '@/services'
import { OfferType, CheckoutSourceType } from '@/models/Offer'
import { UpsellOfferType } from '@/models/UpsellOffer'
import { adminMode } from '@/helper/permission.helper'
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
} from '@/helper/filter'
import UISpinner from '@/components/common/UISpinner.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { UserPurchaseService } from '@/services'
import { Contacts, Stripe } from '@/helper/restAgent'
import Navigation from '@/components/common/Navigation.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PaymentModal from '@/components/checkout/PaymentModal.vue'

const offer = ref({})
const contact = ref<{ [key: string]: any }>({})
const showModal = ref(false)
const showThankYouMsg = ref(false)
const location = ref({})
const loader = ref(false)
const disableCheckoutBtn = ref(false)
const paymentMethods = ref<any[]>([])
const purchasedProductIds = ref<any[]>([])
const offerAlreadyPurchased = ref(false)
const optin = ref(false)
const route = useRoute()
const store = useStore()
const router = useRouter()
const emitter = inject('emitter')

const freeProduct = computed(() => offer.value.type === OfferType.free)
const showPaymentForm = computed(
  () =>
    !optin.value &&
    !showThankYouMsg.value &&
    !freeProduct.value &&
    !offerAlreadyPurchased.value
)
const isAdmin = computed(() => adminMode())
const offerDescription = computed(() =>
  offer.value.description
    ? offer.value.description.length > 130
      ? offer.value.description.substring(0, 130) + '...'
      : offer.value.description
    : ''
)
const offerId = computed(() => route.params.offerId as string)
const upsellId = computed(() => route.params.upsellId as string)
const device = computed(() => store.getters.device)
const newMobileScreens = computed(() => store.getters.newMobileScreens)
const locationId = computed(() => store.getters.locationId)

const fetchUserProducts = async () => {
  loader.value = true
  try {
    const products = await UserPurchaseService.getAllProducts()
    purchasedProductIds.value = products.map((p) => p.id)
  } catch (error) {
    console.error('Error while fetching products --> ', error)
  } finally {
    loader.value = false
  }
}

// Fetch offer data
const fetchOfferData = async () => {
  try {
    loader.value = true
    const contactId = getContactId()
    contact.value = await Contacts.getContactById(locationId.value, contactId)

    const response = await CheckoutService.findOfferById(
      offerId.value,
      contact.value.locationId,
      { productCustomization: true }
    )
    if (response?.status === 200) {
      const { data } = response
      offer.value = data
      location.value = data.location
    }

    if (contact.value.stripeCustomerId) {
      await fetchPaymentMethods()
    }
  } catch (e) {
    console.error(e)
  } finally {
    loader.value = false
  }
}

// Fetch payment methods
const fetchPaymentMethods = async () => {
  try {
    const params = {
      stripeCustomerId: contact.value.stripeCustomerId,
      offerId: offerId.value,
    }
    const { data } = await Stripe.getPaymentMethod(locationId.value, params)
    paymentMethods.value = data
  } catch (error) {
    console.error(error)
  }
}

// Checkout logic
const checkout = async () => {
  if (isAdmin.value) return

  disableCheckoutBtn.value = true
  await checkContactPurchasedOffer()
  if (
    !optin.value &&
    !showThankYouMsg.value &&
    !freeProduct.value &&
    !offerAlreadyPurchased.value
  ) {
    showModal.value = true
  }
  disableCheckoutBtn.value = false
}

// Check if contact has purchased the offer
const checkContactPurchasedOffer = async () => {
  const {
    data: { purchased, loginUrl },
  } = await CheckoutService.checkIfContactPurchasedOffer({
    contactId: contact.value.id,
    offerId: offerId.value,
    locationId: offer.value.locationId,
  })
  offerAlreadyPurchased.value = purchased
  if (offerAlreadyPurchased.value) {
    // Handle if already purchased
    return
  }
  await createLeadEntry()
  if (offer.value.type === OfferType.free) {
    await createFreePurchase()
  }
}

// Create a free purchase
const createFreePurchase = async () => {
  const payload = {
    fingerprint: contact.value.fingerprint,
    locationId: contact.value.locationId,
    productType: 'offer',
    checkoutType: 'free',
  }

  try {
    const response = await CheckoutService.checkout(offerId.value, payload)
    showThankYouMsg.value = true
    const { ct, previewUrl } = response.data
    showModal.value = true
    // Handle success response
  } catch (error) {
    console.error(error)
  }
}

const goToPreview = () => {
  router.push({
    name: 'library-v2',
  })
}

const orderConfirmation = ({ ct, previewUrl, upsellOfferId }) => {
  showThankYouMsg.value = true
  // setTimeout(() => {
  //   window.location.reload()
  // }, 2000)
}

// Lead entry creation
const createLeadEntry = async () => {
  const payload = {
    contactId: contact.value.id,
    locationId: contact.value.locationId,
    offerId: offerId.value,
  }
  await CheckoutService.leadTrack(payload)
}

const backToLibrary = () => {
  router.push({ name: 'library-v2' })
}

const checkoutCallback = () => {
  emitter.emit('handleUpsellCheckout', upsellId.value)
}

// Lifecycle hooks
onBeforeMount(() => {
  if (!newMobileScreens.value || !offer.value) {
    router.push({ name: 'library-v2' })
  }
})

onMounted(async () => {
  await fetchUserProducts()
  await fetchOfferData()
})

// Watchers
watch(device, (newDevice) => {
  if (newDevice === 'mobile') {
    router.push({ name: 'library-v2' })
  }
})
</script>

<template>
  <div class="max-w-xl mx-auto lg:max-w-auto">
    <Navigation :backCallback="backToLibrary" />
    <div class="pt-4 px-4 pb-4 text-center">
      <div
        class="inline-block relative align-start text-left bg-white transform transition-all w-full"
      >
        <div class="text-center mt-2 p-2">
          <span class="block px-2 font-semibold">{{ offer.title }}</span>
        </div>
        <div class="flex flex-col py-2">
          <div class="px-2 flex flex-col items-start mb-4">
            <div class="relative offer-poster-image w-full py-2">
              <img
                :src="
                  offer.posterImage ||
                  replaceBucketUrlWithCdnUrl(defaultPosterImage)
                "
                class="object-contain w-full rounded"
                :class="!offer.posterImage ? 'h-28' : 'h-full'"
                alt="Offer Poster"
              />
              <div
                v-if="offer.products && offer.products.length > 1"
                class="absolute top-0 right-1 mx-3 my-3 px-1 rounded text-gray-900 text-xs"
                style="background-color: #ffbc00"
              >
                Bundle
              </div>
            </div>
            <div class="flex flex-col w-full">
              <div
                v-if="offerDescription"
                v-html="offerDescription"
                class="px-2 text-base custom-list-styling editor-content"
              ></div>
              <div v-else class="px-2 my-4 text-center text-base">
                No Description Available
              </div>
              <div class="flex flex-col justify-center mb-4">
                <div
                  @click="checkoutCallback"
                  class="p-2 text-center text-blue-600 text-xs cursor-pointer mb-2"
                >
                  Learn More
                </div>
                <UISpinner
                  color="#4299e1"
                  v-if="loader"
                  class="min-w-48 flex justify-center items-center p-2 mx-3"
                  size="extra-small"
                />
                <div
                  class="flex items-center rounded bg-blue-500 text-white font-semibold p-2 min-w-48 upsell__purchase-btn mx-3"
                  :class="[
                    loader ? 'hidden' : 'block',
                    !isAdmin && 'cursor-pointer',
                  ]"
                  @click="checkout"
                >
                  <span
                    class="text-left pl-2 flex"
                    :class="{
                      'opacity-75 cursor-not-allowed': disableCheckoutBtn,
                    }"
                  >
                    <UISpinner
                      color="#ffffff"
                      v-if="disableCheckoutBtn"
                      class="pr-2"
                      size="small"
                    />
                    <BaseIcon v-else name="cart" hwClass="w-5 text-white" />
                  </span>
                  <span class="pl-2 pr-2 text-center text-xs flex-1">
                    <span>Enroll for </span>
                    <span
                      id="offer-price-display-override"
                      v-if="offer.priceDisplayOverride"
                    >
                      {{ offer.priceDisplayOverride }}
                    </span>
                    <span id="offer-amount" v-else>
                      {{ fetchCurrencySymbol(offer.currency)
                      }}{{ Number(offer.amount).toFixed(2) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            id="bundle-products"
            class="mx-2 grid grid-cols-2 gap-4 justify-center"
          >
            <template :key="product.id" v-for="product in offer.products">
              <NewProductCard
                class="bg-white"
                :product-id="product.id"
                :product-title="product.title"
                :poster-image="product.posterImage"
                :show-instructor-mobile="true"
                :instructor-name="product.customizations.instructorName"
                :instructor-headshot="product.customizations.instructorHeadshot"
                :upsellProduct="true"
                :label="
                  purchasedProductIds.indexOf(product.id) !== -1
                    ? 'in-library'
                    : 'locked'
                "
                :showProgress="false"
                @handleClick="checkoutCallback"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      id="payment-form"
      class="px-5"
      v-if="showPaymentForm || showThankYouMsg"
    >
      <PaymentModal
        :showThankYouMsg="showThankYouMsg"
        :freeProduct="freeProduct"
        :contact="contact"
        :show-modal="showModal"
        @close="showModal = false"
        :location="location"
        :paymentMethods="paymentMethods"
        :locationId="offer.locationId"
        :offerId="offer.id"
        :isUpsell="true"
        :upsellType="UpsellOfferType.INAPP"
        :offer="offer"
        :checkoutSource="CheckoutSourceType.INAPP"
        @order-successful="orderConfirmation"
        @go-to-preview="goToPreview"
      />
    </div>
  </div>
</template>
<style scoped>
.ui-modal::-webkit-scrollbar {
  display: none;
}

.offer-poster-image > img {
  aspect-ratio: 16/9;
}
</style>
