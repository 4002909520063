<template>
  <div class="category-post-list" :class="!available ? 'drip-post-list' : ''">
    <span
      class="float-right text-gray-500 font-bold text-xl px-6 mx-6"
      v-if="!available"
      >(Coming Soon)</span
    >
    <div v-for="(child, index) in categoryArrays" :key="index">
      <div
        class="mx-6 px-6 font-bold text-xl"
        :class="[
          $route.name.includes('product-overview') && child.available
            ? 'primaryStyle subcategory-title'
            : 'text-gray-500',
          !child.available ? 'drip-category-title' : '',
        ]"
        v-if="child.isSubCategory"
      >
        {{ child.categoryTitle }}
        <span v-if="!child.available" class="float-right">(Coming Soon)</span>
      </div>
      <div
        class="lg:w-full grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-2 gap-5 px-6 row-gap-4 justify-center mb-10 mt-2 mx-6 lg:mx-12"
      >
        <template :key="post.id" v-for="post in child.post">
          <NewYorkPostListItem
            v-if="nodeIsPost(post.type)"
            :post="post.node"
            :category-id="post.parent"
            :completed="completedPosts.has(post.id)"
            :available="child.available"
            :templateId="templateId"
            :hasProductAccess="hasProductAccess"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import NewYorkPostListItem from './NewYorkPostListItem.vue'
import { CategoryTreeNodeType } from '../../models/CategoryTreeNode'
import { unwrapCategoryTree } from '../../helper/index'

export default defineComponent({
  components: {
    NewYorkPostListItem,
  },
  props: {
    categoryTree: {
      type: Object,
    },
    completedPosts: {
      type: Set,
      default: () => new Set(),
    },
    level: {
      type: Number,
      default: 0,
    },
    available: {
      type: Boolean,
      default: true,
    },
    templateId: String,
    hasProductAccess: {
      type: Boolean
    }
  },
  mounted() {
    this.orderSelectedCategoryData(this.categoryTree)
  },
  data() {
    return {
      categoryArrays: [],
    }
  },
  methods: {
    nodeIsPost(type: CategoryTreeNodeType) {
      return type === CategoryTreeNodeType.Post
    },
    orderSelectedCategoryData(tree: CategoryTreeNodeType) {
      this.categoryArrays = unwrapCategoryTree(tree, false)
    },
  },
  watch: {
    categoryTree: function (newVal: CategoryTreeNodeType) {
      this.orderSelectedCategoryData(newVal)
    },
  },
})
</script>
