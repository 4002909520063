<template>
  <div>
    <CategoryPostTree
      v-if="categoryLockData.length"
      :product-id="productId"
      :category-tree="categoryTree"
      :completed-posts="completedPosts"
      :completed-categories="completedCategories"
      :category-lock-data="categoryLockData"
      :hasProductAccess="hasProductAccess"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { createCategoryPostTree } from '@/helper'
import CategoryTreeNode from '../../models/CategoryTreeNode'
import CategoryPostTree from './CategoryPostTree.vue'
import Category from '@/models/Category'

export default defineComponent({
  props: {
    categories: {
      type: Array,
    },
    parentCategory: {
      type: String,
    },
    completedPosts: {
      type: Set,
      default: () => new Set(),
    },
    completedCategories: {
      type: Set,
      default: () => new Set(),
    },
    productId: {
      type: String,
    },
    hasProductAccess: {
      type: Boolean
    }
  },
  components: {
    CategoryPostTree,
  },
  computed: {
    categoryTree(): CategoryTreeNode[] {
      const fetchedTree = createCategoryPostTree(
        this.parentCategory || null,
        this.categories
      )
      return fetchedTree
    },
  },
  data() {
    return {
      categoryLockData: [],
      postLockData: [],
    }
  },
  mounted() {
    this.categoryLockData = this.categories.map((c: Category) => {
      return {
        categoryId: c.id,
        isLocked:
          this.completedPosts.has(c.lockedBy) ||
          this.completedCategories.has(c.lockedByCategory)
            ? false
            : !!(c.lockedBy && c.lockedByCategory),
      }
    })
  },
})
</script>
