import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pwa-modal z-40 fixed top-0 left-0 w-full h-full"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-lg font-bold mt-3 mb-2" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t" }
const _hoisted_7 = { class: "mx-1" }
const _hoisted_8 = { class: "w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t" }
const _hoisted_9 = { class: "mx-1" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 4,
  class: "w-5 h-5 absolute transform rotate-45 left-1/5 bg-white",
  style: {"bottom":"-0.625rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.open || _ctx.pwaTriggerInstallAlert)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-full h-full bg-gray-900 opacity-75 transition-opacity" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["modal-content max-w-sm mb-5 bg-white absolute transform p-4 rounded-lg w-11/12 flex flex-col items-center", _ctx.getModalPosition(_ctx.browserName === 'safari' ? 'bottom' : 'center')])
        }, [
          _createElementVNode("div", {
            class: "flex w-full justify-end text-gray-600",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "cross",
              hwClass: "w-4 h-4"
            })
          ]),
          _createElementVNode("img", {
            class: "w-12",
            src: _ctx.pwaAlertData.logo || _ctx.logo
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, " Install " + _toDisplayString(_ctx.pwaAlertData.name || _ctx.pageTitle), 1),
          (
          _ctx.deviceName === 'iphone' &&
          (_ctx.browserName === 'chrome' || _ctx.browserName === '')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-center px-2 mb-3 text-gray-600" }, " To add this app to your Home Screen, open this link in Safari ", -1)),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyLinkToClipboard && _ctx.copyLinkToClipboard(...args))),
                  class: "w-full rounded text-center p-2 bg-gray-300 font-bold"
                }, [
                  (_ctx.isCopied)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Copied"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, "Copy link to app"))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (
          (_ctx.deviceName === 'iphone' && _ctx.browserName === 'safari') ||
          (_ctx.isTablet && _ctx.deviceName !== 'android' && _ctx.browserName === 'safari')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-center px-2 mb-3 text-gray-600" }, " Add this app to your home screen for easy access and a better experience. ", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[4] || (_cache[4] = _createTextVNode(" Tap ")),
                  _createElementVNode("span", _hoisted_7, [
                    _createVNode(_component_BaseIcon, { name: "iosshare" })
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" then \"Add to Home Screen\" "))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.deviceName === 'android' && _ctx.browserName === 'chrome')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-center px-2 mb-3 text-gray-600" }, " Start your course right from your phone's home screen, no download required ", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createTextVNode(" Tap ")),
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_BaseIcon, {
                      name: "dotsvertical",
                      hwClass: "w-5 h-5"
                    })
                  ]),
                  _cache[8] || (_cache[8] = _createTextVNode(" then \"Add to Home Screen\" "))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (
          _ctx.deviceName === 'android' &&
          (_ctx.browserName === 'safari' || _ctx.browserName === '')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-center px-2 mb-3 text-gray-600" }, " To add this app to your Home Screen, open this link in Chrome ", -1)),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyLinkToClipboard && _ctx.copyLinkToClipboard(...args))),
                  class: "w-full rounded text-center p-2 bg-gray-300 font-bold"
                }, [
                  (_ctx.isCopied)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Copied"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_11, "Copy link to app"))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.browserName === 'safari' && _ctx.popupPos === 'bottom')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}