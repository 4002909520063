import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "category-post-list w-full" }
const _hoisted_2 = { class: "flex-1 justify-center custom-word-break" }
const _hoisted_3 = { class: "flex items-center justify-end" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "md:pr-6" }
const _hoisted_6 = { class: "flex-1 justify-center text-base custom-word-break" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "pr-3 text-xl font-bold"
}
const _hoisted_9 = { class: "custom-word-break" }
const _hoisted_10 = {
  key: 1,
  class: "flex justify-between items-center category-title font-bold text-gray-500 px-3 py-4 drip-post-title"
}
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = {
  key: 0,
  class: "pr-3 text-xl"
}
const _hoisted_13 = {
  key: 3,
  class: "font-semibold px-6 py-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_NeueCategoryPostTree = _resolveComponent("NeueCategoryPostTree", true)!
  const _component_post_material_modal = _resolveComponent("post-material-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTree, (child, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: child.id
      }, [
        (_ctx.nodeIsPost(child.type) && _ctx.available)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasProductAccess ? 'router-link' : 'span'), {
              key: 0,
              tag: "div",
              to: {
          name: 'post-overview',
          params: {
            id: child.node.productId,
            category_id: child.node.categoryId,
            post_id: child.id,
          },
          query: {
            template_id: _ctx.templateId,
          },
        },
              class: _normalizeClass(["flex justify-between items-center py-4 pl-6 pr-2 cursor-pointer text-sm lessonSecondaryStyle", [
          child.id === _ctx.postId
            ? 'postqueue-lesson text-nova-blue-900 post-highlight'
            : 'text-nova-blue-600 hover:bg-gray-300 hover:text-gray-700 postqueue-lesson',
          _ctx.categoryId && _ctx.active[_ctx.categoryId] === false ? 'hidden' : 'block',
        ]]),
              id: `post__${child.id}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(child.node.title), 1),
                _createElementVNode("div", _hoisted_3, [
                  (
              _ctx.postId === child.id &&
              _ctx.activePost.materials &&
              _ctx.activePost.materials.length
            )
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "px-2 flex justify-center",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
                        style: _normalizeStyle({ color: _ctx.highlightColor })
                      }, [
                        _createVNode(_component_BaseIcon, {
                          name: "docdownload",
                          hwClass: "h-3 w-3"
                        })
                      ], 4))
                    : _createCommentVNode("", true),
                  (_ctx.nodeIsCompleted(child))
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "h-5 px-3",
                        src: _ctx.checkIcon,
                        alt: "Completed"
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["to", "id", "class"]))
          : (_ctx.nodeIsPost(child.type) && !_ctx.available)
            ? (_openBlock(), _createElementBlock("div", {
                key: child.id,
                class: "justify-between items-center py-4 pl-6 pr-2 text-gray-500 hidden"
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.postIndexes.get(child.id)), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(child.node.title), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", {
                class: "category-contents",
                key: child.id
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["w-11/12 mx-3", [_ctx.level === 0 && index !== 0 && 'border-t']])
                }, null, 2),
                (child.node.available)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["w-full category-title text-gray-700 font-medium px-3 py-4 lessonSecondaryStyle", [
            _ctx.level === 0
              ? 'text-lg postqueue-category cursor-pointer hover:bg-gray-300 hover:text-gray-700'
              : 'text-sm postqueue-subCategory',
            _ctx.active[child.node.parentCategory] === false ? 'hidden' : 'block',
          ]]),
                      onClick: ($event: any) => (_ctx.emitter.emit('updateActiveCategory', child.id))
                    }, [
                      (_ctx.level === 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.active[child.id] === false ? '+' : '-'), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(child.node.title.toLocaleUpperCase()), 1)
                    ], 10, _hoisted_7))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.level === 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, "+"))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(["custom-word-break", _ctx.level === 0 ? 'text-lg' : 'text-sm'])
                        }, _toDisplayString(child.node.title.toLocaleUpperCase()), 3)
                      ]),
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "float-right text-sm" }, "(Coming Soon)", -1))
                    ])),
                (child.childs && child.childs.length)
                  ? (_openBlock(), _createBlock(_component_NeueCategoryPostTree, {
                      key: 2,
                      available: child.node.available,
                      level: _ctx.level + 1,
                      active: _ctx.active,
                      categoryId: 
            child.node.parentCategory
              ? child.node.parentCategory
              : child.node.id
          ,
                      "category-tree": child.childs,
                      isBackground: _ctx.isBackground,
                      activePost: _ctx.activePost,
                      "post-indexes": _ctx.postIndexes,
                      "completed-posts": _ctx.completedPosts,
                      highlightBackground: _ctx.highlightBackground,
                      highlightColor: _ctx.highlightColor,
                      hasProductAccess: _ctx.hasProductAccess
                    }, null, 8, ["available", "level", "active", "categoryId", "category-tree", "isBackground", "activePost", "post-indexes", "completed-posts", "highlightBackground", "highlightColor", "hasProductAccess"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, "No content available!")),
                _createElementVNode("div", {
                  class: _normalizeClass(["w-11/12 mx-3", [
            _ctx.level === 0 && index === _ctx.categoryTree.length - 1 && 'border-b',
          ]])
                }, null, 2)
              ]))
      ], 64))
    }), 128)),
    (_ctx.hasProductAccess)
      ? (_openBlock(), _createBlock(_component_post_material_modal, {
          key: 0,
          "show-modal": _ctx.showModal,
          materials: _ctx.activePost.materials,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
        }, null, 8, ["show-modal", "materials"]))
      : _createCommentVNode("", true)
  ]))
}