import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_3 = { class: "text-sm text-gray-500 mt-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "grid grid-cols-12 gap-4 p-4 border bg-neo-classic-hover-bg rounded-lg mt-3" }
const _hoisted_6 = { class: "col-span-2" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "col-span-8" }
const _hoisted_9 = { class: "col-span-2 flex items-center" }


import Paper from '@/components/neo-classic-theme/common/Paper.vue' 
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import { adminMode } from '@/helper/permission.helper'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { ChevronRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import { defaultEmptyImage } from '@/helper/constants'
import { useRouter } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'MarkPostComplete',
  props: {
  wrapper: {
    type: Boolean,
    default: true,
  },
  activePost: {
    type: Object,
  },
  isPostCompleted: {
    type: Boolean,
  },
  buttonText: {
    type: String,
    default: 'Mark as completed',
  },
  nextPost: {
    type: Object,
    default: () => ({}),
  },
  disablePostCompletionButton: {
    type: Boolean,
    default: false,
  },
},
  emits: ['togglePostCompletion'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit
const isAdmin = adminMode()
const router = useRouter()

function goToNextPost() {
  router.push({
    name: 'post-overview',
    params: {
      id: props.nextPost.productId,
      category_id: props.nextPost.categoryId,
      post_id: props.nextPost.id,
    },
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.wrapper ? Paper : 'div'), {
    class: _normalizeClass(__props.wrapper ? 'p-4' : 'py-4')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Button, {
          size: "large",
          variant: __props.isPostCompleted ? 'success' : 'primary',
          class: "w-full",
          disabled: _unref(isAdmin) || __props.disablePostCompletionButton,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('togglePostCompletion')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.isPostCompleted ? 'Completed' : __props.buttonText), 1)
          ]),
          _: 1
        }, 8, ["variant", "disabled"])
      ]),
      (_unref(isAdmin))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(isAdmin)
            ? 'You are an admin, you can not mark this post as completed'
            : ''), 1)
          ]))
        : _createCommentVNode("", true),
      (__props.isPostCompleted && __props.nextPost)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: 
              _unref(replaceBucketUrlWithCdnUrl)(__props.nextPost.posterImage) ||
              _unref(defaultEmptyImage)
            ,
                  alt: __props.nextPost.title,
                  class: "w-full object-cover rounded-lg aspect-video"
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(Title, {
                  tag: "h3",
                  class: "font-medium !m-0 !mb-2"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Good job! Keep going! ")
                  ])),
                  _: 1
                }),
                _createVNode(Paragraph, { class: "m-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.nextPost.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(Button, {
                  size: "large",
                  variant: "text",
                  class: "text-neo-classic-primary-accent flex items-center",
                  onClick: goToNextPost
                }, {
                  default: _withCtx(() => [
                    _cache[2] || (_cache[2] = _createTextVNode(" Next Lesson ")),
                    _createVNode(_unref(ChevronRightIcon), { class: "w-4 h-4 ml-2" })
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})