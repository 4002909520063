import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col gap-2" }
const _hoisted_2 = { class: "bg-white cursor-pointer" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center my-4"
}

import { h, ref, provide, onMounted, computed } from 'vue'
import NeoCommentInput from '@/components/neo-classic-theme/comments/NeoCommentInput.vue'
import { CommentService } from '@/services'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { CommentPrivacy } from '@/models/Product'
import NeoCommentList from '@/components/neo-classic-theme/comments/NeoCommentList.vue'
import { Lock01Icon, Globe01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { renderIcon, useNotification } from '@gohighlevel/ghl-ui'
import { CommentVisibility } from '@/models/Comment'
import { notify } from '@/helper'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoComments',
  props: {
  postId: {
    type: String,
    required: true,
  },
  productId: {
    type: String,
    required: true,
  },
  instructor: {
    type: Object,
  },
  pendoPayload: {
    type: Object,
  },
  productCommentPrivacy: {
    type: String,
    default: CommentPrivacy.instructorOnly,
    required: true,
  },
},
  setup(__props) {

const props = __props

const notification = useNotification()
const commentPrivacy = [
  {
    label: 'Instructor only',
    key: CommentPrivacy.instructorOnly,
    visibility: CommentVisibility.private,
    icon() {
      return h(renderIcon(Lock01Icon), null, {})
    },
  },
  {
    type: 'divider',
    key: 'd1',
  },
  {
    label: 'Public',
    key: CommentPrivacy.public,
    visibility: CommentVisibility.public,
    icon() {
      return h(renderIcon(Globe01Icon), null, {})
    },
  },
]

const commentsList = ref<Array<any>>([])
const offset = ref(0)
const limit = ref(5)
const showViewMore = ref(true)
const editableCommentId = ref('')
const enableCommentEdit = ref(false)
const loadingComments = ref(false)

const filteredPrivacyOptions = computed(() => {
  if (props.productCommentPrivacy === CommentPrivacy.publicAndInstructor) {
    return commentPrivacy
  }

  return commentPrivacy.filter(
    (option) => option.key === props.productCommentPrivacy
  )
})
provide('commentPrivacy', filteredPrivacyOptions.value)

function getEditableComment() {
  for (let i = 0; i < commentsList.value.length; i++) {
    if (commentsList.value[i].id === editableCommentId.value) {
      return commentsList.value[i]
    }

    if (
      commentsList.value[i].replies &&
      commentsList.value[i].replies.length > 0
    ) {
      const reply = commentsList.value[i].replies.find(
        (reply: any) => reply.id === editableCommentId.value
      )
      if (reply) return reply
    }
  }
  return {}
}

async function fetchComments() {
  try {
    loadingComments.value = true
    const response = await CommentService.findComments({
      postId: props.postId,
      productId: props.productId,
      offset: offset.value,
      limit: limit.value,
      order: -1,
    })
    const fetchedComments = response.data.comments

    if (fetchedComments.length < limit.value) {
      showViewMore.value = false
    }

    commentsList.value.push(...fetchedComments)
    offset.value += limit.value
  } catch (error) {
    console.error('Something went wrong, fetching comments', error)
  } finally {
    loadingComments.value = false
  }
}

function addNewCommentToList(newComment: any) {
  if (newComment.parentCommentId) {
    commentsList.value = commentsList.value.map((comment) => {
      if (comment.id === newComment.parentCommentId) {
        comment.replies = [
          newComment,
          ...(comment.replies ? comment.replies : []),
        ]
        return comment
      }
      return comment
    })
  } else {
    commentsList.value.unshift(newComment)
  }
}

async function deleteComment(commentId: string) {
  try {
    // Removing from local state first to avoid flickering
    deleteCommentFromList(commentId)
    await CommentService.deleteComment(commentId)
    notify(notification, {
      type: 'success',
      title: 'Comment deleted successfully',
    })
  } catch (error) {
    console.error('Error while deleting comment: ', error)
  }
}

function deleteCommentFromList(commentId: string) {
  commentsList.value = commentsList.value.filter((comment) => {
    if (comment.id === commentId) return false
    if (comment.replies && comment.replies.length > 0) {
      comment.replies = comment.replies.filter(
        (reply) => reply.id !== commentId
      )
    }

    return true
  })
}

function handleOnEdit(commentId: string) {
  editableCommentId.value = commentId
  enableCommentEdit.value = true
}

function updateCommentFromList(updatedComment: any) {
  if (!updatedComment) {
    return disableCommentEdit()
  }
  commentsList.value = commentsList.value.map((comment) => {
    if (comment.id === updatedComment.id) {
      return updatedComment
    }

    if (comment.replies && comment.replies.length <= 0) return comment

    comment.replies = comment.replies?.map((replyComment: any) => {
      if (replyComment.id === updatedComment.id) {
        return updatedComment
      }
      return replyComment
    })
    return comment
  })
  disableCommentEdit()
}

function disableCommentEdit() {
  enableCommentEdit.value = false
  editableCommentId.value = ''
}

onMounted(async () => {
  await fetchComments()
})

// Need this to enable scrolling on comments from notifications

// watch(comments, (newComments) => {
//   if (commentIdToScroll.value) {
//     const comment = newComments.find((e) => e.id === commentIdToScroll.value)
//     if (comment) {
//       commentIdToScroll.value = null
//       comment.$el.scrollIntoView({ behavior: 'smooth' })
//     }
//   }
// })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Title, {
      tag: "h3",
      class: "font-medium"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Comments")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NeoCommentInput, {
        "post-id": __props.postId,
        "product-id": __props.productId,
        pendoPayload: __props.pendoPayload,
        productCommentPrivacy: __props.productCommentPrivacy,
        onAddNewComment: addNewCommentToList
      }, null, 8, ["post-id", "product-id", "pendoPayload", "productCommentPrivacy"])
    ]),
    _createVNode(NeoCommentList, {
      commentsList: commentsList.value,
      editableCommentId: editableCommentId.value,
      instructor: __props.instructor,
      onOnEdit: handleOnEdit,
      onOnDelete: deleteComment
    }, {
      "for-comment-edit": _withCtx(() => [
        _createVNode(NeoCommentInput, {
          "post-id": __props.postId,
          "product-id": __props.productId,
          pendoPayload: __props.pendoPayload,
          enableEdit: enableCommentEdit.value,
          productCommentPrivacy: __props.productCommentPrivacy,
          editThisComment: getEditableComment(),
          onAddNewComment: addNewCommentToList,
          onUpdateEditedComment: updateCommentFromList
        }, null, 8, ["post-id", "product-id", "pendoPayload", "enableEdit", "productCommentPrivacy", "editThisComment"])
      ]),
      "for-comment-reply": _withCtx((slotProps) => [
        _createVNode(NeoCommentInput, _mergeProps(slotProps, {
          "post-id": __props.postId,
          "product-id": __props.productId,
          pendoPayload: __props.pendoPayload,
          productCommentPrivacy: __props.productCommentPrivacy,
          onAddNewComment: addNewCommentToList
        }), null, 16, ["post-id", "product-id", "pendoPayload", "productCommentPrivacy"])
      ]),
      _: 1
    }, 8, ["commentsList", "editableCommentId", "instructor"]),
    (showViewMore.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Button, {
            variant: "text",
            class: "text-neo-classic-primary-accent",
            onClick: fetchComments,
            disabled: loadingComments.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(loadingComments.value ? 'Loading more...' : 'Load more comments'), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})