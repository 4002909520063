import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full flex items-center justify-between gap-4 my-4 hover:bg-slate-50 cursor-pointer" }
const _hoisted_2 = { class: "w-3/5 min-w-36 h-28 xs:h-32 sm:h-48 md:h-60 relative border rounded-md border-gray-300 aspect-video" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "absolute inset-0 flex justify-center items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "course-tag absolute rounded-3xl py-0.5 px-2 bg-[--accent-color]",
  style: {"top":"0.5rem","right":"0.5rem"}
}
const _hoisted_7 = { class: "h-full w-3/4 flex flex-col items-start justify-around gap-2" }
const _hoisted_8 = { class: "w-full text-start flex justify-between items-center text-gray-400 font-medium text-sm gap-2" }
const _hoisted_9 = { class: "text-gray-400 font-medium text-sm sm:text-md w-2/5 text-start" }
const _hoisted_10 = {
  key: 0,
  class: "text-[--accent-color] text-xs font-semibold sm:text-md break-all w-2/5 text-end"
}
const _hoisted_11 = {
  key: 1,
  class: "text-[--accent-color] text-xs font-semibold sm:text-md break-all w-2/5 text-end"
}

import { extractTextFromHTML } from "@/helper";
import { defaultCourseThumbnail, lockedPostIcon } from "@/helper/constants";
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
  truncateText,
} from "@/helper/filter";
import { computed } from "vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'UpsellBundleV2',
  props: ["offer", "upsellId"],
  setup(__props) {

const props = __props;

const offerDescription = computed(() => {
  return truncateText(extractTextFromHTML(props.offer.description), 75)
})

const course = computed(() => {
  if (props.offer?.products?.length) {
    const product = props.offer.products[0];
    return {
      title: product.title,
      posterImage: product.posterImage,
      description: truncateText(product.description, 75),
      instructorName: product.customizations?.instructorName || "Instructor Name",
      instructorHeadshot: product.customizations?.instructorHeadshot,
    };
  } else {
    return {
      title: props.offer?.title,
      posterImage: props.offer?.posterImage,
      description: props.offer?.description && props.offer?.description,
      instructorName: "Multiple Instructors",
      instructorHeadshot: null,
    };
  }
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(replaceBucketUrlWithCdnUrl)(__props.offer?.posterImage) ||
        _unref(defaultCourseThumbnail)
        ,
        class: _normalizeClass([{
          'object-cover': !_unref(replaceBucketUrlWithCdnUrl)(__props.offer?.posterImage)
        }, "w-full h-full rounded-md object-contain brightness-75"]),
        alt: "course-thumbnail "
      }, null, 10, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: _unref(lockedPostIcon),
          alt: "lock-icon",
          class: "h-6 w-6 text-[--accent-color] object-cover"
        }, null, 8, _hoisted_5)
      ]),
      (__props.offer?.products && __props.offer?.products?.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[0] || (_cache[0] = [
            _createElementVNode("span", { class: "text-white text-xs font-medium" }, "Bundle", -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("div", { class: "text-gray-900 font-bold text-sm sm:text-lg line-clamp-2" }, _toDisplayString(_unref(truncateText)(__props.offer.title, 50)), 513), [
        [_vShow, course.value.title]
      ]),
      _withDirectives(_createElementVNode("div", { class: "line-clamp-2 sm:text-sm break-all" }, _toDisplayString(offerDescription.value), 513), [
        [_vShow, __props.offer.description]
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(props.offer.products?.length) + " Products", 1),
        (__props.offer.priceDisplayOverride)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(truncateText)(__props.offer.priceDisplayOverride)), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_unref(fetchCurrencySymbol)(__props.offer.currency)) + " " + _toDisplayString(__props.offer.amount), 1))
      ])
    ])
  ]))
}
}

})