<template>
  <div
    id="product-progress-stats"
    class="bg-white rounded py-5 lg:py-10 px-5 text-base lg:text-xl"
    :class="[
      $route.name.includes('product-overview') ? 'lesson-card' : '',
      newMobileScreens ? 'border' : 'shadow',
    ]"
  >
    <span id="completion-count" class="font-semibold">{{ statMessage }}</span>
    <Progress
      class="mt-2 h-3 lg:h-4"
      :percent-completed="percentCompleted"
      :show-percentage="percentCompleted > 5"
      :progress-color="progressColor"
    />
    <!-- <div class="shadow w-full bg-grey-light">
      <div
        class="mt-2 bg-blue-300 text-xs leading-none py-1 text-center text-white"
        :style="`width: ${this.percentCompleted}%`"
      >{{ percentCompleted }}%</div>
    </div> -->
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Progress from '@/components/common/Progress.vue'

export default defineComponent({
  components: {
    Progress,
  },
  props: {
    completed: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    progressColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    percentCompleted(): number {
      if (this.completed && this.total) {
        return Math.round((this.completed * 100) / this.total)
      }

      return 0
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    statMessage() {
      if (this.total === this.completed) {
        return `All ${this.total} Lessons are completed.`
      } else {
        return `${this.completed} of ${this.total} Lessons Completed`
      }
    },
  },
})
</script>
