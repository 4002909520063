import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "w-11/12 flex flex-col items-start mx-auto my-4" }
const _hoisted_3 = { class: "w-full flex items-center justify-between gap-2" }
const _hoisted_4 = { class: "w-full flex items-center justify-start gap-2 mx-auto my-2" }
const _hoisted_5 = { class: "w-11/12 mx-auto bg-white rounded-xl overflow-hidden" }
const _hoisted_6 = { class: "flex items-center justify-between cursor-pointer m-0 p-4 bg-gray-50 text-gray-800" }
const _hoisted_7 = { class: "w-11/12 flex flex-col gap-2" }

import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategorySyllabusShimmerV2',
  setup(__props) {

const store = useStore();

const { newMobileScreens } = {
    newMobileScreens: store.getters.newMobileScreens,
};

return (_ctx: any,_cache: any) => {
  return (_unref(newMobileScreens))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "24px",
              width: "25%"
            }),
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "16px",
              width: "33.33%"
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "16px",
              width: "20%"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-[--accent-color] font-bold rounded w-1 h-1" }, null, -1)),
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "16px",
              width: "20%"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-[--accent-color] font-bold rounded w-1 h-1" }, null, -1)),
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "16px",
              width: "20%"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
            return _createElementVNode("div", {
              key: n,
              class: "accordion-item rounded-md mt-2"
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(UISkeleton), {
                    sharp: false,
                    height: "20px",
                    width: "50%",
                    class: "my-3"
                  }),
                  _createVNode(_unref(UISkeleton), {
                    sharp: false,
                    height: "16px",
                    width: "25%"
                  })
                ]),
                _createVNode(_unref(UISkeleton), {
                  sharp: false,
                  height: "20px",
                  width: "20px",
                  circle: ""
                })
              ])
            ])
          }), 64))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})