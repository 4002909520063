<script lang="ts" setup>
import UISpinner from "@/components/common/UISpinner.vue";
import VideoPlayer from "@/components/product/VideoPlayer.vue";
import { findNextPostId, findPreviousPostId, renderFileTypeIcon, sanitizeJavaScriptCode } from "@/helper";
import { imageIcon, newDocLogo, newPdfLogo } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import { CategoryTreeNodeType } from "@/models/CategoryTreeNode";
import { PostContentType } from "@/models/Post";
import { PostMaterialType } from "@/models/PostMaterial";
import { ChevronLeftIcon, ChevronRightIcon } from "@gohighlevel/ghl-icons/24/outline";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { generateToken } from "../../../http-common";
import AudioPlayer from "../product/AudioPlayer.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const device = computed(() => store.getters.device);

const {
    product,
    activePost,
    category,
    categoryTree,
    videoOptions,
    fetchingPost,
    playlist,
    quiz,
    postCompletionButtonText,
    postMarkedAsCompleted,
    disablePostCompletionButton,
    totalPosts,
    fetchingPostCompletionStatus,
    lessonSequenceMap
} = defineProps([
    "quiz",
    "product",
    "instructor",
    "activePost",
    "category",
    "videoOptions",
    "videoCompletePercentage",
    "playlist",
    "lessonButton",
    "isAdmin",
    "categoryTree",
    "nextLesson",
    "disableActions",
    "fetchingPost",
    "nextCategoryId",
    "completed",
    "playlist",
    "post",
    "postCompletionButtonText",
    "postMarkedAsCompleted",
    "disablePostCompletionButton",
    "totalPosts",
    "fetchingPostCompletionStatus",
    "lessonSequenceMap"
]);


const emit = defineEmits([
    "togglePostCompletion",
    "updateBackData",
    "storeVideoProgress",
    "updateVideoTime",
    "updateVideoPlay",
    "updateVideoTime",
    "updateAudioPlay",
    "storeAudioProgress",
    "updateAudioTime"
]);

const togglePostCompletion = () => {
    if (disablePostCompletionButton) return;
    emit("togglePostCompletion");
};

const token = ref<string>();
const isExpanded = ref(false);
const nextPost = ref<any>(null);
const previousPost = ref<any>(null);

const mobilePlayerRef = ref<HTMLElement | null>(null);
const mobilePlayerHeight = ref<number | null>(null);
const windowHeight = ref(window.innerHeight);

const MAX_DESCRIPTION_CHARACTERS = 200;

const availableCategoryTree = computed(() => {
    return categoryTree.filter((node) => node.node.available);
});

const availablePlayList = computed(() => {
    return playlist.filter(
        (node) =>
            node.type === CategoryTreeNodeType.Post ||
            (node.type === CategoryTreeNodeType.Category && node.node.available)
    );
});

const isCurrentPostAssignment = computed(() => {
    return activePost.contentType === PostContentType.assignment;
});

const needsViewMore = computed(() => {
    const maxLength = (activePost?.video || activePost?.audio) ? MAX_DESCRIPTION_CHARACTERS : 1800;

    return (
        activePost?.description && sanitizeJavaScriptCode(activePost.description).length > maxLength
    );
});

/**
 * This computed property determines the class applied to the description based on whether the description is expanded or not.
 * It uses the `isExpanded` and `needsViewMore` computed properties to decide the class.
 * 
 * - If the description is not expanded and needs to be viewed more, and the post has a video or audio, it applies `!max-h-20 line-clamp-3 overflow-y-hidden`.
 * - If the description is not expanded and needs to be viewed more, and the post does not have a video or audio, it applies `line-clamp-20`.
 * 
 * This ensures that the description is displayed with the appropriate number of lines based on the post's media presence.
 */
const getDescriptionClass = computed(() => {
    return {
        "!max-h-20 line-clamp-3 overflow-y-hidden": !isExpanded.value && needsViewMore.value && (activePost?.video || activePost?.audio),
        "!max-h-96 line-clamp-20 overflow-y-hidden": !isExpanded.value && needsViewMore.value && !(activePost?.video || activePost?.audio),
    };
});

const toggleView = () => {
    isExpanded.value = !isExpanded.value;
};

function updateVideoPlay() {
    emit('updateVideoPlay')
}
function storeVideoProgress() {
    emit('storeVideoProgress')
}
function getVideoTime(currentTime: Array<number>) {
    emit('updateVideoTime', currentTime)
}



function getPreviousPostData(postId) {
    previousPost.value = findPreviousPostId(
        availableCategoryTree.value,
        postId,
        category,
        availablePlayList.value
    );
}
function getNextPostData(postId) {
    nextPost.value = findNextPostId(
        availableCategoryTree.value,
        postId,
        category,
        availablePlayList.value
    );
}



function handleNextPostClick() {
    if (nextPost.value?.postId) {
        if (nextPost.value?.contentType === PostContentType.assignment) {
            router.push({
                name: "mobile-assignment",
                params: {
                    id: product.id,
                    post_id: nextPost.value.postId,
                    category_id: nextPost.value.categoryId,
                },
                query: {},
            });
        } else if (nextPost.value?.contentType === PostContentType.quiz) {
            router.push({
                name: "mobile-quiz-overview",
                params: {
                    id: product.id,
                    post_id: nextPost.value.postId,
                    category_id: nextPost.value.categoryId,
                },
            });
        } else {
            router.push({
                name: "post-overview",
                params: {
                    id: product.id,  // Consistent parameter naming
                    category_id: nextPost.value.categoryId || category.id,
                    post_id: nextPost.value.postId,
                },
            });
        }
    }
}


function navigateToPost(post, product, category) {
    // Determine the route name based on the content type
    let routeName;

    if (post.value?.contentType === PostContentType.assignment) {
        routeName = "mobile-assignment";
    } else if (post.value?.contentType === PostContentType.quiz) {
        routeName = "mobile-quiz-overview";
    } else {
        routeName = "post-overview";
    }

    // Construct the route parameters
    const routeParams = {
        id: product.id,
        post_id: post.value.postId,
        category_id: post.value.categoryId || category.id,
    };

    // Navigate to the determined route
    router.push({
        name: routeName,
        params: routeParams,
        query: routeName === "mobile-assignment" ? {} : undefined,  // Include query only for "mobile-assignment"
    });
}

function handlePreviousPostClick() {
    if (previousPost.value?.postId) {
        navigateToPost(previousPost, product, category)
    }
}

function startAssessment(query) {
    if (disablePostCompletionButton) return;
    if (activePost.contentType === PostContentType.assignment) {
        router.push({
            name: "mobile-assignment-overview",
            params: {
                id: product.id,
                post_id: activePost.id,
                category_id: category.id,
            },
            query,
        });
    } else {
        router.push({
            name: "mobile-quiz-questions-levelup",
            params: {
                id: product.id,
                post_id: activePost.id,
                category_id: category.id,
                quiz_id: quiz.id,
            },
        });
    }
}

const calculatePostContainerHeight = () => {
    //?? Mobile player height - header height from total real estate will be container height
    return `calc(100dvh - 100px - ${mobilePlayerHeight.value}px)`;
};


// Function to get the height of the element
const getMobilePlayerHeight = () => {
    if (mobilePlayerRef.value) {
        mobilePlayerHeight.value = mobilePlayerRef.value.offsetHeight;
    }
};

const updateWindowHeight = () => {
    windowHeight.value = window.innerHeight;
    getMobilePlayerHeight();
};

const routeToRespectiveLesson = () => {
    if (activePost.contentType === PostContentType.assignment) {
        router.push({
            name: "mobile-assignment",
            params: {
                id: product.id,
                post_id: activePost.id,
                category_id: category.id,
            },
        });
    } else if (activePost.contentType === PostContentType.quiz) {
        router.push({
            name: "mobile-quiz-overview",
            params: {
                id: product.id,
                post_id: activePost.id,
                category_id: category.id,
                quiz_id: quiz.id,
            },
        });
    }
}

function updateAudioPlay() {
    emit('updateAudioPlay')
}
function storeAudioProgress() {
    emit('storeAudioProgress')
}
function getAudioTime(currentTime: Array<number>) {
    emit('updateAudioTime', currentTime)
}

onBeforeUnmount(() => {
    window.removeEventListener("resize", updateWindowHeight);
});

watch(windowHeight, () => {
    getMobilePlayerHeight();
});

watch(
    () => route.params,
    async (newParams) => {
        getNextPostData(newParams.post_id), getPreviousPostData(newParams.post_id);
        token.value = await generateToken();
    }
);


onMounted(async () => {
    getMobilePlayerHeight();
    getNextPostData(route.params.post_id);
    getPreviousPostData(route.params.post_id);
    token.value = await generateToken();
    getMobilePlayerHeight();
    window.addEventListener("resize", updateWindowHeight);
    if (route.query?.from_search === 'true') {
        routeToRespectiveLesson()
    }
});


</script>

<template>
    <div v-if="device === 'mobile'">
        <div class="w-full">
            <div class="post-container w-dvw ">
                <div v-if="activePost?.video || activePost?.audio" id="mobile-player" class="mt-4 mx-auto rounded-md"
                    :style="{ width: '95%' }" ref="mobilePlayerRef">
                    <VideoPlayer v-if="activePost?.video && videoOptions" id="post-video"
                        class="h-full w-full rounded-md" :loading="fetchingPost"
                        :video="activePost && activePost?.video" :options="videoOptions"
                        :video-thumbnail="activePost?.posterImage" :trackTime="true"
                        :videoCompletePercentage="videoCompletePercentage" @getVideoTime="getVideoTime"
                        @onPause="storeVideoProgress" @onEnded="storeVideoProgress" @onPlay="updateVideoPlay" />
                    <AudioPlayer v-else-if="activePost.audio" id="post-audio" :audio="activePost.audio"
                        :thumbnail="activePost.posterImage" :post="activePost" :product="product"
                        @getAudioTime="getAudioTime" @onPause="storeAudioProgress" @onEnded="storeAudioProgress"
                        @onPlay="updateAudioPlay" class='rounded-md' />
                </div>
                <div class="flex flex-col items-center justify-between overflow-y-scroll relative pb-4"
                    :style="{ height: calculatePostContainerHeight() }">

                    <div class="w-11/12 mx-auto my-4 flex flex-col items-center justify-start flex-grow gap-2">
                        <div class="w-full py-4 mx-auto flex items-center justify-between ">
                            <div class="flex flex-col items-start">
                                <span class="text-gray-400 text-xs">Lesson {{ lessonSequenceMap.get(activePost?.id) ?? 0
                                    }} of {{
                                        totalPosts }}
                                </span>
                                <span class="text-gray-900 text-base font-semibold line-clamp-2">{{
                                    activePost?.title
                                    }}</span>
                            </div>
                            <div class="flex items-center gap-2">
                                <div class="min-w-8 max-w-8 h-8 rounded-md border p-1 transition-all duration-200 ease-in-out clickable"
                                    :class="[
                                        previousPost ? 'bg-gray-100' : 'bg-gray-300 cursor-not-allowed',
                                        previousPost ? 'hover:bg-gray-300 active:scale-95' : '',
                                    ]" @click="handlePreviousPostClick">
                                    <ChevronLeftIcon class="text-[#667085] w-full h-full" />
                                </div>
                                <div class="min-w-8 max-w-8 h-8 rounded-md border p-1 transition-all duration-150 ease-in-out clickable"
                                    :class="[
                                        nextPost ? 'bg-gray-100' : 'bg-gray-300 cursor-not-allowed',
                                        nextPost ? 'hover:bg-gray-300 active:scale-95' : '',
                                    ]" @click="handleNextPostClick">
                                    <ChevronRightIcon class="text-[#667085] w-full h-full" />
                                </div>
                            </div>
                        </div>
                        <div v-if="activePost?.description" class="w-full mt-4 mb-8 mx-auto  inline">
                            <div class="post-description mobile-post-details w-full overflow-x-scroll font-normal"
                                :class="getDescriptionClass" v-html="activePost?.description"></div>
                            <span v-if="needsViewMore && !isExpanded" @click="toggleView"
                                class="text-blue-500 font-semibold text-sm cursor-pointer clickable">
                                View More
                            </span>
                            <span v-if="needsViewMore && isExpanded" @click="toggleView"
                                class="text-blue-500 font-semibold text-sm cursor-pointer clickable">
                                View Less
                            </span>
                        </div>
                        <div class="w-full my-4 mx-auto flex items-center gap-1 overflow-x-scroll min-h-32"
                            :style="{ 'overflow-wrap': 'anywhere' }" v-if="activePost?.materials?.length">
                            <div v-for="material in activePost?.materials" :key="material.id"
                                class="min-w-24 w-24 h-24 cursor-pointer flex-shrink-0">
                                <a class="text-blue-500 flex flex-col items-center post-material-link " target="_blank"
                                    :href="replaceBucketUrlWithCdnUrl(material?.url)">
                                    <img class="w-12 h-16 rounded-md" :src="renderFileTypeIcon(material.title)" />

                                    <span class="text-gray-900 text-xs mt-2 text-center line-clamp-2">{{ material?.title
                                        }}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="w-11/12 my-2 mx-auto" v-if="activePost?.contentType === PostContentType.quiz ||
                        activePost?.contentType === PostContentType.assignment
                    ">
                        <img data-v-b7d9666a="" class="p-10 w-full"
                            src="https://storage.googleapis.com/revex-membership-production/assets/assignment_banner.svg"
                            alt="quiz-start" />
                    </div>
                    <div class="w-11/12  mx-auto fixed bottom-0 py-4 bg-white">
                        <div v-if="
                            activePost?.contentType === PostContentType.quiz ||
                            activePost?.contentType === PostContentType.assignment
                        " id="start-assessment"
                            class="w-full rounded-lg flex items-center justify-center clickable py-0 px-4 text-sm font-semibold h-9 cursor-pointer"
                            :style="{
                                backgroundColor: 'var(--primary-color) !important',
                                color: 'white',
                            }" @click="startAssessment">
                            <span>
                                Start {{ isCurrentPostAssignment ? "Assignment" : "Quiz" }}
                            </span>
                        </div>

                        <div v-else id="mark-as-complete"
                            class="w-full rounded-lg flex items-center justify-center clickable py-0 px-4 text-sm font-semibold h-9 cursor-pointer border border-black"
                            :style="{
                                backgroundColor: postMarkedAsCompleted
                                    ? 'var(--primary-color)'
                                    : 'white',
                                color: postMarkedAsCompleted ? 'white' : 'black',
                            }" @click="togglePostCompletion">
                            <UISpinner class="min-w-6 h-6" v-if="fetchingPostCompletionStatus" />
                            <span v-else>
                                {{ postCompletionButtonText }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.post-container {
    height: calc(100dvh - 14vh);
}

.post-description {
    max-height: 10000px;

    height: auto !important;
}

.post-description li {
    white-space: normal !important;
}

.line-clamp-20 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 20;
}
</style>

<style>
/* Border radius for video player */
@media (max-width: 1024px) {
    .plyr--full-ui {
        border-radius: 0.375rem !important;
    }
}
</style>
