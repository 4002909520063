import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { UISkeleton } from '@gohighlevel/ghl-ui'

export default /*@__PURE__*/_defineComponent({
  __name: 'ImageLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UISkeleton), { class: "w-full aspect-video rounded-lg h-full" }))
}
}

})