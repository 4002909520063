import Model from './Model'

export default class Quiz extends Model {
  constructor(payload?: {
    id?: string
    title?: string
    postId?: string
    locationId?: string
    createdAt?: Date
    requiredPassingGrade?: boolean
    passingGrade?: number
    passMessage?: string
    failMessage?: string
    questions?: any[]
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }
  set title(title: string) {
    this._data.title = title
  }

  get questions(): any[] {
    return this._data.questions
  }
  set questions(questions: any[]) {
    this._data.questions = questions
  }

  get passingGrade(): number {
    return this._data.passingGrade
  }
  set passingGrade(passingGrade: number) {
    this._data.passingGrade = passingGrade
  }

  get postId(): string {
    return this._data.postId
  }
  set postId(postId: string) {
    this._data.postId = postId
  }

  get locationId(): string {
    return this._data.locationId
  }
  set locationId(locationId: string) {
    this._data.locationId = locationId
  }

  get createdAt(): string {
    return this._data.createdAt
  }

  get requiredPassingGrade(): boolean {
    return this._data.requiredPassingGrade
  }
  set requiredPassingGrade(requiredPassingGrade: boolean) {
    this._data.requiredPassingGrade = requiredPassingGrade
  }

  get passMessage(): string {
    return this._data.passMessage
  }
  set passMessage(passMessage: string) {
    this._data.passMessage = passMessage
  }

  get failMessage(): string {
    return this._data.failMessage
  }
  set failMessage(failMessage: string) {
    this._data.failMessage = failMessage
  }
}
