import 'firebase/auth'

import axios, { InternalAxiosRequestConfig } from 'axios'

import config from './src/config'
import firebase from 'firebase'

const axiosCreate = axios.create({
  baseURL: config.newBaseUrl,
  headers: {
    channel: 'APP',
    source: 'PORTAL_USER',
    version: '2021-07-28',
    'Content-type': 'application/json',
  },
})

declare global {
  interface Window {
    $cookies: any
  }
}

export async function generateToken() {
  const cat =
    window.$cookies.get('cat') ||
    window.localStorage.getItem('cat') ||
    window.localStorage.getItem('acat') ||
    window.$cookies.get('acat')

  const firebaseToken = cat ? JSON.parse(atob(cat)) : null
  if (firebaseToken && !firebase.auth().currentUser) {
    await firebase
      .auth()
      .signInWithCustomToken(firebaseToken.token)
      .catch(function (error) {
        console.log(error)
      })
  }

  const keyToken = await firebase.auth().currentUser?.getIdToken()
  return keyToken
}

axiosCreate.interceptors.request.use(
  async (requestConfig: InternalAxiosRequestConfig) => {
    try {
      const keyToken = await generateToken()
      requestConfig.headers.authorization = 'Bearer ' + keyToken
    } catch (e) {
      console.log(e)
    }
    return requestConfig
  }
)
export default axiosCreate
