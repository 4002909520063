import http from '../../http-common'

class LocationService {
  protected subdomain = 'feature-flags'

  getFeatureFlags(locationId: string) {
    return http.get(`/locations/${locationId}/${this.subdomain}`)
  }
}

export default new LocationService()
