import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "font-sans" }
const _hoisted_2 = {
  id: "user-library",
  class: "w-full h-screen"
}
const _hoisted_3 = { class: "bg-gray-50 flex flex-col overflow-y-auto min-h-screen" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex items-center justify-center courses-font-family font-semibold mt-20"
}
const _hoisted_5 = {
  key: 1,
  id: "library-container",
  class: "w-11/12 flex-1 flex flex-col xl:w-11/12 mx-auto mt-4 content-fix-width"
}
const _hoisted_6 = {
  key: 0,
  id: "library-title",
  class: "pb-2 w-fit text-left relative border-b border-slate-200 bg-transparent",
  style: {"background-color":"transparent !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_UIEmpty = _resolveComponent("UIEmpty")!
  const _component_LibraryShimmer = _resolveComponent("LibraryShimmer")!
  const _component_NeoUpsellBundle = _resolveComponent("NeoUpsellBundle")!
  const _component_NeoProductCard = _resolveComponent("NeoProductCard")!
  const _component_UniversalSearch = _resolveComponent("UniversalSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Navigation, {
          onChangeActive: _ctx.changeActive,
          coursesTypes: _ctx.coursesTypes,
          active: _ctx.active,
          class: "mb-5"
        }, null, 8, ["onChangeActive", "coursesTypes", "active"]),
        (_ctx.isPortalActive && !_ctx.fetching && !_ctx.allCourses.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_UIEmpty, {
                id: "empty-product-message",
                title: "No courses found",
                description: "You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey."
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (!_ctx.isPortalActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["font-semibold text-md p-2 cursor-pointer", 
                _ctx.active === _ctx.coursesTypes.allCourses
                  ? ' text-blue-600 '
                  : 'text-slate-600 '
              ]),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeActive(_ctx.coursesTypes.allCourses)))
                    }, [
                      _cache[4] || (_cache[4] = _createTextVNode("All Courses ")),
                      (_ctx.fetching)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(["px-2 py-1 rounded font-medium animate-pulse", 
                  _ctx.active === _ctx.coursesTypes.allCourses
                    ? '  bg-blue-100'
                    : ' bg-slate-200'
                ])
                          }, _cache[3] || (_cache[3] = [
                            _createElementVNode("span", { class: "" }, "00", -1)
                          ]), 2))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(["px-2 py-1 rounded font-medium", 
                  _ctx.active === _ctx.coursesTypes.allCourses
                    ? ' text-blue-600 bg-blue-100'
                    : 'text-slate-600 bg-slate-200'
                ])
                          }, _toDisplayString(_ctx.allCourses.length), 3))
                    ], 2),
                    _createElementVNode("span", {
                      class: _normalizeClass(["font-semibold text-md p-2 cursor-pointer ml-4", 
                _ctx.active === _ctx.coursesTypes.myCourses
                  ? ' text-blue-600'
                  : 'text-slate-600'
              ]),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeActive(_ctx.coursesTypes.myCourses)))
                    }, [
                      _cache[6] || (_cache[6] = _createTextVNode("My Courses ")),
                      (_ctx.fetching)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(["px-2 py-1 rounded font-medium animate-pulse", 
                  _ctx.active === _ctx.coursesTypes.allCourses
                    ? '  bg-blue-100'
                    : ' bg-slate-200'
                ])
                          }, _cache[5] || (_cache[5] = [
                            _createElementVNode("span", { class: "" }, "00", -1)
                          ]), 2))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass(["px-2 py-1 rounded font-medium", 
                  _ctx.active === _ctx.coursesTypes.myCourses
                    ? ' text-blue-600 bg-blue-100'
                    : 'text-slate-600 bg-slate-200'
                ])
                          }, _toDisplayString(_ctx.products.length), 3))
                    ], 2),
                    _createElementVNode("span", {
                      class: "absolute bottom-0 h-0.5 left-0 bg-blue-600 transition-all duration-300",
                      style: _normalizeStyle({
                width: _ctx.activeTabWidth + 'px',
                transform: 'translateX(' + _ctx.activeTabLeft + 'px)',
              })
                    }, null, 4)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.fetching)
                ? (_openBlock(), _createBlock(_component_LibraryShimmer, {
                    key: 1,
                    class: "pt-20"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_ctx.active === _ctx.coursesTypes.allCourses && !_ctx.fetching)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 'all-courses',
                        id: "product-list",
                        class: _normalizeClass(["my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8", [_ctx.isPortalActive ? 'pt-20' : '']])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCourses, (course) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: course.id
                          }, [
                            (course.upsellType && course.offerUpSell)
                              ? (_openBlock(), _createBlock(_component_NeoUpsellBundle, {
                                  key: 0,
                                  class: "h-[32rem] cursor-pointer",
                                  offer: course.offerUpSell,
                                  upsellId: course.id,
                                  purchasedProductIds: _ctx.purchasedProductIds
                                }, null, 8, ["offer", "upsellId", "purchasedProductIds"]))
                              : (_openBlock(), _createBlock(_component_NeoProductCard, {
                                  key: 1,
                                  class: "h-[32rem] cursor-pointer",
                                  "product-id": course.id,
                                  "product-title": course.title,
                                  "poster-image": course.posterImage,
                                  "product-description": course.description,
                                  "instructor-name": 
                    course.customizations &&
                    course.customizations.instructorName
                  ,
                                  "instructor-headshot": 
                    course.customizations &&
                    course.customizations.instructorHeadshot
                  ,
                                  "show-in-library": true,
                                  label: "in-library",
                                  progress: _ctx.getCourseProgress(course),
                                  onHandleClick: ($event: any) => (_ctx.openProduct(course.id))
                                }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "progress", "onHandleClick"]))
                          ], 64))
                        }), 128))
                      ], 2))
                    : (_ctx.active === _ctx.coursesTypes.myCourses && !_ctx.fetching)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 'my-courses',
                          id: "product-list",
                          class: _normalizeClass(["my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8", [_ctx.isPortalActive ? 'pt-20' : '']])
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                            return (_openBlock(), _createBlock(_component_NeoProductCard, {
                              key: product.id,
                              class: "h-[32rem]",
                              "product-id": product.id,
                              "product-title": product.title,
                              "poster-image": product.posterImage,
                              "product-description": product.description,
                              "instructor-name": 
                  product.customizations &&
                  product.customizations.instructorName
                ,
                              "instructor-headshot": 
                  product.customizations &&
                  product.customizations.instructorHeadshot
                ,
                              progress: _ctx.getCourseProgress(product),
                              onHandleClick: ($event: any) => (_ctx.openProduct(product.id))
                            }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "progress", "onHandleClick"]))
                          }), 128))
                        ], 2))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
      ]),
      _createVNode(_component_UniversalSearch, {
        "show-modal": _ctx.showSearchModal,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSearchModal = false))
      }, null, 8, ["show-modal"])
    ])
  ]))
}