<template>
  <div
    class="block px-10 py-10 grid grid-cols-1 gap-3 lg:grid lg:grid-cols-3 lg:gap-4"
  >
    <div
      class="w-full flex flex-col items-center lg:w-full rounded mb-3 md:mb-0"
      v-for="i of 1"
      :key="i"
    >
      <shimmer class="w-full mb-2 h-32 rounded-lg" />
      <shimmer class="w-1/2 h-5 rounded-lg" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Shimmer from './Shimmer.vue'
export default defineComponent({
  components: {
    Shimmer,
  },
})
</script>
