import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white cursor-pointer" }
const _hoisted_2 = { class: "flex text-sm pb-5" }
const _hoisted_3 = { class: "text-gray-500 text-sm p-1 flex-1" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "transform rotate-90 inline-block mr-1"
}
const _hoisted_7 = {
  key: 1,
  class: "mr-1 pt-px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_editor, {
        editorText: _ctx.replyText,
        "onUpdate:editorText": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.replyText) = $event)),
        ref: "editorRef"
      }, null, 8, ["editorText"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.replyTextLength) + " / " + _toDisplayString(_ctx.commentCharacterLength), 1),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeReplyModal && _ctx.closeReplyModal(...args))),
        disabled: _ctx.posting,
        class: _normalizeClass(["border rounded ml-2 p-1 cursor-pointer w-20 inline-block text-center justify-center bg-gray-100", _ctx.posting ? 'text-gray-500' : 'text-gray-700'])
      }, " Cancel ", 10, _hoisted_4),
      _createElementVNode("button", {
        disabled: _ctx.postButtonDisabled || _ctx.posting,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.replytoComment && _ctx.replytoComment(...args))),
        class: _normalizeClass(["flex items-center border rounded ml-2 p-1 cursor-pointer w-20 text-center justify-center", 
          _ctx.postButtonDisabled || _ctx.posting
            ? 'bg-gray-100 text-gray-500'
            : 'bg-blue-100 text-blue-500'
        ])
      }, [
        (!_ctx.posting)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_BaseIcon, { name: "paperairplane" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_UISpinner, { size: "extra-small" })
            ])),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "pl-1" }, "Reply", -1))
      ], 10, _hoisted_5)
    ])
  ]))
}