<template>
  <div id="product-breadcrumbs" v-if="productTitle">
    <router-link
      :to="{
        name: 'product-overview',
        params: { id: productId },
        query: { template_id: templateId },
      }"
      exact
      active-class="route-link-active inline-block"
      :class="isPostRoute"
    >
      <span v-if="showIcon" class="px-2" :style="{ color: goBackIconColor }">
        <BaseIcon name="previousarrow" />
      </span>
      {{ productTitle }}</router-link
    >
    <span :class="isPostRoute" v-if="categoryId">
      /
      <router-link
        :to="{ name: 'category-list', params: { id: productId } }"
        exact
        active-class="route-link-active"
        :class="isPostRoute"
        >Categories</router-link
      >
    </span>
    <span v-if="categoryId" :class="isPostRoute">
      /
      <span v-if="!categoryTitle">...</span>
      <router-link
        v-else
        :to="{
          name: 'category-overview',
          params: { id: productId, category_id: categoryId },
        }"
        exact
        active-class="route-link-active"
        :class="isPostRoute"
        >{{ categoryTitle }}</router-link
      >
    </span>
    <span v-if="postId" :class="isPostRoute">
      /
      <span v-if="!postTitle" :class="isPostRoute">...</span>
      <router-link
        v-else
        :to="{
          name: 'post-overview',
          params: { id: productId, category_id: categoryId, post_id: postId },
        }"
        exact
        active-class="route-link-active"
        :class="isPostRoute"
        >{{ postTitle }}</router-link
      >
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    productId: String,
    productTitle: String,
    categoryId: String,
    categoryTitle: String,
    postId: String,
    postTitle: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
    goBackIconColor: {
      type: String,
      default: '#111827',
    },
  },
  computed: {
    isPostRoute(): string {
      if (this.$route.name.includes('post-overview')) {
        return 'lessonPrimaryStyle breadCrumb__nav'
      }
      return ''
    },
    templateId(): string {
      return this.$route.query.template_id
    },
  },
})
</script>
<style scoped>
a:hover {
  text-decoration: underline;
}

span:last-child a:hover {
  text-decoration: inherit;
}
.route-link-active {
  color: #4a5568;
  font-weight: bold;
  cursor: default;
  text-decoration: none;
}
</style>
