import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full text-sm text-gray-400 font-medium"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.productTitle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: {
        name: 'library-v2',
      },
          exact: "",
          class: "text-md text-gray-400 font-semibold no-underline hover:no-underline hover:text-gray-600",
          "active-class": "route-link-active inline-block"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Products / ")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: {
        name: 'product-overview',
        params: { id: _ctx.productId },
        query: { template_id: _ctx.templateId },
      },
          exact: "",
          class: "text-md text-gray-400 font-semibold no-underline hover:no-underline",
          "active-class": "route-link-active inline-block"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.productTitle), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        (_ctx.postId)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _cache[1] || (_cache[1] = _createTextVNode(" / ")),
              (!_ctx.postTitle)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "..."))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: {
          name: 'post-overview',
          params: { id: _ctx.productId, category_id: _ctx.categoryId, post_id: _ctx.postId },
        },
                    exact: "",
                    class: "text-md text-blue-600 font-semibold no-underline hover:no-underline",
                    "active-class": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.postTitle), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}