<template>
  <div
    class="desktop-pwa-modal z-20 fixed top-0 left-0 w-full h-full transition transform duration-50 ease-in-out"
    :class="open ? 'translate-y-0' : '-translate-y-full'"
  >
    <div
      @click="close"
      class="relative w-full h-full cursor-pointer transition-opacity delay-50 duration-300 ease-in-out bg-black-o-55"
      :class="open ? 'opacity-100' : 'opacity-0'"
    >
      <div
        @click.stop=""
        class="absolute p-4 rounded-lg top-4 left-1/2 transform -translate-x-1/2 bg-white cursor-default max-w-sm w-full transition transform duration-500 ease-in-out"
        :class="open ? 'translate-y-0' : '-translate-y-full'"
      >
        <div class="pa-modal-title flex items-center justify-between w-full">
          <div class="pa-title font-semibold">
            {{ modalTitle }}
          </div>
          <button class="flex items-center" @click="close">
            <BaseIcon name="cross" hwClass="w-3" />
          </button>
        </div>
        <div class="pa-modal-body mt-2">
          <p class="my-4 text-center text-gray-600">
            To install this app in your Desktop, open this link in Chrome
          </p>
          <div
            class="p-1 pl-2 bg-gray-200 max-w-sm w-full rounded-lg flex items-center justify-between"
          >
            <span class="select-all break-all pr-1">{{ currentUrl }}</span>
            <button
              class="bg-gray-400 hover:bg-gray-500 p-1 rounded-lg copy-link-btn relative flex items-center"
              @click="copyLinkToClipboard"
            >
              <BaseIcon name="copy" hwClass="h-4 w-4" />
              <div
                v-if="isCopied"
                class="hidden absolute copy-tooltip bg-gray-800 text-white rounded px-2 text-xs"
              >
                Copied!
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    open: Boolean,
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    pwaAppSettings(): any {
      return this.$store.getters.pwaAppSettings
    },
    modalTitle(): string {
      return this.pwaAppSettings
        ? `Install ${this.pwaAppSettings.name}`
        : 'PWA Alert'
    },
    currentUrl(): string {
      return window.location.href
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.isCopied = false
    },
    copyLinkToClipboard() {
      window.navigator.clipboard.writeText(window.location.href)
      this.isCopied = true
    },
  },
})
</script>

<style scoped>
.copy-link-btn:hover .copy-tooltip {
  display: block;
}
.copy-tooltip {
  top: -1.5rem;
  right: -1rem;
}
</style>
