import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-dvw flex flex-col" }
const _hoisted_2 = {
  id: "mobile-player",
  class: "my-4 mx-auto rounded-md w-11/12",
  style: { height: '30vh' }
}
const _hoisted_3 = { class: "w-11/12 mx-auto my-4 flex flex-col items-center justify-start flex-grow overflow-y-scroll py-4" }
const _hoisted_4 = { class: "w-full my-4 mx-auto flex items-center justify-between" }
const _hoisted_5 = { class: "flex flex-col items-start" }
const _hoisted_6 = { class: "flex items-center gap-2" }
const _hoisted_7 = { class: "w-full my-4 mx-auto flex flex-col gap-2 items-start" }
const _hoisted_8 = { class: "w-full my-4 mx-auto flex items-center gap-2 overflow-x-scroll min-h-32" }
const _hoisted_9 = { class: "w-full flex py-4 mx-auto absolute bottom-0 bg-white" }

import { UISkeleton } from "@gohighlevel/ghl-ui";

export default /*@__PURE__*/_defineComponent({
  __name: 'PostDetailsV2Shimmer',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(UISkeleton), {
        sharp: false,
        width: "100%",
        height: "100%",
        class: "rounded-md"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "8rem"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "24px",
            width: "12rem",
            class: "my-2"
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "32px",
            width: "32px"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "32px",
            width: "32px"
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "100%"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "100%"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "40%"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "40%"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "30%"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "12px",
          width: "20%"
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        }),
        _createVNode(_unref(UISkeleton), {
          sharp: false,
          height: "96px",
          width: "7rem",
          class: "shrink-0"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_unref(UISkeleton), {
        height: "40px",
        class: "w-11/12 mx-auto",
        sharp: true
      })
    ])
  ]))
}
}

})