import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center items-center w-full p-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "mt-4 w-full flex items-center justify-center" }

import { bigLockIcon } from '@/helper/constants'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { CategoryTreeNodeType } from '@/models/CategoryTreeNode'
import { ArrowNarrowRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import { onMounted, inject, ref, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Post from '@/models/Post'


export default /*@__PURE__*/_defineComponent({
  __name: 'LockedContent',
  props: {
  contentType: {
    type: String,
    default: CategoryTreeNodeType.Category,
  },
  lockedByPostId: {
    type: String,
    default: '',
  },
  lockedByCategoryId: {
    type: String,
    default: '',
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
},
  emits: ['highlightThisElement'],
  setup(__props, { emit: __emit }) {

const props = __props

const categoriesAndPostsLookup = inject<Ref<Map<string, any>>>(
  'categoriesAndPostsLookup'
)
const route = useRoute()
const router = useRouter()

const emit = __emit

const lockedByPostData = ref<any>(null)
const lockedByCategoryData = ref<any>(null)

function updateLockedData() {
  if (props.lockedByPostId) {
    lockedByPostData.value = getLockedContentData(props.lockedByPostId)
  }

  if (props.lockedByCategoryId) {
    lockedByCategoryData.value = getLockedContentData(props.lockedByCategoryId)
  }
}

onMounted(() => {
  // Need to do this dirty update because vue doesn't really support reactivity for map
  setTimeout(() => {
    updateLockedData()
  }, 100)
})

function getLockedContentData(contentId: string) {
  const data = categoriesAndPostsLookup.value.get(contentId)
  if (props.completedPostIds.has(contentId)) return null

  return {
    type: data?.type,
    id: data?.node?.id,
    title: data?.node?.title,
    isDrip: data?.node?.dripDays > 0,
    parentCategoryId: data?.node?.parentCategory,
  }
}

function goToLockedContent(lockedByContentData: any) {
  if (lockedByContentData.type === CategoryTreeNodeType.Category) {
    if (lockedByContentData.parentCategoryId) {
      return redirectToFirstPostInCategory(
        lockedByContentData.parentCategoryId,
        findFirstPostInCategory(lockedByContentData.id) // First post in sub category
      )
    }

    return redirectToFirstPostInCategory(lockedByContentData.id)
  }

  if (lockedByContentData.id) {
    router.push({
      name: 'post-overview',
      params: {
        id: route.params.id,
        category_id: lockedByContentData.parentCategoryId,
        post_id: lockedByContentData.id,
      },
      query: {
        ...route.query,
      },
    })
  }
}

function redirectToFirstPostInCategory(categoryId: string, post?: Post) {
  const firstPostInCategory = post || findFirstPostInCategory(categoryId)
  if (firstPostInCategory) {
    return router.push({
      name: 'post-overview',
      params: {
        id: route.params.id,
        category_id: categoryId,
        post_id: post?.id || firstPostInCategory.id,
      },
      query: {
        ...route.query,
      },
    })
  }
}

function findFirstPostInCategory(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (category) {
    return category.node.posts[0]
  }
  return null
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(bigLockIcon),
      alt: "Locked"
    }, null, 8, _hoisted_2),
    _createVNode(Title, {
      tag: "h3",
      class: "!my-3 font-medium"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" This " + _toDisplayString(__props.contentType) + " is locked ", 1)
      ]),
      _: 1
    }),
    _createVNode(Paragraph, null, {
      default: _withCtx(() => [
        _createTextVNode(" To gain access to this " + _toDisplayString(__props.contentType) + ", please complete the " + _toDisplayString(lockedByPostData.value ? `lesson: ${lockedByPostData.value.title}` : '') + " " + _toDisplayString(lockedByCategoryData.value
          ? `${lockedByPostData.value ? 'and' : ''} Category: ${
              lockedByCategoryData.value.title
            }`
          : ''), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (lockedByPostData.value)
        ? (_openBlock(), _createBlock(Button, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = () => goToLockedContent(lockedByPostData.value)),
            class: "flex mr-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(lockedByPostData.value.title) + " ", 1),
              _createVNode(_unref(ArrowNarrowRightIcon), { class: "ml-2 h-5 w-5" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (lockedByCategoryData.value)
        ? (_openBlock(), _createBlock(Button, {
            key: 1,
            onClick: _cache[1] || (_cache[1] = () => goToLockedContent(lockedByCategoryData.value)),
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(lockedByCategoryData.value.title) + " ", 1),
              _createVNode(_unref(ArrowNarrowRightIcon), { class: "ml-2 h-5 w-5" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})