<script lang="ts" setup>
import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";

const store = useStore();

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
};
</script>

<template>
  <div v-if="newMobileScreens" class="category-list">
    <UISkeleton height="33.33%" width="100%" :sharp="false" />
    <div class="w-11/12 flex items-center justify-start gap-2 mx-auto my-4">
      <UISkeleton :sharp="false" height="16px" width="64px" />
      <UISkeleton :sharp="false" height="16px" width="16px" circle />
      <UISkeleton :sharp="false" height="16px" width="96px" />
    </div>
    <div class="w-full flex flex-col items-center justify-center">
      <UISkeleton :sharp="false" height="15px" class="w-11/12 mx-auto my-4 rounded-xl" />
      <UISkeleton :sharp="false" height="40px" class="w-11/12 mx-auto my-4" />
    </div>
    <div class="w-11/12 flex items-center justify-evenly mx-auto gap-4">
      <UISkeleton :sharp="false" height="40px" width="50%" />
      <UISkeleton :sharp="false" height="40px" width="50%" />
    </div>

    <div class="w-11/12 flex flex-col items-start mx-auto gap-2 my-4">
      <UISkeleton :sharp="false" height="24px" width="128px" />
      <UISkeleton :sharp="false" height="10px" width="100%" />
      <UISkeleton :sharp="false" height="10px" width="100%" />
      <UISkeleton :sharp="false" height="10px" width="100%" />
      <UISkeleton :sharp="false" height="10px" width="90%" />
      <UISkeleton :sharp="false" height="10px" width="80%" />
      <UISkeleton :sharp="false" height="10px" width="80%" />
      <UISkeleton :sharp="false" height="10px" width="70%" />
      <UISkeleton :sharp="false" height="10px" width="50%" />
    </div>
    <hr />
    <div class="w-11/12 flex flex-col items-start mx-auto gap-2 my-4">
      <UISkeleton :sharp="false" height="24px" width="128px" />
      <div class="flex items-center gap-4 my-2">
        <UISkeleton :sharp="false" height="48px" width="48px" circle />
        <div class="flex flex-col items-start gap-2">
          <UISkeleton :sharp="false" height="16px" width="96px" />
          <UISkeleton :sharp="false" height="12px" width="64px" />
        </div>
      </div>
      <UISkeleton :sharp="false" height="16px" width="100%" />
      <UISkeleton :sharp="false" height="16px" width="100%" />
      <UISkeleton :sharp="false" height="16px" width="100%" />
      <UISkeleton :sharp="false" height="16px" width="90%" />
      <UISkeleton :sharp="false" height="16px" width="70%" />
    </div>
  </div>
</template>
