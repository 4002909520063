import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "category-details mt-4 sm:block lg:grid grid-cols-3 gap-4" }
const _hoisted_2 = { class: "category-post-list-container col-span-2" }
const _hoisted_3 = {
  key: 0,
  class: "lesson-stats-instructor-container col-span-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryShimmer = _resolveComponent("CategoryShimmer")!
  const _component_CategoryPostBreadcrumbs = _resolveComponent("CategoryPostBreadcrumbs")!
  const _component_CategoryPostList = _resolveComponent("CategoryPostList")!
  const _component_LessonsStats = _resolveComponent("LessonsStats")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!

  return (_ctx.fetching)
    ? (_openBlock(), _createBlock(_component_CategoryShimmer, {
        key: 0,
        "hide-extra": _ctx.newMobileScreens
      }, null, 8, ["hide-extra"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["category-details-container max-w-xl lg:max-w-6xl w-full mx-auto px-4 lg:px-0", _ctx.isPortalNavbar ? 'pt-20' : _ctx.newMobileScreens ? 'pb-16' : 'my-4'])
      }, [
        (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens)
          ? (_openBlock(), _createBlock(_component_CategoryPostBreadcrumbs, {
              key: 0,
              "product-id": _ctx.productId,
              "product-title": _ctx.product && _ctx.product.title,
              "category-id": _ctx.categoryId,
              "category-title": _ctx.category && _ctx.category.title
            }, null, 8, ["product-id", "product-title", "category-id", "category-title"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CategoryPostList, {
              "product-id": _ctx.productId,
              categories: _ctx.categories,
              "parent-category": _ctx.category.parentCategory,
              "completed-posts": _ctx.postIdsCompleted,
              "completed-categories": _ctx.categoryIdsCompleted,
              hasProductAccess: _ctx.hasProductAccess
            }, null, 8, ["product-id", "categories", "parent-category", "completed-posts", "completed-categories", "hasProductAccess"])
          ]),
          (!_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_LessonsStats, {
                  completed: _ctx.completedPosts,
                  total: _ctx.totalPosts,
                  "progress-color": _ctx.getMobileTheme.primary,
                  class: "mb-3"
                }, null, 8, ["completed", "total", "progress-color"]),
                _createVNode(_component_InstructorCard, { instructor: _ctx.instructor }, null, 8, ["instructor"])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
}