import axios from 'axios'
import http, { generateToken } from '../../http-common'
import config from '../config'

const responseBody = (res: any) => res.data

const DEFAULT_HEADERS = {
  channel: 'APP',
  source: 'PORTAL_USER',
  'source-id': 'PORTAL',
  version: '2021-07-28',
}

const requests = {
  get: (
    url: string,
    headers: { [key: string]: string },
    params?: { [key: string]: any },
    baseDomain: string = config.restApiUrl
  ) =>
    axios({
      url: baseDomain + url,
      method: 'GET',
      headers: headers,
      params: params,
    }).then(responseBody),
  post: (
    url: string,
    body: any,
    headers?: { [key: string]: string },
    baseDomain: string = config.restApiUrl
  ) =>
    axios({
      url: baseDomain + url,
      method: 'POST',
      headers: headers,
      data: body,
    }).then(responseBody),
  put: (
    url: string,
    body: any,
    headers: { [key: string]: string },
    baseDomain: string = config.restApiUrl
  ) =>
    axios({
      url: baseDomain + url,
      method: 'PUT',
      headers: headers,
      data: body,
    }).then(responseBody),
  delete: (
    url: string,
    headers: { [key: string]: string },
    baseDomain: string = config.restApiUrl
  ) =>
    axios({
      url: baseDomain + url,
      method: 'DELETE',
      headers: headers,
    }).then(responseBody),
}

const ClientPortal = {
  getUserByContactId: async (locationId: string, contactId: string) => {
    const token = await generateToken()
    return requests.get(`/clientclub/${locationId}/users/${contactId}`, {
      ...DEFAULT_HEADERS,
      'source-id': locationId,
      'token-id': token,
    })
  },
  getPortalSettings: (domain: string) => {
    return requests.get(
      `/clientclub/portal-settings?domain=${domain}`,
      DEFAULT_HEADERS
    )
  },

  regenerateToken: async (token: string) =>
    requests.post(
      '/clientclub/auth/regenerate-token',
      {},
      {
        ...DEFAULT_HEADERS,
        'Token-Id': token,
      }
    ),
}

export const Coupons = {
  verify: async (body: {
    altId: string
    altType: string
    code: string
    products: Array<{ id: string; priceId: string; qty: number }>
  }) => {
    return await requests.post('/payments/coupon/verify', body)
  },
}

export const Contacts = {
  getContactById: async (locationId: string, contactId: string) => {
    return await http
      .get(`/locations/${locationId}/contacts/${contactId}`)
      .then(async (response) => response?.data?.contact)
  },
}

export const Stripe = {
  getPaymentMethod: async (locationId: string, params: any) => {
    return await http.get(`/locations/${locationId}/stripe/payment-methods`, {
      params,
    })
  },
}

export default { ClientPortal }
