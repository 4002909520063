import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-full brand-logo-container" }
const _hoisted_2 = { class: "flex h-10 lg:h-auto relative flex-row-reverse lg:flex-row items-center justify-between w-full lg:w-auto" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "font-semibold text-white lg:text-current m-0"
}
const _hoisted_5 = { class: "mr-2 absolute left-0 top-0 lg:relative flex items-center lg:hidden z-10" }
const _hoisted_6 = {
  key: 1,
  class: "flex items-center py-2"
}
const _hoisted_7 = { class: "flex relative shadow-md w-full h-full" }
const _hoisted_8 = { class: "h-full max-w-sm flex flex-col justify-between w-2/3 bg-white shadow-xs overflow-hidden z-50" }
const _hoisted_9 = { class: "px-2 pt-4 flex items-center justify-between" }
const _hoisted_10 = { class: "ml-auto" }
const _hoisted_11 = { class: "px-2 pt-2 pb-3 flex flex-col items-center justify-center border-b" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "py-2 text-base font-medium text-gray-700 w-full truncate text-center" }
const _hoisted_14 = { class: "px-2 pt-2 pb-3" }
const _hoisted_15 = { class: "pwa-app-logo mb-3" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "pwa-installation-trigger text-center mb-2" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UITextLgMedium = _resolveComponent("UITextLgMedium")!
  const _component_Home05Icon = _resolveComponent("Home05Icon")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_AppSwitcher = _resolveComponent("AppSwitcher")!
  const _component_BookOpen01Icon = _resolveComponent("BookOpen01Icon")!
  const _component_user_avatar = _resolveComponent("user-avatar")!

  return (_openBlock(), _createElementBlock("div", {
    id: "navigation-header",
    class: _normalizeClass([!_ctx.isCpAppInstalled ? 'py-3' : ' py-[4.5px]', "sticky top-0 z-20 px-4 w-full"]),
    style: _normalizeStyle({ backgroundColor: !_ctx.isCpAppInstalled ? 'var(--primary-color)' : 'white' })
  }, [
    _createElementVNode("nav", {
      class: _normalizeClass(["flex w-full items-center", [`justify-${_ctx.alignment}`]])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isCpAppInstalled)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                id: "brandLogo",
                to: { name: _ctx.libraryPath },
                class: "m-auto lg:m-0"
              }, {
                default: _withCtx(() => [
                  (_ctx.showLogo && _ctx.brandLogoUrl)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "brand-logo h-10 w-auto",
                        src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.brandLogoUrl),
                        alt: "Logo"
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, "Your Logo"))
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.backCallback)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backCallback && _ctx.backCallback(...args))),
                  class: _normalizeClass(["flex items-center py-2", !_ctx.isCpAppInstalled && 'text-white'])
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "chevronleft",
                    hwClass: "w-4 h-4"
                  }),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "ml-2 text-lg" }, "Back", -1))
                ], 2))
              : (_ctx.isCpAppInstalled && !_ctx.backCallback)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.activeLibrary === _ctx.LIBRARY_MENU_ITEMS.allCourses)
                      ? (_openBlock(), _createBlock(_component_UITextLgMedium, { key: 0 }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Courses ")
                          ])),
                          _: 1
                        }))
                      : (_ctx.activeLibrary === _ctx.LIBRARY_MENU_ITEMS.myCourses)
                        ? (_openBlock(), _createBlock(_component_UITextLgMedium, { key: 1 }, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode(" My Courses ")
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                  ]))
                : (!_ctx.isCpAppInstalled)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      id: "openMobileMenu",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = true)),
                      class: "inline-flex items-center justify-center py-2 rounded-md text-white lg:text-gray-400 transition duration-150 ease-in-out"
                    }, [
                      _createVNode(_component_BaseIcon, {
                        name: "hamburger",
                        hwClass: "w-6 h-6"
                      })
                    ]))
                  : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["ml-2 absolute right-0 top-1/2 transform -translate-y-1/2 lg:relative flex items-center lg:hidden z-10", !_ctx.isCpAppInstalled && 'text-white'])
          }, [
            (_ctx.isPortalActive && !_ctx.isCpAppInstalled && !_ctx.isPostDetailsPath && !_ctx.isAssignmentOrQuizPath && !_ctx.isLibraryPath)
              ? (_openBlock(), _createBlock(_component_UIButton, {
                  key: 0,
                  id: "client-portal-home",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redirectTo('home'))),
                  quaternary: true,
                  circle: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Home05Icon, { class: "h-5 w-5 text-white" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isPortalActive && !_ctx.isCpAppInstalled && !_ctx.isPostDetailsPath && !_ctx.isAssignmentOrQuizPath)
              ? (_openBlock(), _createBlock(_component_AppSwitcher, {
                  key: 1,
                  onToggleAppLauncher: _ctx.toggleAppLauncher,
                  appLauncherIsOpen: _ctx.appLauncherIsOpen,
                  triggerColor: 'text-white'
                }, null, 8, ["onToggleAppLauncher", "appLauncherIsOpen"]))
              : _createCommentVNode("", true),
            (_ctx.isLevelUpItem)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSearchDrawer && _ctx.handleSearchDrawer(...args))),
                  class: "global-search clickable"
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["flex items-center justify-center p-2 rounded-full w-10 h-10", !_ctx.isCpAppInstalled ? 'text-white' : 'text-gray-900'])
                  }, [
                    _createVNode(_component_BaseIcon, { name: "search" })
                  ], 2)
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateActiveLibrary(_ctx.LIBRARY_MENU_ITEMS.search)))
                }, [
                  _createVNode(_component_router_link, {
                    id: "library-search",
                    to: { name: _ctx.libraryPath }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass(["flex items-center justify-center p-2 rounded-full w-10 h-10", !_ctx.isCpAppInstalled ? 'text-white' : 'text-gray-900'])
                      }, [
                        _createVNode(_component_BaseIcon, { name: "search" })
                      ], 2)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ])),
            (_ctx.isLevelUpItem && (_ctx.isPostDetailsPath || _ctx.isAssignmentOrQuizPath))
              ? (_openBlock(), _createBlock(_component_BookOpen01Icon, {
                  key: 4,
                  class: "curriculum-drawer h-5 w-5 ml-2 clickable",
                  onClick: _ctx.handleOpenCurriculumComments
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ], 2),
    (!_ctx.isCpAppInstalled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "mobile-settings-menu",
          class: _normalizeClass(["z-20 fixed top-0 left-0 inset-x-0 h-full transition ease-in-out duration-300 transform origin-top-right lg:hidden", _ctx.open ? '-translate-x-0' : '-translate-x-full'])
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      id: "closeMobileMenu",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.open = false)),
                      type: "button",
                      class: "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    }, [
                      _createVNode(_component_BaseIcon, {
                        name: "cross",
                        hwClass: "w-4 h-4"
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  (_ctx.userImage)
                    ? (_openBlock(), _createBlock(_component_user_avatar, {
                        key: 0,
                        class: "h-20 w-20",
                        src: _ctx.userImage
                      }, null, 8, ["src"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, [
                        _createVNode(_component_BaseIcon, { name: "userprofile" })
                      ])),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.username), 1),
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.open = !_ctx.open))
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: 'profile-settings' },
                      class: _normalizeClass(["text-sm", _ctx.getMobileTheme.primaryText])
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode("Edit Profile")
                      ])),
                      _: 1
                    }, 8, ["class"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.open = !_ctx.open))
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: 'password-settings' },
                      class: "block px-3 py-2 flex justify-between items-center rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    }, {
                      default: _withCtx(() => [
                        _cache[17] || (_cache[17] = _createElementVNode("span", null, "Password", -1)),
                        _createVNode(_component_BaseIcon, {
                          name: "chevronright",
                          hwClass: "w-4 h-4"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open = !_ctx.open))
                  }, [
                    (!_ctx.isAdmin)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: _ctx.isLevelUpItem ? { name: 'profile-settings', query: { tab: 'purchase_history' } } : { name: 'purchase-history' },
                          class: "mt-1 flex justify-between items-center block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                        }, {
                          default: _withCtx(() => [
                            _cache[18] || (_cache[18] = _createElementVNode("span", null, "Purchase History", -1)),
                            _createVNode(_component_BaseIcon, {
                              name: "chevronright",
                              hwClass: "w-4 h-4"
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true)
                  ]),
                  (!_ctx.isPWAInstalled && !_ctx.isPortalActive && _ctx.pwaAppSettings.pwaEnabled)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.open = !_ctx.open)),
                        class: "mt-4 flex flex-col justify-between items-center block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("img", {
                            src: _ctx.pwaAppSettings.logo,
                            alt: "app-logo",
                            class: "w-12"
                          }, null, 8, _hoisted_16)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _cache[19] || (_cache[19] = _createTextVNode(" Add ")),
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.pwaAppSettings && _ctx.pwaAppSettings.name), 1),
                          _cache[20] || (_cache[20] = _createTextVNode(" to your home screen "))
                        ]),
                        _createElementVNode("button", {
                          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.openPWAInstallAlert && _ctx.openPWAInstallAlert(...args))),
                          class: _normalizeClass(["py-1", _ctx.getMobileTheme.primaryText])
                        }, [
                          _createVNode(_component_BaseIcon, { name: "install" })
                        ], 2)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("a", {
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                  href: "#",
                  class: _normalizeClass(["block flex items-center w-full px-5 py-3 text-base font-medium text-danger bg-gray-50", _ctx.getMobileTheme.primaryText])
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "exit",
                    hwClass: "w-5 h-5"
                  }),
                  _cache[21] || (_cache[21] = _createElementVNode("span", { class: "ml-2" }, "Log Out", -1))
                ], 2)
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full absolute right-0 top-0 transition-transform ease duration-100 bg-black-o-55 h-full", _ctx.open ? '-translate-y-0' : '-translate-y-full']),
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.open = !_ctx.open))
            }, null, 2)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 6))
}