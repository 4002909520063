<template>
  <div class="w-full">
    <ProductsThemeController v-bind="$props" v-if="enableNeoTheme" />
    <div v-else :class="isPortalNavbar ? 'pt-10' : ''">
      <UISpinner
        class="flex justify-center items-center h-screen"
        v-if="fetching || fetchingProduct"
        size="large"
      />
      <div v-else>
        <component
          :is="`${selectedTheme}Template`"
          :heroSection="heroSection"
          :product="product"
          :categoryTree="categoryTree"
          :postIdsCompleted="postIdsCompleted"
          :categoryIdsCompleted="categoryIdsCompleted"
          :completedPosts="completedPosts"
          :totalPosts="totalPosts"
          :instructor="instructor"
          :nextPost="nextPost"
          :productStarted="productStarted"
          :logo="logo"
          :hasProductAccess="hasProductAccess"
          @goToNextPost="goToNextPost"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw, PropType } from 'vue'
import DefaultTemplate from '@/components/product/templates/DefaultTemplate.vue'
import ClassicTemplate from '@/components/product/templates/ClassicTemplate.vue'
import NewYorkTemplate from '@/components/product/templates/NewYorkTemplate.vue'
import ProductsThemeController from '@/views/ProductsThemeController.vue'
import {
  PostService,
  UserProductTrackingService,
  UserPurchaseService,
} from '../services'
import Category from '../models/Category'
import {
  getHeroAlignmentClass,
  getHeroSpacingClass,
  createCategoryPostTree,
  computeNextPost,
  getPost,
  isClientPortal,
} from '../helper'
import CategoryTreeNode from '../models/CategoryTreeNode'
import { defaultProductBackground, SOURCES } from '../helper/constants'
import UISpinner from '@/components/common/UISpinner.vue'
import { cloneDeep } from 'lodash'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'

export default defineComponent({
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    fetching: Boolean,
    productStarted: Boolean,
    logo: String,
    selectedTheme: {
      type: String,
      default: 'Default',
    },
    allCompletedPostsData: {
      type: Object as PropType<{
        completedPostIds: Set<string>
        posts: Array<object>
      }>,
      default: null,
    },
  },
  components: {
    UISpinner,
    DefaultTemplate,
    ClassicTemplate,
    NewYorkTemplate,
    ProductsThemeController,
  },
  computed: {
    productId(): string {
      return this.$route.params.id || this.product.id
    },
    disableActions(): any {
      const inPreviewMode = !!this.$route.query.builder
      return {
        pointerEvents: inPreviewMode ? 'none' : 'all',
      }
    },
    templateId(): string {
      return this.$route.query.template_id || 'Default'
    },
    categoryTree(): CategoryTreeNode[] {
      return createCategoryPostTree(null, this.categories)
    },
    courseCompleted(): boolean {
      return this.completedPosts === this.totalPosts
      // if (this.completedPosts > 0 && !this.nextPost.id) {
      //   return true
      // } else {
      //   return false
      // }
    },
    heroSection(): any {
      const templateId = this.selectedTheme || 'Default'
      if (this.product && this.product.customizations) {
        const customization = this.product.customizations

        return {
          alignment: getHeroAlignmentClass(customization.heroTextAlignment),
          spacing: getHeroSpacingClass(customization.heroSpacing),
          background:
            customization.heroImage || defaultProductBackground[templateId],
          overlayColor: customization.heroOverlayColor || 'rgba(0, 0, 0, 0)',
        }
      }

      return {
        alignment: 'text-center' as string,
        spacing: 'py-16' as string,
        background: defaultProductBackground[templateId],
        overlayColor: 'rgba(0, 0, 0, 0)',
      }
    },
    postIdsCompleted(): Set<string> {
      return this.allCompletedPostsData?.completedPostIds
        ? this.allCompletedPostsData?.completedPostIds
        : new Set()
    },
    hasProductAccess(): boolean {
      return toRaw(this.product)?._data?.hasProductAccess
    },
    isClientPortal() {
      return isClientPortal()
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isPortalNavbar(): boolean {
      return (
        isClientPortal() &&
        !this.$store.getters.newMobileScreens &&
        this.source !== SOURCES.communities
      )
    },
    enableNeoTheme(): boolean {
      return true //this.$store.state.featureFlag?.enableNeoTheme
    },
  },
  beforeMount() {
    /**
     * Redirecting user to category list page if its mobile device
     */
    if (this.newMobileScreens && this.isClientPortal) {
      // Before redirection check if its coming from communities and store a flag
      if (this.source === SOURCES.communities) {
        this.$store.commit('SET_COMMUNITIES_FLAG', this.source)
      }
      this.$router.push({
        name: 'category-list',
        params: {
          id: this.productId,
        },
        query: {
          ...this.$route.query,
        },
      })
    }
  },
  async mounted() {
    this.fetchingProduct = true
    await Promise.all([this.fetchCategories(), this.fetchCompletionData()])
    this.fetchingProduct = false
    // this.computeNextPost()
    this.nextPost = computeNextPost(
      cloneDeep(this.categoryTree),
      this.postIdsCompleted
    )
    if (
      this.nextPost &&
      (this.selectedTheme === 'Neue' || this.templateId === 'Neue')
    ) {
      this.goToNextPost()
    }
  },
  data() {
    return {
      fetchingProduct: false,
      fetchingCategories: false,
      categories: [] as Category[],
      totalPosts: 0,
      completedPosts: 0,
      categoryIdsCompleted: new Set() as Set<string>,
      nextPost: {} as CategoryTreeNode,
      source: this.$route.query.source,
    }
  },
  methods: {
    async fetchCategories() {
      this.fetchingCategories = true

      try {
        // this.categories = await CategoryService.findAll({
        //   product_id: this.productId,
        //   visibility: CategoryVisibility.published,
        //   posts: true,
        //   published_posts: true
        // })
        this.categories = await UserPurchaseService.getCategories({
          product_id: this.productId,
        })
        // console.log('categories fetched --> ', this.categories)
      } catch (error) {
        console.error('Error while fetching product --> ', error)
      } finally {
        this.fetchingCategories = false
      }
    },
    fetchCompletionData() {
      const fetchingCompletedPosts = this.fetchCompletedPosts()
      const fetchingPublishedPosts = this.fetchNoOfPublishedPosts()
      return Promise.all([fetchingCompletedPosts, fetchingPublishedPosts])
    },
    async fetchCompletedPosts() {
      this.categoryIdsCompleted = new Set(
        this.categories.reduce((acc, categoryElem) => {
          const { posts } = categoryElem
          const categoryPostIds = posts.map((e) => e.id)
          const completedPostLengthInCategory = categoryPostIds.filter(
            (element) => this.postIdsCompleted.has(element)
          ).length
          if (
            categoryPostIds.length > 0 &&
            completedPostLengthInCategory === categoryPostIds.length
          ) {
            acc.push(categoryElem.id)
          }
          return acc
        }, [])
      )

      this.completedPosts = this.postIdsCompleted.size
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId,
      })

      this.totalPosts = publishedResp.count
    },
    // computeNextPost() {
    //   let nextPostToGo = null

    //   const tree = this.removeUnavailableCategories(
    //     [...this.categoryTree],
    //     this.postIdsCompleted
    //   )

    //   if (this.completedPosts > 0) {
    //     const lastCompletedPost = findLastCompletedPost(
    //       tree,
    //       this.postIdsCompleted
    //     )

    //     // find the next post after the last post that is completed
    //     if (lastCompletedPost) {
    //       console.log('available tree --> ', tree)
    //       nextPostToGo = findNextPostToComplete(
    //         tree,
    //         lastCompletedPost.id,
    //         this.postIdsCompleted
    //       )
    //     }
    //   }

    //   if (!nextPostToGo) {
    //     // if there are no completed posts, or the last post in course is completed
    //     const firstUncompletedPost = findFirstUncompletedPost(
    //       tree,
    //       this.postIdsCompleted
    //     )
    //     if (firstUncompletedPost) {
    //       nextPostToGo = firstUncompletedPost
    //     }
    //     // else, couldn't find an uncompleted post
    //   }

    //   if (nextPostToGo) {
    //     this.nextPost = nextPostToGo
    //   } else {
    //     // all posts done, course is completed
    //   }
    // },
    // removeUnavailableCategories(categoryTree: Array<CategoryTreeNode>) {
    //   const availableNodes = categoryTree.filter((node: CategoryTreeNode) => {
    //     if (node.type === CategoryTreeNodeType.Category) {
    //       const cat = node.node as Category
    //       return cat.available
    //     }

    //     return true
    //   })

    //   return availableNodes.map((node: CategoryTreeNode) => {
    //     if (node.type === CategoryTreeNodeType.Category && node.childs) {
    //       node.childs = this.removeUnavailableCategories(node.childs)
    //     }
    //     return node
    //   })
    // },
    goToNextPost() {
      if (this.hasProductAccess === false) return
      if (!this.productStarted) {
        // start the product
        UserProductTrackingService.trackProductStart(this.productId)
      }
      let payload: any = null
      if (this.nextPost?.id) {
        payload = {
          name: 'post-overview',
          params: {
            id: this.productId,
            category_id: this.nextPost?.parent,
            post_id: this.nextPost.id,
          },
        }
      } else if (this.selectedTheme === 'Neue') {
        const post = getPost(this.categoryTree)
        payload = {
          name: 'post-overview',
          params: {
            id: this.productId,
            category_id: post?.parent,
            post_id: post?.id,
          },
        }
      }
      if (this.$route.query.template_id) {
        payload.query = {
          template_id: this.templateId,
        }
        if (this.$route.query.builder) {
          payload.query.builder = this.$route.query.builder
        }
      }
      if (payload) {
        trackCustomGAEvents(GAEventTypes.NEXT_LESSON, { postId: this.nextPost.id })
        this.$router.push(payload)
      }
    },
  },
  watch: {
    async productId() {
      await this.fetchCategories()
      await this.fetchCompletionData()
      // this.computeNextPost()
      this.nextPost = computeNextPost(
        cloneDeep(this.categoryTree),
        this.postIdsCompleted
      )
    },
    selectedTheme(val) {
      if (val === 'Neue') {
        this.goToNextPost()
      }
    },
    templateId(val) {
      if (val === 'Neue') {
        this.goToNextPost()
      }
    },
  },
})
</script>
<style scoped>
#banner {
  position: relative;
  z-index: 1;
}

#banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>
