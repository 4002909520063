import BaseService from './BaseService'
import http from '../../http-common'
import Audio from '@/models/Audio'

export default class AudioService extends BaseService<Audio> {
  protected subdomain = 'audios'

  model() {
    return Audio
  }

  constructor(locationId: string) {
    super(locationId)
  }

  sendWatchtimeLogs(payload: any) {
    return http
      .post(`${this.endpoint}/watchtime-logs`, payload)
      .then((res) => res.data)
  }

  sendPlaytimeErrorLogs(payload: any) {
    return http
      .post(`${this.endpoint}/streaming-error`, payload)
      .then((res) => res.data)
  }
}
