import http from '../../http-common'
import BaseService from './BaseService'
import AssessmentStatus from '@/models/AssessmentStatus'

export default class AssessmentStatusService extends BaseService<AssessmentStatus> {
  protected subdomain = 'assessments/quiz/assessmentStatus'
  model() {
    return AssessmentStatus
  }

  create(data: { [key: string]: any }) {
    return http.post(`${this.endpoint}`, data)
  }

  getLastSubmissionByPostId(postId: string) {
    return http.get(`${this.endpoint}/last/submission/${postId}`)
  }
}
