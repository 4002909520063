import Model from './Model'

export enum TRANSCODING_STATUS {
  COMPLETED = 'completed',
  PROCESSING = 'processing',
  REMAINING = 'remaining',
  ERROR = 'error',
}

export default class Video extends Model {
  constructor(payload?: {
    title?: string
    url?: string
    postId?: string
    thumbnail?: string
    transcodingStatus?: string
    hdTranscoded?: boolean
    videoFormats?: Array<string>
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get url(): string {
    return this._data.url
  }

  set url(url: string) {
    this._data.url = url
  }

  get thumbnail(): string {
    return this._data.thumbnail
  }

  set thumbnail(thumbnail: string) {
    this._data.thumbnail = thumbnail
  }

  get postId(): string {
    return this._data.postId
  }

  set postId(postId: string) {
    this._data.postId = postId
  }

  get transcodingStatus(): string {
    return this._data.transcodingStatus
  }

  set transcodingStatus(transcodingStatus: string) {
    this._data.transcodingStatus = transcodingStatus
  }

  get hdTranscoded(): boolean {
    return this._data.hdTranscoded
  }

  set hdTranscoded(hdTranscoded: boolean) {
    this._data.hdTranscoded = hdTranscoded
  }

  get videoFormats(): Array<string> {
    return this._data.videoFormats
  }
}
