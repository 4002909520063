<template>
  <div
    class="max-w-xl lg:max-w-6xl w-full mx-auto"
    :class="
      isPortalActive && !newMobileScreens
        ? 'pt-14'
        : newMobileScreens
        ? 'pt-4 pb-16'
        : 'py-6'
    "
  >
    <div class="flex items-center justify-center" v-if="newMobileScreens">
      <shimmer class="h-3 my-1 w-3/12 rounded-lg" />
    </div>
    <div class="lg:grid lg:grid-cols-5 gap-4 my-10">
      <div
        class="md:col-span-3 rounded-sm bg-white p-8"
        :class="newMobileScreens ? '' : 'shadow'"
      >
        <shimmer class="h-3 my-1 w-3/12 rounded-lg" v-if="!newMobileScreens" />
        <div
          class="flex justify-start items-center mb-2"
          v-for="i of 4"
          :key="i"
        >
          <shimmer v-if="!newMobileScreens" class="h-16 my-1 w-16 rounded-lg" />
          <div
            class="flex w-full justify-between"
            :class="newMobileScreens ? 'h-16' : ''"
          >
            <shimmer
              class="h-5 my-1 rounded-lg"
              :class="newMobileScreens ? 'w-full mr-10' : 'w-6/12 mx-10'"
            />
            <shimmer class="h-3 my-1 w-5 rounded-lg" />
          </div>
        </div>
      </div>
      <div v-if="!hideExtra" class="md:col-span-2">
        <div
          class="items-center rounded-sm bg-white p-8 mb-5"
          :class="newMobileScreens ? '' : 'shadow'"
        >
          <shimmer class="rounded w-2/3 h-3" />
          <shimmer class="h-3 my-1 w-full rounded-lg" />
        </div>
        <div
          class="flex items-center rounded-sm bg-white p-8"
          :class="newMobileScreens ? '' : 'shadow'"
        >
          <shimmer class="rounded-full w-16 h-16" />
          <div class="flex-1 ml-2 py-2">
            <shimmer
              class="h-3 my-1 w-full rounded-lg"
              v-for="i of 2"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Shimmer from './Shimmer.vue'
import { isClientPortal } from '@/helper'
export default defineComponent({
  props: {
    hideExtra: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Shimmer,
  },
  computed: {
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isPortalActive() {
      return isClientPortal()
    },
  },
})
</script>
