// v-highlight.ts
import { DirectiveBinding, ObjectDirective } from 'vue'

interface HighlightBindingValue {
  label: string
  isEditing: boolean
}

const highlightDirective: ObjectDirective<HTMLElement, HighlightBindingValue> =
  {
    beforeMount(
      el: HTMLElement,
      binding: DirectiveBinding<HighlightBindingValue>
    ) {
      if (!binding.value.isEditing) {
        return
      }

      el.classList.add('border-2', 'border-orange-500')
      const label = document.createElement('div')
      label.className =
        'highlight-label absolute bottom-0 bg-orange-500 text-white text-xs px-1 transform translate-x-full translate-y-1/2'
      label.innerText = binding.value.label
      label.style.display = 'none'

      el.style.position = 'relative'
      el.appendChild(label)
    },
    updated(el: HTMLElement, binding: DirectiveBinding<HighlightBindingValue>) {
      if (!binding.value.isEditing) {
        return
      }

      const label = el.querySelector('.highlight-label') as HTMLElement
      el.classList.add('border-2', 'border-orange-500')
      label.style.display = 'block'
    },
  }

export default highlightDirective
