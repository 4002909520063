import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "category-post-list" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  key: 2,
  class: "font-semibold my-1 ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostListItem = _resolveComponent("PostListItem")!
  const _component_CategoryPostTree = _resolveComponent("CategoryPostTree", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTree, (child) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: child.id
      }, [
        (_ctx.nodeIsPost(child.type))
          ? (_openBlock(), _createBlock(_component_PostListItem, {
              key: 0,
              post: child.node,
              "is-locked": _ctx.isLocked(child.parent),
              "category-id": child.parent,
              completed: _ctx.completedPosts.has(child.id),
              available: _ctx.available,
              hasProductAccess: _ctx.hasProductAccess
            }, null, 8, ["post", "is-locked", "category-id", "completed", "available", "hasProductAccess"]))
          : (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([[
          _ctx.level === 0 ? 'mb-4 rounded-sm' : 'lg:ml-6 my-12 lg:my-0',
          _ctx.level === 0 && _ctx.isBackground
            ? `bg-white ${!_ctx.newMobileScreens ? 'shadow' : ''}`
            : '',
          _ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens ? 'p-5' : '',
          !child.node.available ? 'drip-category' : '',
        ], "category-contents"]),
              key: child.id
            }, [
              _createElementVNode("div", _hoisted_2, [
                (child.node.available)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasProductAccess ? 'router-link' : 'span'), {
                      key: 0,
                      to: {
              name: 'category-overview',
              params: { id: child.node.productId, category_id: child.id },
            },
                      class: _normalizeClass(["category-title cursor-pointer font-bold", [
              _ctx.level === 0 ? 'text-xl lg:text-2xl' : 'text-xl',
              _ctx.device === 'desktop' &&
              _ctx.$route.name.includes('product-overview') &&
              _ctx.level === 0
                ? 'primaryStyle course-category-title'
                : _ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens
                ? 'primaryStyle subcategory-title'
                : 'text-gray-700 mb-2',
            ]]),
                      tag: "div"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(child.node.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "class"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["category-title font-bold text-gray-500 drip-category-title", _ctx.level === 0 ? 'text-xl lg:text-2xl' : 'text-xl'])
                    }, [
                      _createTextVNode(_toDisplayString(child.node.title), 1),
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "float-right" }, "(Coming Soon)", -1))
                    ], 2))
              ]),
              (child.node.description)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([
            _ctx.level !== 0 ? 'truncate' : '',
            _ctx.$route.name.includes('product-overview')
              ? 'secondaryStyle category-desc'
              : '',
            _ctx.newMobileScreens ? 'ml-0' : '',
            'whitespace-pre-line',
          ])
                  }, _toDisplayString(child.node.description), 3))
                : _createCommentVNode("", true),
              (child.childs && child.childs.length)
                ? (_openBlock(), _createBlock(_component_CategoryPostTree, {
                    key: 1,
                    available: child.node.available,
                    level: _ctx.level + 1,
                    "category-tree": child.childs,
                    "completed-posts": _ctx.completedPosts,
                    "complete-categories": _ctx.completedCategories,
                    isBackground: _ctx.isBackground,
                    "category-lock-data": _ctx.categoryLockData,
                    hasProductAccess: _ctx.hasProductAccess
                  }, null, 8, ["available", "level", "category-tree", "completed-posts", "complete-categories", "isBackground", "category-lock-data", "hasProductAccess"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, "No content available!"))
            ], 2))
      ], 64))
    }), 128))
  ]))
}