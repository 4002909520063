import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Title',
  props: {
  tag: {
    type: String,
    default: 'h1',
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  textColor: {
    type: String,
    default: '',
  },
  fontSize: {
    type: String,
    default: '',
  },
  fontWeight: {
    type: String,
    default: '',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  customClass: {
    type: [String, Object, Array],
    default: '',
  },
},
  setup(__props) {

const props = __props

const computedStyle = computed(() => ({
  color: props.textColor,
  fontSize: props.fontSize,
  fontWeight: props.fontWeight,
}))

const fontSize = computed(() => {
  switch (props.tag) {
    case 'h1':
      return 'text-3xl'
    case 'h2':
      return 'text-2xl'
    case 'h3':
      return 'text-xl'
    case 'h4':
      return 'text-lg'
    case 'h5':
      return 'text-base'
    case 'h6':
      return 'text-sm'
    default:
      return 'text-base'
  }
})

return (_ctx: any,_cache: any) => {
  const _directive_highlight = _resolveDirective("highlight")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), _mergeProps({
    style: [computedStyle.value, __props.customStyle],
    class: [['mt-0', __props.customClass, fontSize.value], "font-medium"]
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["style", "class"])), [
    [_directive_highlight, { isEditing: __props.isEditing, label: 'Title' }]
  ])
}
}

})