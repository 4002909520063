<template>
  <div
    class="bg-white rounded shadow border border-gray-200 postqueue-background"
  >
    <div class="w-full flex justify-between items-center p-4 border-b">
      <router-link
        v-if="previousCategory && previousCategory.category"
        :class="previousCategory && 'cursor-pointer'"
        tag="div"
        :event="previousCategory ? 'click' : ''"
        :to="{
          name: previousCategory.post ? 'post-overview' : 'category-overview',
          params: {
            id: productId,
            category_id: previousCategory && previousCategory.category,
            post_id: previousCategory && previousCategory.post,
          },
          query: {
            template_id: templateId,
          },
        }"
        :style="{ color: previousCategory ? categoryNavIcon : '#a0aec0' }"
      >
        <BaseIcon name="previousarrow" />
      </router-link>
      <div class="lessonSecondaryStyle postqueue-category">
        {{
          (currentCategoryTree &&
            currentCategoryTree.node &&
            currentCategoryTree.node.title &&
            currentCategoryTree.node.title.toLocaleUpperCase()) ||
          'Category'
        }}
      </div>
      <router-link
        v-if="nextCategory && nextCategory.category"
        :class="nextCategory && 'cursor-pointer'"
        tag="div"
        :event="nextCategory ? 'click' : ''"
        :to="{
          name: nextCategory.post ? 'post-overview' : 'category-overview',
          params: {
            id: productId,
            category_id: nextCategory && nextCategory.category,
            post_id: nextCategory && nextCategory.post,
          },
          query: {
            template_id: this.templateId,
          },
        }"
        :style="{ color: nextCategory ? categoryNavIcon : '#a0aec0' }"
      >
        <BaseIcon name="nextarrow" />
      </router-link>
    </div>
    <div>
      <NewYorkLessonPostTree
        v-if="currentCategoryTree"
        :categoryTree="currentCategoryTree.childs"
        :completed-posts="completedPosts"
        :activePost="activePost"
        :post-indexes="postIndexes"
        :highlightBackground="highlightBackground"
        :highlightColor="highlightColor"
      />
    </div>
  </div>
</template>
<script lang="ts">
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import NewYorkLessonPostTree from './NewYorkLessonPostTree.vue'
import { defineComponent } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    NewYorkLessonPostTree,
    BaseIcon,
  },
  mounted() {
    console.log('previousCategory', this.previousCategory)
  },
  props: {
    categoryTree: {
      type: Array,
    },
    activePost: {
      type: Object,
    },
    previousCategory: Object,
    nextCategory: Object,
    category: Object,
    completedPosts: {
      type: Set,
      default: new Set(),
    },
    categoryNavIcon: String,
    highlightBackground: String,
    highlightColor: String,
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    templateId(): string {
      return this.$route.query.template_id
    },
    currentCategoryTree(): any {
      let id = this.categoryId
      if (this.category?.parentCategory) {
        id = this.category?.parentCategory
      }
      return (
        this.categoryTree.find((node: CategoryTreeNode) => node.id === id) ||
        null
      )
    },
    postIndexes(): any {
      let index = 1
      return this.currentCategoryTree?.childs?.reduce(
        (agg, node: CategoryTreeNode) => {
          if (node.type === CategoryTreeNodeType.Post) {
            agg[node.id] = index++
          } else {
            for (let i = 0; i < node.childs.length; i++) {
              agg[node.childs[i].id] = index++
            }
          }
          return agg
        },
        {}
      )
    },
  },
})
</script>
