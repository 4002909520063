<template>
  <component
    :is="tag"
    v-highlight="{ isEditing: isEditing, label: 'Title' }"
    :style="[computedStyle, customStyle]"
    :class="['mt-0', customClass, fontSize]"
    class="font-medium"
    v-bind="$attrs"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  tag: {
    type: String,
    default: 'h1',
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  textColor: {
    type: String,
    default: '',
  },
  fontSize: {
    type: String,
    default: '',
  },
  fontWeight: {
    type: String,
    default: '',
  },
  customStyle: {
    type: Object,
    default: () => ({}),
  },
  customClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const computedStyle = computed(() => ({
  color: props.textColor,
  fontSize: props.fontSize,
  fontWeight: props.fontWeight,
}))

const fontSize = computed(() => {
  switch (props.tag) {
    case 'h1':
      return 'text-3xl'
    case 'h2':
      return 'text-2xl'
    case 'h3':
      return 'text-xl'
    case 'h4':
      return 'text-lg'
    case 'h5':
      return 'text-base'
    case 'h6':
      return 'text-sm'
    default:
      return 'text-base'
  }
})
</script>

<style scoped></style>
