import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 3,
  id: "user-library",
  class: "w-full mb-2 h-screen"
}
const _hoisted_2 = { class: "bg-white flex flex-col overflow-y-auto min-h-screen" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex items-center justify-center courses-font-family font-semibold mt-20"
}
const _hoisted_4 = {
  key: 1,
  id: "library-container",
  class: "w-11/12 flex-1 flex flex-col xl:w-9/12 mx-auto my-4 p-4 lg:p-6"
}
const _hoisted_5 = {
  key: 0,
  id: "library-title",
  class: "py-4 w-full text-left lg:px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileLibraryV2 = _resolveComponent("MobileLibraryV2")!
  const _component_MobileLibrary = _resolveComponent("MobileLibrary")!
  const _component_NeoLibrary = _resolveComponent("NeoLibrary")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_UIEmpty = _resolveComponent("UIEmpty")!
  const _component_LibraryShimmer = _resolveComponent("LibraryShimmer")!
  const _component_UpsellBundle = _resolveComponent("UpsellBundle")!
  const _component_NewProductCard = _resolveComponent("NewProductCard")!
  const _component_UniversalSearch = _resolveComponent("UniversalSearch")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.newMobileScreens && _ctx.isLevelUpItem)
      ? (_openBlock(), _createBlock(_component_MobileLibraryV2, { key: 0 }))
      : (_ctx.newMobileScreens && !_ctx.isLevelUpItem)
        ? (_openBlock(), _createBlock(_component_MobileLibrary, { key: 1 }))
        : (!_ctx.newMobileScreens && _ctx.enableNeoTheme)
          ? (_openBlock(), _createBlock(_component_NeoLibrary, { key: 2 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Navigation, {
                  onChangeActive: _ctx.changeActive,
                  coursesTypes: _ctx.coursesTypes,
                  active: _ctx.active
                }, null, 8, ["onChangeActive", "coursesTypes", "active"]),
                (_ctx.isPortalActive && !_ctx.fetching && !_ctx.allCourses.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_UIEmpty, {
                        id: "empty-product-message",
                        title: "No courses found",
                        description: "You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey."
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      (!_ctx.isPortalActive)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["font-semibold text-lg px-2 cursor-pointer", _ctx.active === _ctx.coursesTypes.allCourses
                ? 'bg-nova-blue-600 py-1 rounded text-white'
                : ''
              ]),
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeActive(_ctx.coursesTypes.allCourses)))
                            }, "All Courses", 2),
                            _createElementVNode("span", {
                              class: _normalizeClass(["font-semibold text-lg px-2 cursor-pointer", _ctx.active === _ctx.coursesTypes.myCourses
                ? 'bg-nova-blue-600 py-1 rounded text-white'
                : ''
              ]),
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeActive(_ctx.coursesTypes.myCourses)))
                            }, "My Courses", 2)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.fetching)
                        ? (_openBlock(), _createBlock(_component_LibraryShimmer, { key: 1 }))
                        : _createCommentVNode("", true),
                      (_ctx.active === _ctx.coursesTypes.allCourses && !_ctx.fetching)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            id: "product-list",
                            class: _normalizeClass(["my-4 mb-8 grid md:grid-cols-2 lg:grid-cols-3 row-gap-6 justify-center md:gap-8", [_ctx.isPortalActive ? 'pt-20' : '']])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCourses, (course) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: course.id
                              }, [
                                (course.upsellType && course.offerUpSell)
                                  ? (_openBlock(), _createBlock(_component_UpsellBundle, {
                                      key: 0,
                                      offer: course.offerUpSell,
                                      upsellId: course.id,
                                      purchasedProductIds: _ctx.purchasedProductIds
                                    }, null, 8, ["offer", "upsellId", "purchasedProductIds"]))
                                  : (_openBlock(), _createBlock(_component_NewProductCard, {
                                      key: 1,
                                      "product-id": course.id,
                                      "product-title": course.title,
                                      "poster-image": course.posterImage,
                                      "product-description": course.description,
                                      "instructor-name": course.customizations && course.customizations.instructorName
                  ,
                                      "instructor-headshot": course.customizations &&
                  course.customizations.instructorHeadshot
                  ,
                                      "show-in-library": true,
                                      label: "in-library",
                                      onHandleClick: ($event: any) => (_ctx.openProduct(course.id))
                                    }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "onHandleClick"]))
                              ], 64))
                            }), 128))
                          ], 2))
                        : _createCommentVNode("", true),
                      (_ctx.active === _ctx.coursesTypes.myCourses && !_ctx.fetching)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            id: "product-list",
                            class: _normalizeClass(["my-4 mb-8 grid md:grid-cols-2 lg:grid-cols-3 row-gap-6 justify-center md:gap-8", [_ctx.isPortalActive ? 'pt-20' : '']])
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                              return (_openBlock(), _createBlock(_component_NewProductCard, {
                                key: product.id,
                                "product-id": product.id,
                                "product-title": product.title,
                                "poster-image": product.posterImage,
                                "product-description": product.description,
                                "instructor-name": product.customizations && product.customizations.instructorName
                ,
                                "instructor-headshot": product.customizations &&
                product.customizations.instructorHeadshot
                ,
                                onHandleClick: ($event: any) => (_ctx.openProduct(product.id))
                              }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "onHandleClick"]))
                            }), 128))
                          ], 2))
                        : _createCommentVNode("", true)
                    ]))
              ]),
              _createVNode(_component_UniversalSearch, {
                "show-modal": _ctx.showSearchModal,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSearchModal = false))
              }, null, 8, ["show-modal"])
            ]))
  ]))
}