<template>
  <Paper class="p-4 w-full">
    <AssignmentPreview :assignment="assignment">
      <template v-if="assessmentStatus" #after-title>
        <ResultMessageComponent
          assignmentType="assignment"
          class="my-2"
          :resultMessage="resultMessage"
          :htmlConfirmationMessage="
            assessmentStatus.status === AssessmentStatusOptions.processing
              ? processingMessage
              : assignment.confirmMessage
          "
          :score="assessmentStatus.score"
          :result="assessmentStatus.status"
        />
      </template>
      <template v-if="assessmentStatus" #at-end>
        <div class="w-full mt-8">
          <div v-if="assessmentStatus.answer" class="w-full mb-2">
            <Title tag="h4" class="mb-2">Submitted Answer</Title>
            <RichTextViewer
              :html="assessmentStatus.answer"
              :replaceImages="false"
              :replaceLinks="false"
            />
          </div>
          <div v-if="assessmentStatus.feedback" class="w-full mb-2">
            <Title tag="h4" class="mb-2">Feedback</Title>
            <div class="p-4 bg-neo-classic-hover-bg rounded-lg overflow-x-auto">
              <RichTextViewer
                :html="assessmentStatus.feedback"
                :replaceImages="false"
                :replaceLinks="false"
              />
            </div>
          </div>
          <div v-if="assessmentStatus.submission?.length > 0">
            <AttachedFiles
              :fileList="assessmentStatus.submission"
              :wrapInPaper="false"
              :showBorder="true"
            >
              <template #title>
                <Title tag="h4" class="w-full">Submitted Files</Title>
              </template>
            </AttachedFiles>
          </div>
        </div>
      </template>
    </AssignmentPreview>
    <div class="text-center pt-4">
      <Button
        @click="launchAssignment"
        :disabled="assessmentStatus?.status === AssessmentStatusOptions.passed"
      >
        {{ assignmentButtonText }}
      </Button>
    </div>
  </Paper>
</template>

<script setup lang="ts">
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import ResultMessageComponent from '@/components/neo-classic-theme/quiz/ResultMessageComponent.vue'
import AssignmentPreview from '@/components/neo-classic-theme/assignment/AssignmentPreview.vue'
import AttachedFiles from '@/components/neo-classic-theme/common/AttachedFiles.vue'
import Assignment from '@/models/Assignment'
import AssessmentStatus, {
  AssessmentStatusOptions,
} from '@/models/AssessmentStatus'
import { PropType, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const props = defineProps({
  assignment: {
    type: Object as PropType<Assignment>,
    required: true,
  },
  assessmentStatus: {
    type: Object as PropType<AssessmentStatus>,
    default: () => ({}),
  },
})

const assignmentButtonText = computed(() => {
  if (!props.assessmentStatus) return 'Start assignment'

  return 'Retake Assignment'
})

const resultMessage = computed(() => {
  let message = ''
  switch (props.assessmentStatus?.status) {
    case AssessmentStatusOptions.processing:
      message = 'Your assignment is under review.'
      break
    case AssessmentStatusOptions.passed:
      message = 'You have passed the assignment.'
      break
    case AssessmentStatusOptions.failed:
      message = 'You failed the assignment.'
      break
  }

  return message
})

const processingMessage =
  'Your assignment is currently under review by the instructor. We appreciate your patience during this process. You will receive feedback and your final grade once the review is complete.'

const launchAssignment = () => {
  router.push({
    name: 'new-assignment-overview',
    params: {
      id: route.params.id,
      category_id: route.params.category_id,
      post_id: route.params.post_id,
    },
  })
}
</script>
