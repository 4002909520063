<template>
  <div :class="mergedWrapperClass" :style="wrapperStyle">
    <component
      :is="alternateComponent"
      v-bind="$attrs"
      @mouseover="showPreview"
      @mouseleave="hidePreview"
      :class="mergedLinkClass"
      :style="linkStyle"
    >
      <slot></slot>
    </component>
    <Transition
      enter-active-class="transform ease-out duration-200"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transform ease-in duration-200"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div
        v-if="isPreviewVisible"
        :id="previewId"
        :class="mergedPreviewClass"
        :style="mergedPreviewStyle"
      >
        <div class="iframe-wrapper">
          <iframe
            v-if="previewUrl"
            :src="previewUrl"
            :style="iframeStyle"
            frameborder="0"
            scrolling="no"
            :title="iframeTitle"
          ></iframe>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, reactive, computed, Transition } from 'vue'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  alternateComponent: {
    type: [String, Object],
    default: 'a',
  },
  href: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    default: 'bottom', // 'bottom', 'top', 'left', 'right'
  },
  width: {
    type: String,
    default: '16rem', // default width
  },
  wrapperClass: {
    type: String,
    default: '',
  },
  wrapperStyle: {
    type: Object,
    default: () => ({}),
  },
  linkClass: {
    type: String,
    default: '',
  },
  linkStyle: {
    type: Object,
    default: () => ({}),
  },
  previewClass: {
    type: String,
    default: '',
  },
  previewStyle: {
    type: Object,
    default: () => ({}),
  },
  previewId: {
    type: String,
    default: 'preview',
  },
})

const isPreviewVisible = ref(false)
const previewUrl = ref('')

const showPreview = () => {
  isPreviewVisible.value = true
  previewUrl.value = props.href
}

const hidePreview = () => {
  isPreviewVisible.value = false
  previewUrl.value = ''
}

const positionClass = computed(() => {
  switch (props.position) {
    case 'top':
      return 'bottom-full mb-2'
    case 'left':
      return 'right-full mr-2'
    case 'right':
      return 'left-full ml-2'
    default:
      return 'top-full mt-2'
  }
})

const previewStyles = computed(() => ({
  width: props.width,
}))

const mergedWrapperClass = computed(() =>
  twMerge('relative inline-block', props.wrapperClass)
)
const mergedLinkClass = computed(() =>
  twMerge('text-neo-classic-primary-accent hover:underline', props.linkClass)
)
const mergedPreviewClass = computed(() =>
  twMerge(
    'absolute z-10 p-2 bg-white border rounded shadow-lg ',
    positionClass.value,
    props.previewClass
  )
)
const mergedPreviewStyle = computed(() => ({
  ...previewStyles.value,
  ...props.previewStyle,
}))

const iframeStyle = computed(() => ({
  width: '1280px', // Adjust this to match the original width of  content
  height: '720px', // Adjust this to match the original height of  content
  transform: `scale(${scaleRatio.value})`,
  transformOrigin: 'top left',
}))

const scaleRatio = computed(() => {
  const containerWidth = parseInt(props.width)
  const contentWidth = 1280 // This should match the width set in iframeStyle
  return containerWidth / contentWidth
})
const iframeTitle = computed(() => {
  const url = new URL(previewUrl?.value)
  const domain = url?.hostname.replace(/^www\./, '')
  return domain.split('.')[0]
})
</script>

<style scoped>
/* Add any additional styling if needed */
.iframe-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  overflow: hidden;
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
}
</style>
