<template>
  <div :class="mergedClasses" v-bind="attrs" style="background-color: #fefeff">
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, useAttrs } from 'vue'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
  hoverEffect: {
    type: Boolean,
    default: true,
  },
})

const attrs = useAttrs()

const baseClasses = 'rounded-lg border bg-white shadow-sm'
const hoverClasses = !props.hoverEffect
  ? 'transition-transform transformhover:translate-y-[-2px] hover:shadow-md'
  : ''

const mergedClasses = twMerge(baseClasses, hoverClasses, attrs.class)
</script>

<style scoped>
/* You can add additional styles here if needed */
</style>
