import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "ml-2 w-full single-comment-wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "bg-neo-classic-hover-bg p-4 rounded-lg grid grid-cols-12 gap-1 w-full"
}
const _hoisted_5 = { class: "w-full font-medium mb-2" }
const _hoisted_6 = { class: "text-neo-classic-secondary-text ml-2" }
const _hoisted_7 = {
  key: 0,
  class: "col-span-1 flex justify-end"
}
const _hoisted_8 = { class: "flex justify-between items-center mt-2" }
const _hoisted_9 = { class: "flex items-center text-neo-classic-primary-accent" }
const _hoisted_10 = {
  key: 2,
  class: "mt-4"
}

import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import { userAvatarImage } from '@/helper/constants'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import {
  DotsHorizontalIcon,
  Pencil01Icon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { inject, ref, h, computed } from 'vue'
import { CommentPrivacy } from '@/models/Product'
import { CommentVisibility } from '@/models/Comment'
import { getUserId } from '@/services'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleCommentBlock',
  props: {
  comment: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  editableCommentId: {
    type: String,
    default: '',
  },
  instructor: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['onEdit', 'onDelete'],
  setup(__props, { emit: __emit }) {

const props = __props

const commentActions = [
  {
    label: 'Edit',
    key: 'edit',
    icon() {
      return h(renderIcon(Pencil01Icon), null, {})
    },
  },
  {
    type: 'divider',
    key: 'd1',
  },
  {
    label: 'Delete',
    key: 'delete',
    icon() {
      return h(renderIcon(Trash01Icon), { class: 'text-neo-classic-error' }, {})
    },
  },
]

const commentPrivacy: Array<{
  label: string
  key: CommentPrivacy
  visibility: CommentVisibility
  icon: any
}> = inject('commentPrivacy') || []

const replyToComment = ref('')
const store = useStore()

const emit = __emit

const isCommentBelongToCurrentUser = computed(() => {
  return getUserId() === props.comment.userdetails?.id
})

const clientPortalUserData = computed(() => store.getters.clientPortalUserData)

const commentAvatar = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId)
    return userAvatarImage

  if (!props.comment?.userdetails)
    return props.instructor?.headshot || userAvatarImage

  if (props.comment.userdetails?.id === getUserId()) {
    return clientPortalUserData.value?.avatar || store.getters.userAvatar || userAvatarImage
  }

  return props.comment?.userdetails?.avatar || userAvatarImage
})

const userName = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId)
    return 'Deleted User'
  return props.comment?.userdetails && props.comment?.userdetails?.name
    ? props.comment?.userdetails?.name
    : props.instructor && props.instructor.name
    ? props.instructor.name
    : 'Instructor'
})

function toggleReplyComment(commentId: string) {
  if (replyToComment.value === commentId) {
    replyToComment.value = ''
  } else {
    replyToComment.value = commentId
  }
}

function handleCommentAction(key: string) {
  switch (key) {
    case 'edit': {
      emit('onEdit', props.comment.id)
      break
    }
    case 'delete':
      emit('onDelete', props.comment.id)
      break
    default:
      break
  }
}

function getPrivacy(value: CommentVisibility) {
  return commentPrivacy.find((cp) => cp.visibility === value)
}

function commentCreatedAt(time: string) {
  return moment(time).fromNow()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: __props.comment.id,
    class: "mb-4 grid grid-cols-2 single-comment-block-wrapper"
  }, [
    _createElementVNode("img", {
      src: commentAvatar.value,
      class: "w-8 h-8 rounded-full user-avatar",
      alt: 'comment-avatar' + userName.value
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (__props.editableCommentId === __props.comment.id)
        ? _renderSlot(_ctx.$slots, "edit-section", { key: 0 })
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["user-comment-wrapper", isCommentBelongToCurrentUser.value ? 'col-span-11' : 'col-span-12'])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createTextVNode(_toDisplayString(userName.value) + " ", 1),
                _createElementVNode("span", _hoisted_6, _toDisplayString(commentCreatedAt(__props.comment.createdAt)), 1)
              ]),
              _createVNode(RichTextViewer, {
                html: __props.comment.content,
                replaceImages: false,
                replaceLinks: false
              }, null, 8, ["html"])
            ], 2),
            (isCommentBelongToCurrentUser.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(UIDropdown), {
                    id: "comment-actions-dropdown",
                    options: commentActions,
                    placement: "bottom",
                    onSelect: handleCommentAction
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(DotsHorizontalIcon), { class: "w-5 h-5 cursor-pointer" })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(Button, {
          class: "text-neo-classic-secondary-text focus:outline-none outline-none",
          id: `comment-${__props.comment.id}-reply-btn`,
          variant: "text",
          onClick: _cache[0] || (_cache[0] = () => toggleReplyComment(__props.comment.id))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Reply ")
          ])),
          _: 1
        }, 8, ["id"]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(getPrivacy(__props.comment.visibility)?.icon), { class: "mr-1" })),
          _createTextVNode(" " + _toDisplayString(getPrivacy(__props.comment.visibility)?.label), 1)
        ])
      ]),
      (replyToComment.value === __props.comment.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "at-end", _normalizeProps(_guardReactiveProps({
            onCancel: () => toggleReplyComment(__props.comment.id),
            isReplying: true,
            replyToComment: replyToComment.value,
            parentCommentId: __props.comment.parentCommentId || __props.comment.id,
            repliedToComment: __props.comment.content,
            repliedToUserId: __props.comment.userId,
            repliedToCommentPrivacy: __props.comment.visibility,
          })))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}
}

})