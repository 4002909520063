import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full bg-white mt-5 px-10 pb-10" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white cursor-pointer"
}
const _hoisted_3 = {
  key: 1,
  class: "flex text-sm pb-5"
}
const _hoisted_4 = { class: "text-gray-500 flex-1 text-sm p-1 pt-2 pr-2" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "ml-1" }
const _hoisted_8 = {
  key: 1,
  class: "flex items-center cursor-pointer"
}
const _hoisted_9 = { class: "ml-1" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 0,
  class: "transform rotate-90 mr-1"
}
const _hoisted_12 = {
  key: 1,
  class: "mr-1 pt-px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_UIDropdown = _resolveComponent("UIDropdown")!
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_CommentBlock = _resolveComponent("CommentBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-xl font-semibold py-2 text-center text-gray-600" }, " COMMENTS ", -1)),
    (_ctx.commentPermission === 'enabled')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_editor, {
            editorText: _ctx.contentText,
            "onUpdate:editorText": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentText) = $event)),
            ref: "editorRef"
          }, null, 8, ["editorText"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.commentPermission === 'enabled')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.replyTextLength) + " / " + _toDisplayString(_ctx.commentCharacterLength), 1),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.productCommentPrivacy === 'publicAndInstructor')
              ? (_openBlock(), _createBlock(_component_UIDropdown, {
                  key: 0,
                  id: "privacy-option-dropdown",
                  options: _ctx.commentPrivacy,
                  onSelect: _ctx.selectPrivacyOption
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_BaseIcon, {
                        name: _ctx.selectedPrivacyOption.iconname,
                        hwClass: "h-5 w-5"
                      }, null, 8, ["name"]),
                      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedPrivacyOption.label), 1),
                      _createVNode(_component_ChevronDownIcon, { class: "w-4 h-4" })
                    ])
                  ]),
                  _: 1
                }, 8, ["options", "onSelect"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_BaseIcon, {
                    name: _ctx.selectedPrivacyOption.iconname,
                    hwClass: "h-5 w-5"
                  }, null, 8, ["name"]),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.selectedPrivacyOption.label), 1)
                ])),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.createComment && _ctx.createComment(...args))),
              disabled: _ctx.postButtonDisabled || _ctx.posting,
              class: _normalizeClass(["flex items-center border rounded ml-2 cursor-pointer w-20 text-center justify-center", 
            _ctx.postButtonDisabled || _ctx.posting
              ? 'bg-gray-100 text-gray-500'
              : 'bg-blue-100 text-blue-500'
          ])
            }, [
              (!_ctx.posting)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_BaseIcon, {
                      name: "paperairplane",
                      hwClass: "w-5 h-5"
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_UISpinner, { size: "extra-small" })
                  ])),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "pl-1" }, "Post", -1))
            ], 10, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
        return (_openBlock(), _createBlock(_component_CommentBlock, {
          key: comment.id,
          comment: comment,
          instructor: _ctx.instructor,
          commentPermission: _ctx.commentPermission,
          pendoPayload: _ctx.pendoPayload,
          productCommentPrivacy: _ctx.productCommentPrivacy,
          scrollCommentId: _ctx.commentIdToScroll
        }, null, 8, ["comment", "instructor", "commentPermission", "pendoPayload", "productCommentPrivacy", "scrollCommentId"]))
      }), 128))
    ]),
    (_ctx.showViewMore && _ctx.comments?.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.findComments && _ctx.findComments(...args))),
          class: "border-0 rounded-sm text-center bg-blue-200 text-blue-700 py-1 cursor-pointer"
        }, " View More "))
      : _createCommentVNode("", true)
  ]))
}