import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_2 = { class: "lg:grid lg:grid-cols-5 gap-4 my-10" }
const _hoisted_3 = {
  key: 0,
  class: "md:col-span-2"
}
const _hoisted_4 = { class: "flex-1 ml-2 py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shimmer = _resolveComponent("shimmer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["max-w-xl lg:max-w-6xl w-full mx-auto", 
      _ctx.isPortalActive && !_ctx.newMobileScreens
        ? 'pt-14'
        : _ctx.newMobileScreens
        ? 'pt-4 pb-16'
        : 'py-6'
    ])
  }, [
    (_ctx.newMobileScreens)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_shimmer, { class: "h-3 my-1 w-3/12 rounded-lg" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["md:col-span-3 rounded-sm bg-white p-8", _ctx.newMobileScreens ? '' : 'shadow'])
      }, [
        (!_ctx.newMobileScreens)
          ? (_openBlock(), _createBlock(_component_shimmer, {
              key: 0,
              class: "h-3 my-1 w-3/12 rounded-lg"
            }))
          : _createCommentVNode("", true),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (i) => {
          return _createElementVNode("div", {
            class: "flex justify-start items-center mb-2",
            key: i
          }, [
            (!_ctx.newMobileScreens)
              ? (_openBlock(), _createBlock(_component_shimmer, {
                  key: 0,
                  class: "h-16 my-1 w-16 rounded-lg"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["flex w-full justify-between", _ctx.newMobileScreens ? 'h-16' : ''])
            }, [
              _createVNode(_component_shimmer, {
                class: _normalizeClass(["h-5 my-1 rounded-lg", _ctx.newMobileScreens ? 'w-full mr-10' : 'w-6/12 mx-10'])
              }, null, 8, ["class"]),
              _createVNode(_component_shimmer, { class: "h-3 my-1 w-5 rounded-lg" })
            ], 2)
          ])
        }), 64))
      ], 2),
      (!_ctx.hideExtra)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["items-center rounded-sm bg-white p-8 mb-5", _ctx.newMobileScreens ? '' : 'shadow'])
            }, [
              _createVNode(_component_shimmer, { class: "rounded w-2/3 h-3" }),
              _createVNode(_component_shimmer, { class: "h-3 my-1 w-full rounded-lg" })
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["flex items-center rounded-sm bg-white p-8", _ctx.newMobileScreens ? '' : 'shadow'])
            }, [
              _createVNode(_component_shimmer, { class: "rounded-full w-16 h-16" }),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                  return _createVNode(_component_shimmer, {
                    class: "h-3 my-1 w-full rounded-lg",
                    key: i
                  })
                }), 64))
              ])
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}