<template>
  <div v-if="renderComponent">
    <div
      id="mobile-navigation"
      class="lg:hidden max-w-xs fixed bottom-0 left-1/2 transform -translate-y-5 -translate-x-1/2 w-10/12 h-16 bg-white rounded-full flex items-center justify-between py-4 px-3 shadow-xl border border-#607179 border-opacity-10"
    >
      <div @click="updateActiveLibrary(LIBRARY_MENU_ITEMS.allCourses)">
        <router-link id="library-all-courses" :to="{ name: libraryPath }">
          <span
            class="flex items-center text-#607179 justify-center p-2 rounded-full w-10 h-10"
            :class="
              active === LIBRARY_MENU_ITEMS.allCourses
                ? `text-white ${getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            "
          >
            <BaseIcon name="scholar" />
          </span>
        </router-link>
      </div>
      <div @click="updateActiveLibrary(LIBRARY_MENU_ITEMS.myCourses)">
        <router-link id="library-my-courses" :to="{ name: libraryPath }">
          <span
            class="flex items-center justify-center p-2 rounded-full w-10 h-10"
            :class="
              active === LIBRARY_MENU_ITEMS.myCourses
                ? `text-white ${getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            "
          >
            <BaseIcon name="book" />
          </span>
        </router-link>
      </div>
      <div @click="updateActiveLibrary(LIBRARY_MENU_ITEMS.search)">
        <router-link id="library-search" :to="{ name: libraryPath }">
          <span
            class="flex items-center justify-center p-2 rounded-full w-10 h-10"
            :class="
              active === LIBRARY_MENU_ITEMS.search
                ? `text-white ${getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            "
          >
            <BaseIcon name="search" />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { getMobileTheme } from '../../helper'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  data() {
    return {
      LIBRARY_MENU_ITEMS,
    }
  },
  methods: {
    updateActiveLibrary(val: string) {
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', val)
      window.scrollTo(0, 0)
    },
  },
  computed: {
    libraryPath(): string {
      return 'library-v2'
    },
    active(): string {
      return this.$store.getters.activeLibrary
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    renderComponent(): boolean {
      const renderRoutes = [
        'library-v2',
        'library-v2-v2',
        'category-overview',
        'category-overview-v2',
        'category-list',
        'category-list-v2',
      ]
      const routeName = this.$route.name
      if (this.newMobileScreens && renderRoutes.find((r) => r === routeName)) {
        return true
      }
      return false
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
})
</script>
