import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "w-full" }

import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import { UISkeleton } from '@gohighlevel/ghl-ui'

export default /*@__PURE__*/_defineComponent({
  __name: 'LessonPageLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Paper, { class: "player-height p-4" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-lg" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-4 mt-4" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(UISkeleton), { class: "w-1/2 h-6" }),
          _createVNode(_unref(UISkeleton), { class: "w-11/12 h-6" })
        ]),
        _createVNode(_unref(UISkeleton), { class: "w-full h-6" }),
        _createVNode(_unref(UISkeleton), { class: "w-full h-6" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-4 mt-4 h-20" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-4 mt-4 h-48" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full" })
      ]),
      _: 1
    })
  ]))
}
}

})