<template>
  <div
    v-if="openModal"
    class="fixed z-50 inset-0 overflow-y-auto"
    :class="[isPortalActive ? 'pt-14' : '']"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-900 opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="ui-modal inline-block relative align-start rounded-lg text-left overflow-scroll bg-white shadow-xl transform transition-all h-9/10-screen my-8 lg:align-start md:align-start w-9/12 md:8/12 lg:w-6/12"
        :class="
          offer.products && offer.products.length > 3
            ? 'md:h-9/10-screen'
            : 'md:h-6/10-screen'
        "
        style="background-color: #f5f5f5"
      >
        <div class="flex justify-between items-center mt-2 p-2">
          <span class="px-2 font-semibold">{{ offer.title }}</span>
          <div class="px-2 cursor-pointer" @click="$emit('close')">
            <BaseIcon name="cross" hwClass="w-4 h-4" />
          </div>
        </div>
        <div class="flex flex-col py-2">
          <div class="px-2 flex flex-col md:pl-4 md:flex-row items-start mb-4">
            <div class="relative offer-poster-image w-full md:w-3/10 py-2">
              <img
                :src="
                  replaceBucketUrlWithCdnUrl(offer.posterImage) ||
                  defaultPosterImage
                "
                class="object-contain w-full rounded"
                :class="!offer.posterImage ? 'h-28' : 'h-full'"
              />
              <div
                class="absolute top-0 right-1 mx-3 md:mx-0 my-3 px-1 rounded text-gray-900 text-xs"
                style="background-color: #ffbc00"
              >
                Bundle
              </div>
            </div>
            <div class="flex flex-col w-full md:w-9/12 md:px-2">
              <div
                class="flex justify-between text-lg font-semibold text-gray-900 items-center lg:items-start md:px-2"
              ></div>
              <div
                v-if="offerDescription"
                v-html="offerDescription"
                class="px-2 text-base custom-list-styling editor-content"
              ></div>
              <div v-else class="px-2 my-4 text-base md:h-12">
                No Description Available
              </div>
              <div
                class="flex flex-col md:flex-row justify-center md:w-full md:justify-end mt-2 mb-4 md:ml-2"
              >
                <div
                  @click="$emit('checkout')"
                  class="p-2 text-center text-blue-600 text-xs cursor-pointer mb-2 md:mb-0"
                >
                  Learn More
                </div>
                <UISpinner
                  color="#4299e1"
                  v-if="loader"
                  class="min-w-48 flex justify-center items-center p-2 mx-3"
                  size="extra-small"
                />
                <div
                  class="flex items-center rounded bg-blue-500 text-white font-semibold p-2 min-w-48 upsell__purchase-btn mx-3"
                  :class="[
                    loader ? 'hidden' : 'block',
                    !isAdmin && 'cursor-pointer',
                  ]"
                  @click="checkout"
                >
                  <span
                    class="text-left inline-flex items-center"
                    :class="{
                      'opacity-75 cursor-not-allowed': disableCheckoutBtn,
                    }"
                  >
                    <UISpinner
                      color="#ffffff"
                      v-if="disableCheckoutBtn"
                      class="pr-2"
                      size="small"
                    />
                    <BaseIcon name="cart" hwClass="w-5 h-5 text-white" />
                  </span>
                  <span
                    class="inline-flex items-end pl-2 pr-2 text-center text-xs"
                  >
                    <span class="mr-1">Enroll for </span>
                    <span
                      id="offer-price-display-override"
                      v-if="offer.priceDisplayOverride"
                    >
                      {{ offer.priceDisplayOverride }}
                    </span>
                    <span id="offer-amount" v-else>
                      {{ fetchCurrencySymbol(offer.currency)
                      }}{{ Number(offer.amount).toFixed(2) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            id="bundle-products"
            class="px-2 grid md:grid-cols-3 row-gap-6 justify-center md:gap-5 mt-0 ml-3 lg:ml-0 justify-center"
          >
            <template :key="product.id" v-for="product in offer.products">
              <NewProductCard
                class="bg-white"
                :product-id="product.id"
                :product-title="product.title"
                :poster-image="product.posterImage"
                :instructor-name="product.customizations.instructorName"
                :instructor-headshot="product.customizations.instructorHeadshot"
                :upsellProduct="true"
                :label="
                  purchasedProductIds.indexOf(product.id) !== -1
                    ? 'in-library'
                    : 'locked'
                "
                :showProgress="false"
                @handleClick="$emit('checkout')"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      id="payment-form"
      class="px-5"
      v-if="showPaymentForm || showThankYouMsg"
    >
      <PaymentModal
        :showThankYouMsg="showThankYouMsg"
        :freeProduct="freeProduct"
        :contact="contact"
        :show-modal="showModal"
        @close="() => (showModal = false)"
        :location="location"
        :paymentMethods="paymentMethods"
        :locationId="offer.locationId"
        :offerId="offer.id"
        :isUpsell="true"
        :showCoupon="showCoupons"
        :upsellType="UpsellOfferType.INAPP"
        :offer="offer"
        :checkoutSource="CheckoutSourceType.INAPP"
        @order-successful="orderConfirmation"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { defaultPosterImage } from '../../helper/constants'
import CheckoutService from '@/services/CheckoutService'
import NewProductCard from './../product/NewProductCard.vue'
import { getContactId } from '@/services'
import { OfferType, CheckoutSourceType } from '@/models/Offer'
import { UpsellOfferType } from '@/models/UpsellOffer'
import PaymentModal from './../checkout/PaymentModal.vue'
import { adminMode } from '../../helper/permission.helper'
import UISpinner from '@/components/common/UISpinner.vue'
import http from './../../../http-common'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { Contacts, Stripe } from '@/helper/restAgent'
import { isClientPortal } from '@/helper'

export default defineComponent({
  components: { NewProductCard, PaymentModal, UISpinner, BaseIcon },
  props: {
    showUpsellModal: {
      type: Boolean,
      default: false,
    },
    offer: {
      type: Object,
    },
    purchasedProductIds: Array,
    upsellId: String,
  },
  data() {
    return {
      openModal: false,
      defaultPosterImage,
      contact: {} as { [key: string]: any },
      showModal: false,
      UpsellOfferType,
      CheckoutSourceType,
      showThankYouMsg: false,
      location: {},
      loader: false,
      disableCheckoutBtn: false,
      paymentMethods: [],
      optin: true,
      offerAlreadyPurchased: null,
    }
  },
  async mounted() {
    this.openModal = this.showUpsellModal
    await this.fetchOfferData()
  },
  computed: {
    showPaymentForm(): boolean {
      return (
        !this.optin &&
        !this.showThankYouMsg &&
        !this.freeProduct &&
        !this.offerAlreadyPurchased
      )
    },
    freeProduct(): boolean {
      return this.offer.type === OfferType.free
    },
    isAdmin() {
      return adminMode()
    },
    showCoupons(): boolean {
      return this.offer.type === OfferType.oneTime && this.offer.coupons
    },
    offerDescription(): string {
      return this.offer.description
        ? this.offer.description.length > 130
          ? this.offer.description.substring(0, 130) + '...'
          : this.offer.description
        : ''
    },
    isPortalActive() {
      return isClientPortal()
    },
  },
  methods: {
    orderConfirmation({ ct, previewUrl, upsellOfferId }) {
      this.showThankYouMsg = true
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
    async fetchOfferData() {
      try {
        this.loader = true
        const response = await CheckoutService.findById(this.offer.id)

        const { data } = response
        if (response?.status === 200) {
          this.location = data.location
        }
        const contactId = getContactId()
        this.contact = await Contacts.getContactById(
          this.offer.locationId,
          contactId
        )

        if (this.contact.stripeCustomerId) {
          await this.fetchPaymentMethods()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loader = false
      }
    },
    async fetchPaymentMethods() {
      try {
        const params: any = {
          stripeCustomerId: this.contact.stripeCustomerId,
          offerId: this.offer.id,
        }
        const { data } = await Stripe.getPaymentMethod(
          this.offer.locationId,
          params
        )
        this.paymentMethods = data
      } catch (error) {
        console.error(error)
      }
    },
    async checkout() {
      if (this.isAdmin) {
        return
      }
      this.disableCheckoutBtn = true
      await this.checkContactPurchasedOffer()
      if (
        !this.optin &&
        !this.showThankYouMsg &&
        !this.freeProduct &&
        !this.offerAlreadyPurchased
      ) {
        this.showModal = true
      }

      this.disableCheckoutBtn = false
    },
    async checkContactPurchasedOffer() {
      const {
        data: { purchased, loginUrl },
      } = await CheckoutService.checkIfContactPurchasedOffer({
        contactId: this.contact.id,
        offerId: this.offer.id,
        locationId: this.offer.locationId,
      })
      this.offerAlreadyPurchased = purchased
      if (this.offerAlreadyPurchased) {
        this.url = loginUrl
        return
      }
      await this.createLeadEntry()
      if (this.offer.type === OfferType.free) {
        await this.createFreePurchase()
      }
      this.disableSubmitBtn = false
      this.optin = false
    },
    async createFreePurchase() {
      const payload = {
        fingerprint: this.contact.fingerprint,
        locationId: this.contact.locationId,
        productType: 'offer',
        checkoutType: 'free',
      }

      await CheckoutService.checkout(this.offer.id, payload)
        .then((response) => {
          this.showThankYouMsg = true
          const { ct, previewUrl } = response.data
          this.cToken = ct
          this.url = previewUrl
          this.showModal = true
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        })
        .catch((error) => {
          console.error(error)
          const errorMessage = error?.response?.data?.msg
          this.errMsg =
            errorMessage || 'Something went wrong, please try again! '
        })
    },
    async createLeadEntry() {
      const payload = {
        contactId: this.contact.id,
        locationId: this.contact.locationId,
        offerId: this.offer.id,
      }
      await CheckoutService.leadTrack(payload)
    },
  },
  watch: {
    showUpsellModal(val: boolean) {
      this.openModal = val
    },
  },
})
</script>
<style scoped>
.ui-modal::-webkit-scrollbar {
  display: none;
}
</style>
