import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4 w-full" }

import Title from '@/components/neo-classic-theme/common/Title.vue'
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import { PropType } from 'vue'
import Assignment from '@/models/Assignment'
import AttachedFiles from '@/components/neo-classic-theme/common/AttachedFiles.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AssignmentPreview',
  props: {
  assignment: {
    type: Object as PropType<Assignment>,
    default: () => new Assignment(),
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Title, {
      tag: "h4",
      class: "text-center"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.assignment.title), 1)
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "after-title"),
    _createVNode(Title, {
      tag: "h4",
      class: "w-full"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Instructions")
      ])),
      _: 1
    }),
    _createVNode(RichTextViewer, {
      html: __props.assignment.introduction ? __props.assignment.introduction : '',
      replaceImages: false,
      replaceLinks: false
    }, null, 8, ["html"]),
    (__props.assignment.fileUrls?.length > 0)
      ? (_openBlock(), _createBlock(AttachedFiles, {
          key: 0,
          fileList: __props.assignment.fileUrls,
          wrapInPaper: false,
          showBorder: true
        }, {
          title: _withCtx(() => [
            _createVNode(Title, {
              tag: "h4",
              class: "w-full my-2"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Attached Files")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["fileList"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "at-end")
  ]))
}
}

})