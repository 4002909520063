import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full mt-8" }
const _hoisted_2 = {
  key: 0,
  class: "w-full mb-2"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full mb-2"
}
const _hoisted_4 = { class: "p-4 bg-neo-classic-hover-bg rounded-lg overflow-x-auto" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "text-center pt-4" }

import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import ResultMessageComponent from '@/components/neo-classic-theme/quiz/ResultMessageComponent.vue'
import AssignmentPreview from '@/components/neo-classic-theme/assignment/AssignmentPreview.vue'
import AttachedFiles from '@/components/neo-classic-theme/common/AttachedFiles.vue'
import Assignment from '@/models/Assignment'
import AssessmentStatus, {
  AssessmentStatusOptions,
} from '@/models/AssessmentStatus'
import { PropType, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const processingMessage =
  'Your assignment is currently under review by the instructor. We appreciate your patience during this process. You will receive feedback and your final grade once the review is complete.'


export default /*@__PURE__*/_defineComponent({
  __name: 'AssignmentLaunch',
  props: {
  assignment: {
    type: Object as PropType<Assignment>,
    required: true,
  },
  assessmentStatus: {
    type: Object as PropType<AssessmentStatus>,
    default: () => ({}),
  },
},
  setup(__props) {

const router = useRouter()
const route = useRoute()

const props = __props

const assignmentButtonText = computed(() => {
  if (!props.assessmentStatus) return 'Start assignment'

  return 'Retake Assignment'
})

const resultMessage = computed(() => {
  let message = ''
  switch (props.assessmentStatus?.status) {
    case AssessmentStatusOptions.processing:
      message = 'Your assignment is under review.'
      break
    case AssessmentStatusOptions.passed:
      message = 'You have passed the assignment.'
      break
    case AssessmentStatusOptions.failed:
      message = 'You failed the assignment.'
      break
  }

  return message
})

const launchAssignment = () => {
  router.push({
    name: 'new-assignment-overview',
    params: {
      id: route.params.id,
      category_id: route.params.category_id,
      post_id: route.params.post_id,
    },
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Paper, { class: "p-4 w-full" }, {
    default: _withCtx(() => [
      _createVNode(AssignmentPreview, { assignment: __props.assignment }, _createSlots({ _: 2 }, [
        (__props.assessmentStatus)
          ? {
              name: "after-title",
              fn: _withCtx(() => [
                _createVNode(ResultMessageComponent, {
                  assignmentType: "assignment",
                  class: "my-2",
                  resultMessage: resultMessage.value,
                  htmlConfirmationMessage: 
            __props.assessmentStatus.status === _unref(AssessmentStatusOptions).processing
              ? processingMessage
              : __props.assignment.confirmMessage
          ,
                  score: __props.assessmentStatus.score,
                  result: __props.assessmentStatus.status
                }, null, 8, ["resultMessage", "htmlConfirmationMessage", "score", "result"])
              ]),
              key: "0"
            }
          : undefined,
        (__props.assessmentStatus)
          ? {
              name: "at-end",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (__props.assessmentStatus.answer)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(Title, {
                          tag: "h4",
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode("Submitted Answer")
                          ])),
                          _: 1
                        }),
                        _createVNode(RichTextViewer, {
                          html: __props.assessmentStatus.answer,
                          replaceImages: false,
                          replaceLinks: false
                        }, null, 8, ["html"])
                      ]))
                    : _createCommentVNode("", true),
                  (__props.assessmentStatus.feedback)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(Title, {
                          tag: "h4",
                          class: "mb-2"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode("Feedback")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(RichTextViewer, {
                            html: __props.assessmentStatus.feedback,
                            replaceImages: false,
                            replaceLinks: false
                          }, null, 8, ["html"])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (__props.assessmentStatus.submission?.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(AttachedFiles, {
                          fileList: __props.assessmentStatus.submission,
                          wrapInPaper: false,
                          showBorder: true
                        }, {
                          title: _withCtx(() => [
                            _createVNode(Title, {
                              tag: "h4",
                              class: "w-full"
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("Submitted Files")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["fileList"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              key: "1"
            }
          : undefined
      ]), 1032, ["assignment"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(Button, {
          onClick: launchAssignment,
          disabled: __props.assessmentStatus?.status === _unref(AssessmentStatusOptions).passed
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(assignmentButtonText.value), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }))
}
}

})