import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-start h-screen"
}
const _hoisted_2 = {
  key: 1,
  class: "w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostDetailsV2Shimmer = _resolveComponent("PostDetailsV2Shimmer")!
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_CategoryPostLockedV2 = _resolveComponent("CategoryPostLockedV2")!
  const _component_LockedView = _resolveComponent("LockedView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CurriculamCommentsDrawerV2 = _resolveComponent("CurriculamCommentsDrawerV2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isPortalNavbar && !_ctx.newMobileScreens ? 'pt-16' : '')
  }, [
    (
      (_ctx.fetching || _ctx.fetchingPost || _ctx.fetchingDetails) &&
      _ctx.selectedTheme !== 'NeoClassic'
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.newMobileScreens && _ctx.isLevelUpItem)
            ? (_openBlock(), _createBlock(_component_PostDetailsV2Shimmer, { key: 0 }))
            : (_openBlock(), _createBlock(_component_UISpinner, {
                key: 1,
                size: "large"
              }))
        ]))
      : (
      (_ctx.isCategoryLocked || _ctx.parentCategoryLocked || _ctx.isActivePostLocked) &&
      _ctx.selectedTheme !== 'NeoClassic'
    )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.newMobileScreens)
              ? (_openBlock(), _createBlock(_component_CategoryPostLockedV2, {
                  key: 0,
                  lockedOverlayContentText: _ctx.lockedOverlayContentText,
                  lockedPost: _ctx.lockedPost,
                  lockedCategory: _ctx.lockedCategory,
                  hasProductAccess: _ctx.hasProductAccess,
                  showLockedButton: _ctx.showLockedButton
                }, null, 8, ["lockedOverlayContentText", "lockedPost", "lockedCategory", "hasProductAccess", "showLockedButton"]))
              : (_openBlock(), _createBlock(_component_LockedView, {
                  key: 1,
                  isCategoryLocked: _ctx.isCategoryLocked,
                  parentCategoryLocked: _ctx.parentCategoryLocked,
                  isActivePostLocked: _ctx.isActivePostLocked,
                  lockedOverlayContentText: _ctx.lockedOverlayContentText,
                  lockedPost: _ctx.lockedPost,
                  lockedCategory: _ctx.lockedCategory,
                  showLockedButton: _ctx.showLockedButton,
                  lockIcon: _ctx.lockIcon,
                  productId: _ctx.productId
                }, null, 8, ["isCategoryLocked", "parentCategoryLocked", "isActivePostLocked", "lockedOverlayContentText", "lockedPost", "lockedCategory", "showLockedButton", "lockIcon", "productId"]))
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["mx-auto md:px-0", [
      _ctx.newMobileScreens ? '' : 'px-4',
      _ctx.selectedTheme !== 'Neue' ? 'max-w-6xl' : '',
      _ctx.selectedTheme === 'NeoClassic' ? 'max-w-full font-sans' : 'max-w-6xl',
    ]])
          }, [
            (_ctx.routeName.includes('post-overview'))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.selectedTheme}LessonTheme`), {
                  key: 0,
                  isLoading: _ctx.fetching || _ctx.fetchingPost || _ctx.fetchingDetails,
                  product: _ctx.product,
                  instructor: _ctx.instructor,
                  activePost: _ctx.activePost,
                  category: _ctx.category,
                  videoOptions: _ctx.videoOptions,
                  lessonSequenceMap: _ctx.lessonSequenceMap,
                  videoCompletePercentage: _ctx.videoCompletePercentage,
                  audioCompletePercentage: _ctx.audioCompletePercentage,
                  nextPost: _ctx.nextPost,
                  assignment: _ctx.assignment,
                  assessmentStatus: _ctx.assessmentStatus,
                  playlist: _ctx.playlist,
                  lessonButton: _ctx.lessonButton,
                  isAdmin: _ctx.isAdmin,
                  categoryTree: _ctx.categoryTree,
                  nextLesson: _ctx.nextLesson,
                  disableActions: _ctx.disableActions,
                  fetchingPost: _ctx.fetchingPost,
                  nextCategoryId: _ctx.nextCategoryId,
                  completedPostIds: _ctx.completedPostIds,
                  markCompletedBtnCss: _ctx.markCompletedBtnCss,
                  postMarkedAsCompleted: _ctx.postMarkedAsCompleted,
                  postCompletionButtonText: _ctx.postCompletionButtonText,
                  disablePostCompletionButton: _ctx.disablePostCompletionButton,
                  fetchingPostCompletionStatus: _ctx.fetchingPostCompletionStatus,
                  totalPosts: _ctx.totalPosts,
                  completedPosts: _ctx.completedPosts,
                  allCompletedPosts: _ctx.allCompletedPosts,
                  isCategoryLocked: _ctx.isCategoryLocked,
                  isActivePostLocked: _ctx.isActivePostLocked,
                  onTogglePostCompletion: _ctx.togglePostCompletion,
                  onUpdateBackData: _ctx.updateBackData,
                  onStoreVideoProgress: _ctx.storeVideoProgress,
                  onUpdateVideoTime: _ctx.updateVideoTime,
                  onUpdateVideoPlay: _ctx.updateVideoPlay,
                  hasProductAccess: _ctx.hasProductAccess,
                  lockedPost: _ctx.lockedPost,
                  showLockedButton: _ctx.showLockedButton,
                  lockedCategory: _ctx.lockedCategory,
                  lockedOverlayContentText: _ctx.lockedOverlayContentText,
                  allCompletedPostsData: _ctx.allCompletedPostsData,
                  quiz: _ctx.quiz,
                  parentCategoryLocked: _ctx.parentCategoryLocked,
                  onUpdateAudioPlay: _ctx.updateAudioPlay,
                  onStoreAudioProgress: _ctx.storeAudioProgress,
                  onUpdateAudioTime: _ctx.updateAudioTime
                }, null, 40, ["isLoading", "product", "instructor", "activePost", "category", "videoOptions", "lessonSequenceMap", "videoCompletePercentage", "audioCompletePercentage", "nextPost", "assignment", "assessmentStatus", "playlist", "lessonButton", "isAdmin", "categoryTree", "nextLesson", "disableActions", "fetchingPost", "nextCategoryId", "completedPostIds", "markCompletedBtnCss", "postMarkedAsCompleted", "postCompletionButtonText", "disablePostCompletionButton", "fetchingPostCompletionStatus", "totalPosts", "completedPosts", "allCompletedPosts", "isCategoryLocked", "isActivePostLocked", "onTogglePostCompletion", "onUpdateBackData", "onStoreVideoProgress", "onUpdateVideoTime", "onUpdateVideoPlay", "hasProductAccess", "lockedPost", "showLockedButton", "lockedCategory", "lockedOverlayContentText", "allCompletedPostsData", "quiz", "parentCategoryLocked", "onUpdateAudioPlay", "onStoreAudioProgress", "onUpdateAudioTime"]))
              : (_openBlock(), _createBlock(_component_router_view, {
                  key: 1,
                  product: _ctx.product,
                  activePost: _ctx.activePost,
                  category: _ctx.category,
                  categoryTree: _ctx.categoryTree,
                  nextPost: _ctx.nextPost,
                  previousPost: _ctx.previousPostData,
                  playlist: _ctx.playlist,
                  instructor: _ctx.instructor,
                  lessonSequenceMap: _ctx.lessonSequenceMap,
                  assignment: _ctx.assignment,
                  assessmentStatus: _ctx.assessmentStatus,
                  fetchingPostCompletionStatus: _ctx.fetchingPostCompletionStatus,
                  disablePostCompletionButton: _ctx.disablePostCompletionButton,
                  isAdmin: _ctx.isAdmin,
                  totalPosts: _ctx.totalPosts,
                  isUngradedAssignment: _ctx.isUngradedAssignment,
                  markCompletedBtnCss: _ctx.markCompletedBtnCss,
                  postCompletionButtonText: _ctx.postCompletionButtonText,
                  onUpdateBackData: _ctx.updateBackData,
                  onTogglePostCompletion: _ctx.togglePostCompletion
                }, null, 8, ["product", "activePost", "category", "categoryTree", "nextPost", "previousPost", "playlist", "instructor", "lessonSequenceMap", "assignment", "assessmentStatus", "fetchingPostCompletionStatus", "disablePostCompletionButton", "isAdmin", "totalPosts", "isUngradedAssignment", "markCompletedBtnCss", "postCompletionButtonText", "onUpdateBackData", "onTogglePostCompletion"]))
          ], 2)),
    (_ctx.newMobileScreens)
      ? (_openBlock(), _createBlock(_component_CurriculamCommentsDrawerV2, {
          key: 3,
          categoryTree: _ctx.categoryTree,
          totalPosts: _ctx.totalPosts,
          allCompletedPostsData: _ctx.allCompletedPostsData,
          commentPermission: _ctx.activePost?.commentPermission,
          product: _ctx.product,
          category: _ctx.category,
          activePost: _ctx.activePost,
          productId: _ctx.productId,
          hasProductAccess: _ctx.hasProductAccess
        }, null, 8, ["categoryTree", "totalPosts", "allCompletedPostsData", "commentPermission", "product", "category", "activePost", "productId", "hasProductAccess"]))
      : _createCommentVNode("", true)
  ], 2))
}