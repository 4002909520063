<template>
  <div
    id="post-details-container mobilenewyork-template"
    class="max-w-xl mx-auto grid grid-rows-mobile-newyork-theme-layout mobile-newyork-template"
    :class="
      disableActions && disableActions.pointerEvents === 'none' ? 'px-2' : ''
    "
  >
    <div class="heading-video-wrapper">
      <div
        class="p-4 w-full text-center text-base bg-white font-medium"
        :class="[activePost.video && 'lg:pr-10']"
      >
        <span v-if="parentCategoryTitle"
          >{{
            parentCategoryTitle.length > mobileHeadingLimit
              ? parentCategoryTitle.slice(0, mobileHeadingLimit) + '...'
              : parentCategoryTitle
          }}
          |
        </span>
        <span v-if="category">{{
          category.title && category.title.length > mobileHeadingLimit
            ? category.title.slice(0, mobileHeadingLimit) + '...'
            : category.title
        }}</span>
        |
        <span v-if="activePost">{{
          activePost.title && activePost.title.length > mobileHeadingLimit
            ? activePost.title.slice(0, mobileHeadingLimit) + '...'
            : activePost.title
        }}</span>
      </div>
      <div class="mobile-video-post-body-container">
        <video-player
          id="post-video"
          v-if="activePost.video && videoOptions"
          class="items-center player-height"
          :loading="fetchingPost"
          :video="activePost && activePost.video"
          :assetUrl="activePost && activePost.assetUrls"
          :options="videoOptions"
          video-width="w-full lg:w-auto"
          :trackTime="true"
          :videoCompletePercentage="videoCompletePercentage"
          :video-thumbnail="activePost.posterImage"
          @getVideoTime="getVideoTime"
          @onPause="storeVideoProgress"
          @onEnded="storeVideoProgress"
          @onPlay="updateVideoPlay"
        />
        <audio-player
          v-else-if="activePost.audio"
          id="post-audio"
          :audio="activePost.audio"
          :thumbnail="activePost.posterImage"
          :post="activePost"
          :product="product"
          @getAudioTime="getAudioTime"
          @onPause="storeAudioProgress"
          @onEnded="storeAudioProgress"
          @onPlay="updateAudioPlay"
        />
      </div>
      <MobileNewYorkActionButtons
        v-if="activePost.video"
        class="border-b"
        :activePost="activePost"
        :previousPostData="previousPostData"
        :nextPostData="nextPostData"
        :postCompletionButtonText="postCompletionButtonText"
        :postMarkedAsCompleted="postMarkedAsCompleted"
        :fetchingPostCompletionStatus="fetchingPostCompletionStatus"
        :disablePostCompletionButton="disablePostCompletionButton"
        :isAdmin="isAdmin"
        @gotoPreviousPost="gotoPreviousPost"
        @goToNextPost="goToNextPost"
        @togglePostCompletion="togglePostCompletion"
      />
    </div>
    <div
      ref="postDetails"
      class="mobile-post-details py-5 px-4 overflow-y-scroll relative"
      @click="updatePostScrollDirection"
    >
      <div
        class="pb-8"
        :class="[
          expandPost && 'bg-white rounded',
          activePost.video && 'mb-2 lg:mx-10',
        ]"
      >
        <span
          class="custom-list-styling custom-word-break editor-content"
          v-html="postDescription"
        ></span>
        <span
          v-if="showFullDescription"
          class="text-blue-500 cursor-pointer pb-2"
          @click="showFullDescription = false"
          >Show Less</span
        >
        <span
          v-else-if="
            activePost.description && activePost.description.length > textLimit
          "
          class="text-blue-500 cursor-pointer pb-2"
          @click="showFullDescription = true"
          >Show More</span
        >
      </div>
      <div
        class="flex justify-center fixed left-1/2 -translate-x-1/2 transform transition ease-in-out duration-300 pb-4"
        :class="[
          !activePost.video ? 'mobile-files-comments-chip' : 'bottom-0',
          textPostScrollDirection && textPostScrollDirection === 'down'
            ? 'translate-y-full mfc-chip-extra-animation'
            : 'translate-y-0',
        ]"
      >
        <div
          class="grid grid-cols-2 items-center divide-x max-w-xl border bg-white rounded-full"
        >
          <button
            @click="goToMaterials"
            class="focus:bg-gray-100 focus:outline-none rounded-full py-2 px-4"
            :class="getMobileTheme.primaryText"
          >
            <span class="relative w-full h-full block">
              <span class="flex items-center">
                <BaseIcon name="files" hwClass="h-5 w-5" />
              </span>
              <span
                v-if="activePost.materials"
                class="m-badge-4 ml-1 flex items-center justify-center absolute right-0 top-0 text-xxs bg-gray-100 rounded-full w-4 h-4 text-white"
                :class="getMobileTheme.secondary"
              >
                {{ activePost.materials.length }}
              </span>
            </span>
          </button>
          <button
            @click="goToComments"
            class="focus:bg-gray-100 focus:outline-none rounded-r-full relative py-2 px-4"
            :class="[
              activePost.commentPermission === 'hidden'
                ? 'bg-gray-200 focus:bg-gray-200 text-gray-500'
                : getMobileTheme.primaryText,
            ]"
          >
            <span class="relative w-full h-full block">
              <span class="flex items-center">
                <BaseIcon name="comment" hwClass="h-5 w-5" />
              </span>
              <span
                class="m-badge-4 ml-1 flex items-center justify-center absolute right-0 top-0 text-xxs bg-gray-100 rounded-full w-4 h-4 text-white"
                :class="getMobileTheme.secondary"
              >
                {{ totalCommentCount > 99 ? '99+' : totalCommentCount }}
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="items-center w-full flex flex-col z-10 bg-white"
      :class="[getMobileTheme.primaryText, isCpAppInstalled ? '' : 'pb-3']"
      v-if="!activePost.video"
    >
      <MobileNewYorkActionButtons
        class="border-t max-w-xl w-full"
        :activePost="activePost"
        :previousPostData="previousPostData"
        :nextPostData="nextPostData"
        :postCompletionButtonText="postCompletionButtonText"
        :postMarkedAsCompleted="postMarkedAsCompleted"
        :fetchingPostCompletionStatus="fetchingPostCompletionStatus"
        :disablePostCompletionButton="disablePostCompletionButton"
        :isAdmin="isAdmin"
        @gotoPreviousPost="gotoPreviousPost"
        @goToNextPost="goToNextPost"
        @togglePostCompletion="togglePostCompletion"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Category from '@/models/Category'
import {
  MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE_MOBILE,
  MOBILE_HEADING_MAX_LENGTH,
} from '@/helper/constants'
import { getMobileTheme, isClientPortal } from '@/helper'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import {
  findNextPostId,
  findPreviousPostId,
  getCurrentPostIndex,
} from '@/helper/index'
import MobileNewYorkActionButtons from '@/components/posts/MobileNewYorkActionButtons.vue'
import { CommentService, getUserId } from '@/services'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { isPwa } from '@gohighlevel/clientportal-core'
import { PostContentType } from '@/models/Post'

export default defineComponent({
  components: {
    VideoPlayer,
    MobileNewYorkActionButtons,
    BaseIcon,
    AudioPlayer,
  },
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    activePost: {
      type: Object,
    },
    category: {
      type: Object,
    },
    videoOptions: {
      type: Object,
    },
    playlist: {
      type: Array,
    },
    disableActions: {
      type: Object,
    },
    videoCompletePercentage: Number,
    postCompletionButtonText: String,
    postMarkedAsCompleted: Boolean,
    lessonButton: Object,
    isAdmin: Boolean,
    categoryTree: Array,
    fetchingPost: Boolean,
    fetchingPostCompletionStatus: Boolean,
    disablePostCompletionButton: Boolean,
    completedPostIds: {
      type: Set,
      default: new Set(),
    },
    derivePostDescription: Function,
    findSubstringInstances: Function,
    expandPostDetails: Function,
    gotoPreviousPost: Function,
    goToNextPost: Function,
  },
  async mounted() {
    if (this.activePost.contentType === this.postContentType.quiz) {
      this.$router.push({
        name: 'mobile-quiz-overview',
        params: {
          id: this.productId,
          category_id: this.categoryId,
          post_id: this.postId,
        },
        query: this.$route.query,
      })
      return
    }
    window.scrollTo(0, 0)
    await this.fetchCommentsCount()
    this.$emit('updateBackData', {
      name: 'category-overview',
      params: { id: this.productId, category_id: this.categoryId },
    })
    this.textPostScrollLocation()
    this.loadStylesheetTags()
  },
  computed: {
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    lessonSettings(): any {
      return this.product.customizations?.lessonSettings
    },
    templateId(): string {
      return this.$route.query.template_id
    },
    postDescription(): string {
      return this.showFullDescription
        ? this.activePost.description
        : this.activePost.description &&
            this.activePost.description.slice(0, this.textLimit)
    },
    isExpandable(): boolean {
      return this.activePost.description &&
        this.activePost.description.length > 5700
        ? true
        : false
    },
    themeSettings(): any {
      return this.product.customizations?.lessonSettings?.themeSettings
    },
    sections(): any {
      return this.product.customizations?.lessonSettings?.sections
    },
    postCompletedIcon(): string {
      if (this.sections?.lessonBody?.button?.color) {
        return this.sections?.lessonBody?.button?.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return this.postMarkedAsCompleted
        ? '#2f855a'
        : this.disablePostCompletionButton || this.isAdmin
        ? '#6b7280'
        : '#ffffff'
    },
    navigationButtonColor(): string {
      if (this.sections?.lessonBody?.navigationButton?.color) {
        return this.sections.lessonBody.navigationButton.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return '#709BE0'
    },
    parentCategoryTitle(): string {
      if (this.category.parentCategory) {
        const parentCategory = this.categoryTree.find(
          (node: CategoryTreeNode) => node.id === this.category.parentCategory
        )
        return parentCategory?.node.title
      }
      return null
    },
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'text-green-700'
      }
      return this.getMobileTheme.primaryText
    },
    availableCategoryTree(): any {
      return this.categoryTree.filter((node: any) => node.node.available)
    },
    availablePlayList(): Array<CategoryTreeNode> {
      return this.playlist.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
    },
    previousPostData(): any {
      return findPreviousPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    nextPostData(): any {
      return findNextPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    postContainer() {
      return this.$refs['postDetails']
    },
    featureFlags() {
      return this.$store.state.featureFlag
    },
    isCpAppInstalled(): boolean {
      return this.$store.getters.appFlags.isCpAppInstalled
    },
    extraHeight() {
      return this.isCpAppInstalled ? '3.125rem' : '4rem'
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  data() {
    return {
      parentCategory: null as Category,
      expandPost: false,
      readMorePost: null,
      post: {},
      showFullDescription: false,
      totalCommentCount: 0,
      textLimit: MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE_MOBILE,
      mobileHeadingLimit: MOBILE_HEADING_MAX_LENGTH,
      textPostScrollDirection: null,
      postContentType: PostContentType,
    }
  },
  beforeDestroy() {
    this.storeVideoProgress()
  },
  methods: {
    loadStylesheetTags() {
      const customStyle = document.createElement('link')
      customStyle.setAttribute('rel', 'stylesheet')
      customStyle.setAttribute('vmid', 'newYorkFonts')
      customStyle.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=Lato'
      )
      document.head.appendChild(customStyle)
    },
    updateVideoPlay() {
      this.$emit('updateVideoPlay')
    },
    storeVideoProgress() {
      this.$emit('storeVideoProgress')
    },
    getVideoTime(currentTime: Array<number>) {
      this.$emit('getVideoTime', currentTime)
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    storeAudioProgress() {
      this.$emit('storeAudioProgress')
    },
    getAudioTime(currentTime: Array<number>) {
      this.$emit('updateAudioTime', currentTime)
    },
    async fetchCommentsCount() {
      try {
        this.totalCommentCount =
          await CommentService.getTotalCommentsByUserIdForPost({
            postId: this.postId,
            userId: getUserId(),
            adminMode: this.isAdmin,
          }).then((res) => {
            if (res.data?.counts.length) {
              return res.data?.counts.reduce(
                (res, comment) => (res += comment.count),
                0
              )
            }
            return 0
          })
      } catch (error) {
        console.log('Something went wrong', error)
      }
    },
    togglePostCompletion() {
      this.$emit('togglePostCompletion')
    },
    goToMaterials() {
      this.$router.push({
        name: 'post-materials',
        params: {
          id: this.product.id,
          category_id: this.category.id,
          post_id: this.activePost.id,
        },
        query: this.$route.query,
      })
    },
    goToComments() {
      if (this.activePost.commentPermission === 'hidden') return
      this.$router.push({
        name: 'post-comments',
        params: {
          id: this.product.id,
          category_id: this.category.id,
          post_id: this.activePost.id,
        },
        query: this.$route.query,
      })
    },
    textPostScrollLocation() {
      if (!this.postContainer) return

      let lastLocation = 0
      this.postContainer.addEventListener('scroll', (event) => {
        const currentLocation = event.target.scrollTop
        if (
          currentLocation <= 0 ||
          event.target.offsetHeight + currentLocation >=
            event.target.scrollHeight
        ) {
          this.textPostScrollDirection = 'up'
          return
        }
        if (currentLocation > lastLocation) {
          this.textPostScrollDirection = 'down'
        } else {
          this.textPostScrollDirection = 'up'
        }
        lastLocation = currentLocation
      })
    },
    updatePostScrollDirection() {
      this.textPostScrollDirection = 'up'
    },
  },
})
</script>
<style scoped>
.backdrop-blur-sm {
  backdrop-filter: blur(4px);
}
.custom-word-break {
  word-break: break-word;
}
.mobile-newyork-template {
  max-height: calc(100vh - v-bind('extraHeight'));
  height: calc(100vh - v-bind('extraHeight'));
}
.mobile-post-details {
  word-break: break-word;
}
.m-badge-4 {
  right: -0.35rem;
  top: -0.35rem;
}
.mobile-files-comments-chip {
  bottom: 3.5rem;
}
.mfc-chip-extra-animation {
  --transform-translate-y: 120%;
}

@media only screen and (max-width: 1024px) {
  .mark-as-post-completed {
    background-color: transparent;
  }
}
</style>
