<template>
  <span>
    <OneComment
      :content="comment.content"
      :commentElem="comment"
      :id="comment.id"
      :instructor="instructor"
      @updateStateParentComment="updateStateParentComment"
      :commentPermission="commentPermission"
      @hideReplyList="hideReplyList"
      :pendoPayload="pendoPayload"
      :productCommentPrivacy="productCommentPrivacy"
    />
    <ReplyList
      v-if="showReplyList"
      :replies="comment.replies"
      :commentPermission="commentPermission"
      :pendoPayload="pendoPayload"
      :instructor="instructor"
      :parentComment="comment"
      :scrollCommentId="scrollCommentId"
    />
  </span>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import OneComment from './OneComment.vue'
import ReplyList from './ReplyList.vue'

export default defineComponent({
  data() {
    return {
      stateComment: {},
      showReplyList: true,
    }
  },
  components: {
    OneComment,
    ReplyList,
  },
  props: {
    comment: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    commentPermission: {
      type: String,
    },
    pendoPayload: {
      type: Object,
    },
    productCommentPrivacy: {
      type: String,
    },
    scrollCommentId: {
      type: String,
      default: null,
    },
  },
  methods: {
    updateStateParentComment(params) {
      this.stateComment.content = params.content
      this.stateComment.visibility = params.visibility
    },
    replyComment(params) {
      this.emitter.emit('replyComment', params)
    },
    hideReplyList(status: boolean) {
      this.showReplyList = status
    },
  },
  mounted() {
    this.stateComment = this.comment
  },
})
</script>
