import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "category-list"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "w-11/12 flex items-center justify-start gap-2 mx-auto my-2" }
const _hoisted_6 = { class: "w-11/12 mx-auto flex items-center gap-3 mt-2" }
const _hoisted_7 = { class: "w-11/12 mx-auto my-4" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "w-11/12 flex items-center justify-evenly mx-auto gap-4" }
const _hoisted_11 = { key: 1 }

import CategoryOverviewV2 from "@/components/mobile/CategoryOverviewV2.vue";
import CategorySyllabusV2 from "@/components/mobile/CategorySyllabusV2.vue";
import CategoryShimmerV2 from "@/components/shimmer/mobile/CategoryShimmerV2.vue";
import {
  computeNextPost,
  createCategoryPostTree,
  getMobileTheme,
  isClientPortal
} from "@/helper";
import { COURSE_DETAILS_V2_TABS, defaultCourseThumbnail } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl } from "@/helper/filter";
import { getProductAccessMessage } from "@/helper/offerAccess";
import CategoryTreeNode from "@/models/CategoryTreeNode";
import Product from "@/models/Product";
import { CategoryService, PostService, UserPurchaseService } from "@/services";
import {
  UIProgress,
  UITextMdMedium,
  UITextSmMedium,
  UITextXsMedium
} from "@gohighlevel/ghl-ui";
import { cloneDeep } from "lodash";
import { computed, onMounted, ref, toRaw, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CategoryList from "../CategoryList.vue";
import { PostContentType } from "@/models/Post";
import TabComponentV2 from "@/components/mobile/TabComponentV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryListV2',
  props: {
    product: {},
    instructor: {},
    allCompletedPostsData: {}
  },
  setup(__props: any) {

const props = __props;

const NODE_TYPES = {
  CATEGORY: "Category",
  POST: "Post",
  QUIZ: "Quiz",
  LESSON: "Lesson",
};

const route = useRoute();
const router = useRouter();
const store = useStore();

const selectedTab = ref('overview');
const categories = ref<any[]>([]);
const totalPosts = ref(0);
const completedPosts = ref(0);
const categoryIdsCompleted = ref(new Set<string>());
const categoryProgress = ref<Record<string, number>>({});
const fetching = ref(false);
const allCategories = ref<any[]>([]);
const lessonSequenceMap = ref(new Map<string, number>());
const categoryProgressCount = ref(
  new Map<string, { totalPosts: number; completedPosts: number }>()
);
const nextPostData = ref<Record<string, any>>({});
const source = ref(route.query.source as string);
const categoryTree = ref<Array<CategoryTreeNode>>([]);

const newMobileScreens = computed(() => store.getters.newMobileScreens);
const hasProductAccess = computed(() => toRaw(props.product)?._data?.hasProductAccess);
const productId = computed(() => route.params.id || props.product.id);
const device = computed(() => store.getters.device);
const postIdsCompleted = computed(() =>
  props.allCompletedPostsData?.completedPostIds
    ? props.allCompletedPostsData?.completedPostIds
    : new Set()
);
const productAccessMessage = computed(() =>
  getProductAccessMessage(hasProductAccess.value, props.product)
);

async function fetchCategories() {
  const allCategoriesResponse = await UserPurchaseService.getCategories({
    product_id: productId.value,
  });
  categories.value = allCategoriesResponse
    .filter((c: any) => c.parentCategory === null)
    .sort((a, b) => a.sequenceNo - b.sequenceNo);

  allCategories.value = allCategoriesResponse;
}

function fetchCompletedPosts() {
  categoryIdsCompleted.value = new Set(
    categories.value.reduce((acc, categoryElem) => {
      const { posts } = categoryElem;
      const categoryPostIds = posts.map((e) => e.id);
      const completedPostLengthInCategory = categoryPostIds.filter((element) =>
        postIdsCompleted.value?.has(element)
      ).length;
      if (
        categoryPostIds.length > 0 &&
        completedPostLengthInCategory === categoryPostIds.length
      ) {
        acc.push(categoryElem.id);
      }
      return acc;
    }, [] as string[])
  );

  completedPosts.value = postIdsCompleted.value?.size;
}

async function fetchNoOfPublishedPosts() {
  const publishedResp = await PostService.noOfPublishedPosts({
    product_id: productId.value,
  });
  totalPosts.value = publishedResp.count;
}

async function fetchCategoryProgress() {
  const categoriesIds = categories.value.map((c: any) => c.id);
  const resp = await CategoryService.fetchCategoriesProgress(
    productId.value,
    categoriesIds
  );
  categoryProgress.value = resp.reduce((agg, data) => {
    const { categoryId, progress } = data;
    const newProgress = {};
    newProgress[categoryId] = progress;
    return { ...agg, ...newProgress };
  }, {});
}

async function fetchCompletionData() {
  fetchCompletedPosts();
  await fetchNoOfPublishedPosts();
}

function getTotalCompletedPosts(c: any) {
  let count = 0;
  c.posts.forEach((p: any) => {
    if (postIdsCompleted.value?.has(p.id)) {
      count += 1;
    }
  });
  return count;
}

function updateCategoryProgressCount() {
  allCategories.value.forEach((c: any) => {
    if (c.parentCategory === null) {
      categoryProgressCount.value.set(c.id, {
        ...categoryProgressCount.value.get(c.id),
        totalPosts: c.posts.length,
        completedPosts: getTotalCompletedPosts(c),
      });
    } else {
      const categoryProgress = categoryProgressCount.value.get(c.parentCategory);
      const prevTotalPosts =
        categoryProgress && categoryProgress.totalPosts ? categoryProgress.totalPosts : 0;
      const prevCompletedPosts =
        categoryProgress && categoryProgress.completedPosts
          ? categoryProgress.completedPosts
          : 0;
      categoryProgressCount.value.set(c.parentCategory, {
        ...categoryProgress,
        totalPosts: c.posts.length + prevTotalPosts,
        completedPosts: getTotalCompletedPosts(c) + prevCompletedPosts,
      });
    }
  });
}


function handleNextPostClick() {
  if (!hasProductAccess.value || !totalPosts.value || !categoryTree.value.length) return;

  if (nextPostData.value.id || hasProductAccess.value) {
    const node = nextPostData.value?.node;
    if (node?.contentType === PostContentType.assignment) {
      router.push({
        name: "mobile-assignment",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        },
        query: {},
      });
    } else if (node?.contentType === PostContentType.quiz) {
      router.push({
        name: "mobile-quiz-overview",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        }
      });
    } else {
      router.push({
        name: "post-overview",
        params: {
          id: productId.value,
          category_id: nextPostData.value.parent,
          post_id: nextPostData.value.id,
        }
      });
    }
  }
}
/*
 @description: This function is used to get the lesson sequence map.
 @param {CategoryTreeNode[]} treeNodes - The tree nodes.
 @returns {Map<string, number>} - The lesson sequence map.
*/
function getLessonSequenceMap(treeNodes: CategoryTreeNode[]): Map<string, number> {
  const lessonSequenceMap = new Map<string, number>();
  let currentSequenceNo = 1;

  function traverseTree(node: CategoryTreeNode) {
    if (node.type === NODE_TYPES.POST) {
      lessonSequenceMap.set(node.id, currentSequenceNo);
      currentSequenceNo++;
    }

    // Traverse the children of the current node (if any)
    for (const child of node?.childs || []) {
      traverseTree(child);
    }
  }
  // Start the traversal from each root node in the array
  for (const treeNode of treeNodes) {
    traverseTree(treeNode);
  }

  return lessonSequenceMap;
}



onMounted(async () => {
  fetching.value = true;
  await fetchCompletionData();
  await fetchCategories();
  await fetchCategoryProgress();
  fetching.value = false;
  updateCategoryProgressCount();

  const categoryPostTree = createCategoryPostTree(null, allCategories.value);
  categoryTree.value = categoryPostTree;
  lessonSequenceMap.value = getLessonSequenceMap(categoryPostTree);


  nextPostData.value = computeNextPost(
    cloneDeep(categoryPostTree),
    postIdsCompleted.value
  );
});

function handleTabChange(tab: string) {
  selectedTab.value = tab;
}

onMounted(() => {
  const selected_tab = route.query?.selected_tab;
  if (selected_tab) {
    selectedTab.value = Array.isArray(selected_tab) ? selected_tab[0] : selected_tab
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (device.value === 'mobile')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (fetching.value)
            ? (_openBlock(), _createBlock(CategoryShimmerV2, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _unref(replaceBucketUrlWithCdnUrl)(_ctx.product?.posterImage) || _unref(defaultCourseThumbnail)
          ,
                  class: _normalizeClass(["w-full h-1/3 border-b border-gray-200", _unref(replaceBucketUrlWithCdnUrl)(_ctx.product?.posterImage) ? 'object-fill' : 'object-cover'])
                }, null, 10, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_unref(UITextXsMedium), { class: "text-[--accent-color] text-xs font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(totalPosts.value) + " " + _toDisplayString(totalPosts.value > 1 ? 'Lessons'
            : 'Lesson'), 1)
                    ]),
                    _: 1
                  }),
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-[--accent-color] text-xs font-bold rounded w-1 h-1" }, null, -1)),
                  _createVNode(_unref(UITextXsMedium), { class: "text-[--accent-color] text-xs font-bold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(categories.value.length) + " " + _toDisplayString(categories.value?.length
            > 1 ? 'Modules' : 'Module'), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_unref(UITextMdMedium), { class: "w-11/12 mx-auto font-semibold text-[#101828]" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.product.title), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(UIProgress), {
                    percentage: (completedPosts.value / totalPosts.value) * 100,
                    type: 'line',
                    indicatorPlacement: 'outside',
                    status: undefined,
                    showIndicator: false,
                    color: _unref(getMobileTheme)().primaryHexCode,
                    strokeWidth: 8,
                    indicatorTextColor: '#000000'
                  }, null, 8, ["percentage", "color"]),
                  _createVNode(_unref(UITextSmMedium), { class: "font-semibold text-[#101828]" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(completedPosts.value || 0) + "/" + _toDisplayString(totalPosts.value || 0), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["w-full rounded-lg flex items-center justify-center py-0 px-4 text-[13px] font-semibold text-white h-9 cursor-pointer", { 'clickable': (hasProductAccess.value || (totalPosts.value && categoryTree.value.length)) }]),
                    style: _normalizeStyle({
              backgroundColor: !hasProductAccess.value || !totalPosts.value || !categoryTree.value.length
                ? 'rgb(152 162 179)'
                : `${_unref(getMobileTheme)().primaryHexCode} !important`,
            }),
                    onClick: handleNextPostClick
                  }, [
                    (!hasProductAccess.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(productAccessMessage.value), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(completedPosts.value > 0 ? "Resume Learning" : "Start Learning"), 1))
                  ], 6)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(TabComponentV2, {
                    selectedTab: selectedTab.value,
                    tabOptions: _unref(COURSE_DETAILS_V2_TABS),
                    onTabChange: handleTabChange
                  }, null, 8, ["selectedTab", "tabOptions"])
                ]),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (selectedTab.value === 'syllabus')
                      ? (_openBlock(), _createBlock(CategorySyllabusV2, {
                          key: 0,
                          categoryTree: categoryTree.value,
                          allCompletedPostsData: _ctx.allCompletedPostsData,
                          totalPosts: totalPosts.value,
                          productId: productId.value,
                          hasProductAccess: hasProductAccess.value,
                          product: _ctx.product
                        }, null, 8, ["categoryTree", "allCompletedPostsData", "totalPosts", "productId", "hasProductAccess", "product"]))
                      : (_openBlock(), _createBlock(CategoryOverviewV2, {
                          key: 1,
                          product: _ctx.product
                        }, null, 8, ["product"]))
                  ]),
                  _: 1
                })
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(CategoryList, {
            product: _ctx.product,
            instructor: _ctx.instructor,
            allCompletedPostsData: _ctx.allCompletedPostsData
          }, null, 8, ["product", "instructor", "allCompletedPostsData"])
        ]))
  ]))
}
}

})