export default class Model {
  private _id: string
  private _locationId: string
  private _userId: string

  protected _data: any

  constructor(payload: { [key: string]: any }) {
    const { id, locationId, userId, ...data } = payload

    this._id = id
    this._locationId = locationId
    this._userId = userId
    this._data = data
  }

  get id(): string {
    return this._id
  }

  get locationId(): string {
    return this._locationId
  }

  get userId(): string {
    return this._userId
  }
}
