<script lang="ts" setup>
import Plyr from 'plyr'
import Hls from 'hls.js'
import 'plyr/dist/plyr.css'
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { getMobileTheme, extractVideoTypeFromUrl } from '@/helper'
import {
  videoPlayerControls,
  videoPlayerPlaybackSpeeds,
} from '@/helper/constants'
import { TRANSCODING_STATUS } from '@/models/Video'
import { VideoService } from '@/services'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  ScreenOrientation,
  ScreenOrientationResult,
} from '@capacitor/screen-orientation'
import { StatusBar } from '@capacitor/status-bar'
import { isMobileTablet } from '@/helper/device_info'

const props = defineProps({
  video: {
    type: Object,
    default: () => ({}),
  },
  assetUrl: {
    type: Object || null,
    default: () => null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  videoWidth: {
    type: String,
    default: 'w-full',
  },
  trackTime: {
    type: Boolean,
    default: false,
  },
  videoCompletePercentage: {
    type: Number,
    default: 0,
  },
  videoThumbnail: {
    type: String,
    default: '',
  },
  radius: {
    type: String,
    default: '0px',
  },
  themeColor: {
    type: String,
    default: '#00b2ff',
  },
  controlsBackground: {
    type: String,
    default: '',
  },
  defaultIconColors: {
    type: String,
    default: '#00b2ff',
  },
  menuColor: {
    type: String,
    default: '#ffffff',
  },
  textColor: {
    type: String,
    default: '#000000',
  },
})

const hideStatusBar = async () => {
  await StatusBar.hide()
}

const showStatusBar = async () => {
  await StatusBar.show()
}

const emit = defineEmits([
  'onPause',
  'onEnded',
  'onPlay',
  'onError',
  'getVideoTime',
])

const plyrVideo = ref<any>(null)
const player = ref<Plyr>(null)
const hls = ref<Hls>(null)
const retries = ref<number>(0)
const retryLimit = ref<number>(3)
const retriesHls = ref<number>(0)
const transcodingStatus = ref<string>('')
const playbackError = ref<string>('')
const watchTime = ref(0)
const lastUpdateTime = ref(0)
const isPlaying = ref(false)

const route = useRoute()
const store = useStore()

const primaryThemeColor = computed(() => {
  if (store.state.newMobileScreens) return getMobileTheme().primaryHexCode
  return props.themeColor || '#00b3ff'
})

const primaryTextColor = computed(() => {
  if (props.textColor) return props.textColor
  return primaryThemeColor.value
})

const postId = computed(() => {
  return route.params.post_id as string
})

onMounted(async () => {
  if (store.state.newMobileScreens) {
    ScreenOrientation.unlock()
    ScreenOrientation.addListener(
      'screenOrientationChange',
      (orientation: ScreenOrientationResult) => {
        if (
          orientation.type === 'landscape-primary' ||
          orientation.type === 'landscape-secondary'
        ) {
          hideStatusBar()
        } else {
          showStatusBar()
        }
      }
    )
  }
  await getTranscodingStatus()
  await checkAndExitPipMode()
  initPlayer()
})

/**
 * Checks if the current video is in Picture-in-Picture mode and exits it if it is.
 *
 * @return {Promise<void>} A promise that resolves when the Picture-in-Picture mode is exited.
 */
async function checkAndExitPipMode(): Promise<void> {
  if (document.pictureInPictureElement) {
    await document.exitPictureInPicture()
  }
}

onUnmounted(() => {
  if (store.state.newMobileScreens) {
    ScreenOrientation.removeAllListeners()
    ScreenOrientation.lock({ orientation: 'portrait' })
    showStatusBar()
  }
  if (hls.value) hls.value.destroy()
  if (player.value) player.value.destroy()
})

const videoUrl = computed(() => {
  return replaceBucketUrlWithCdnUrl(props?.assetUrl?.url || props.video.url)
})

function initPlayer(options?: { reInit?: boolean; useRawUrl?: boolean }) {
  if (options?.reInit) {
    // Clearing existing instances while re initializing player
    player.value?.destroy()
    hls.value?.destroy()
  }

  try {
    const [videoSource] = props.options.sources ?? []

    if (!videoSource) {
      console.error('Video source is not available')
      return
    }

    const videoOptions = {
      autoplay: false,
      speed: { selected: 1, options: videoPlayerPlaybackSpeeds },
      keyboard: { focused: true, global: true },
      fullscreen: {
        enabled: true,
        fallback: true,
        iosNative: true,
        container: null,
      },
      controls: store.state.newMobileScreens
        ? videoPlayerControls.filter((controlName) => controlName !== 'volume')
        : videoPlayerControls,
      storage: { enabled: true, key: 'plyr-video' },
    }

    if (
      options?.useRawUrl ||
      (transcodingStatus.value !== TRANSCODING_STATUS.COMPLETED &&
        !props.assetUrl?.url)
    ) {
      plyrVideo.value.src = videoUrl.value
      player.value = new Plyr(plyrVideo.value, {
        ...videoOptions,
      })
      initTriggers(player.value)
      const transcodingErrorPayload = {
        deviceName: navigator.userAgent,
        deviceType: isMobileTablet() ? 'mobile' : 'desktop',
        playbackError: playbackError.value,
      }
      VideoService.checkTranscodingError(postId.value, transcodingErrorPayload)
      return
    }

    if (Hls.isSupported()) {
      hls.value = new Hls()
      hls.value.loadSource(videoSource.src)

      hls.value.on(Hls.Events.LEVEL_LOADED, function () {
        const availableQualities = hls.value.levels.map((l) => l.height)
        availableQualities.unshift(0)
        const qualities = {
          default: 0,
          options: availableQualities,
          forced: true,
          onChange: (e) => updateQuality(e),
        }

        player.value = new Plyr(plyrVideo.value, {
          ...videoOptions,
          quality: qualities,
          i18n: {
            qualityLabel: {
              0: 'Auto',
            },
          },
        })
        initTriggers(player.value)
      })

      hls.value.on(Hls.Events.ERROR, function (error, data) {
        console.error(error)
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover', data)
              playbackError.value = `MEDIA_ERROR: ${data?.details}`
              emit('onError', data)
              //hls.value.recoverMediaError()
              break
            case Hls.ErrorTypes.NETWORK_ERROR:
              playbackError.value = `NETWORK_ERROR: ${data?.details}`
              if (retriesHls.value < retryLimit.value) {
                retriesHls.value += 1
                initPlayer({ reInit: true, useRawUrl: false })
                return
              }
              break
            default:
              playbackError.value = data.type
              break
          }
        }
      })

      hls.value.attachMedia(plyrVideo.value)
    } else if (plyrVideo.value.canPlayType('application/vnd.apple.mpegurl')) {
      plyrVideo.value.src = videoSource.src
      player.value = new Plyr(plyrVideo.value, {
        ...props.options,
        ...videoOptions,
      })
      initTriggers(player.value)
    } else {
      throw Error('Video player initialization failed.')
    }
  } catch (error) {
    console.error('Error while initializing player: ', error)
  }
}

function initTriggers(playerInstance: Plyr) {
  initEvents(playerInstance)
  initCurrentTime(playerInstance)

  playerInstance.once('loadedmetadata', () => {
    const isAlreadyStarted = playerInstance.playing
    if (isAlreadyStarted) playerInstance.stop()

    // Resume video if already started
    setTimeout(() => {
      if (isAlreadyStarted) playerInstance.play()
    }, 0)
  })
}

function initCurrentTime(playerInstance: Plyr) {
  playerInstance.once('canplay', () => {
    playerInstance.currentTime =
      (props.videoCompletePercentage * playerInstance.duration) / 100
    if (hls.value) hls.value.startLoad(playerInstance.currentTime)
  })
}

function initEvents(playerInstance: Plyr) {
  playerInstance.on('error', (e: any) => {
    // handle retries
    emit('onError', e)
    console.error(e)
    console.log('Error while initializing player... Retrying...')
    if (retries.value < retryLimit.value) {
      initPlayer({ reInit: true })
      retries.value += 1
    }
  })
  playerInstance.on('play', (e: any) => {
    // This is auto resume stream from last played time
    if (hls.value) hls.value.startLoad(playerInstance.currentTime)
    emit('onPlay', e)
    isPlaying.value = true
    lastUpdateTime.value = playerInstance.currentTime
  })
  playerInstance.on('pause', (e: any) => {
    emit('onPause', e)
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeToLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for pause: ', error)
    }
  })

  playerInstance.on('timeupdate', () => {
    emit('getVideoTime', [playerInstance.currentTime, playerInstance.duration])
  })

  playerInstance.on('ended', (e: any) => {
    try {
      if (isPlaying.value) {
        watchTime.value = playerInstance.currentTime - lastUpdateTime.value
        sendWatchTimeToLogs()
        isPlaying.value = false
        lastUpdateTime.value = 0
      }
    } catch (error: any) {
      console.error('Error while sending watch time logs for ended: ', error)
    }
    emit('onEnded', e)
  })
}

function updateQuality(newQuality) {
  if (newQuality === 0) {
    hls.value.currentLevel = -1 // -1 is for auto quality
  } else {
    hls.value.levels.forEach((level, levelIndex) => {
      if (level.height === newQuality) {
        console.log('Found quality match with ' + newQuality)
        hls.value.currentLevel = levelIndex
      }
    })
  }
}

async function getTranscodingStatus() {
  const params: any = {
    post_id: postId.value,
  }
  const response = await VideoService.findAll(params)
  transcodingStatus.value = response[0]?.transcodingStatus
}

async function sendWatchTimeToLogs() {
  if (watchTime.value > 0) {
    const logPayload = {
      locationId: props.video.locationId,
      watchTime: watchTime.value,
      postId: postId.value,
      videoUrl: replaceBucketUrlWithCdnUrl(props.video.url),
    }

    await VideoService.sendWatchtimeLogs(logPayload)
  }
}
</script>

<template>
  <main class="video-player-wrapper">
    <video
      v-if="video"
      :class="videoWidth"
      playsinline
      controls
      :src="videoUrl"
      :type="extractVideoTypeFromUrl(videoUrl)"
      id="plyr-video"
      ref="plyrVideo"
      :poster="replaceBucketUrlWithCdnUrl(videoThumbnail)"
    ></video>
  </main>
</template>

<style>
.video-player-wrapper {
  --plyr-color-main: v-bind('primaryThemeColor');
}

.video-player-wrapper .plyr.plyr--video {
  border-radius: v-bind('radius');
}

.video-player-wrapper #plyr-video,
.video-player-wrapper .plyr__video-wrapper {
  aspect-ratio: 16/9;
  height: 100%;
}
.video-player-wrapper .plyr__menu__container [role='menu'] {
  max-height: 8rem;
  overflow-y: auto;
  background: v-bind('menuColor');
  border-radius: 5px;
}

.video-player-wrapper .plyr__menu__container {
  background: v-bind('controlsBackground');
}

.video-player-wrapper .plyr__menu__container .plyr__control {
  color: v-bind('primaryTextColor');
  background: v-bind('controlsBackground');
}

.video-player-wrapper .plyr__menu__container .plyr__control:has(> div) {
  background: v-bind('controlsBackground');
  padding-top: 2px;
  border-radius: 5px;
}

.video-player-wrapper .plyr__volume input {
  display: none;
}
.video-player-wrapper .plyr__volume:hover input {
  display: block;
  position: absolute;
  left: -106%;
  top: -174%;
  transform: rotate(270deg);
  background: v-bind('controlsBackground');
  padding: 5px;
  border-radius: 0px;
  width: 100px;
  height: 22px;
}
</style>
