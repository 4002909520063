import http from '../../http-common'
import BaseService from './BaseService'
import Quiz from '@/models/Quiz'

export default class QuizService extends BaseService<Quiz> {
  protected subdomain = 'assessments/quiz'

  model() {
    return Quiz
  }
}
