import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pa-modal-title flex items-center justify-between w-full" }
const _hoisted_2 = { class: "pa-title font-semibold" }
const _hoisted_3 = { class: "pa-modal-body mt-2" }
const _hoisted_4 = { class: "p-1 pl-2 bg-gray-200 max-w-sm w-full rounded-lg flex items-center justify-between" }
const _hoisted_5 = { class: "select-all break-all pr-1" }
const _hoisted_6 = {
  key: 0,
  class: "hidden absolute copy-tooltip bg-gray-800 text-white rounded px-2 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["desktop-pwa-modal z-20 fixed top-0 left-0 w-full h-full transition transform duration-50 ease-in-out", _ctx.open ? 'translate-y-0' : '-translate-y-full'])
  }, [
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: _normalizeClass(["relative w-full h-full cursor-pointer transition-opacity delay-50 duration-300 ease-in-out bg-black-o-55", _ctx.open ? 'opacity-100' : 'opacity-0'])
    }, [
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
        class: _normalizeClass(["absolute p-4 rounded-lg top-4 left-1/2 transform -translate-x-1/2 bg-white cursor-default max-w-sm w-full transition transform duration-500 ease-in-out", _ctx.open ? 'translate-y-0' : '-translate-y-full'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.modalTitle), 1),
          _createElementVNode("button", {
            class: "flex items-center",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "cross",
              hwClass: "w-3"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "my-4 text-center text-gray-600" }, " To install this app in your Desktop, open this link in Chrome ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currentUrl), 1),
            _createElementVNode("button", {
              class: "bg-gray-400 hover:bg-gray-500 p-1 rounded-lg copy-link-btn relative flex items-center",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyLinkToClipboard && _ctx.copyLinkToClipboard(...args)))
            }, [
              _createVNode(_component_BaseIcon, {
                name: "copy",
                hwClass: "h-4 w-4"
              }),
              (_ctx.isCopied)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Copied! "))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 2)
    ], 2)
  ], 2))
}