<template>
  <Paper
    id="product-progress-stats"
    class="bg-white py-5 lg:p-4 px-4 text-base lg:text-lg rounded-lg font-sans lessons-card"
  >
    <img
      class="w-full h-full aspect-video object-cover rounded-lg"
      :src="replaceBucketUrlWithCdnUrl(posterImage) || defaultEmptyImage"
      alt="Product Poster Image"
    />
    <div>
      <Title
        tag="h5"
        id="product-title"
        class="text-black font-medium mt-6 font-sans"
      >
        {{ statMessage }}
      </Title>
      <ProgressBar
        :progress="progress"
        :show-percentage="false"
        class="mt-2"
        size="sm"
      />
    </div>
  </Paper>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { defaultEmptyImage } from '@/helper/constants'

const props = defineProps({
  completed: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  progressColor: {
    type: String,
    default: '',
  },
  progress: {
    type: Number,
    default: 0,
  },
  posterImage: {
    type: String,
    default: '',
  },
})

const percentCompleted = computed(() => {
  if (props.completed && props.total) {
    return Math.round((props.completed * 100) / props.total)
  }
  return 0
})

const statMessage = computed(() => {
  if (props.total === props.completed) {
    return `All ${props.total} Lessons are completed.`
  } else {
    return `${props.completed} of ${props.total} Lessons Completed`
  }
})
</script>
