import config from '../config'
import { currency, FIREBASE_STORAGE_BASE_URL } from './constants'

export const replaceBucketUrlWithCdnUrl = (value) => {
  try {
    if (!value || value?.includes(config.firebase.assetsUrl) || value?.includes(FIREBASE_STORAGE_BASE_URL)) {
      return value
    }

    let assetUrl
    if (typeof value === 'object') {
      assetUrl = value.backgroundImage
    } else {
      assetUrl = value
    }
    //check if blob is present in membership bucket
    const membershipBucketIndex = assetUrl.indexOf(
      config.firebase.membershipStorageBucket
    )
    //check if blob is present in central bucket
    const parentBucketIndex = assetUrl.indexOf(config.firebase.storageBucket)

    //check if url is already a central bucket cdn url
    const cdnIndex = Math.max(
      assetUrl.indexOf(config.firebase.cdnUrl),
      assetUrl.indexOf(config.firebase.oldCdnUrl)
    )

    //check if url is already a membership bucket cdn url
    const membershipCdnIndex = assetUrl.indexOf(
      config.firebase.membershipCdnUrl
    )

    let newAssetUrl

    //if its an url the lets append the cdn url
    if (membershipBucketIndex !== -1 || parentBucketIndex !== -1) {
      // identify the bucket name
      const bucket =
        membershipBucketIndex > -1
          ? config.firebase.membershipStorageBucket
          : config.firebase.storageBucket

      // identify the cdn url to append
      const bucketCdnUrl =
        membershipBucketIndex > -1
          ? config.firebase.membershipCdnUrl
          : config.firebase.cdnUrl

      // find out the bucket string position in the url
      const bucketIndex = Math.max(membershipBucketIndex, parentBucketIndex)
      const length = bucket.length
      let startIndex = bucketIndex + length

      // remove the google cloud url and append the cdn url
      if (assetUrl.substring(startIndex, startIndex + 2) === '/o') {
        startIndex += 2
      }
      newAssetUrl = `${bucketCdnUrl}${assetUrl.substring(
        startIndex,
        assetUrl.length
      )}`
    } else if (cdnIndex === -1 && membershipCdnIndex === -1) {
      // if its a path,its from new bucket and we append membership cdn url
      newAssetUrl = config.firebase.membershipCdnUrl + value
    }
    if (typeof value === 'object') {
      return membershipBucketIndex !== -1 || parentBucketIndex !== -1
        ? { backgroundImage: `url(${newAssetUrl}` }
        : value
    }

    return cdnIndex !== -1 || membershipCdnIndex !== -1 ? value : newAssetUrl
  } catch (e) {
    console.error('Error while replacing bucket url with cdn url --> ', e)
    return value
  }
}

export function fetchCurrencySymbol(locationCurrency: string) {
  if (locationCurrency) {
    return currency[locationCurrency]?.symbol
  }
  return ''
}

export function truncateText(text: string, MAX_LENGTH = 25): string {
  if (!text) return ''
  if (text.length <= MAX_LENGTH) return text
  return text.slice(0, MAX_LENGTH) + '...'
}

export const getStreamingUrl = (videoUrl) => {
  try {
    let transcodingURL
    const parentBucketIndex = videoUrl.indexOf(config.firebase.storageBucket)

    if (parentBucketIndex !== -1) {
      transcodingURL = config.transcodingURL
    } else {
      transcodingURL = config.transcodingURLV2
    }
    return transcodingURL
  } catch (e) {
    console.log(e)
    return config.transcodingURLV2
  }
}
