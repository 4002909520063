import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from 'vue'
import ProgressBar from '@/components/common/ProgressBar.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { defaultEmptyImage } from '@/helper/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'LessonsStats',
  props: {
  completed: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  progressColor: {
    type: String,
    default: '',
  },
  progress: {
    type: Number,
    default: 0,
  },
  posterImage: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props

const percentCompleted = computed(() => {
  if (props.completed && props.total) {
    return Math.round((props.completed * 100) / props.total)
  }
  return 0
})

const statMessage = computed(() => {
  if (props.total === props.completed) {
    return `All ${props.total} Lessons are completed.`
  } else {
    return `${props.completed} of ${props.total} Lessons Completed`
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Paper, {
    id: "product-progress-stats",
    class: "bg-white py-5 lg:p-4 px-4 text-base lg:text-lg rounded-lg font-sans lessons-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "w-full h-full aspect-video object-cover rounded-lg",
        src: _unref(replaceBucketUrlWithCdnUrl)(__props.posterImage) || _unref(defaultEmptyImage),
        alt: "Product Poster Image"
      }, null, 8, _hoisted_1),
      _createElementVNode("div", null, [
        _createVNode(Title, {
          tag: "h5",
          id: "product-title",
          class: "text-black font-medium mt-6 font-sans"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(statMessage.value), 1)
          ]),
          _: 1
        }),
        _createVNode(ProgressBar, {
          progress: __props.progress,
          "show-percentage": false,
          class: "mt-2",
          size: "sm"
        }, null, 8, ["progress"])
      ])
    ]),
    _: 1
  }))
}
}

})