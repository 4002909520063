import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex items-start gap-4 w-full p-3" }
const _hoisted_3 = { class: "flex flex-col items-start w-full gap-1" }
const _hoisted_4 = { class: "flex items-center justify-between w-full" }
const _hoisted_5 = { class: "flex items-center gap-2" }

import { UISkeleton } from "@gohighlevel/ghl-ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'CommentShimmerV2',
  props: {
  count: {
    type: Number,
    default: 4,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.count || 4, (items, index) => {
      return (_openBlock(), _createElementBlock("div", {
        id: "comment-skeleton",
        class: "flex flex-col gap-2 w-full",
        key: index
      }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "comment-date-skeleton my-2" }, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(UISkeleton), {
            height: "48px",
            width: "48px",
            circle: "",
            class: "min-h-12 min-w-12"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(UISkeleton), {
                height: "16px",
                width: "120px",
                sharp: false
              }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(UISkeleton), {
                  height: "12px",
                  width: "64px",
                  sharp: false
                }),
                _createVNode(_unref(UISkeleton), {
                  height: "1rem",
                  width: "1rem"
                })
              ])
            ]),
            _createVNode(_unref(UISkeleton), {
              height: "14px",
              width: "100%",
              class: "my-1",
              sharp: false
            }),
            _createVNode(_unref(UISkeleton), {
              height: "14px",
              width: "90%",
              class: "my-1",
              sharp: false
            }),
            _createVNode(_unref(UISkeleton), {
              height: "14px",
              width: "80%",
              class: "my-1",
              sharp: false
            }),
            _createVNode(_unref(UISkeleton), {
              height: "14px",
              width: "48px",
              class: "mt-2",
              sharp: false
            })
          ])
        ])
      ]))
    }), 128))
  ]))
}
}

})