import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  id: "completion-message",
  class: "font-semibold text-2xl text-white"
}
const _hoisted_4 = {
  key: 1,
  id: "partial-completion-message",
  class: "font-semibold text-xl text-white"
}
const _hoisted_5 = {
  key: 3,
  class: "text-center flex flex-col items-center justify-center"
}
const _hoisted_6 = {
  id: "product-details-container",
  class: "max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-5 gap-4 my-10"
}
const _hoisted_7 = { class: "lesson-stats-instructor-container col-span-3 md:col-span-2" }
const _hoisted_8 = { class: "category-post-list-container bg-white shadow col-span-3 course-background" }
const _hoisted_9 = { class: "h-screen overflow-y-scroll course-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Lock01Icon = _resolveComponent("Lock01Icon")!
  const _component_UITextLgMedium = _resolveComponent("UITextLgMedium")!
  const _component_LessonsStats = _resolveComponent("LessonsStats")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!
  const _component_CategoryPostTree = _resolveComponent("CategoryPostTree")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["default-post h-auto bg-no-repeat bg-cover bg-center bg-gray-300 hero-image w-full flex justify-center items-center", _ctx.heroSection.alignment]),
      id: "banner",
      style: _normalizeStyle(!_ctx.heroCustomBackground && {
          backgroundImage: `url(${_ctx.replaceBucketUrlWithCdnUrl(
            _ctx.heroSection.background
          )})`,
        }
        )
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle(!_ctx.heroCustomBackground &&
        `background-color: ${_ctx.heroSection.overlayColor}`
        ),
        class: "overlay"
      }, null, 4),
      _createElementVNode("div", {
        id: "hero-section",
        class: _normalizeClass(["hero-content xl:px-24 lg:x-16 md:px-12 px-6 w-full", _ctx.heroSpacing || _ctx.heroSection.spacing])
      }, [
        (_ctx.showLogo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_ctx.brandLogoUrl)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "brand-logo h-10 w-auto lg:h-12 md:h-12",
                    src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.brandLogoUrl),
                    alt: "Logo"
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "product-title",
          class: _normalizeClass(["font-bold text-4xl", _ctx.$route.name.includes('product-overview')
            ? 'primaryStyle hero-title'
            : ''
          ])
        }, _toDisplayString(_ctx.product?.title || 'Fetching Product..'), 3),
        (_ctx.product.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              id: "product-description",
              class: _normalizeClass(["text-xl py-2", _ctx.$route.name.includes('product-overview')
            ? 'secondaryStyle hero-description'
            : ''
          ])
            }, _toDisplayString(_ctx.product?.description), 3))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "product-status",
          class: _normalizeClass(["mt-8", _ctx.heroButton && _ctx.heroButton.textAlign
            ? `text-${_ctx.heroButton.textAlign}`
            : 'text-center'
          ])
        }, [
          (_ctx.courseCompleted)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Congrats!! You have completed this course! "))
            : _createCommentVNode("", true),
          (
            !_ctx.courseCompleted && !(_ctx.nextPost && _ctx.nextPost.id) && _ctx.hasProductAccess
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " You seem to have completed all available contents!! "))
            : _createCommentVNode("", true),
          (_ctx.nextPost && _ctx.nextPost.id && _ctx.hasProductAccess)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                id: "load-next-post",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleNextPostClick())),
                class: _normalizeClass(["inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 focus:outline-none transition duration-150 ease-in-out", _ctx.$route.name.includes('product-overview')
                ? 'primary-hero-button secondaryStyle hero-button'
                : ''
              ])
              }, _toDisplayString(_ctx.buttonText), 3))
            : _createCommentVNode("", true),
          (!_ctx.hasProductAccess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Lock01Icon, { class: "h-20 w-20" }),
                _createVNode(_component_UITextLgMedium, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.productAccessMessage), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ], 6),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LessonsStats, {
          completed: _ctx.completedPosts,
          total: _ctx.totalPosts,
          "progress-color": _ctx.progressColor,
          class: "mb-3"
        }, null, 8, ["completed", "total", "progress-color"]),
        _createVNode(_component_InstructorCard, { instructor: _ctx.instructor }, null, 8, ["instructor"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_CategoryPostTree, {
            level: 0,
            "category-tree": _ctx.categoryTree,
            "completed-posts": _ctx.postIdsCompleted,
            "completed-categories": _ctx.categoryIdsCompleted,
            isBackground: false,
            hasProductAccess: _ctx.hasProductAccess
          }, null, 8, ["category-tree", "completed-posts", "completed-categories", "hasProductAccess"])
        ])
      ])
    ])
  ]))
}