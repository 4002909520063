import config from '@/config'
import axios from 'axios'

export const extractAPIErrorMsg = (error) => {
  let errorMsg = error?.response?.data?.message
  if (Array.isArray(errorMsg)) errorMsg = errorMsg.join(', ')
  if (errorMsg?.includes('FDS'))
    errorMsg =
      'Your transaction has been declined. Please contact the business owner'
  return errorMsg
}

export async function verifyStripeOrderPayment(
  sourceParams,
  altId,
  altType,
  paymentIntent,
  setupIntent?
) {
  const { sourceId } = sourceParams
  const requestURL = `${config.paymentBaseUrl}/stripe/verify`

  const requestBody = {
    altId: altId,
    altType: altType,
    orderId: sourceId,
    paymentIntentId: paymentIntent,
    setupIntentId: setupIntent,
    isAuth: Boolean(setupIntent),
  }
  const headers = {}
  //if (sourceId) headers['x-parent-trace-context'] = sourceId
  const { data } = await axios.post(requestURL, requestBody, { headers })
  if (!data.success) throw new Error(data.message)
  return data
}
