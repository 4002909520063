import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded shadow border border-gray-200 postqueue-background" }
const _hoisted_2 = { class: "w-full flex justify-between items-center p-4 border-b" }
const _hoisted_3 = { class: "lessonSecondaryStyle postqueue-category" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NewYorkLessonPostTree = _resolveComponent("NewYorkLessonPostTree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.previousCategory && _ctx.previousCategory.category)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: _normalizeClass(_ctx.previousCategory && 'cursor-pointer'),
            tag: "div",
            event: _ctx.previousCategory ? 'click' : '',
            to: {
          name: _ctx.previousCategory.post ? 'post-overview' : 'category-overview',
          params: {
            id: _ctx.productId,
            category_id: _ctx.previousCategory && _ctx.previousCategory.category,
            post_id: _ctx.previousCategory && _ctx.previousCategory.post,
          },
          query: {
            template_id: _ctx.templateId,
          },
        },
            style: _normalizeStyle({ color: _ctx.previousCategory ? _ctx.categoryNavIcon : '#a0aec0' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseIcon, { name: "previousarrow" })
            ]),
            _: 1
          }, 8, ["class", "event", "to", "style"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString((_ctx.currentCategoryTree &&
            _ctx.currentCategoryTree.node &&
            _ctx.currentCategoryTree.node.title &&
            _ctx.currentCategoryTree.node.title.toLocaleUpperCase()) ||
          'Category'), 1),
      (_ctx.nextCategory && _ctx.nextCategory.category)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            class: _normalizeClass(_ctx.nextCategory && 'cursor-pointer'),
            tag: "div",
            event: _ctx.nextCategory ? 'click' : '',
            to: {
          name: _ctx.nextCategory.post ? 'post-overview' : 'category-overview',
          params: {
            id: _ctx.productId,
            category_id: _ctx.nextCategory && _ctx.nextCategory.category,
            post_id: _ctx.nextCategory && _ctx.nextCategory.post,
          },
          query: {
            template_id: this.templateId,
          },
        },
            style: _normalizeStyle({ color: _ctx.nextCategory ? _ctx.categoryNavIcon : '#a0aec0' })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseIcon, { name: "nextarrow" })
            ]),
            _: 1
          }, 8, ["class", "event", "to", "style"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.currentCategoryTree)
        ? (_openBlock(), _createBlock(_component_NewYorkLessonPostTree, {
            key: 0,
            categoryTree: _ctx.currentCategoryTree.childs,
            "completed-posts": _ctx.completedPosts,
            activePost: _ctx.activePost,
            "post-indexes": _ctx.postIndexes,
            highlightBackground: _ctx.highlightBackground,
            highlightColor: _ctx.highlightColor
          }, null, 8, ["categoryTree", "completed-posts", "activePost", "post-indexes", "highlightBackground", "highlightColor"]))
        : _createCommentVNode("", true)
    ])
  ]))
}