import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative cursor-pointer group" }
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 w-[102%] h-full bg-red border-2 border-slate-200 rounded-lg z-10 transition-transform duration-300 ease-in-out group-hover:translate-x-0"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute top-0 w-[104%] h-full bg-red border-2 border-slate-200 rounded-lg z-20 transition-transform duration-300 ease-in-out group-hover:shadow-xl group-hover:translate-x-1"
}
const _hoisted_4 = {
  key: 0,
  class: "rounded-lg block absolute top-0 left-0 z-1 w-full h-full",
  style: { backdropFilter: 'blur(16px)' }
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  id: "offer-price-display-override"
}
const _hoisted_7 = {
  key: 1,
  id: "offer-amount"
}
const _hoisted_8 = {
  key: 0,
  class: "absolute top-5 right-5 m-2 lg:px-4 p-2 rounded-full text-white font-semibold bg-warning-500"
}
const _hoisted_9 = {
  key: 0,
  class: "text-gray-700 mt-2 leading-normal font-normal text-sm"
}
const _hoisted_10 = {
  key: 1,
  class: "flex items-center justify-between w-full self-end"
}
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = { id: "instructor-headshot" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  id: "instructor-name",
  class: "text-sm font-semibold text-black pl-2"
}
const _hoisted_15 = ["title"]
const _hoisted_16 = {
  key: 1,
  id: "offer-amount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.offer?.products && _ctx.offer.products?.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.offer?.products && _ctx.offer.products?.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute top-0 z-30 border-2 shadow-sm bg-white border-slate-200 p-4 rounded-lg w-[100%] h-full transition-transform duration-300 ease-in-out group-hover:-translate-x-1", _ctx.newMobileScreens ? 'h-full' : ''])
    }, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openUpsell && _ctx.openUpsell(...args))),
        class: _normalizeClass(["w-full h-full z-10 flex justify-between flex-col", [
          _ctx.newMobileScreens
            ? !_ctx.notInCarousel
              ? 'shadow-none'
              : 'shadow rounded'
            : '',
        ]])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["flex justify-center items-center relative rounded-lg", 
            _ctx.newMobileScreens && !_ctx.notInCarousel
              ? 'shadow rounded-lg'
              : _ctx.notInCarousel
              ? ''
              : ''
          ])
        }, [
          (!_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("img", {
            class: "w-full h-full aspect-video object-cover rounded-lg",
            src: _ctx.posterImage,
            alt: "Course Poster"
          }, null, 8, _hoisted_5),
          (_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["absolute text-xs rounded text-white px-1 right-0 bottom-0 mr-2 mb-2 z-1", 
              _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : 'bg-nova-blue-600'
            ])
              }, [
                (_ctx.offer.priceDisplayOverride)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.offer.priceDisplayOverride), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.fetchCurrencySymbol(_ctx.offer.currency)) + _toDisplayString(Number(_ctx.offer.amount).toFixed(2)), 1))
              ], 2))
            : _createCommentVNode("", true),
          _createVNode(_component_BaseIcon, {
            name: "lockfilled",
            hwClass: "w-16 h-16",
            class: "absolute w-4 h-4 lg:w-12 lg:h-12 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2",
            style: { color: '#fff' }
          })
        ], 2),
        (_ctx.offer.products && _ctx.offer.products.length !== 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, " Bundle "))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["product-details lg:pb-2 lg:mb-1", _ctx.newMobileScreens ? '' : 'h-44'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["tracking-wide text-black font-medium text-lg", _ctx.newMobileScreens ? !_ctx.notInCarousel && 'text-center' : ''])
          }, _toDisplayString(_ctx.courseData.title || ''), 3),
          (!_ctx.newMobileScreens)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.courseData.description || ''), 1))
            : _createCommentVNode("", true)
        ], 2),
        (!_ctx.newMobileScreens)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("img", {
                    class: "rounded-lg aspect-square object-cover w-12",
                    src: _ctx.userAvatarImage,
                    alt: "Instructor"
                  }, null, 8, _hoisted_13)
                ]),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.truncateText(
                  _ctx.courseData.instructorName || 'Instructor Name',
                  _ctx.instructorNameLimit
                )), 1)
              ]),
              _createVNode(_component_Badge, {
                id: "bundled-card-button",
                variant: "primary",
                size: "medium",
                class: "max-w-32"
              }, {
                default: _withCtx(() => [
                  (_ctx.offer.priceDisplayOverride)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "overflow-hidden text-ellipsis whitespace-nowrap block",
                        title: _ctx.offer.priceDisplayOverride,
                        id: "offer-price-display-override"
                      }, _toDisplayString(_ctx.offer.priceDisplayOverride), 9, _hoisted_15))
                    : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.fetchCurrencySymbol(_ctx.offer.currency)) + " " + _toDisplayString('') + " " + _toDisplayString(Number(_ctx.offer.amount).toFixed(2)), 1))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ]))
}