<script setup lang="ts">
import { extractTextFromHTML } from "@/helper";
import { defaultCourseThumbnail, lockedPostIcon } from "@/helper/constants";
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
  truncateText,
} from "@/helper/filter";
import { computed } from "vue";


const props = defineProps(["offer", "upsellId"]);

const offerDescription = computed(() => {
  return truncateText(extractTextFromHTML(props.offer.description), 75)
})

const course = computed(() => {
  if (props.offer?.products?.length) {
    const product = props.offer.products[0];
    return {
      title: product.title,
      posterImage: product.posterImage,
      description: truncateText(product.description, 75),
      instructorName: product.customizations?.instructorName || "Instructor Name",
      instructorHeadshot: product.customizations?.instructorHeadshot,
    };
  } else {
    return {
      title: props.offer?.title,
      posterImage: props.offer?.posterImage,
      description: props.offer?.description && props.offer?.description,
      instructorName: "Multiple Instructors",
      instructorHeadshot: null,
    };
  }
});


</script>

<template>

  <div class="w-full flex items-center justify-between gap-4 my-4 hover:bg-slate-50 cursor-pointer">
    <div class="w-3/5 min-w-36  h-28 xs:h-32 sm:h-48 md:h-60 relative border rounded-md border-gray-300 aspect-video">
      <img :src="replaceBucketUrlWithCdnUrl(offer?.posterImage) ||
        defaultCourseThumbnail
        " :class="{
          'object-cover': !replaceBucketUrlWithCdnUrl(offer?.posterImage)
        }" alt="course-thumbnail " class="w-full h-full rounded-md object-contain  brightness-75" />

      <!-- Centered Lock Icon -->
      <div class="absolute inset-0 flex justify-center items-center">
        <img :src="lockedPostIcon" alt="lock-icon" class="h-6 w-6 text-[--accent-color] object-cover" />
      </div>

      <!-- Bundle Tag in Top Right -->
      <div class="course-tag absolute rounded-3xl py-0.5 px-2 bg-[--accent-color]" style="top: 0.5rem; right: 0.5rem;"
        v-if="offer?.products && offer?.products?.length > 1">
        <span class="text-white text-xs font-medium">Bundle</span>
      </div>
    </div>

    <div class="h-full w-3/4 flex flex-col items-start justify-around gap-2">
      <div v-show="course.title" class="text-gray-900 font-bold text-sm sm:text-lg   line-clamp-2">
        {{ truncateText(offer.title, 50) }}
      </div>
      <div v-show="offer.description" class="line-clamp-2 sm:text-sm break-all">
        {{ offerDescription }}
      </div>
      <div class="w-full text-start flex justify-between items-center text-gray-400 font-medium text-sm   gap-2">
        <span class="text-gray-400 font-medium text-sm sm:text-md   w-2/5 text-start">{{ props.offer.products?.length }}
          Products</span>

        <span class="text-[--accent-color] text-xs font-semibold sm:text-md break-all w-2/5 text-end"
          v-if="offer.priceDisplayOverride">
          {{ truncateText(offer.priceDisplayOverride) }}
        </span>
        <span class="text-[--accent-color] text-xs font-semibold sm:text-md break-all w-2/5 text-end" v-else>
          {{ fetchCurrencySymbol(offer.currency) }} {{ offer.amount }}
        </span>
      </div>
    </div>

  </div>

</template>

<style></style>
