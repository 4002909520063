import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-10 inset-0 overflow-y-auto",
  "aria-labelledby": "modal-title",
  role: "dialog",
  "aria-modal": "true"
}
const _hoisted_2 = { class: "flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_3 = { class: "ui-modal inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-40 my-8 lg:align-middle md:align-middle w-11/12 lg:w-2/5 md:w-2/3" }
const _hoisted_4 = { class: "flex flex-col bg-white py-2 sm:p-6 sm:pb-4" }
const _hoisted_5 = { class: "flex justify-between items-center" }
const _hoisted_6 = { class: "sm:flex sm:items-start data-list overflow-y-auto" }
const _hoisted_7 = { class: "w-full text-center sm:text-left h-16" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_ctx.openModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("div", {
            class: "fixed inset-0 bg-gray-900 opacity-75 transition-opacity",
            "aria-hidden": "true"
          }, null, -1)),
          _cache[4] || (_cache[4] = _createElementVNode("span", {
            class: "hidden sm:inline-block sm:align-middle sm:h-screen",
            "aria-hidden": "true"
          }, "​", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "pt-2" }, "Files", -1)),
                _createElementVNode("div", {
                  class: "h-1.5 p-2 md:px-0 lg:px-0 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "cross",
                    hwClass: "w-3 h-3"
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postMaterials, (material) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex flex-col my-2 rounded py-4 px-2 text-gray-700 cursor-pointer border bg-gray-50 hover:bg-gray-100 leading-5",
                          key: material.id
                        }, [
                          _createElementVNode("button", {
                            class: "text-blue-500 flex items-center justify-between post-material-link",
                            onClick: ($event: any) => (_ctx.downloadFileFromURL(material.id, material.url, material.title))
                          }, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("img", {
                                src: _ctx.getIcon(material.type),
                                class: "post-material-icon mr-2 h-6"
                              }, null, 8, _hoisted_11),
                              _createElementVNode("a", {
                                href: _ctx.replaceBucketUrlWithCdnUrl(material.url),
                                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
                                class: "post-material-title custom-word-break !no-underline"
                              }, _toDisplayString(material.title), 9, _hoisted_12)
                            ]),
                            (_ctx.downloadingQueue.has(material.id))
                              ? (_openBlock(), _createBlock(_component_UISpinner, {
                                  key: 0,
                                  size: "small"
                                }))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_9)
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}