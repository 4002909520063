import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "no-underline flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center md:justify-between lg:justify-between" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "my-0 completion-message next-lesson__completionMsg" }
const _hoisted_4 = { class: "my-0 post-title next-lesson__post-title" }
const _hoisted_5 = {
  key: 1,
  class: "bg-green-100 border border-green-400 text-green-800 px-6 py-4 rounded-lg shadow-md relative flex flex-col justify-between items-start transition-transform transform duration-300 ease-in-out",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITextSmNormal = _resolveComponent("UITextSmNormal")!
  const _component_UIContentWrap = _resolveComponent("UIContentWrap")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.completed)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        id: "go-to-next-post",
        class: _normalizeClass([
      'w-full rounded p-2 next-lesson__bg-color hover:no-underline max-h-fit',
      'cursor-pointer',
    ]),
        tag: "div",
        onClick: _ctx.trackEvent,
        to: {
      name: 'post-overview',
      params: {
        id: _ctx.post.productId,
        category_id: _ctx.post.categoryId,
        post_id: _ctx.post.id,
      },
    }
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                class: "max-h-28",
                src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.posterImage),
                alt: "Post Image"
              }, null, 8, _hoisted_2)
            ]),
            _createVNode(_component_UIContentWrap, { class: "post-title-container flex-1 text-xs md:text-base lg:text-base" }, {
              default: _withCtx(() => [
                _createVNode(_component_UITextSmNormal, null, {
                  default: _withCtx(() => [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.nextLessonContent), 1),
                    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.post.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (!_ctx.completed)
              ? (_openBlock(), _createBlock(_component_UIContentWrap, { key: 0 }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Next lesson > ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onClick", "to"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
        _createElementVNode("strong", { class: "font-bold text-lg" }, "Congratulations!", -1),
        _createElementVNode("p", { class: "mt-2 mb-0 text-md" }, [
          _createTextVNode(" You have successfully completed the course. "),
          _createElementVNode("br"),
          _createTextVNode(" Thank you for being a part of our learning community! ")
        ], -1)
      ])))
}