<template>
  <div class="w-full lesson-post-tree">
    <template :key="child.id" v-for="child in categoryTree">
      <router-link
        v-if="nodeIsPost(child.type) && available"
        tag="div"
        :to="{
          name: 'post-overview',
          params: {
            id: child.node.productId,
            category_id: child.node.categoryId,
            post_id: child.id,
          },
          query: {
            template_id: templateId,
          },
        }"
        class="w-full py-3 border-b cursor-pointer flex justify-between hover:bg-gray-300 hover:text-gray-700 lessonSecondaryStyle"
        :class="
          postId === child.id
            ? 'text-white post-highlight'
            : 'text-blue-700 postqueue-lesson'
        "
        :style="postId === child.id && { backgroundColor: highlightBackground }"
      >
        <div>
          <span class="px-6" v-if="postIndexes[child.id]">
            {{ postIndexes[child.id] }}</span
          >
          <span :class="isParentSubCategory ? 'px-4' : 'px-2'">{{
            child.node.title
          }}</span>
        </div>
        <div
          v-if="
            postId === child.id &&
            activePost.materials &&
            activePost.materials.length
          "
          class="px-10 flex items-center justify-center"
          @click="showModal = true"
          :style="{ color: highlightColor }"
        >
          <BaseIcon name="docdownload" hwClass="h-4 w-4" />
        </div>
        <img
          v-if="nodeIsCompleted(child)"
          class="h-5 px-6"
          :src="checkIcon"
          alt="Completed"
        />
      </router-link>
      <div
        :key="child.id"
        v-else-if="nodeIsPost(child.type) && !available"
        class="w-full py-3 border-b flex justify-between text-gray-500"
      >
        <div>
          <span class="px-6" v-if="postIndexes[child.id]">
            {{ postIndexes[child.id] }}</span
          >
          <span :class="isParentSubCategory ? 'px-4' : 'px-2'">{{
            child.node.title
          }}</span>
        </div>
      </div>
      <div v-else :key="child.id" class="w-full">
        <div class="py-3 border-b postqueue-lesson">
          <span
            class="px-6"
            :class="
              !child.node.available
                ? 'text-gray-500 drip-category-title'
                : 'lessonSecondaryStyle postqueue-subCategory'
            "
          >
            {{ child.node.title.toLocaleUpperCase() }}
            <span v-if="!child.node.available" class="float-right px-4"
              >(Coming Soon)</span
            >
          </span>
        </div>
        <NewYorkPostCategoryPostTree
          :categoryTree="child.childs"
          :completed-posts="completedPosts"
          :post-indexes="postIndexes"
          :activePost="activePost"
          :available="child.node.available"
          :highlightBackground="highlightBackground"
          :highlightColor="highlightColor"
          :isParentSubCategory="true"
        />
      </div>
    </template>
    <post-material-modal
      :show-modal="showModal"
      :materials="activePost.materials"
      @close="showModal = false"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import { checkIcon } from '@/helper/constants'
import PostMaterialModal from '@/components/common/PostMaterialModal.vue'
import { checkNodeIsCompleted } from '@/helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: { PostMaterialModal, BaseIcon },
  name: 'NewYorkPostCategoryPostTree',
  props: {
    categoryTree: {
      type: Array,
    },
    completedPosts: {
      type: Set,
      default: new Set(),
    },
    postIndexes: {
      type: Object,
      default: null,
    },
    activePost: {
      type: Object,
    },
    isParentSubCategory: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    highlightBackground: String,
    highlightColor: String,
  },
  data() {
    return {
      checkIcon,
      showModal: false,
    }
  },
  computed: {
    postId(): string {
      return this.$route.params.post_id
    },
    templateId(): string {
      return this.$route.query.template_id
    },
  },
  methods: {
    nodeIsPost(type: CategoryTreeNodeType) {
      return type === CategoryTreeNodeType.Post
    },
    nodeIsCompleted(entity: CategoryTreeNode): boolean {
      return checkNodeIsCompleted(this.completedPosts, entity)
    },
  },
})
</script>
