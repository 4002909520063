<script setup lang="ts">
import { defaultCourseThumbnail, lockedPostIcon } from "@/helper/constants";
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
  truncateText,
} from "@/helper/filter";
import { UIEmpty } from "@gohighlevel/ghl-ui";
import { useRouter } from "vue-router";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import CourseCardV2 from "./CourseCardV2.vue";
import UpsellBundleV2 from "./UpsellBundleV2.vue";
import { computed } from "vue";

const props = defineProps(["allCourses", "upsellOffers", "fetching"]);

const router = useRouter();

const computedUpsellOffers = computed(() => {
  return props.upsellOffers?.filter(course => course.upsellType && course.offerUpSell)
})
function openProduct(productId) {
  router.push({ name: "category-list-levelup", params: { id: productId } });
}
function openUpsell(offerId, upsellId) {
  router.push({
    name: "upsell-bundle-levelup",
    params: {
      offerId,
      upsellId,
    },
  });
}
</script>
<template>
  <div>
    <div class="w-full h-full flex items-center justify-center courses-font-family font-semibold"
      v-if="!props.fetching && !props.allCourses.length">
      <UIEmpty id="empty-product-message" title="No courses found"
        description="You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey." />
    </div>
    <div v-else class="w-11/12 h-full flex flex-col items-center justify-start mx-auto py-2 gap-4">
      <div class="w-full h-full ">
        <div class="w-full" v-if="props.upsellOffers.length">
          <!-- <span class="font-semibold text-[#101828] text-sm  ">Featured</span> -->
          <div class="w-full my-4">
            <Carousel :items-to-show="1.5" :wrap-around="true">
              <Slide v-for="course in props.upsellOffers" :key="course.id">
                <div class="w-11/12 flex flex-col items-start gap-2 justify-around clickable"
                  v-if="course.upsellType && course.offerUpSell" @click="openUpsell(course.offerUpSell.id, course.id)">
                  <div class="relative w-full min-h-40 aspect-video">
                    <img :src="replaceBucketUrlWithCdnUrl(course.offerUpSell?.posterImage) ||
                      defaultCourseThumbnail
                      " alt="bundle-image" class="w-full h-full relative rounded-md brightness-75" :class="{
                        'object-cover': !replaceBucketUrlWithCdnUrl(
                          course.offerUpSell?.posterImage
                        ),
                      }" />

                    <div class="absolute inset-0 flex justify-center items-center">
                      <img :src="lockedPostIcon" alt="lock-icon" class="h-6 w-6 text-[--accent-color] object-cover" />
                    </div>

                    <div class="course-tag absolute rounded-3xl py-0.5 px-2 bg-[--accent-color]"
                      style="top: 1rem; right: 1rem;" v-if="
                        course?.offerUpSell?.products &&
                        course?.offerUpSell?.products?.length > 1
                      ">
                      <span class="text-white   text-xs font-medium">Bundle</span>
                    </div>
                  </div>
                  <div class="text-gray-900 font-semibold   line-clamp-1">
                    {{ truncateText(course.offerUpSell?.title) }}
                  </div>
                  <div class="text-[--accent-color] font-semibold w-full   text-start break-all line-clamp-1"
                    v-if="course.offerUpSell?.priceDisplayOverride">
                    {{ truncateText(course.offerUpSell?.priceDisplayOverride, 50) }}
                  </div>
                  <div class="text-[--accent-color] font-semibold   text-start" v-else>
                    {{ fetchCurrencySymbol(course.offerUpSell?.currency) }}
                    {{ course.offerUpSell?.amount }}
                  </div>
                </div>
              </Slide>
            </Carousel>
          </div>
          <hr />
        </div>
        <CourseCardV2 v-for="course in props.allCourses" :key="course.id" @click="openProduct(course.id)"
          :course="course" />
        <hr />
        <UpsellBundleV2 class="w-full clickable overflow-x-scroll" v-for="course in computedUpsellOffers"
          :key="course.id" :offer="course.offerUpSell" :upsellId="course.id"
          @click="openUpsell(course.offerUpSell.id, course.id)" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
