<script lang="ts" setup>
import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";

const store = useStore();

const { newMobileScreens } = {
    newMobileScreens: store.getters.newMobileScreens,
};
</script>

<template>
    <div v-if="newMobileScreens">
        <!-- Curriculum Header -->
        <div class="w-11/12 flex flex-col items-start mx-auto my-4">
            <div class="w-full flex items-center justify-between gap-2">
                <UISkeleton :sharp="false" height="24px" width="25%" />
                <UISkeleton :sharp="false" height="16px" width="33.33%" />
            </div>
            <div class="w-full flex items-center justify-start gap-2 mx-auto my-2">
                <UISkeleton :sharp="false" height="16px" width="20%" />
                <div class="bg-[--accent-color] font-bold rounded w-1 h-1"></div>
                <UISkeleton :sharp="false" height="16px" width="20%" />
                <div class="bg-[--accent-color] font-bold rounded w-1 h-1"></div>
                <UISkeleton :sharp="false" height="16px" width="20%" />
            </div>
        </div>

        <!-- Accordion Skeleton -->
        <div class="w-11/12 mx-auto bg-white rounded-xl overflow-hidden">
            <div v-for="n in 3" :key="n" class="accordion-item rounded-md mt-2">
                <div class="flex items-center justify-between cursor-pointer m-0 p-4 bg-gray-50 text-gray-800">
                    <div class="w-11/12 flex flex-col gap-2">
                        <UISkeleton :sharp="false" height="20px" width="50%" class="my-3" />
                        <UISkeleton :sharp="false" height="16px" width="25%" />
                    </div>
                    <UISkeleton :sharp="false" height="20px" width="20px" circle />
                </div>

            </div>
        </div>
    </div>
</template>
