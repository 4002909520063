import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { FaceSmileIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  Placeholder,
  RequiredExtensions,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import { UIButton, UIPopover } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { computed, onMounted, ref, onBeforeUnmount } from 'vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { onClickOutside } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'Editor',
  props: {
  editorText: {
    type: String,
    default: '',
  },
  desktopScreen: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: 'Type your message here...',
  },
},
  emits: ['update:editorText', 'getInstance'],
  setup(__props, { expose: __expose, emit: __emit }) {

const editor = ref<any>(null)
const showEmojiPicker = ref<boolean>(false)
const modalRef = ref(null)
const editorContainerElement = ref(null)

const props = __props
const emit = __emit
const content = computed({
  get() {
    return props.editorText
  },
  set(value) {
    emit('update:editorText', value)
  },
})

const initializing = ref(false)

onMounted(() => {
  initializing.value = true
  editor.value = new Editor({
    content: props.editorText,
    autofocus: false, // setting this true will scroll page to comment section
    extensions: [
      RequiredExtensions,
      ...(props?.placeholder
        ? [
            Placeholder.configure({
              placeholder: props.placeholder,
              color: '#D0D5DD',
            }),
          ]
        : []),
    ],
    onUpdate({ editor }) {
      content.value = editor.getHTML()
    },
  })

  editor.value.on('create', ({ editor }: { editor: any; event: any }) => {
    if (props.editorText) {
      editor.commands?.setContent(props.editorText)
    }
    editor.commands?.blur()
  })

  emit('getInstance', editor.value)

  initializing.value = false
})

function onSelectEmoji(emoji: {
  i: string
  n: string[]
  r: string // with skin tone
  t: string // skin tone
  u: string // without tone
}) {
  editor.value.commands.insertContent(emoji.i)
  closeEmojiPicker()
}

function toggleEmojiPicker() {
  showEmojiPicker.value = !showEmojiPicker.value
}

function closeEmojiPicker() {
  showEmojiPicker.value = false
}

function resetContent() {
  editor.value.commands.clearContent(true)
}

onBeforeUnmount(() => {
  if (editor.value) editor.value.destroy()
})

__expose({
  resetContent,
  closeEmojiPicker,
  editorContainerElement,
})

onClickOutside(modalRef, (event) => {
  closeEmojiPicker()
})

return (_ctx: any,_cache: any) => {
  return (!initializing.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "editorContainerElement",
        ref: editorContainerElement,
        class: "border rounded my-1 w-full"
      }, [
        _createVNode(_unref(UIRichTextEditor), {
          editor: editor.value,
          containerClass: "force-border-0 overflow-x-auto custom-editor-styling",
          placeholder: __props.placeholder
        }, null, 8, ["editor", "placeholder"]),
        (__props.desktopScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_unref(UIPopover), {
                placement: "bottom",
                style: {"padding":"0"},
                show: showEmojiPicker.value
              }, {
                trigger: _withCtx(() => [
                  _createVNode(_unref(UIButton), {
                    id: "id-testing",
                    disabled: false,
                    loading: false,
                    ghost: false,
                    quaternary: false,
                    circle: false,
                    text: true,
                    class: "p-2",
                    onClick: toggleEmojiPicker
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(FaceSmileIcon), { class: "w-4 h-4" })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_unref(EmojiPicker), {
                    native: true,
                    disableSkinTones: true,
                    onSelect: onSelectEmoji,
                    ref_key: "modalRef",
                    ref: modalRef,
                    "static-texts": { placeholder: 'Search emoji' }
                  }, null, 512)
                ]),
                _: 1
              }, 8, ["show"])
            ]))
          : _createCommentVNode("", true)
      ], 512))
    : _createCommentVNode("", true)
}
}

})