import http from '../../http-common'
import BaseService from './BaseService'
import Category from '@/models/Category'
import Post from '@/models/Post'

export default class CategoryService extends BaseService<Category> {
  protected subdomain = 'categories'

  model() {
    return Category
  }

  constructor(locationId: string) {
    super(locationId)
  }

  async findAll(params?: any): Promise<Category[]> {
    const fetchedCategories = await super.findAll(params)
    const categories = fetchedCategories.map((c: Category) => {
      if (c.posts && c.posts.length) {
        c.posts = c.posts.map((p: any) => new Post(p))
      }

      return c
    })
    return categories
  }

  async findById(id: string, params?: any): Promise<Category> {
    const category = await super.findById(id, params)

    if (category.posts && category.posts.length) {
      category.posts = category.posts.map((p: any) => new Post(p))
    }

    return category
  }

  async findNextCategory(id: string): Promise<string> {
    return http
      .get(`${this.endpoint}/find-next-category/${id}`)
      .then((resp) => resp.data.categoryId)
  }

  async fetchCategoriesProgress(
    productId: string,
    categories: string[]
  ): Promise<any> {
    return http
      .post(`${this.endpoint}/get-progress`, {
        product_id: productId,
        categories,
      })
      .then((resp) => resp.data)
  }
}
