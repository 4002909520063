import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { UIModal, UIModalFooter, UIModalHeader } from '@gohighlevel/ghl-ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteModalV2',
  props: ['toggleModal', 'submitCallback', 'showModal', 'title', 'description', 'modalIcon', 'positiveText', 'negativeText', "width", "loading"],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UIModal), {
    width: __props.width,
    zIndex: undefined,
    to: undefined,
    autoFocus: false,
    className: 'delete-popup',
    maskClosable: true,
    closeOnEsc: undefined,
    onOnMaskClick: __props.toggleModal,
    onOnEsc: __props.toggleModal,
    show: __props.showModal,
    class: "fixed top-10 left-0 right-0 flex justify-center"
  }, {
    header: _withCtx(() => [
      _createVNode(_unref(UIModalHeader), {
        type: 'error',
        title: __props.title,
        description: __props.description,
        icon: __props.modalIcon,
        onClose: __props.toggleModal,
        id: "modal-header"
      }, null, 8, ["title", "description", "icon", "onClose"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_unref(UIModalFooter), {
        loading: __props.loading,
        positiveText: __props.positiveText,
        negativeText: __props.negativeText,
        onPositiveClick: __props.submitCallback,
        onNegativeClick: __props.toggleModal,
        disabled: false,
        id: "modal-footer",
        type: 'error',
        disableNegativeBtn: false
      }, null, 8, ["loading", "positiveText", "negativeText", "onPositiveClick", "onNegativeClick"])
    ]),
    _: 1
  }, 8, ["width", "onOnMaskClick", "onOnEsc", "show"]))
}
}

})