import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "float-right text-gray-500 font-bold text-xl px-6 mx-6"
}
const _hoisted_2 = {
  key: 0,
  class: "float-right"
}
const _hoisted_3 = { class: "lg:w-full grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-2 gap-5 px-6 row-gap-4 justify-center mb-10 mt-2 mx-6 lg:mx-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewYorkPostListItem = _resolveComponent("NewYorkPostListItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["category-post-list", !_ctx.available ? 'drip-post-list' : ''])
  }, [
    (!_ctx.available)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "(Coming Soon)"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryArrays, (child, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (child.isSubCategory)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["mx-6 px-6 font-bold text-xl", [
          _ctx.$route.name.includes('product-overview') && child.available
            ? 'primaryStyle subcategory-title'
            : 'text-gray-500',
          !child.available ? 'drip-category-title' : '',
        ]])
            }, [
              _createTextVNode(_toDisplayString(child.categoryTitle) + " ", 1),
              (!child.available)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(Coming Soon)"))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child.post, (post) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: post.id
            }, [
              (_ctx.nodeIsPost(post.type))
                ? (_openBlock(), _createBlock(_component_NewYorkPostListItem, {
                    key: 0,
                    post: post.node,
                    "category-id": post.parent,
                    completed: _ctx.completedPosts.has(post.id),
                    available: child.available,
                    templateId: _ctx.templateId,
                    hasProductAccess: _ctx.hasProductAccess
                  }, null, 8, ["post", "category-id", "completed", "available", "templateId", "hasProductAccess"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ])
      ]))
    }), 128))
  ], 2))
}