<template>
  <component :is="wrapInPaper ? Paper : 'div'" class="w-full">
    <slot v-if="$slots.title" name="title"></slot>
    <Title v-else tag="h4" class="font-medium m-0 p-6">Attached Files</Title>
    <div :class="showBorder ? 'border rounded-lg' : ''" class="w-full">
      <div
        tabindex="0"
        role="button"
        v-for="(file, fileIndex) in revisedFileList"
        :key="file.id"
        :id="file.id"
        @click="
          initiateDownload({
            fileUrl: file.url,
            fileTitle: file.title,
            fileId: file.id,
          })
        "
        class="cursor-pointer py-4 px-6 grid grid-cols-12 items-center justify-between hover:bg-neo-classic-hover-bg text-neo-classic-primary-accent group"
        :aria-disabled="downloadingQueue.has(file.id)"
        :aria-label="
          downloadingQueue.has(file.id)
            ? 'Downloading...'
            : 'Download ' + file.title
        "
        :class="fileIndex === revisedFileList.length - 1 ? '' : 'border-b'"
      >
        <div
          class="flex items-center w-full col-span-10"
          :class="{
            'animate-pulse opacity-50': downloadingQueue.has(file.id),
          }"
        >
          <component :is="getFileIcon(file.type)" class="w-4 h-4 mr-2" />
          <LinkPreview
            v-if="file.type.includes(PostMaterialType.image)"
            :alternateComponent="Title"
            :href="replaceBucketUrlWithCdnUrl(file.url)"
            linkClass="hover:no-underline"
            tag="h6"
            class="m-0 truncate"
          >
            {{ file.title }}
          </LinkPreview>
          <Title tag="h5" class="m-0 truncate" v-else>{{ file.title }}</Title>
        </div>
        <div
          v-if="downloadingQueue.has(file.id)"
          class="flex items-center w-full col-span-2 justify-end"
        >
          <ArrowDownIcon class="w-4 h-4 animate-bounce mr-2" />
          <span class="text-gray-900" v-if="downloadingQueue.has(file.id)">
            {{ Math.round(downloadingQueue.get(file.id).progress) + '%' }}
          </span>
          <XIcon
            class="w-4 h-4 ml-auto text-neo-classic-error"
            @click.prevent.stop="downloadingQueue.get(file.id).cancelDownload"
          />
        </div>
        <CheckCircleIcon
          v-else-if="downloadCompleteQueue.has(file.id)"
          class="w-4 h-4 ml-auto col-span-2 text-neo-classic-success"
        />
        <div v-else class="flex items-center col-span-2 justify-end">
          <span
            v-if="file.size"
            class="text-neo-classic-primary-accent text-xxs ml-auto"
          >
            {{ getFileSize(file.size) }}
          </span>
          <Download01Icon class="w-4 h-4 ml-4" />
        </div>
      </div>
    </div>
  </component>
</template>
<script setup lang="ts">
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { downloadFileWithProgress } from '@/helper/fileHandler'
import LinkPreview from '@/components/neo-classic-theme/common/LinkPreview.vue'
import {
  ArrowDownIcon,
  CheckCircleIcon,
  Download01Icon,
  File04Icon,
  Image01Icon,
  XIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { getFileSize } from '@/helper'
import { ref, computed } from 'vue'
import PostMaterial, { PostMaterialType } from '@/models/PostMaterial'

const props = defineProps({
  fileList: {
    type: Array<PostMaterial>,
    required: true,
  },
  wrapInPaper: {
    type: Boolean,
    default: true,
  },
  showBorder: {
    type: Boolean,
    default: false,
  },
})

const revisedFileList = computed(() => {
  return props.fileList.map((file) => {
    return {
      title: file.title,
      url: file.url,
      type: file.type,
      size: file.size,
      id: file.id || (file.title + file.type).replaceAll(' ', '_'),
    }
  }) as Array<PostMaterial>
})

const downloadingQueue = ref(new Map())
const downloadCompleteQueue = ref(new Map())

function getFileIcon(fileType: string) {
  if (fileType?.includes(PostMaterialType.image)) return Image01Icon
  return File04Icon
}

function updateProgress(progress: number, fileId: string) {
  downloadingQueue.value.set(fileId, {
    ...downloadingQueue.value.get(fileId),
    progress,
  })

  if (progress === 100) {
    downloadCompleteQueue.value.set(fileId, {
      ...downloadCompleteQueue.value.get(fileId),
      progress,
    })
    downloadingQueue.value.delete(fileId)
  }
}

async function initiateDownload({ fileUrl, fileTitle, fileId }) {
  try {
    if (downloadingQueue.value.has(fileId)) return
    const { downloadPromise, cancelDownload } = downloadFileWithProgress({
      url: replaceBucketUrlWithCdnUrl(fileUrl),
      givenFileName: fileTitle,
      progressUpdate: (progress) => updateProgress(progress, fileId),
      onCancel: () => {
        downloadingQueue.value.delete(fileId)
      },
    })
    downloadingQueue.value.set(fileId, {
      fileUrl,
      fileTitle,
      progress: 0,
      cancelDownload,
    })
    await downloadPromise
  } catch (error) {
    if (error.type === 'abort') {
      console.warn('Download cancelled by the user!')
    } else {
      console.error('Error initiating download: ', error)
    }
  }
}
</script>
