<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="w-full bg-white mt-5 px-10 pb-10">
    <div class="text-xl font-semibold py-2 text-center text-gray-600">
      COMMENTS
    </div>
    <div class="bg-white cursor-pointer" v-if="commentPermission === 'enabled'">
      <editor v-model:editorText="contentText" ref="editorRef" />
    </div>
    <div class="flex text-sm pb-5" v-if="commentPermission === 'enabled'">
      <div class="text-gray-500 flex-1 text-sm p-1 pt-2 pr-2">
        {{ replyTextLength }} / {{ commentCharacterLength }}
      </div>
      <div class="flex">
        <UIDropdown
          v-if="productCommentPrivacy === 'publicAndInstructor'"
          id="privacy-option-dropdown"
          :options="commentPrivacy"
          @select="selectPrivacyOption"
        >
          <div class="flex items-center">
            <BaseIcon
              :name="selectedPrivacyOption.iconname"
              hwClass="h-5 w-5"
            />
            <div class="ml-1">{{ selectedPrivacyOption.label }}</div>
            <ChevronDownIcon class="w-4 h-4" />
          </div>
        </UIDropdown>
        <div v-else class="flex items-center cursor-pointer">
          <BaseIcon :name="selectedPrivacyOption.iconname" hwClass="h-5 w-5" />
          <div class="ml-1">{{ selectedPrivacyOption.label }}</div>
        </div>
        <button
          @click="createComment"
          :disabled="postButtonDisabled || posting"
          class="flex items-center border rounded ml-2 cursor-pointer w-20 text-center justify-center"
          :class="
            postButtonDisabled || posting
              ? 'bg-gray-100 text-gray-500'
              : 'bg-blue-100 text-blue-500'
          "
        >
          <div v-if="!posting" class="transform rotate-90 mr-1">
            <BaseIcon name="paperairplane" hwClass="w-5 h-5" />
          </div>
          <div v-else class="mr-1 pt-px">
            <UISpinner size="extra-small" />
          </div>
          <div class="pl-1">Post</div>
        </button>
      </div>
    </div>
    <span>
      <CommentBlock
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :instructor="instructor"
        :commentPermission="commentPermission"
        :pendoPayload="pendoPayload"
        :productCommentPrivacy="productCommentPrivacy"
        :scrollCommentId="commentIdToScroll"
      />
    </span>
    <div
      v-if="showViewMore && comments?.length > 0"
      @click="findComments"
      class="border-0 rounded-sm text-center bg-blue-200 text-blue-700 py-1 cursor-pointer"
    >
      View More
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, h, ref } from 'vue'
import { CommentService } from '@/services/'
import BaseIcon from '../svgicons/BaseIcon.vue'
import CommentBlock from '@/components/comments/CommentBlock.vue'
import { editorConfig } from '@/helper/constants'
import { COMMENT_MAX_LENGTH } from '@/helper/constants'
import UISpinner from '../common/UISpinner.vue'
import { CommentVisibility } from '@/models/Comment'
import { UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import { ChevronDownIcon } from '@gohighlevel/ghl-icons/24/outline'
import { hideTinyMceWarning } from '@/helper/editorHelper'
import Editor from '../common/Editor.vue'
const editorRef = ref(null)

export default defineComponent({
  data() {
    return {
      contentText: '',
      comments: [],
      offset: 0,
      limit: 5,
      showViewMore: true,
      posting: false,
      selectedCommentPrivacy: 'instructorOnly',
      commentPrivacy: [
        {
          label: 'Instructor only',
          key: 'instructorOnly',
          iconname: 'usergroup',
          icon() {
            return h(BaseIcon, {
              name: 'usergroup',
              hwClass: 'h-5 w-5',
            })
          },
        },
        {
          label: 'Public',
          key: 'public',
          iconname: 'globealt',
          icon() {
            return h(BaseIcon, {
              name: 'globealt',
              hwClass: 'h-5 w-5',
            })
          },
        },
      ],
      lastCommentRequestTime: null,
      commentIdToScroll: this.$route.query.commentId,
    }
  },
  components: {
    editor: Editor,
    BaseIcon,
    CommentBlock,
    UISpinner,
    UIDropdown,
    ChevronDownIcon,
  },
  props: {
    postId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    instructor: {
      type: Object,
    },
    commentPermission: {
      type: String,
      default: '',
    },
    pendoPayload: {
      type: Object,
    },
    productCommentPrivacy: {
      type: String,
      default: 'instructorOnly',
      required: true,
    },
  },
  async mounted() {
    this.lastCommentRequestTime = new Date().toISOString()
    if (this.$route.query.commentId) {
      // If it is, update the commentIdToScroll
      this.commentIdToScroll = this.$route.query.commentId
    }

    await this.findComments()
    if (this.productCommentPrivacy === 'public') {
      this.selectedCommentPrivacy = this.productCommentPrivacy
    }
    this.scrollToComment(this.commentIdToScroll)
  },
  watch: {
    commentIdToScroll(newCommentId) {
      this.scrollToComment(newCommentId)
    },
  },
  created() {
    this.emitter.on('updateComment', this.updateComment)
    this.emitter.on('deleteStateParentComment', this.deleteStateParentComment)
    this.emitter.on('addNewReplyToState', this.addNewReplyToState)
    this.emitter.on('deleteStateChildComment', this.deleteStateChildComment)
  },
  beforeDestroy() {
    this.emitter.off('updateComment')
    this.emitter.off('deleteStateParentComment')
    this.emitter.off('addNewReplyToState')
    this.emitter.off('deleteStateChildComment')
  },
  methods: {
    selectPrivacyOption(privacy: string) {
      this.selectedCommentPrivacy = privacy
    },
    addNewReplyToState(params) {
      const parentCommentIndex = this.findCommentIndexById(
        params.parentCommentId
      )
      if (parentCommentIndex > -1) {
        this.comments[parentCommentIndex].replies.unshift(params)
      }
    },
    deleteStateParentComment(params) {
      const indexOfChild = this.findCommentIndexById(params.id)
      if (indexOfChild > -1) {
        this.comments = this.comments.filter((elem) => elem.id !== params.id)
      }
    },
    findCommentIndexById(id: string) {
      return this.comments.findIndex((elem) => String(elem.id) === String(id))
    },
    async createComment() {
      try {
        const visibility: string =
          this.selectedCommentPrivacy === 'instructorOnly'
            ? CommentVisibility.private
            : CommentVisibility.public
        this.posting = true
        const { data: createdComment } = await CommentService.createComment({
          postId: this.postId,
          productId: this.productId,
          content: this.contentText,
          visibility,
          pendoPayload: {
            postTitle: this.pendoPayload.post.title,
            productTitle: this.pendoPayload.product.title,
            categoryTitle: this.pendoPayload.category.title,
          },
        })
        if (createdComment.error === false) {
          this.comments = [
            { ...createdComment.comment, replies: [], isNonDb: true },
            ...this.comments,
          ]
        }
        this.$refs.editorRef.resetContent()
        this.contentText = ''
        this.posting = false
      } catch (error) {
        console.error('Something went wrong', error)
      }
    },
    async updateComment(params: any) {
      try {
        await CommentService.updateComment(params.id, {
          content: params.content,
          ...(params.visibility ? { visibility: params.visibility } : {}),
          pendoPayload: {
            postTitle: this.pendoPayload.post.title,
            productTitle: this.pendoPayload.product.title,
            categoryTitle: this.pendoPayload.category.title,
          },
        })
      } catch (error) {
        console.error('Something went wrong', error)
      }
    },
    deleteStateChildComment(params) {
      const parentCommentIndex = this.findCommentIndexById(
        params.parentCommentId
      )
      if (parentCommentIndex > -1) {
        this.comments[parentCommentIndex].replies = this.comments[
          parentCommentIndex
        ].replies.filter((elem) => elem.id !== params.id)
      }
    },
    async findComments() {
      try {
        let commentslist
        if (this.commentIdToScroll) {
          const response = await CommentService.findComments({
            postId: this.postId,
            productId: this.productId,
            commentId: this.commentIdToScroll,
            scrollSearch: true,
          })
          commentslist = response.data
        } else {
          const response = await CommentService.findComments({
            postId: this.postId,
            productId: this.productId,
            offset: this.offset,
            limit: this.limit,
            commentsAfter: this.lastCommentRequestTime ?? undefined,
          })
          commentslist = response.data
        }

        if (commentslist.comments.length < this.limit) {
          this.showViewMore = false
        }

        this.offset = this.offset + this.limit

        let nondbComments = this.comments.filter(
          (elem) => elem.isNonDb === true
        )

        if (nondbComments.length === 0) {
          this.comments.push(...commentslist.comments)
        } else {
          const commentsToPushInList = []
          for (const index in commentslist.comments) {
            const comment = commentslist.comments[index]
            const foundNonDbComment = nondbComments.find(
              (elem) => elem.id === comment.id
            )
            if (foundNonDbComment === undefined) {
              commentsToPushInList.push(comment)
            } else {
              nondbComments = foundNonDbComment.filter(
                (elem) => elem.id !== comment.id
              )
            }
          }
          this.comments.push(...commentsToPushInList)
        }
        this.comments = this.comments.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      } catch (error) {
        console.error('Something went wrong', error)
      }
    },
    scrollToComment(commentId) {
      if (commentId !== null) {
        const commentElement = document.getElementById(`${commentId}`)
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
  },

  computed: {
    selectedPrivacyOption(): {
      label: string
      value: string
      iconname: string
    } {
      let value = this.selectedCommentPrivacy
      if (value === 'publicAndInstructor') value = 'instructorOnly'
      return this.commentPrivacy.find((e) => e.key === value)
    },
    commentCharacterLength() {
      return COMMENT_MAX_LENGTH || 140
    },
    postButtonDisabled() {
      return (
        this.replyTextLength > this.commentCharacterLength ||
        this.replyTextLength === 0
      )
    },
    replyTextLength() {
      return this.contentText
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/g, ' ').length
    },
    editorConfig() {
      return {
        ...editorConfig,
        setup: function (editor: any) {
          editor.on('init', () => {
            hideTinyMceWarning(0)
            for (const timeS of [100, 500, 1000, 2000, 5000]) {
              setTimeout(() => hideTinyMceWarning(timeS), timeS)
            }
          })
        },
      }
    },
  },
})
</script>
<style>
#tinymce-div .tox {
  border-radius: 10px;
}
#tinymce-div .tox-editor-header {
  border: none;
}
#privacy-dropdown {
  margin: 0;
  padding: 0;
}
.align-everything-center {
  display: inline-flex;
  align-items: end;
}
.highlighted-comment {
  background-color: #a0d3ff; /* Change this to the desired highlight color */
}
</style>
