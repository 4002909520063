<template>
  <div class="mx-2 flex justify-center items-center">
    <div
      class="text-center cursor-pointer"
      :disabled="!leftClickEnabled"
      @click="scroll('left')"
      :style="{ color: !leftClickEnabled ? '#a0aec0' : '#709BE0' }"
    >
      <BaseIcon name="chevronleft" />
    </div>
    <template :key="tree.id" v-for="tree in categoryList">
      <div class="has-tooltip">
        <span
          v-if="tree.node.title.length > 15"
          class="tooltip rounded shadow-lg p-2 ml-4 mx-w-xs -mt-10 bg-white text-blue-500"
          >{{ tree.node.title }}</span
        >
        <span
          class="mx-4 cursor-pointer text-xl font-bold text-center"
          @click="changeSelectCategoryIndex(tree.id)"
          :class="[
            activeCategory === tree.id && 'text-blue-600 border-b',
            $route.name.includes('product-overview')
              ? 'primaryStyle course-category-title'
              : '',
          ]"
          :style="`borderColor: ${categoryBorder}`"
        >
          {{
            tree.node.title.length > 15
              ? `${tree.node.title.substring(0, 16).toLocaleUpperCase()}...`
              : tree.node.title.toLocaleUpperCase()
          }}</span
        >
      </div>
    </template>
    <div
      class="text-center cursor-pointer"
      :disabled="!rightClickEnabled"
      @click="scroll('right')"
      :style="{ color: !rightClickEnabled ? '#a0aec0' : '#709BE0' }"
    >
      <BaseIcon name="chevronright" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    categoryTree: {
      type: Array,
    },
    currentCategoryIndex: {
      type: Number,
      default: 0,
    },
    categoryBorder: {
      type: String,
    }
  },
  data() {
    return {
      leftClickEnabled: false,
      rightClickEnabled: false,
      leftIndex: 0,
      rightIndex: 2,
      categoryList: [],
      activeCategory: null,
      maxLength: 3,
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    getCategoryList() {
      const list = []
      let i = 0
      if (screen.width < 760) {
        this.maxLength = 1
        this.rightIndex = 1
      } else if (screen.width < 840) {
        this.maxLength = 2
        this.rightIndex = 2
      }
      for (; i < this.categoryTree.length && i < this.maxLength; i++) {
        list.push(this.categoryTree[i])
      }
      this.activeCategory = this.categoryTree[0]?.id
      this.categoryList = [...list]
      if (i < this.categoryTree.length) {
        this.rightClickEnabled = true
        this.rightIndex = i
      }
    },
    scroll(scrollType: string) {
      const list = [...this.categoryList]
      if (scrollType === 'left') {
        if (!this.leftClickEnabled) {
          return
        }
        list.pop()
        list.splice(0, 0, this.categoryTree[--this.leftIndex])
        this.rightIndex--
        if (this.rightIndex < this.categoryTree.length) {
          this.rightClickEnabled = true
        }
        if (this.leftIndex == 0) {
          this.leftClickEnabled = false
        }
      } else {
        if (!this.rightClickEnabled) {
          return
        }
        list.shift()
        list.push(this.categoryTree[this.rightIndex++])
        this.leftIndex++
        if (this.rightIndex >= this.categoryTree.length) {
          this.rightClickEnabled = false
        }
        if (this.leftIndex > 0) {
          this.leftClickEnabled = true
        }
      }
      this.categoryList = []
      this.categoryList = [...list]
    },
    changeSelectCategoryIndex(id: string) {
      const index = this.categoryTree.findIndex(
        (category) => category.id === id
      )
      this.activeCategory = id
      this.$emit('change-category-index', index)
    },
  },
  watch: {
    categoryTree(newValue) {
      this.getCategoryList()
    },
  },
})
</script>
<style scoped>
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
</style>
