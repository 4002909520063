import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["onClick", "aria-label"]
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-700 font-medium text-sm ml-2"
}
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 0,
  class: "border-b"
}
const _hoisted_8 = { class: "w-full" }

import { ref, inject, Ref, onMounted } from 'vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import LockedContent from '@/components/neo-classic-theme/common/post/LockedContent.vue'
import { ChevronRightIcon, Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import CategoryListItem from '@/components/neo-classic-theme/landing-page/CategoryListItem.vue'
import CategoryTreeNode, {
  CategoryTreeNodeType,
  CategoryTreeNodeTypeView,
} from '@/models/CategoryTreeNode'
import { UITag } from '@gohighlevel/ghl-ui'
import { CategoryVisibility } from '@/models/Category'
import { useRoute } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryListView',
  props: {
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  isSubCategory: {
    type: Boolean,
    default: false,
  },
  paperWrapper: {
    type: Boolean,
    default: true,
  },
  postProgress: {
    type: Map<string, number>,
    default: new Map(),
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  itemIndex: {
    type: Number,
    default: 0,
  },
  hasProductAccess: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props

const route = useRoute()

const openedCategories = ref([])
const categoriesAndPostsLookup = inject<Ref<Map<string, any>>>(
  'categoriesAndPostsLookup'
)

function isItemLocked(treeItem: CategoryTreeNode) {
  if (treeItem.node.visibility === CategoryVisibility.locked) {
    const lockedByPostId = treeItem.lockedBy || treeItem.lockedByPost
    const lockedByCategory = treeItem.node.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false
    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)
      if (category) {
        return (
          isLocked ||
          !category.childs?.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

function getCategoryAvailability(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (!category) return false
  return category.node.available
}

function toggleCategory(categoryId: string) {
  if (!props.hasProductAccess) return
  if (openedCategories.value.includes(categoryId)) {
    openedCategories.value = openedCategories.value.filter(
      (id) => id !== categoryId
    )
  } else {
    openedCategories.value.push(categoryId)
  }
}

const highlightElement = ref('')
function highlightThisElement({
  categoryId,
  highlightCategoryId,
}: {
  categoryId: string
  highlightCategoryId: string
}) {
  highlightElement.value = highlightCategoryId
  setTimeout(() => {
    highlightElement.value = ''
  }, 1500)

  // If the category is not opened, we open it
  if (!openedCategories.value.includes(categoryId)) {
    toggleCategory(categoryId)
  }
  // We scroll to the category
  window.history.pushState(null, null, `#${categoryId}`)
  document.getElementById(categoryId).scrollIntoView({
    behavior: 'smooth',
  })
}

onMounted(() => {
  if (route.hash) {
    const categoryId = route.hash.slice(1)
    const category = props.categoryTree.find(
      (category) => category.id === categoryId
    )

    if (category) {
      highlightThisElement({
        categoryId: categoryId,
        highlightCategoryId: categoryId,
      })
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_CategoryListView = _resolveComponent("CategoryListView", true)!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([__props.isSubCategory ? 'ml-14 border-l-2' : 'w-full', "font-sans"])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.categoryTree, (treeItem, treeIndex) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.isSubCategory || !__props.paperWrapper ? 'div' : Paper), {
        key: treeItem.id,
        class: _normalizeClass(["w-full", { 'border-b mb-3': !__props.isSubCategory }]),
        id: treeItem.id
      }, {
        default: _withCtx(() => [
          (!__props.isSubCategory)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: ($event: any) => (toggleCategory(treeItem.id)),
                class: _normalizeClass(["collapse-trigger p-4 border-b flex justify-between items-center", [
          {
            'cursor-default': !treeItem.isAvailable || !__props.hasProductAccess,
            'border border-neo-classic-primary-accent':
              highlightElement.value === treeItem.id,
          },
          __props.hasProductAccess ? 'cursor-pointer' : '',
        ]]),
                role: "button",
                tabindex: "0",
                "aria-label": treeItem.node.title
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(Title, {
                    tag: "h4",
                    class: "m-0 text-lg font-medium"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(!__props.isSubCategory ? treeItem.node.title : '') + " ", 1),
                      (!treeItem.isAvailable)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Drip (Coming soon) "))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024),
                  (isItemLocked(treeItem))
                    ? (_openBlock(), _createBlock(_unref(Lock01Icon), {
                        key: 0,
                        class: "h-5 w-5"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  (!treeItem.isAvailable)
                    ? (_openBlock(), _createBlock(_unref(UITag), {
                        key: 0,
                        type: "error"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Available after " + _toDisplayString(treeItem.dripDays) + " day" + _toDisplayString(treeItem.dripDays > 1 ? 's' : ''), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(ChevronRightIcon), {
                    class: _normalizeClass(["w-5 h-5 transition-all ease-in-out", {
              'rotate-90': openedCategories.value.includes(treeItem.id),
              'text-gray-300': !treeItem.isAvailable,
            }])
                  }, null, 8, ["class"])
                ])
              ], 10, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "transition-all duration-500 ease-in-out overflow-hidden",
            style: _normalizeStyle({
          display: 'grid',
          gridTemplateRows:
            openedCategories.value.includes(treeItem.id) || __props.isSubCategory
              ? '1fr'
              : '0fr',
        })
          }, [
            _createElementVNode("div", {
              class: "collapse-content transition-all duration-500 ease-in-out overflow-hidden",
              style: _normalizeStyle({
            opacity:
              openedCategories.value.includes(treeItem.id) || __props.isSubCategory
                ? '1'
                : '0',
            transform:
              openedCategories.value.includes(treeItem.id) || __props.isSubCategory
                ? 'translateY(0)'
                : 'translateY(-100px)',
          })
            }, [
              (isItemLocked(treeItem))
                ? (_openBlock(), _createBlock(LockedContent, {
                    key: 0,
                    "content-type": _unref(CategoryTreeNodeTypeView)[treeItem.type],
                    lockedByPostId: treeItem.lockedBy || treeItem.lockedByPost,
                    lockedByCategoryId: treeItem.node.lockedByCategory,
                    completedPostIds: __props.completedPostIds,
                    onHighlightThisElement: highlightThisElement
                  }, null, 8, ["content-type", "lockedByPostId", "lockedByCategoryId", "completedPostIds"]))
                : (__props.isSubCategory)
                  ? (_openBlock(), _createBlock(CategoryListItem, {
                      key: treeItem.id,
                      listItemData: treeItem,
                      showBottomBorder: treeIndex !== __props.categoryTree.length - 1,
                      class: "pl-8",
                      shrinkSize: __props.isSubCategory,
                      progress: __props.postProgress.get(treeItem.node.id),
                      "is-post-completed": __props.completedPostIds.has(treeItem.id),
                      "is-item-locked": isItemLocked(treeItem),
                      "show-description": 
              getCategoryAvailability(
                treeItem.categoryId || treeItem.node.parentCategory
              )
            ,
                      isCategoryAvailable: 
              getCategoryAvailability(
                treeItem.categoryId || treeItem.node.parentCategory
              )
            
                    }, null, 8, ["listItemData", "showBottomBorder", "shrinkSize", "progress", "is-post-completed", "is-item-locked", "show-description", "isCategoryAvailable"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(treeItem.childs, (postListItem, listIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: postListItem.node.id,
                          id: postListItem.node.id
                        }, [
                          (postListItem.type === _unref(CategoryTreeNodeType).Category)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["bg-neo-classic-sub-category-bg py-1 pl-20 flex items-center justify-between pr-4", {
                    'border border-neo-classic-primary-accent':
                      highlightElement.value === postListItem.id,
                  }])
                                }, [
                                  _createVNode(Title, {
                                    tag: "h5",
                                    class: "m-0 font-medium"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(postListItem.node.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (!postListItem.isAvailable)
                                    ? (_openBlock(), _createBlock(_unref(UITag), {
                                        key: 0,
                                        type: "error",
                                        size: "small"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Available after " + _toDisplayString(postListItem.dripDays) + " day" + _toDisplayString(postListItem.dripDays > 1 ? 's' : ''), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ], 2),
                                _createElementVNode("div", _hoisted_8, [
                                  (isItemLocked(postListItem))
                                    ? (_openBlock(), _createBlock(LockedContent, {
                                        key: 0,
                                        "content-type": _unref(CategoryTreeNodeTypeView)[postListItem.type],
                                        lockedByPostId: 
                      postListItem.lockedBy || postListItem.lockedByPost
                    ,
                                        lockedByCategoryId: postListItem.node.lockedByCategory,
                                        completedPostIds: __props.completedPostIds,
                                        onHighlightThisElement: highlightThisElement
                                      }, null, 8, ["content-type", "lockedByPostId", "lockedByCategoryId", "completedPostIds"]))
                                    : (_openBlock(), _createBlock(_component_CategoryListView, {
                                        key: 1,
                                        categoryTree: postListItem.childs,
                                        isSubCategory: true,
                                        completedPostIds: __props.completedPostIds,
                                        hasProductAccess: __props.hasProductAccess,
                                        postProgress: __props.postProgress
                                      }, null, 8, ["categoryTree", "completedPostIds", "hasProductAccess", "postProgress"]))
                                ])
                              ]))
                            : (_openBlock(), _createBlock(CategoryListItem, {
                                key: 1,
                                listItemData: postListItem,
                                showBottomBorder: listIndex !== treeItem.childs.length - 1,
                                class: "pl-14",
                                progress: __props.postProgress.get(postListItem.node.id),
                                "is-post-completed": __props.completedPostIds.has(postListItem.id),
                                "is-item-locked": isItemLocked(postListItem),
                                "show-description": 
                  getCategoryAvailability(postListItem.node.parentCategory)
                ,
                                isCategoryAvailable: 
                  getCategoryAvailability(postListItem.node.parentCategory)
                
                              }, null, 8, ["listItemData", "showBottomBorder", "progress", "is-post-completed", "is-item-locked", "show-description", "isCategoryAvailable"]))
                        ], 8, _hoisted_6))
                      }), 128))
                    ]))
            ], 4)
          ], 4)
        ]),
        _: 2
      }, 1032, ["class", "id"]))
    }), 128))
  ], 2))
}
}

})