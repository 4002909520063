<script setup lang="ts">
import { FaceSmileIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  Placeholder,
  RequiredExtensions,
  UIRichTextEditor,
} from '@gohighlevel/ghl-text-editor'
import { UIButton, UIPopover } from '@gohighlevel/ghl-ui'
import { Editor } from '@tiptap/vue-3'
import { computed, defineProps, onMounted, ref, onBeforeUnmount } from 'vue'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import { onClickOutside } from '@vueuse/core'

const editor = ref<any>(null)
const showEmojiPicker = ref<boolean>(false)
const modalRef = ref(null)
const editorContainerElement = ref(null)

const props = defineProps({
  editorText: {
    type: String,
    default: '',
  },
  desktopScreen: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: 'Type your message here...',
  },
})
const emit = defineEmits(['update:editorText', 'getInstance'])
const content = computed({
  get() {
    return props.editorText
  },
  set(value) {
    emit('update:editorText', value)
  },
})

const initializing = ref(false)

onMounted(() => {
  initializing.value = true
  editor.value = new Editor({
    content: props.editorText,
    autofocus: false, // setting this true will scroll page to comment section
    extensions: [
      RequiredExtensions,
      ...(props?.placeholder
        ? [
            Placeholder.configure({
              placeholder: props.placeholder,
              color: '#D0D5DD',
            }),
          ]
        : []),
    ],
    onUpdate({ editor }) {
      content.value = editor.getHTML()
    },
  })

  editor.value.on('create', ({ editor }: { editor: any; event: any }) => {
    if (props.editorText) {
      editor.commands?.setContent(props.editorText)
    }
    editor.commands?.blur()
  })

  emit('getInstance', editor.value)

  initializing.value = false
})

function onSelectEmoji(emoji: {
  i: string
  n: string[]
  r: string // with skin tone
  t: string // skin tone
  u: string // without tone
}) {
  editor.value.commands.insertContent(emoji.i)
  closeEmojiPicker()
}

function toggleEmojiPicker() {
  showEmojiPicker.value = !showEmojiPicker.value
}

function closeEmojiPicker() {
  showEmojiPicker.value = false
}

function resetContent() {
  editor.value.commands.clearContent(true)
}

onBeforeUnmount(() => {
  if (editor.value) editor.value.destroy()
})

defineExpose({
  resetContent,
  closeEmojiPicker,
  editorContainerElement,
})

onClickOutside(modalRef, (event) => {
  closeEmojiPicker()
})
</script>
<template>
  <div
    v-if="!initializing"
    ref="editorContainerElement"
    class="border rounded my-1 w-full"
  >
    <UIRichTextEditor
      :editor="editor"
      containerClass="force-border-0 overflow-x-auto custom-editor-styling"
      :placeholder="placeholder"
    >
    </UIRichTextEditor>
    <div v-if="desktopScreen">
      <UIPopover placement="bottom" style="padding: 0" :show="showEmojiPicker">
        <template #trigger>
          <UIButton
            id="id-testing"
            :disabled="false"
            :loading="false"
            :ghost="false"
            :quaternary="false"
            :circle="false"
            :text="true"
            class="p-2"
            @click="toggleEmojiPicker"
          >
            <FaceSmileIcon class="w-4 h-4" />
          </UIButton>
        </template>
        <EmojiPicker
          :native="true"
          :disableSkinTones="true"
          @select="onSelectEmoji"
          ref="modalRef"
          :static-texts="{ placeholder: 'Search emoji' }"
        />
      </UIPopover>
    </div>
  </div>
</template>
<style>
.custom-editor-styling {
  min-width: auto !important;
}

.force-border-0 {
  border: 0 !important;
  max-height: 200px !important;
}

.ProseMirror p {
  margin: 0;
}

.v3-search input::placeholder {
  font-size: 1rem;
  text-align: left;
  font-weight: normal;
  font-size: 0.875rem;
}
</style>
