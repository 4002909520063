import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "course-card w-full flex items-center justify-between gap-4 my-4 cursor-pointer clickable overflow-x-scroll"
}
const _hoisted_2 = { class: "w-3/5 min-w-36 h-28 xs:h-32 sm:h-48 md:h-60 rounded-md border border-gray-300 aspect-video" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "h-full w-3/4 flex flex-col items-start justify-around gap-2" }
const _hoisted_5 = { class: "w-full text-start text-gray-400 font-medium sm:text-md" }

import { extractTextFromHTML } from "@/helper";
import { defaultCourseThumbnail } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl, truncateText } from "@/helper/filter";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseCardV2',
  props: ["course"],
  setup(__props) {



const courseDescription = computed(() => {
  return truncateText(extractTextFromHTML(__props.course?._data.description), 75)
})

return (_ctx: any,_cache: any) => {
  return (!__props.course.upsellType)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(replaceBucketUrlWithCdnUrl)(__props.course?._data?.posterImage) || _unref(defaultCourseThumbnail)
        ,
            alt: "course-thumbnail",
            class: _normalizeClass(["w-full h-full rounded-md object-contain", {
          'object-cover': !_unref(replaceBucketUrlWithCdnUrl)(__props.course?._data?.posterImage),
        }])
          }, null, 10, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("div", { class: "text-gray-900 font-bold break-all line-clamp-2 sm:text-lg" }, _toDisplayString(_unref(truncateText)(__props.course._data.title, 50)), 513), [
            [_vShow, __props.course?._data?.title]
          ]),
          _withDirectives(_createElementVNode("div", { class: "line-clamp-3 font-normal text-xs text-gray-500 sm:text-sm sm:line-clamp-4 break-all" }, _toDisplayString(courseDescription.value), 513), [
            [_vShow, __props.course?._data?.description]
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(__props.course._data?.noOfPostCompleted || 0) + "/" + _toDisplayString(__props.course._data?.totalPosts || 0), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})