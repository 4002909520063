<template>
  <div class="w-full">
    <div v-for="comment in commentsList" :key="comment.id">
      <SingleCommentBlock
        :comment="comment"
        :editableCommentId="editableCommentId"
        :instructor="instructor"
        @onEdit="onEdit"
        @onDelete="onDelete"
      >
        <template #at-end="slotProps">
          <slot v-bind="slotProps" name="for-comment-reply"></slot>
        </template>
        <template #edit-section>
          <slot name="for-comment-edit"></slot>
        </template>
      </SingleCommentBlock>
      <Button
        v-if="comment.replies && comment.replies.length > 0"
        class="text-neo-classic-primary-accent focus:outline-none outline-none ml-10 my-4 flex items-center"
        variant="text"
        @click="toggleReplyComment(comment.id)"
      >
        <ReverseLeftIcon class="w-4 h-4 mr-1" />
        {{
          showReplyToComment.includes(comment.id)
            ? 'Hide replies'
            : `View ${comment.replies.length} replies`
        }}
      </Button>
      <div class="ml-10" v-if="showReplyToComment.includes(comment.id)">
        <NeoCommentList
          :commentsList="comment.replies"
          :editableCommentId="editableCommentId"
          :instructor="instructor"
          @onEdit="onEdit"
          @onDelete="onDelete"
        >
          <template #for-comment-reply="slotProps">
            <slot v-bind="slotProps" name="for-comment-reply"></slot>
          </template>
          <template #for-comment-edit>
            <slot name="for-comment-edit"></slot>
          </template>
        </NeoCommentList>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import SingleCommentBlock from '@/components/neo-classic-theme/comments/SingleCommentBlock.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { ReverseLeftIcon } from '@gohighlevel/ghl-icons/24/outline'

defineProps({
  commentsList: {
    type: Array<any>,
    default: () => [],
  },
  editableCommentId: {
    type: String,
    default: '',
  },
  instructor: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['onEdit', 'onDelete'])

const showReplyToComment = ref([])

function toggleReplyComment(parentCommentId: string) {
  if (showReplyToComment.value.includes(parentCommentId)) {
    showReplyToComment.value = showReplyToComment.value.filter(
      (id) => id !== parentCommentId
    )
  } else {
    showReplyToComment.value.push(parentCommentId)
  }
}

function onEdit(commentId: string) {
  emit('onEdit', commentId)
}
function onDelete(commentId: string) {
  emit('onDelete', commentId)
}
</script>
