import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mb-4" }
const _hoisted_2 = ["src"]

import { dripContentImage } from '@/helper/constants'
import Title from '@/components/neo-classic-theme/common/Title.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DripContent',
  props: {
  remainingTime: {
    type: Number,
    default: 0,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(dripContentImage),
      class: "max-w-3xl w-1/2 mx-auto",
      alt: "Drip Content Image"
    }, null, 8, _hoisted_2),
    _createVNode(Title, {
      tag: "h3",
      class: "m-0 text-center"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Exciting learning ahead—coming soon in " + _toDisplayString(__props.remainingTime) + " days! ", 1)
      ]),
      _: 1
    })
  ]))
}
}

})