import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  id: "mobile-navigation",
  class: "lg:hidden max-w-xs fixed bottom-0 left-1/2 transform -translate-y-5 -translate-x-1/2 w-10/12 h-16 bg-white rounded-full flex items-center justify-between py-4 px-3 shadow-xl border border-#607179 border-opacity-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.renderComponent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateActiveLibrary(_ctx.LIBRARY_MENU_ITEMS.allCourses)))
          }, [
            _createVNode(_component_router_link, {
              id: "library-all-courses",
              to: { name: _ctx.libraryPath }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(["flex items-center text-#607179 justify-center p-2 rounded-full w-10 h-10", 
              _ctx.active === _ctx.LIBRARY_MENU_ITEMS.allCourses
                ? `text-white ${_ctx.getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            ])
                }, [
                  _createVNode(_component_BaseIcon, { name: "scholar" })
                ], 2)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateActiveLibrary(_ctx.LIBRARY_MENU_ITEMS.myCourses)))
          }, [
            _createVNode(_component_router_link, {
              id: "library-my-courses",
              to: { name: _ctx.libraryPath }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(["flex items-center justify-center p-2 rounded-full w-10 h-10", 
              _ctx.active === _ctx.LIBRARY_MENU_ITEMS.myCourses
                ? `text-white ${_ctx.getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            ])
                }, [
                  _createVNode(_component_BaseIcon, { name: "book" })
                ], 2)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateActiveLibrary(_ctx.LIBRARY_MENU_ITEMS.search)))
          }, [
            _createVNode(_component_router_link, {
              id: "library-search",
              to: { name: _ctx.libraryPath }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(["flex items-center justify-center p-2 rounded-full w-10 h-10", 
              _ctx.active === _ctx.LIBRARY_MENU_ITEMS.search
                ? `text-white ${_ctx.getMobileTheme.secondary}`
                : 'text-gray-700 bg-disable'
            ])
                }, [
                  _createVNode(_component_BaseIcon, { name: "search" })
                ], 2)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}