<template>
  <div
    class="bg-white rounded px-5"
    :class="newMobileScreens ? 'mx-4 py-2 border' : 'py-5 shadow'"
  >
    <div
      class="materials-card-ttile font-semibold"
      :class="newMobileScreens ? 'text-lg text-gray-700' : 'text-xl'"
    >
      Files
    </div>
    <div
      class="post-material"
      :class="newMobileScreens ? 'my-2' : 'my-4'"
      v-for="material in postMaterials"
      :key="material.id"
    >
      <button
        class="flex justify-between items-center post-material-link border-none focus:border-none"
        :class="
          newMobileScreens ? getMobileTheme().primaryText : 'text-blue-500'
        "
        @click="downloadFileFromURL(material.id, material.url, material.title)"
      >
        <div class="flex items-center">
          <img
            :src="getIcon(material.type)"
            class="post-material-icon mr-2 h-6"
          />
          <a
            :href="replaceBucketUrlWithCdnUrl(material.url)"
            @click.prevent
            class="post-material-title custom-word-break !no-underline"
          >
            {{ material.title }}
          </a>
        </div>
        <UISpinner size="small" v-if="downloadingQueue.has(material.id)" />
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PostMaterial, { PostMaterialType } from '../../models/PostMaterial'
import { pdfIcon, imageIcon, docIcon } from '../../helper/constants'
import { getMobileTheme } from '@/helper'
import UISpinner from '@/components/common/UISpinner.vue'
import { downloadFile } from '@/helper/fileHandler'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'

export default defineComponent({
  props: {
    materials: {
      type: Array,
    },
  },
  components: {
    UISpinner,
  },
  data() {
    return {
      downloadingQueue: new Set(),
    }
  },
  computed: {
    postMaterials(): PostMaterial[] {
      if (!this.materials) return [] as PostMaterial[]

      return this.materials
        .slice()
        .sort((a: any, b: any) => a.sequenceNo - b.sequenceNo) as PostMaterial[]
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  methods: {
    async downloadFileFromURL(id: string, url: string, title: string) {
      try {
        this.downloadingQueue.add(id)
        await downloadFile(url, title)
      } catch (error) {
        console.error('Error while downloading file --> ', error)
      } finally {
        this.downloadingQueue.delete(id)
      }
    },
    getIcon(materialType: string) {
      switch (materialType) {
        case PostMaterialType.pdf: {
          return pdfIcon
        }
        case PostMaterialType.image: {
          return imageIcon
        }
        default: {
          return docIcon
        }
      }
    },
    replaceBucketUrlWithCdnUrl,
  },
})
</script>
<style scoped>
.post-material {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: inherit;
}
.custom-word-break {
  word-break: break-word;
}
</style>
