import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-11/12 mx-auto my-4 flex flex-col justify-center items-center shadow-sm rounded-lg border border-[#E4E4E4]",
  style: { padding: '94px 10px 208px 10px' }
}
const _hoisted_2 = { class: "text-[#1D2939] text-2xl font-normal my-4" }
const _hoisted_3 = { class: "text-[#1D2939] text-base font-normal my-4 text-center" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center"
}
const _hoisted_5 = { class: "line-clamp-1" }
const _hoisted_6 = { class: "line-clamp-1" }
const _hoisted_7 = {
  key: 1,
  class: "w-11/12 mx-auto flex items-center justify-center"
}

import { ArrowNarrowRightIcon, Lock01Icon } from "@gohighlevel/ghl-icons/24/outline";
import UISpinner from "../common/UISpinner.vue";
import { useRouter } from "vue-router";
import { PostContentType } from "@/models/Post";
import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";
import { computed } from "vue";
import { LEVELUP_LOCATION_IDS } from "@/helper/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryPostLockedV2',
  props: [
    "lockedOverlayContentText",
    "lockedPost",
    "lockedCategory",
    "hasProductAccess",
    "showLockedButton",
],
  setup(__props) {

const router = useRouter();
const store = useStore();
const isLevelUpItem = computed(() => true)
// return LEVELUP_LOCATION_IDS.includes(store.getters.locationId))


const newMobileScreens = computed(() => {
    return store.getters.newMobileScreens;
})

function navigateToPost(lockedPost) {
    let routeName;

    if (lockedPost?.contentType === PostContentType.assignment) {
        routeName = "mobile-assignment";
    } else if (lockedPost?.contentType === PostContentType.quiz) {
        routeName = "mobile-quiz-overview";
    } else {
        routeName = "post-overview";
    }

    router.push({
        name: routeName,
        params: {
            id: lockedPost?.productId,
            category_id: lockedPost?.categoryId,
            post_id: lockedPost?.id,
        },
    });
}

function routeToRespectivePostOrCategory(lockedPost, lockedCategory) {
    if (lockedPost && lockedPost?.id) {
        // Determine the type of lesson content and navigate accordingly.
        navigateToPost(lockedPost);
    }
    if (lockedCategory && lockedCategory?.id) {
        if (isLevelUpItem.value) {
            router.push({
                name: "category-list-levelup",
                params: { id: lockedCategory?.productId },
                query: {
                    selected_tab: 'syllabus',
                    category_id: lockedCategory?.parentCategory || lockedCategory?.id,
                },
            });
        } else {
            router.push({
                name: "category-list",
                params: { id: lockedCategory?.productId },
            });
        }
    }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Lock01Icon), { class: "w-24 h-24 text-[--primary-color] my-4" }),
    _createElementVNode("span", _hoisted_2, "This " + _toDisplayString(__props.lockedOverlayContentText) + " Is Locked ", 1),
    _createElementVNode("span", _hoisted_3, "To gain access to this " + _toDisplayString(__props.lockedOverlayContentText) + ", please complete", 1),
    (__props.showLockedButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (__props.lockedCategory?.id)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["w-3/4 rounded-lg flex items-center justify-center py-0 px-4 text-sm font-semibold text-white h-9 cursor-pointer bg-[--primary-color] my-4", { clickable: __props.hasProductAccess }]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (routeToRespectivePostOrCategory(null, __props.lockedCategory)))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(__props.lockedCategory?.title), 1),
                _createVNode(_unref(ArrowNarrowRightIcon), { class: "min-w-5 h-5 mx-2 flex-shrink-0" })
              ], 2))
            : _createCommentVNode("", true),
          (__props.lockedPost?.id)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["w-3/4 rounded-lg flex items-center justify-center py-0 px-4 text-sm font-semibold text-white h-9 cursor-pointer bg-[--primary-color] my-4", { clickable: __props.hasProductAccess }]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (routeToRespectivePostOrCategory(__props.lockedPost, null)))
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(__props.lockedPost?.title), 1),
                _createVNode(_unref(ArrowNarrowRightIcon), { class: "min-w-5 h-5 mx-2 flex-shrink-0" })
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (newMobileScreens.value)
            ? (_openBlock(), _createBlock(_unref(UISkeleton), {
                key: 0,
                height: "36px",
                class: "w-3/4 my-2 mx-auto",
                sharp: false
              }))
            : (_openBlock(), _createBlock(UISpinner, {
                key: 1,
                size: "small"
              }))
        ]))
  ]))
}
}

})