import http from '../../http-common'

export default class UserPostTrackingService {
  protected subdomain = 'user-post-tracking'
  private readonly locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  private get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  checkIfPostStarted(postId: string, categoryId: string, productId: string) {
    return http
      .get(
        `${this.endpoint}/products/${productId}/posts/${postId}/categories/${categoryId}/check-post-started`
      )
      .then((resp) => resp.data)
  }
}
