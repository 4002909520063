<template>
  <div
    id="result-container"
    class="px-8 py-2 rounded-md flex justify-between items-center w-full font-medium min-h-52 gap-2"
    :class="{
      'custom-gradient text-white':
        props.result === AssessmentStatusOptions.passed,
      'bg-neo-classic-light-error text-gray-900':
        props.result === AssessmentStatusOptions.failed,
      'assignments-processing-bg text-white':
        props.result === AssessmentStatusOptions.processing,
    }"
    v-if="props.result !== 'loading'"
  >
    <div id="result-message-wrapper" class="w-5/6">
      <div class="flex items-center gap-2">
        <CheckCircleIcon
          class="w-6 h-6"
          v-if="props.result === AssessmentStatusOptions.passed"
        />
        <XCircleIcon
          class="w-6 h-6"
          v-if="props.result === AssessmentStatusOptions.failed"
        />
        <Title tag="h2" class="font-medium mb-0">
          {{ resultHeader }}
        </Title>
      </div>
      <RichTextViewer
        v-if="htmlConfirmationMessage"
        :html="htmlConfirmationMessage"
      />
      <Paragraph
        v-else
        :maxLength="250"
        :showArrows="false"
        mode="tooltip"
        class="text-[16px] font-normal text-sm mt-0"
      >
        {{ props.confirmationMessage }}
      </Paragraph>
    </div>

    <div
      v-if="props.assignmentType === 'quiz'"
      id="result-score-container "
      class="flex justify-between w-[35%] mr-4 gap-2"
    >
      <div id="result-score-wrapper" class="flex flex-col h-fit items-center">
        <Title tag="h3" class="font-normal mb-0">Score</Title>
        <p class="text-2xl font-normal mb-0 m-0">{{ props.score }}%</p>
      </div>
      <div id="result-correct-wrapper" class="flex flex-col h-fit items-center">
        <Title tag="h3" class="font-normal mb-0">Correct</Title>
        <p class="text-2xl font-normal mb-0 m-0">
          {{ props.correct }}/{{ props.totalQuestions }}
        </p>
      </div>
      <div
        id="result-attempted-wrapper"
        class="flex flex-col h-fit items-center"
      >
        <Title tag="h3" class="font-normal mb-0">Attempted</Title>
        <p class="text-2xl font-normal mb-0 m-0">
          {{ props.attempted }}/{{ props.totalQuestions }}
        </p>
      </div>
    </div>
    <div id="result-svg-wrapper">
      <img
        class="h-10/12"
        v-if="result === AssessmentStatusOptions.passed"
        :src="resultImage"
        alt="quiz-pass-illus"
      />
      <img
        class="h-10/12"
        v-if="result === AssessmentStatusOptions.failed"
        :src="resultImage"
        alt="quiz-pass-illus"
      />
      <img
        class="h-10/12"
        v-if="result === AssessmentStatusOptions.processing"
        :src="resultImage"
        alt="quiz-pass-illus"
      />
    </div>
  </div>
  <UISkeleton
    class="px-8 py-2 rounded-md flex justify-between items-center w-full font-medium h-52"
    v-else
  />
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import { CheckCircleIcon, XCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  assignmentProcessingImage,
  quizFailImage,
  quizPassImage,
} from '@/helper/constants'
import { UISkeleton } from '@gohighlevel/ghl-ui'

const props = defineProps({
  resultMessage: {
    type: String,
    default: '',
  },
  htmlConfirmationMessage: {
    type: String,
    default: '',
  },
  confirmationMessage: {
    type: String,
    default: '',
  },
  score: {
    type: Number,
    default: 0,
  },
  correct: {
    type: Number,
    default: 0,
  },
  totalQuestions: {
    type: Number,
    default: 0,
  },
  result: {
    type: String as () => AssessmentStatusOptions | 'loading',
    default: 'loading',
  },
  attempted: {
    type: Number,
    default: 0,
  },
  header: {
    type: String,
    default: '',
  },
  assignmentType: {
    type: String,
    default: 'assessment',
  },
})

const resultImage = computed(() => {
  switch (props.result) {
    case AssessmentStatusOptions.passed:
      return quizPassImage
    case AssessmentStatusOptions.failed:
      return quizFailImage
    case AssessmentStatusOptions.processing:
      return assignmentProcessingImage
    default:
      return null
  }
})

const resultHeader = computed(() => {
  if (props.header) return props.header
  else {
    switch (props.result) {
      case AssessmentStatusOptions.passed:
        return 'You have passed the assignment.'
      case AssessmentStatusOptions.failed:
        return 'You failed the assignment.'
      case AssessmentStatusOptions.processing:
        return 'Assessment under review'
      default:
        return ''
    }
  }
})
</script>

<style scoped>
.custom-gradient {
  background: linear-gradient(88.15deg, #5bc4aa 32.48%, #469ab9 122.99%);
}
.assignments-processing-bg {
  background: linear-gradient(0deg, #407bff, #407bff);
}
</style>
