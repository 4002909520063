import Model from './Model'
import Product from './Product'
import UpsellOffer from './UpsellOffer'
import OfferCustomization from './OfferCustomization'

export enum OfferVisibility {
  published = 'published',
  draft = 'draft',
}

export enum OfferType {
  free = 'free',
  oneTime = 'onetime',
  subscription = 'subscription',
  multiple = 'multiple',
}

export enum OfferEmailTemplateType {
  default = 'default',
  custom = 'custom',
}

export enum CheckoutSourceType {
  default = 'default',
  ONECLICK = 'one_click_checkout',
  INAPP = 'in_app_checkout',
}

export default class Offer extends Model {
  constructor(payload?: {
    id?: string
    title?: string
    type?: string
    description?: string
    offerTitle?: string
    offerBody?: string
    visibility?: string
    posterImage?: string
    cartAbandonment?: number
    offerStartDate?: string
    offerDuration?: number
    emailTemplateType?: string
    emailTemplateId?: string
    notificationEmailAddress?: string
    afterPurchaseRedirectPage?: string
    paymentId?: string
    amount?: number
    subscriptionId?: string
    subscriptionEndDate?: string
    priceDisplayOverride?: string
    currency?: string
    setupFee?: number
    trialDays?: number
    numberOfPayments?: number
    interval?: string
    intervalCount?: string
    paymentProvider?: string
    planId?: string
    productIds?: Array<string>
    paymentProductId?: string
    paymentPriceId?: string
    coupons?: boolean
  }) {
    super(payload || {})
  }

  get isLivePaymentMode(): boolean {
    return this._data.isLivePaymentMode
  }

  get paymentProductId(): string {
    return this._data.paymentProductId
  }

  get paymentPriceId(): string {
    return this._data.paymentPriceId
  }

  get products(): Array<Product> {
    return this._data.products
  }

  set products(products: Array<Product>) {
    this._data.products = products
  }

  get productIds(): Array<string> {
    return this._data.productIds
  }

  set productIds(productIds: Array<string>) {
    this._data.productIds = productIds
  }

  get type(): string {
    return this._data.type
  }

  set type(type: string) {
    this._data.type = type
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get description(): string {
    return this._data.description
  }

  set description(description: string) {
    this._data.description = description
  }

  get internalTitle(): string {
    return this._data.internalTitle
  }

  set internalTitle(internalTitle: string) {
    this._data.internalTitle = internalTitle
  }

  get offerBody(): string {
    return this._data.offerBody
  }

  set offerBody(offerBody: string) {
    this._data.offerBody = offerBody
  }

  get visibility(): string {
    return this._data.visibility
  }

  set visibility(visibility: string) {
    this._data.visibility = visibility
  }

  get posterImage(): string {
    return this._data.posterImage
  }

  set posterImage(posterImage: string) {
    this._data.posterImage = posterImage
  }

  get cartAbandonment(): number {
    return this._data.cartAbandonment
  }

  set cartAbandonment(cartAbandonment: number) {
    this._data.cartAbandonment = cartAbandonment
  }

  get offerStartDate(): string {
    return this._data.offerStartDate
  }

  set offerStartDate(offerStartDate: string) {
    this._data.offerStartDate = offerStartDate
  }

  get offerDuration(): number {
    return this._data.offerDuration
  }

  set offerDuration(offerDuration: number) {
    this._data.offerDuration = offerDuration
  }

  get emailTemplateType(): string {
    return this._data.emailTemplateType
  }

  set emailTemplateType(emailTemplateType: string) {
    this._data.emailTemplateType = emailTemplateType
  }

  get emailTemplateId(): string {
    return this._data.emailTemplateId
  }

  set emailTemplateId(emailTemplateId: string) {
    this._data.emailTemplateId = emailTemplateId
  }

  get notificationEmailAddress(): string {
    return this._data.notificationEmailAddress
  }

  set notificationEmailAddress(notificationEmailAddress: string) {
    this._data.notificationEmailAddress = notificationEmailAddress
  }

  get afterPurchaseRedirectPage(): string {
    return this._data.afterPurchaseRedirectPage
  }

  set afterPurchaseRedirectPage(afterPurchaseRedirectPage: string) {
    this._data.afterPurchaseRedirectPage = afterPurchaseRedirectPage
  }

  get paymentId(): string {
    return this._data.paymentId
  }

  set paymentId(paymentId: string) {
    this._data.paymentId = paymentId
  }

  get amount(): number {
    return this._data.amount
  }

  set amount(amount: number) {
    this._data.amount = amount
  }

  get subscriptionId(): string {
    return this._data.subscriptionId
  }

  set subscriptionId(subscriptionId: string) {
    this._data.subscriptionId = subscriptionId
  }

  get planId(): string {
    return this._data.planId
  }

  set planId(planId: string) {
    this._data.planId = planId
  }

  get subscriptionEndDate(): string {
    return this._data.subscriptionEndDate
  }

  set subscriptionEndDate(subscriptionEndDate: string) {
    this._data.subscriptionEndDate = subscriptionEndDate
  }

  get priceDisplayOverride(): string {
    return this._data.priceDisplayOverride
  }

  set priceDisplayOverride(priceDisplayOverride: string) {
    this._data.priceDisplayOverride = priceDisplayOverride
  }

  get currency(): string {
    return this._data.currency
  }

  set currency(currency: string) {
    this._data.currency = currency
  }

  get setupFee(): number {
    return this._data.setupFee
  }

  set setupFee(setupFee: number) {
    this._data.setupFee = setupFee
  }

  get trialDays(): number {
    return this._data.trialDays
  }

  set trialDays(trialDays: number) {
    this._data.trialDays = trialDays
  }

  get numberOfPayments(): number {
    return this._data.numberOfPayments
  }

  set numberOfPayments(numberOfPayments: number) {
    this._data.numberOfPayments = numberOfPayments
  }

  get interval(): string {
    return this._data.interval
  }

  set interval(interval: string) {
    this._data.interval = interval
  }

  get intervalCount(): number {
    return this._data.intervalCount
  }

  set intervalCount(intervalCount: number) {
    this._data.intervalCount = intervalCount
  }

  get paymentProvider(): string {
    return this._data.paymentProvider
  }

  set paymentProvider(paymentProvider: string) {
    this._data.paymentProvider = paymentProvider
  }

  get upSellOffer(): UpsellOffer {
    return this._data.upSellOffer
  }

  set upSellOffer(UpsellOffer: UpsellOffer) {
    this._data.upSellOffer = UpsellOffer
  }

  get OfferCustomization(): OfferCustomization {
    return this._data.customizations
  }

  set OfferCustomization(OfferCustomization: OfferCustomization) {
    this._data.customizations = OfferCustomization
  }

  get customJs(): string {
    return this._data.customJs
  }

  set customJs(customJs: string) {
    this._data.customJs = customJs
  }

  get customCss(): string {
    return this._data.customCss
  }

  set customCss(customCss: string) {
    this._data.customCss = customCss
  }

  get customHeader(): string {
    return this._data.customHeader
  }

  set customHeader(customHeader: string) {
    this._data.customHeader = customHeader
  }

  get customFooter(): string {
    return this._data.customFooter
  }

  set customFooter(customFooter: string) {
    this._data.customFooter = customFooter
  }

  get coupons(): boolean {
    return this._data.coupons
  }

  set coupons(value: boolean) {
    this._data.coupons = value
  }
}
