import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "completion-count",
  class: "font-semibold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!

  return (_openBlock(), _createElementBlock("div", {
    id: "product-progress-stats",
    class: _normalizeClass(["bg-white rounded py-5 lg:py-10 px-5 text-base lg:text-xl", [
      _ctx.$route.name.includes('product-overview') ? 'lesson-card' : '',
      _ctx.newMobileScreens ? 'border' : 'shadow',
    ]])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.statMessage), 1),
    _createVNode(_component_Progress, {
      class: "mt-2 h-3 lg:h-4",
      "percent-completed": _ctx.percentCompleted,
      "show-percentage": _ctx.percentCompleted > 5,
      "progress-color": _ctx.progressColor
    }, null, 8, ["percent-completed", "show-percentage", "progress-color"])
  ], 2))
}