<script setup lang="ts">
import { LEVELUP_LOCATION_IDS } from "@/helper/constants";
import { TopNavBar } from "@gohighlevel/clientportal-core";
import { BookOpen01Icon, Home05Icon, SearchSmIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIButton } from "@gohighlevel/ghl-ui";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import BaseIcon from "../svgicons/BaseIcon.vue";
import { redirectTo } from "@/helper";

defineProps({
    backCallback: Function,
});

const store = useStore();
const route = useRoute();

const isLibraryPath = () => {
    const libraryPath = ["library-v2", "library-v2-v2"];
    return libraryPath.includes(route.name as string);
}
const isCpAppInstalled = computed(() => {
    return store.getters.appFlags.isCpAppInstalled
});
const isPostDetailsPath = computed(() => {
    const postDetailsPath = ["post-overview", "post-overview-v2"];
    return postDetailsPath.includes(route.name as string);
});
const isAssignmentOrQuizPath = computed(() => {
    const assignmentDetailsPath = ["mobile-assignment", "mobile-assignment-v2", "mobile-assignment-result", "mobile-assignment-result-v2", "mobile-assignment-overview", "mobile-assignment-overview-v2"];
    const quizDetailsPath = ["mobile-quiz-result", "mobile-quiz-result-v2", "mobile-quiz-questions-v2", "mobile-quiz-questions", "mobile-quiz-overview", "mobile-quiz-overview-v2"];
    return assignmentDetailsPath.includes(route.name as string) || quizDetailsPath.includes(route.name as string);
});

const locationId = computed(() => {
    return store.getters.locationId;
});
const isLevelUpItem = computed(() => {
    return true
    // return LEVELUP_LOCATION_IDS.includes(locationId.value)
});

const isPortalActive = computed(() => {
    return isPortalActive;
});

const handleOpenCurriculumComments = () => {
    store.commit("SET_CURRICULUM_COMMENTS_OPEN", !store.getters.openCurriculumComments);
};
const handleSearchDrawer = () => {
    store.commit("SET_SEARCH_DRAWER_OPEN", !store.getters.openSearchDrawer);
};
</script>

<template>
    <TopNavBar :App="'Courses'">
        <template #filter-left>
            <UIButton v-if="backCallback" @click="backCallback" name="search" class="global-search mr-[8px]"
                id="btn-notification" type="default" :quaternary="true" :circle="true">
                <BaseIcon name="chevronleft" hwClass="w-5 h-5 text-clientportal-font-secondary" />
            </UIButton>
        </template>
        <template #filter-right>
            <UIButton v-if="isLevelUpItem" name="search" class="global-search" @click="handleSearchDrawer"
                id="btn-notification" type="default" :quaternary="true" :circle="true">
                <SearchSmIcon class="w-5 h-5 text-clientportal-font-secondary" />
            </UIButton>
            <UIButton v-if="isLevelUpItem && (isPostDetailsPath || isAssignmentOrQuizPath)" name="search"
                @click="handleOpenCurriculumComments" id="btn-notification" type="default" :quaternary="true"
                :circle="true">
                <BookOpen01Icon class="w-5 h-5 text-clientportal-font-secondary" />
            </UIButton>
        </template>
    </TopNavBar>
</template>