<template>
  <div id="comment-editor-container">
    <div class="relative" id="comment-editor-wrapper">
      <Editor
        ref="commentInputRef"
        :editorText="contentText"
        class="custom-editor-styling border p-2"
        :class="{
          'border-neo-classic-error': replyTextLength > commentCharacterLength,
        }"
        @update:editorText="updateEditorText"
        @getInstance="updateEditorInstance"
      />
    </div>
    <!-- Comment Controls -->
    <div class="flex items-center justify-between w-full">
      <div class="text-gray-500 flex-1 text-xs m-2 h-full">
        {{ replyTextLength }} / {{ commentCharacterLength }}
      </div>
      <div id="comment-controls" class="flex gap-2 justify-end mt-2">
        <UIDropdown
          v-if="!isReplying"
          id="comment-privacy-dropdown"
          :options="
            enableEdit && editThisComment.parentCommentId ? [] : commentPrivacy
          "
          placement="bottom"
          @select="setCommentPrivacy"
        >
          <div
            class="flex items-center gap-1 font-medium text-neo-classic-primary-accent cursor-pointer"
            :class="{
              'text-neo-classic-secondary-text':
                enableEdit && editThisComment.parentCommentId,
            }"
          >
            <component :is="selectedPrivacyOption.icon" />
            {{ selectedPrivacyOption.label }}
            <ChevronDownIcon class="w-4 h-4" />
          </div>
        </UIDropdown>
        <Button
          @click="clearContent"
          :disabled="buttonDisable && !isReplying && !enableEdit"
          variant="subtle"
        >
          Cancel
        </Button>
        <Button
          @click="enableEdit ? updateComment() : createComment()"
          :disabled="buttonDisable"
          variant="primary"
        >
          {{ enableEdit ? 'Update Comment' : 'Comment' }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UIDropdown } from '@gohighlevel/ghl-ui'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { COMMENT_MAX_LENGTH } from '@/helper/constants'
import { computed, defineProps, ref, inject, watch, onMounted } from 'vue'
import { ChevronDownIcon } from '@gohighlevel/ghl-icons/24/outline'
import { CommentService } from '@/services'
import { CommentVisibility } from '@/models/Comment'
import { CommentPrivacy } from '@/models/Product'
import Editor from '@/components/common/Editor.vue'

const props = defineProps({
  editorText: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Type your comment here...',
  },
  commentCharacterLength: {
    type: Number,
    default: COMMENT_MAX_LENGTH || 500,
  },
  postId: {
    type: String,
    default: '',
  },
  productId: {
    type: String,
    default: '',
  },
  pendoPayload: {
    type: Object,
    default: () => ({}),
  },
  isReplying: {
    type: Boolean,
    default: false,
  },
  replyToComment: {
    type: String,
    default: '',
  },
  parentCommentId: {
    type: String,
    default: '',
  },
  repliedToComment: {
    type: String,
    default: '',
  },
  repliedToUserId: {
    type: String,
    default: '',
  },
  repliedToCommentPrivacy: {
    type: String,
    default: '',
  },
  onCancel: {
    type: Function,
    default: () => null,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
  editThisComment: {
    type: Object,
    default: () => ({}),
  },
  productCommentPrivacy: {
    type: String,
    default: CommentPrivacy.instructorOnly,
  },
})

const emit = defineEmits([
  'update:editorText',
  'addNewComment',
  'updateEditedComment',
])

const commentPrivacy: Array<{ label: string; key: CommentPrivacy; icon: any }> =
  inject('commentPrivacy') || []

const commentInputRef = ref(null)
const contentText = ref('')
const editorInstance = ref(null)
const posting = ref(false)
const selectedCommentPrivacy = ref<string>(CommentPrivacy.instructorOnly)

const selectedPrivacyOption = computed(() => {
  if (
    !selectedCommentPrivacy.value ||
    selectedCommentPrivacy.value === CommentPrivacy.publicAndInstructor
  )
    return commentPrivacy[0]
  return commentPrivacy.find((cp) => cp.key === selectedCommentPrivacy.value)
})

const buttonDisable = computed(() => {
  return (
    posting.value ||
    contentText.value.length === 0 ||
    replyTextLength.value > props.commentCharacterLength
  )
})

const replyTextLength = computed(
  () =>
    contentText.value.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ')
      .length
)

function setCommentPrivacy(value: CommentPrivacy) {
  selectedCommentPrivacy.value = value
}

function updateEditorInstance(instance: any) {
  editorInstance.value = instance
}

function clearContent() {
  if (props.enableEdit) {
    emit('updateEditedComment', null)
  }
  contentText.value = ''
  editorInstance.value.commands?.setContent('')
  setDefaultCommentPrivacy()
  props.onCancel()
}

function updateEditorText(updatedContent: string) {
  contentText.value = updatedContent
  emit('update:editorText', updatedContent)
}

function getCommentVisibility(privacy: string) {
  return privacy === CommentPrivacy.instructorOnly
    ? CommentVisibility.private
    : CommentVisibility.public
}

function getCommentPrivacy(visibility: string) {
  return visibility === CommentVisibility.private
    ? CommentPrivacy.instructorOnly
    : CommentPrivacy.public
}

async function createComment() {
  try {
    let visibility = getCommentVisibility(selectedCommentPrivacy.value)

    if (props.isReplying) {
      visibility = props.repliedToCommentPrivacy as CommentVisibility
    }

    posting.value = true

    let payload: any = {
      postId: props.postId,
      productId: props.productId,
      content: contentText.value,
      visibility,
      pendoPayload: {
        postTitle: props.pendoPayload.post.title,
        productTitle: props.pendoPayload.product.title,
        categoryTitle: props.pendoPayload.category.title,
      },
    }

    if (props.isReplying) {
      payload = {
        ...payload,
        parentCommentId: props.parentCommentId,
        repliedToComment: props.repliedToComment,
        repliedToUserId: props.repliedToUserId,
      }
    }
    const { data: createdComment } = await CommentService.createComment(payload)

    if (createdComment.error) {
      throw createdComment.error
    }
    posting.value = false
    emit('addNewComment', createdComment.comment)
    clearContent()
  } catch (error) {
    console.error('Something went wrong', error)
  }
}

async function updateComment() {
  try {
    posting.value = true
    await CommentService.updateComment(props.editThisComment.id, {
      content: contentText.value,
      visibility: getCommentVisibility(selectedCommentPrivacy.value),
      pendoPayload: {
        postTitle: props.pendoPayload.post.title,
        productTitle: props.pendoPayload.product.title,
        categoryTitle: props.pendoPayload.category.title,
      },
    })
    emit('updateEditedComment', {
      ...props.editThisComment,
      content: contentText.value,
      visibility: getCommentVisibility(selectedCommentPrivacy.value),
    })
    clearContent()
  } catch (error) {
    console.error('Error while updating comment: ', error)
  } finally {
    posting.value = false
  }
}

function updateCommentContent() {
  if (props.editThisComment && props.editThisComment.content) {
    contentText.value = props.editThisComment.content || ''
    selectedCommentPrivacy.value =
      getCommentPrivacy(props.editThisComment.visibility) ||
      CommentPrivacy.instructorOnly

    if (commentInputRef.value && commentInputRef.value.editorContainerElement) {
      commentInputRef.value.editorContainerElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })

      commentInputRef.value.editorContainerElement?.focus()
    }
  }
}

function setDefaultCommentPrivacy() {
  if (props.productCommentPrivacy) {
    selectedCommentPrivacy.value =
      props.productCommentPrivacy === CommentPrivacy.publicAndInstructor
        ? CommentPrivacy.instructorOnly
        : props.productCommentPrivacy
  }
}

onMounted(() => {
  updateCommentContent()
  setDefaultCommentPrivacy()
})

watch(() => props.editThisComment, updateCommentContent, { deep: true })
</script>
