import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryPostTree = _resolveComponent("CategoryPostTree")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.categoryLockData.length)
      ? (_openBlock(), _createBlock(_component_CategoryPostTree, {
          key: 0,
          "product-id": _ctx.productId,
          "category-tree": _ctx.categoryTree,
          "completed-posts": _ctx.completedPosts,
          "completed-categories": _ctx.completedCategories,
          "category-lock-data": _ctx.categoryLockData,
          hasProductAccess: _ctx.hasProductAccess
        }, null, 8, ["product-id", "category-tree", "completed-posts", "completed-categories", "category-lock-data", "hasProductAccess"]))
      : _createCommentVNode("", true)
  ]))
}