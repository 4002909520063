import Model from './Model'
import Post from './Post'

export enum CategoryVisibility {
  published = 'published',
  draft = 'draft',
  locked = 'locked',
  drip = 'drip',
}
export default class Category extends Model {
  constructor(payload?: {
    title?: string
    description?: string
    parentCategory?: string | null
    visibility?: string
    dripDays?: number
    posterImage?: string
    sequenceNo?: number
    productId?: string
    lockedBy?: string
    lockedByCategory?: string
    hasProductAccess?: boolean
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get description(): string {
    return this._data.description
  }

  set description(description: string) {
    this._data.description = description
  }

  get parentCategory(): string | null {
    return this._data.parentCategory
  }

  set parentCategory(parentCategory: string | null) {
    this._data.parentCategory = parentCategory
  }

  get visibility(): string {
    return this._data.visibility
  }

  set visibility(visibility: string) {
    this._data.visibility = visibility
  }

  get dripDays(): number {
    return this._data.dripDays
  }

  set dripDays(dripDays: number) {
    this._data.dripDays = dripDays
  }

  get posterImage(): string {
    return this._data.posterImage
  }

  set posterImage(posterImage: string) {
    this._data.posterImage = posterImage
  }

  get sequenceNo(): number {
    return this._data.sequenceNo
  }

  set sequenceNo(sequenceNo: number) {
    this._data.sequenceNo = sequenceNo
  }

  get productId(): string {
    return this._data.productId
  }

  set productId(productId: string) {
    this._data.productId = productId
  }

  get posts(): Array<Post> {
    return this._data.posts
  }

  set posts(posts: Array<Post>) {
    this._data.posts = posts
  }

  get available(): boolean {
    return this._data.available || false
  }

  set available(available: boolean) {
    this._data.available = available
  }

  get isLocked(): boolean {
    return this._data.isLocked || false
  }

  set isLocked(isLocked: boolean) {
    this._data.isLocked = isLocked
  }

  get lockedBy(): string {
    // post
    return this._data.lockedBy
  }

  get lockedByCategory(): string {
    // category
    return this._data.lockedByCategory
  }

  get hasProductAccess(): boolean {
    return this._data.hasProductAccess
  }

  set hasProductAccess(hasProductAccess: boolean) {
    this._data.hasProductAccess = hasProductAccess
  }
}
