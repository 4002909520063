<template>
  <div id="app-container">
    <div v-if="fetching && !isCpAppInstalled" class="w-full h-screen flex items-center justify-center">
      <UIContentWrap :fullScreen="true">
        <UISpinner size="extra-large" />
      </UIContentWrap>
    </div>
    <div v-if="isAppLoading() && isCpAppInstalled" class="fixed z-50">
      <AppLoader />
    </div>
    <UIContentWrap class="route-container" v-else :fullScreen="true">
      <router-view @updateLoginStatus="checkLoginStatus" />
    </UIContentWrap>
    <MobileFloatingNavigation v-if="
      !isLevelUpItem && !fetching && newMobileScreens && !isCpAppInstalled
    " />
    <BottomNavBar v-else-if="!fetching && newMobileScreens && showCpBottomNav" :navigation-items="getNavigationItems"
      class="cols-span-5 fixed bottom-0 w-full justify-center" />
    <MobileAlertModal v-if="newMobileScreens && isLoggedIn && !isClientPortal() && localPWAFlag"
      :pwa-alert-data="pwaAppSettings" :isLoggedIn="isLoggedIn" />
    <MobileSearchV2 />

    <PaymentRedirectionConfirmation v-if="!fetching" />
  </div>
</template>
<script lang="ts">
import PaymentRedirectionConfirmation from '@/components/checkout/PaymentRedirectionConfirmation.vue'
import MobileAlertModal from '@/components/common/MobileAlertModal.vue'
import MobileFloatingNavigation from '@/components/common/MobileFloatingNavigation.vue'
import UISpinner from '@/components/common/UISpinner.vue'
import { fetchAdminCat, fetchCat } from '@/helper/permission.helper'
import { Capacitor } from '@capacitor/core'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { SplashScreen } from '@capacitor/splash-screen'
import useNavigation from '@/compositions/navigation'
import {
  LEVELUP_LOCATION_IDS,
  LIBRARY_MENU_ITEMS,
  disableZoomMetaContent,
} from '@/helper/constants'
import {
  AppLoader,
  BottomNavBar,
  handleIOSPushRedirection,
  isAppLoading,
  isPwa,
  setAppLoading,
} from '@gohighlevel/clientportal-core'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import 'firebase/auth'
import postscribe from 'postscribe'
import { defineComponent } from 'vue'
import http, { generateToken } from '../http-common'
import config from './config'
import 'firebase/auth'
import {
  createElement,
  detectDevice,
  fetchAndSaveBrandData,
  isAppInstalled,
  isClientPortal,
  sanitizeJavaScriptCodeToExecuteIt,
} from './helper'
import restAgent from './helper/restAgent'
import MobileSearchV2 from './views/mobile/MobileSearchV2.vue'
import 'firebase/auth'

export default defineComponent({
  head() {
    const meta = {
      title: this.$store.getters.pageTitle,
      link: [
        {
          vmid: 'favicon',
          rel: 'shortcut icon',
          href: this.$store.getters.favicon,
        },
      ],
    }

    if (this.appManifest) {
      meta['link'].push(this.appManifest)
      meta['link'].push(...this.appleIcons)
      meta['name'] = this.appleMeta
    }
    if (this.newMobileScreens) {
      meta['meta'] = [this.zoomDisableMeta]
    }

    return meta
  },
  components: {
    MobileAlertModal,
    UISpinner,
    BottomNavBar,
    AppLoader,
    UIContentWrap,
    PaymentRedirectionConfirmation,
    MobileSearchV2,
    MobileFloatingNavigation,
  },
  beforeMount() {
    this.updateDevice()
    // window.addEventListener('resize', this.updateDevice)
    this.checkLoginStatus()
  },
  async created() {
    await this.reIssueTokenWithExternalId()
  },
  unmounted() {
    this.emitter.off('handleUpsellCheckout')
  },
  async mounted() {
    if (Capacitor.getPlatform() === 'android') {
      SplashScreen.hide()
    }
    ScreenOrientation.lock({ orientation: 'portrait' })
    const activeCoursesRoute = this.$route.query.courses
      ? this.$route.query.courses === 'all'
        ? LIBRARY_MENU_ITEMS.allCourses
        : LIBRARY_MENU_ITEMS.myCourses
      : LIBRARY_MENU_ITEMS.allCourses
    this.$store.commit('UPDATE_ACTIVE_LIBRARY', activeCoursesRoute)
    this.emitter.on('handleUpsellCheckout', this.handleUpsellCheckout)
    this.fetching = true
    try {
      if (isClientPortal()) {
        await this.getUserDataFromClientPortal()
      }
      const brandData = await fetchAndSaveBrandData(this)
      if (!brandData) return
      // fetch portal settings data if not the cat
      const { siteInfo, builderSettings } = brandData

      if (siteInfo) {
        const { customJs, customCss, customHeader, customFooter } = siteInfo

        this.siteCustomJs = customJs || ''
        this.siteCustomCss = customCss || ''
        this.siteCustomHeader = customHeader || ''
        this.siteCustomFooter = customFooter || ''

        if (!this.locationId)
          this.$store.commit('SAVE_LOCATION_ID', siteInfo.locationId)
      }

      if (builderSettings) {
        const { appSettings, pwaEnabled } = builderSettings
        // making PWA as default
        const defaultPWAEnabled = true
        if (!isClientPortal()) {
          this.manifestData = appSettings || null
        }
        if (this.manifestData && pwaEnabled) {
          this.manifestData.start_url =
            config.mode === 'dev'
              ? 'http://localhost:4040/'
              : `https://${window.location.hostname}/`
          this.manifestData.display = 'standalone'
          this.manifestData.offline_enabled = true
        }

        if (appSettings) {
          this.setPWAAppSettings({
            name: appSettings.name,
            logo:
              appSettings.icons && appSettings.icons.length > 0
                ? appSettings.icons[0].src
                : '',
            description: appSettings.description,
            pwaEnabled: pwaEnabled, // keeping this flag if the PWA is not configured
          })
        }

        this.localPWAFlag = pwaEnabled
        this.updateMobileScreens(defaultPWAEnabled) //pwaEnabled
      }

      this.setAppFlags() // setting app flags once brand data is fetched

      // This will block selection and zoom in membership legacy PWA as well
      if (this.newMobileScreens && isPwa()) {
        this.blockSelectionAndZoom()
      }
      window.addEventListener('push-notification', (event: Event) => {
        handleIOSPushRedirection(event)
      })

      // Below code will add custom code in Web and Legacy courses which is coming from courses
      if (this.isCoursesCustomCodeExecutable) {
        this.addCoursesCustomCode()
      }
      // Below code will add custom code in Web which is coming from client portal
      if (this.isClientPortalWeb) {
        this.addClientPortalCustomCode()
      }
    } catch (error) {
      console.error('Error while fetching brand data --> ', error)
    } finally {
      this.fetching = false
      setAppLoading(false)
    }
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.updateDevice)
    this.removePWAEvent()
  },
  methods: {
    async getUserDataFromClientPortal() {
      this.fetchingClientPortal = true
      const cat = fetchCat()
      const token = await generateToken()
      const contactId = cat && cat.contactId ? cat.contactId : cat?.contact_id
      const locationId =
        cat && cat.locationId ? cat.locationId : cat?.location_id
      try {
        if (locationId && contactId) {
          const data = await restAgent.ClientPortal.getUserByContactId(
            locationId,
            contactId
          )
          this.$store.commit('SET_CLIENT_PORTAL_USER_DATA', data)
          this.$store.commit('SAVE_LOCATION_ID', locationId)
        }
      } catch (e) {
        console.error('Error while fetching client portal data: ', e)
      } finally {
        this.fetchingClientPortal = false
      }
    },
    addCoursesCustomCode() {
      this.addCustomCSSCode()
      this.addCustomJsCode()
      this.addCustomHeaderAndFooterCode()
    },
    blockSelectionAndZoom() {
      document.head.appendChild(
        createElement('meta', [
          { type: 'name', value: 'viewport' },
          { type: 'content', value: disableZoomMetaContent },
        ])
      )
      // Apply user-select: none; to the body
      document.body.style.userSelect = 'none'
    },
    addClientPortalCustomCode() {
      const modifiedCustomJs = sanitizeJavaScriptCodeToExecuteIt(
        this.clientPortalData?.customJs,
        [
          { type: 'type', value: 'text/javascript' },
          { type: 'vmid', value: 'client-portal-custom-js' },
        ]
      )
      document.head.appendChild(modifiedCustomJs.tag)
      modifiedCustomJs.trigger() // Triggering custom DOMContentLoaded event

      const cpCustomCss = createElement(
        'style',
        [
          { type: 'type', value: 'text/css' },
          { type: 'vmid', value: 'client-portal-custom-css' },
        ],
        this.clientPortalData?.customCss
      )
      document.head.appendChild(cpCustomCss)
      postscribe(
        '#clientPortalSiteCustomHeader',
        this.clientPortalData?.headerCode || ''
      )
      postscribe(
        '#clientPortalSiteCustomFooter',
        this.clientPortalData?.footerCode || ''
      )
    },
    addCustomHeaderAndFooterCode() {
      if (this.siteCustomHeader) {
        postscribe(`#siteCustomHeader`, this.siteCustomHeader || '')
      }

      if (this.siteCustomFooter) {
        postscribe(`#siteCustomFooter`, this.siteCustomFooter || '')
      }
    },
    addCustomCSSCode() {
      const customCss = document.createElement('style')
      customCss.setAttribute('type', 'text/css')
      customCss.setAttribute('vmid', 'site-custom-css')
      customCss.innerHTML = this.siteCustomCss
      document.head.appendChild(customCss)
    },
    addCustomJsCode() {
      const modifiedCustomJs = sanitizeJavaScriptCodeToExecuteIt(
        this.siteCustomJs,
        [
          { type: 'vmid', value: 'site-custom-js' },
          { type: 'type', value: 'text/javascript' },
        ]
      )
      document.head.appendChild(modifiedCustomJs.tag)
      modifiedCustomJs.trigger() // Triggering custom DOMContentLoaded event
    },
    handleUpsellCheckout(upsellId: string) {
      const routeData = {
        name: isClientPortal() ? 'in-app-upsell-v2' : 'in-app-upsell',
        params: {
          id: upsellId,
        },
      }
      if (!this.newMobileScreens) {
        const routerData = this.$router.resolve(routeData)
        const openedWindow = window.open(routerData.href, '_blank')
        const timer = setInterval(async () => {
          if (openedWindow.closed) {
            clearInterval(timer)
            window.location.reload()
          }
        }, 500)
      } else {
        this.$router.push(routeData)
      }
    },
    updateDevice() {
      if (window.innerWidth <= 1024) {
        this.$store.commit('SET_DEVICE', 'mobile')
      } else {
        this.$store.commit('SET_DEVICE', 'desktop')
      }

      this.updateMobileScreens(this.localPWAFlag)
    },
    updateMobileScreens(pwaFlag) {
      this.$store.commit(
        'SHOW_NEW_MOBILE_SCREENS',
        this.device === 'mobile' && !this.isBuilder // ? pwaFlag : false
      )
    },
    setPWAAppSettings(settings) {
      this.$store.commit('SET_PWA_APP_SETTINGS', settings)
    },
    removePWAEvent() {
      this.$store.commit('SET_PWA_APP_SETTINGS', {
        addWithExisting: true,
        pwaEvent: null,
      })
    },
    checkLoginStatus() {
      const cat = fetchCat()
      this.isLoggedIn = cat && (cat.contact_id || cat.contactId) ? true : false
    },

    isClientPortal,
    async reIssueTokenWithExternalId() {
      // fetch new token with external user id to let user access the membership portal
      const cat = await fetchCat()
      if (cat?.externalUserId || !isClientPortal()) {
        return
      }

      const response = await http.post(
        `${config.clientPortalServerUrl}/auth/regenerate-token`,
        {},
        {
          headers: {
            'Token-Id': cat?.tokenId, // Set 'Token-id' with the extracted token value
          },
        }
      )
      const firebaseToken = response.data

      window.$cookies.remove('cat')
      window.$cookies.set('cat', window.btoa(JSON.stringify(firebaseToken)))
      await generateToken()
    },
    setAppFlags() {
      this.$store.commit('SET_APP_FLAGS', {
        isLegacyWeb: !this.newMobileScreens && !isClientPortal(),
        isLegacyMobileBrowser: this.newMobileScreens && !isClientPortal(),
        isLegacyPWA:
          this.newMobileScreens && !isClientPortal() && isAppInstalled(),
        isClientPortalWeb: !this.newMobileScreens && isClientPortal(),
        isClientPortalMobileBrowser: this.newMobileScreens && isClientPortal(),
        isClientPortalMobileIOS:
          this.newMobileScreens &&
          isClientPortal() &&
          detectDevice() === 'iphone' &&
          isPwa(),
        isClientPortalMobileAndroid:
          this.newMobileScreens &&
          isClientPortal() &&
          detectDevice() === 'android' &&
          isPwa(),
        isCpAppInstalled: isPwa() && isClientPortal(),
      })
    },
    isPwa,
    isAppLoading,
  },
  data() {
    return {
      siteCustomJs: '',
      siteCustomCss: '',
      manifestData: null,
      localPWAFlag: false,
      fetching: true,
      isLoggedIn: false,
      fetchingClientPortal: false,
      siteCustomHeader: '',
      siteCustomFooter: '',
    }
  },
  computed: {
    appManifest(): any {
      if (this.manifestData) {
        const data = JSON.stringify(this.manifestData)
        const blob = new Blob([data], { type: 'application/json' })
        const manifestURL = URL.createObjectURL(blob)
        return {
          vmid: 'manifest',
          rel: 'manifest',
          href: manifestURL,
        }
      }
      return null
    },
    appleMeta(): any {
      return [
        {
          vmid: 'apple-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.manifestData.name,
        },
        {
          vmid: 'apple-web-app-capable',
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
      ]
    },
    appleIcons(): any {
      return this.manifestData.icons.map((icon, index) => {
        if (icon.src) {
          const appleIcon = {
            vmid: `apple-touch-icon-${index + 1}`,
            rel: 'apple-touch-icon',
            sizes: icon.sizes,
            href: icon.src,
          }
          return appleIcon
        }
      })
    },
    zoomDisableMeta() {
      return {
        name: 'viewport',
        content: disableZoomMetaContent,
      }
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    pwaAppSettings() {
      return this.$store.getters.pwaAppSettings
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
    isCpAppInstalled(): boolean {
      return this.$store.getters.appFlags.isCpAppInstalled
    },
    showCpBottomNav(): boolean {
      return (
        this.newMobileScreens &&
        [
          'library-v2',
          'library-v2-v2',
          'my-courses-library',
          'my-courses-library-v2',
          'ProfileContainer',
          'NotificationMobileView',
          'LandingPage',
          'LandingPage-v2',
        ].includes(this.$route.name)
      )
    },
    clientPortalData() {
      return this.$store.getters.clientPortalDomainData
    },
    isCoursesCustomCodeExecutable() {
      return (
        this.$store.getters.appFlags.isLegacyMobileBrowser ||
        this.$store.getters.appFlags.isLegacyPWA ||
        this.$store.getters.appFlags.isLegacyWeb ||
        this.$store.getters.appFlags.isClientPortalWeb
      )
    },
    isClientPortalWeb() {
      return this.$store.getters.appFlags.isClientPortalWeb
    },
    isLevelUpItem() {
      return true
      // return LEVELUP_LOCATION_IDS.includes(this.locationId)
    },
    getNavigationItems() {
      const navigation = useNavigation(this.isLevelUpItem)
      return navigation.navigationItems.value
    },
    openSearchDrawer() {
      return this.$store.getters.openSearchDrawer
    },
  },
})
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  overscroll-behavior: none;
}

/* Removes double tap to zoom useful in mobile ui */
@media (max-width: 1024px) {
  * {
    touch-action: manipulation;
  }
}

.clickable {
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.clickable:active {
  transform: scale(0.97);
}

.courses-font-family {
  font-family: Inter var, sans-serif;
}

.notification-area {
  height: calc(100vh - 4.5rem) !important;
}

#edit_profile_btn {
  color: #fff !important;
  background-color: var(--gray-900) !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.route-container {
  padding: 0px !important;
}
</style>
