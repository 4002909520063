import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }
const _hoisted_2 = { class: "flex items-start gap-3" }
const _hoisted_3 = { class: "flex flex-col items-start w-full gap-1" }
const _hoisted_4 = { class: "flex items-center justify-between w-full" }
const _hoisted_5 = { class: "font-bold text-sm" }
const _hoisted_6 = { class: "flex items-center gap-2" }
const _hoisted_7 = {
  key: 0,
  class: "pt-2"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex items-center justify-between w-full" }
const _hoisted_10 = { class: "flex items-center gap-1 text-[--accent-color] text-xs mx-2" }
const _hoisted_11 = {
  key: 0,
  class: "w-full flex items-center justify-center"
}
const _hoisted_12 = {
  key: 0,
  class: "w-full"
}

import { sanitizeJavaScriptCode } from "@/helper";
import {
  userAvatarImage
} from "@/helper/constants";
import { truncateText } from "@/helper/filter";
import Category from "@/models/Category";
import Comment from "@/models/Comment";
import Post from "@/models/Post";
import Product from "@/models/Product";
import { Instructor } from "@/models/ProductCustomization";
import { CommentService, getUserId } from "@/services";
import {
  Edit01Icon,
  FlipBackwardIcon,
  FlipForwardIcon,
  Globe01Icon,
  Lock01Icon,
  Trash01Icon
} from "@gohighlevel/ghl-icons/24/outline";
import { renderIcon, UIAvatar } from "@gohighlevel/ghl-ui";
import moment from "moment";
import { computed, onMounted, ref } from "vue";
import BaseIcon from "../svgicons/BaseIcon.vue";
import DeleteModalV2 from "./DeleteModalV2.vue";
import DrawerMenuV2 from "./DrawerMenuV2.vue";
import UISpinner from "../common/UISpinner.vue";
import CommentShimmerV2 from "../shimmer/mobile/CommentShimmerV2.vue";




export default /*@__PURE__*/_defineComponent({
  __name: 'CommentV2',
  props: {
    userAvatar: {},
    isRepliesAvailable: { type: Boolean },
    comment: {},
    instructor: {},
    product: {},
    activePost: {},
    category: {},
    visibility: {},
    repliedComment: {},
    deletingComment: { type: Boolean }
  },
  emits: ["updateReplyToUser", "deleteComment", "updateEditComment"],
  setup(__props: any, { emit: __emit }) {

const commentEditOptions = ref([
  {
    label: "Edit",
    key: "edit",
    icon: renderIcon(Edit01Icon)
  },
  {
    label: "Delete",
    key: "delete",
    icon: renderIcon(Trash01Icon),
    color: 'text-red-500'
  },
]);


const commentPrivacyOptions = ref([
  {
    label: "Public",
    key: "public",
    icon: renderIcon(Globe01Icon),
  },
  {
    label: "Instructor Only",
    key: "private",
    icon: renderIcon(Lock01Icon),
  },
]);


const props = __props;

const emit = __emit;


const fetching = ref(false);
const fetchingReplies = ref(false);
const comments = ref([]);
const commentsLimit = ref(5);
const commentsOffset = ref(0);
const hideLoadMoreComments = ref(false);
const isCommentReplyView = ref(false);
const editDrawerOpen = ref(false);
const lastCommentRequestTime = ref<string | null>(null);
const openDeleteCommentModal = ref<boolean>(false);
const currentUserId = computed(() => getUserId());

const repliedToComment = computed(() => props.comment.content);

const repliedToUserId = computed(() => props.comment.userId);

const replyToThisUser = computed(() => {
  return props.comment?.userdetails
    ? props.comment?.userdetails
    : { name: "Instructor", id: null };
});

const commentAvatar = computed(() => {
  return props.comment?.userdetails && props.comment?.userdetails?.avatar
    ? props.comment?.userdetails?.avatar
    : props.instructor && props.instructor.headshot
      ? props.instructor.headshot
      : userAvatarImage;
});

const userName = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId) return "Deleted User";
  return props.comment?.userdetails && props.comment?.userdetails?.name
    ? props.comment?.userdetails?.name
    : props.instructor && props.instructor.name
      ? props.instructor.name
      : "Instructor";
});

const productId = computed(() => props.product?.id);
const postId = computed(() => props.activePost?.id);

const commentPrivacy = computed(() =>
  commentPrivacyOptions.value.find((option) => option?.key === props.comment?.visibility)
);

function selectCommentEditOption(value: string) {
  handleEditDrawerToggle()
  if (value === "edit") {
    emit("updateEditComment", {
      id: props.comment?.id,
      content: props.comment?.content,
      name: props.comment?.userdetails ? props.comment?.userdetails.name : "",
      avatar: props.comment?.userdetails ? props.comment?.userdetails.avatar : "",
      repliedToUserId: props.comment?.repliedToUserId,
      visibility: props.visibility,
    });
  } else if (value === "delete") {
    toggleDeleteModal()
  }
}

function formatCreatedAtForComment(time) {
  return moment(time).fromNow(false);
}

function triggerReply(userDetails) {
  emit("updateReplyToUser", userDetails);
}


async function fetchComments(commentId = "", overWrite = false) {
  fetching.value = true;
  try {
    const { data } = await CommentService.findComments({
      postId: postId.value,
      productId: productId.value,
      limit: commentId ? 1 : commentsLimit.value,
      offset: commentId ? 0 : commentsOffset.value,
      order: -1,
      commentId: commentId ? commentId : "",
      commentsAfter: lastCommentRequestTime.value ?? undefined,
    });

    if (!data.comments.length) {
      hideLoadMoreComments.value = true;
      return;
    }

    comments.value = overWrite ? data.comments : comments.value.concat(data.comments);
    commentsOffset.value += commentsLimit.value;
  } catch (error) {
    console.error("Error while fetching comments --> ", error);
  } finally {
    fetching.value = false;
  }
}

function extractCommentData(comment) {
  return {
    commentId: comment.id,
    ...comment,
    parentCommentId: comment.parentCommentId ? comment.parentCommentId : comment.id,
    avatar: comment.userdetails ? comment.userdetails.avatar : "",
    name: comment.userdetails ? comment.userdetails.name : "",
    visibility: props.visibility,
  };
}

async function openCommentReplies(comment) {
  fetchingReplies.value = true;
  await fetchComments(comment.id, true);
  fetchingReplies.value = false;

  // triggerReply(extractCommentData(comment));
  isCommentReplyView.value = !isCommentReplyView.value;
}

function handleUpdateReplyToUser(comment) {
  triggerReply(extractCommentData(comment));
}

function handleUpdateEditComment(comment, visibility) {
  emit("updateEditComment", {
    id: comment.id,
    content: comment.content,
    name: comment.userdetails ? comment.userdetails.name : "",
    avatar: comment.userdetails ? comment.userdetails.avatar : "",
    repliedToUserId: comment.repliedToUserId,
    visibility: visibility,
  });
}

function handleDeleteComment(comment) {
  emit("deleteComment", comment.id);
}

function handleEditDrawerToggle() {
  editDrawerOpen.value = !editDrawerOpen.value;
}


function toggleDeleteModal() {
  openDeleteCommentModal.value = !openDeleteCommentModal.value;
}



onMounted(() => {
  fetchComments();
});

return (_ctx: any,_cache: any) => {
  const _component_CommentV2 = _resolveComponent("CommentV2", true)!

  return (_openBlock(), _createElementBlock("div", {
    id: "comment",
    class: _normalizeClass(["my-4 p-3 rounded", { 'bg-primary-25': _ctx.repliedComment == _ctx.comment.id }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(UIAvatar), {
          src: commentAvatar.value,
          size: "large",
          class: "min-h-10 min-w-10"
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(userName.value), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                class: _normalizeClass(["font-normal text-xs text-[#101828]", {
                'mr-6':
                  _ctx.comment?.userdetails && _ctx.comment?.userdetails?.id !== currentUserId.value,
              }])
              }, _toDisplayString(formatCreatedAtForComment(_ctx.comment?.createdAt)), 3),
              (_ctx.comment?.userdetails && _ctx.comment?.userdetails?.id === currentUserId.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(BaseIcon, {
                      name: "dotsvertical",
                      style: { height: '1rem', width: '1rem' },
                      onClick: handleEditDrawerToggle
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", {
            class: "comment-content font-normal text-sm text-gray-500",
            innerHTML: _ctx.comment.content
          }, null, 8, _hoisted_8),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", {
              class: "font-normal text-sm text-[--accent-color] clickable",
              onClick: _cache[0] || (_cache[0] = () =>
              triggerReply({
                commentId: _ctx.comment.id,
                ...replyToThisUser.value,
                parentCommentId: _ctx.comment.parentCommentId
                  ? _ctx.comment.parentCommentId
                  : _ctx.comment.id,
                repliedToComment: repliedToComment.value,
                repliedToUserId: repliedToUserId.value,
                visibility: _ctx.visibility,
              })
              )
            }, "Reply"),
            _createElementVNode("span", _hoisted_10, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(commentPrivacy.value?.icon), { style: { height: '0.75rem', width: '0.75rem ' } })),
              _createTextVNode(" " + _toDisplayString(commentPrivacy.value?.label), 1)
            ])
          ]),
          (fetchingReplies.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(CommentShimmerV2, {
                  count: _ctx.comment?.replies?.length || 1
                }, null, 8, ["count"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isRepliesAvailable && !isCommentReplyView.value && !fetchingReplies.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: "font-semibold text-sm text-[--accent-color] clickable flex items-center gap-2 clickable",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (openCommentReplies(_ctx.comment)))
              }, [
                _createVNode(_unref(FlipBackwardIcon), { class: "h-5 w-5" }),
                _createTextVNode(" View " + _toDisplayString(_ctx.comment?.replies?.length) + " " + _toDisplayString(_ctx.comment?.replies?.length > 1 ? 'replies' : 'reply'), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isRepliesAvailable && isCommentReplyView.value && !fetchingReplies.value)
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: "font-semibold text-sm text-[--accent-color] clickable flex items-center gap-2 clickable",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (openCommentReplies(_ctx.comment)))
              }, [
                _createVNode(_unref(FlipForwardIcon), { class: "h-5 w-5" }),
                _cache[3] || (_cache[3] = _createTextVNode(" Hide replies "))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_Transition, { name: "slide-fade" }, {
            default: _withCtx(() => [
              (isCommentReplyView.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comment?.replies, (reply) => {
                      return (_openBlock(), _createBlock(_component_CommentV2, {
                        key: reply.id,
                        userAvatar: _ctx.userAvatar,
                        comment: reply,
                        instructor: _ctx.instructor,
                        activePost: _ctx.activePost,
                        product: _ctx.product,
                        category: _ctx.category,
                        isRepliesAvailable: false,
                        onUpdateReplyToUser: () => handleUpdateReplyToUser(reply),
                        onUpdateEditComment: () => handleUpdateEditComment(reply, _ctx.visibility),
                        onDeleteComment: () => handleDeleteComment(reply),
                        repliedComment: _ctx.repliedComment
                      }, null, 8, ["userAvatar", "comment", "instructor", "activePost", "product", "category", "onUpdateReplyToUser", "onUpdateEditComment", "onDeleteComment", "repliedComment"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(DrawerMenuV2, {
      placement: 'bottom',
      drawerOpen: editDrawerOpen.value,
      drawerMenuOptions: commentEditOptions.value,
      handleDrawerMenuClick: selectCommentEditOption,
      handleDrawerMenuToggle: handleEditDrawerToggle
    }, null, 8, ["drawerOpen", "drawerMenuOptions"]),
    _createVNode(DeleteModalV2, {
      loading: _ctx.deletingComment,
      showModal: openDeleteCommentModal.value,
      submitCallback: () => handleDeleteComment(_ctx.comment),
      "modal-icon": _unref(Trash01Icon),
      toggleModal: toggleDeleteModal,
      title: 'Are you sure you want to delete this comment?',
      description: _unref(truncateText)(_unref(sanitizeJavaScriptCode)(_ctx.comment.content), 50),
      positiveText: 'Delete',
      negativeText: 'Cancel',
      width: 400
    }, null, 8, ["loading", "showModal", "submitCallback", "modal-icon", "description"])
  ], 2))
}
}

})