<script setup lang="ts">
import { UIEmpty } from "@gohighlevel/ghl-ui";
import { useRouter } from "vue-router";
import CourseCardV2 from "./CourseCardV2.vue";

const router = useRouter();
const props = defineProps(["myCourses", "fetching"]);

function openProduct(productId) {
  router.push({ name: "category-list-levelup", params: { id: productId } });
}
</script>

<template>
  <div>
    <div class="w-full h-full flex items-center justify-center courses-font-family font-semibold"
      v-if="!props.fetching && !props.myCourses.length">
      <UIEmpty id="empty-product-message" title="No courses found"
        description="You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey." />
    </div>
    <div v-else class="w-11/12 h-full flex flex-col items-center justify-start mx-auto my-5 gap-4">
      <div class="w-full">
        <div v-for="course in props.myCourses" :key="course.id" class="w-full clickable"
          @click="openProduct(course.id)">
          <CourseCardV2 :course="course" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
