import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "result-message-wrapper",
  class: "w-5/6"
}
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = {
  key: 0,
  id: "result-score-container ",
  class: "flex justify-between w-[35%] mr-4 gap-2"
}
const _hoisted_4 = {
  id: "result-score-wrapper",
  class: "flex flex-col h-fit items-center"
}
const _hoisted_5 = { class: "text-2xl font-normal mb-0 m-0" }
const _hoisted_6 = {
  id: "result-correct-wrapper",
  class: "flex flex-col h-fit items-center"
}
const _hoisted_7 = { class: "text-2xl font-normal mb-0 m-0" }
const _hoisted_8 = {
  id: "result-attempted-wrapper",
  class: "flex flex-col h-fit items-center"
}
const _hoisted_9 = { class: "text-2xl font-normal mb-0 m-0" }
const _hoisted_10 = { id: "result-svg-wrapper" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]

import { computed } from 'vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import { CheckCircleIcon, XCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import {
  assignmentProcessingImage,
  quizFailImage,
  quizPassImage,
} from '@/helper/constants'
import { UISkeleton } from '@gohighlevel/ghl-ui'


export default /*@__PURE__*/_defineComponent({
  __name: 'ResultMessageComponent',
  props: {
  resultMessage: {
    type: String,
    default: '',
  },
  htmlConfirmationMessage: {
    type: String,
    default: '',
  },
  confirmationMessage: {
    type: String,
    default: '',
  },
  score: {
    type: Number,
    default: 0,
  },
  correct: {
    type: Number,
    default: 0,
  },
  totalQuestions: {
    type: Number,
    default: 0,
  },
  result: {
    type: String as () => AssessmentStatusOptions | 'loading',
    default: 'loading',
  },
  attempted: {
    type: Number,
    default: 0,
  },
  header: {
    type: String,
    default: '',
  },
  assignmentType: {
    type: String,
    default: 'assessment',
  },
},
  setup(__props) {

const props = __props

const resultImage = computed(() => {
  switch (props.result) {
    case AssessmentStatusOptions.passed:
      return quizPassImage
    case AssessmentStatusOptions.failed:
      return quizFailImage
    case AssessmentStatusOptions.processing:
      return assignmentProcessingImage
    default:
      return null
  }
})

const resultHeader = computed(() => {
  if (props.header) return props.header
  else {
    switch (props.result) {
      case AssessmentStatusOptions.passed:
        return 'You have passed the assignment.'
      case AssessmentStatusOptions.failed:
        return 'You failed the assignment.'
      case AssessmentStatusOptions.processing:
        return 'Assessment under review'
      default:
        return ''
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (props.result !== 'loading')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "result-container",
        class: _normalizeClass(["px-8 py-2 rounded-md flex justify-between items-center w-full font-medium min-h-52 gap-2", {
      'custom-gradient text-white':
        props.result === _unref(AssessmentStatusOptions).passed,
      'bg-neo-classic-light-error text-gray-900':
        props.result === _unref(AssessmentStatusOptions).failed,
      'assignments-processing-bg text-white':
        props.result === _unref(AssessmentStatusOptions).processing,
    }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (props.result === _unref(AssessmentStatusOptions).passed)
              ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                  key: 0,
                  class: "w-6 h-6"
                }))
              : _createCommentVNode("", true),
            (props.result === _unref(AssessmentStatusOptions).failed)
              ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                  key: 1,
                  class: "w-6 h-6"
                }))
              : _createCommentVNode("", true),
            _createVNode(Title, {
              tag: "h2",
              class: "font-medium mb-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(resultHeader.value), 1)
              ]),
              _: 1
            })
          ]),
          (__props.htmlConfirmationMessage)
            ? (_openBlock(), _createBlock(RichTextViewer, {
                key: 0,
                html: __props.htmlConfirmationMessage
              }, null, 8, ["html"]))
            : (_openBlock(), _createBlock(Paragraph, {
                key: 1,
                maxLength: 250,
                showArrows: false,
                mode: "tooltip",
                class: "text-[16px] font-normal text-sm mt-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.confirmationMessage), 1)
                ]),
                _: 1
              }))
        ]),
        (props.assignmentType === 'quiz')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(Title, {
                  tag: "h3",
                  class: "font-normal mb-0"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Score")
                  ])),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_5, _toDisplayString(props.score) + "%", 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(Title, {
                  tag: "h3",
                  class: "font-normal mb-0"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Correct")
                  ])),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_7, _toDisplayString(props.correct) + "/" + _toDisplayString(props.totalQuestions), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(Title, {
                  tag: "h3",
                  class: "font-normal mb-0"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Attempted")
                  ])),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_9, _toDisplayString(props.attempted) + "/" + _toDisplayString(props.totalQuestions), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          (__props.result === _unref(AssessmentStatusOptions).passed)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "h-10/12",
                src: resultImage.value,
                alt: "quiz-pass-illus"
              }, null, 8, _hoisted_11))
            : _createCommentVNode("", true),
          (__props.result === _unref(AssessmentStatusOptions).failed)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                class: "h-10/12",
                src: resultImage.value,
                alt: "quiz-pass-illus"
              }, null, 8, _hoisted_12))
            : _createCommentVNode("", true),
          (__props.result === _unref(AssessmentStatusOptions).processing)
            ? (_openBlock(), _createElementBlock("img", {
                key: 2,
                class: "h-10/12",
                src: resultImage.value,
                alt: "quiz-pass-illus"
              }, null, 8, _hoisted_13))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : (_openBlock(), _createBlock(_unref(UISkeleton), {
        key: 1,
        class: "px-8 py-2 rounded-md flex justify-between items-center w-full font-medium h-52"
      }))
}
}

})