<template>
  <div class="flex flex-col justify-center items-center w-full p-10">
    <img :src="bigLockIcon" alt="Locked" />
    <Title tag="h3" class="!my-3 font-medium">
      This {{ contentType }} is locked
    </Title>
    <Paragraph>
      To gain access to this {{ contentType }}, please complete the
      {{ lockedByPostData ? `lesson: ${lockedByPostData.title}` : '' }}
      {{
        lockedByCategoryData
          ? `${lockedByPostData ? 'and' : ''} Category: ${
              lockedByCategoryData.title
            }`
          : ''
      }}
    </Paragraph>
    <div class="mt-4 w-full flex items-center justify-center">
      <Button
        v-if="lockedByPostData"
        @click="() => goToLockedContent(lockedByPostData)"
        class="flex mr-2"
      >
        {{ lockedByPostData.title }}
        <ArrowNarrowRightIcon class="ml-2 h-5 w-5" />
      </Button>
      <Button
        v-if="lockedByCategoryData"
        @click="() => goToLockedContent(lockedByCategoryData)"
        class="flex"
      >
        {{ lockedByCategoryData.title }}
        <ArrowNarrowRightIcon class="ml-2 h-5 w-5" />
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { bigLockIcon } from '@/helper/constants'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { CategoryTreeNodeType } from '@/models/CategoryTreeNode'
import { ArrowNarrowRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import { onMounted, inject, ref, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Post from '@/models/Post'

const props = defineProps({
  contentType: {
    type: String,
    default: CategoryTreeNodeType.Category,
  },
  lockedByPostId: {
    type: String,
    default: '',
  },
  lockedByCategoryId: {
    type: String,
    default: '',
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
})

const categoriesAndPostsLookup = inject<Ref<Map<string, any>>>(
  'categoriesAndPostsLookup'
)
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['highlightThisElement'])

const lockedByPostData = ref<any>(null)
const lockedByCategoryData = ref<any>(null)

function updateLockedData() {
  if (props.lockedByPostId) {
    lockedByPostData.value = getLockedContentData(props.lockedByPostId)
  }

  if (props.lockedByCategoryId) {
    lockedByCategoryData.value = getLockedContentData(props.lockedByCategoryId)
  }
}

onMounted(() => {
  // Need to do this dirty update because vue doesn't really support reactivity for map
  setTimeout(() => {
    updateLockedData()
  }, 100)
})

function getLockedContentData(contentId: string) {
  const data = categoriesAndPostsLookup.value.get(contentId)
  if (props.completedPostIds.has(contentId)) return null

  return {
    type: data?.type,
    id: data?.node?.id,
    title: data?.node?.title,
    isDrip: data?.node?.dripDays > 0,
    parentCategoryId: data?.node?.parentCategory,
  }
}

function goToLockedContent(lockedByContentData: any) {
  if (lockedByContentData.type === CategoryTreeNodeType.Category) {
    if (lockedByContentData.parentCategoryId) {
      return redirectToFirstPostInCategory(
        lockedByContentData.parentCategoryId,
        findFirstPostInCategory(lockedByContentData.id) // First post in sub category
      )
    }

    return redirectToFirstPostInCategory(lockedByContentData.id)
  }

  if (lockedByContentData.id) {
    router.push({
      name: 'post-overview',
      params: {
        id: route.params.id,
        category_id: lockedByContentData.parentCategoryId,
        post_id: lockedByContentData.id,
      },
      query: {
        ...route.query,
      },
    })
  }
}

function redirectToFirstPostInCategory(categoryId: string, post?: Post) {
  const firstPostInCategory = post || findFirstPostInCategory(categoryId)
  if (firstPostInCategory) {
    return router.push({
      name: 'post-overview',
      params: {
        id: route.params.id,
        category_id: categoryId,
        post_id: post?.id || firstPostInCategory.id,
      },
      query: {
        ...route.query,
      },
    })
  }
}

function findFirstPostInCategory(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (category) {
    return category.node.posts[0]
  }
  return null
}
</script>
