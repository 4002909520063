import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full lesson-post-tree" }
const _hoisted_2 = {
  key: 0,
  class: "px-6"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "px-6"
}
const _hoisted_5 = { class: "py-3 border-b postqueue-lesson" }
const _hoisted_6 = {
  key: 0,
  class: "float-right px-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NewYorkPostCategoryPostTree = _resolveComponent("NewYorkPostCategoryPostTree")!
  const _component_post_material_modal = _resolveComponent("post-material-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryTree, (child) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: child.id
      }, [
        (_ctx.nodeIsPost(child.type) && _ctx.available)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              tag: "div",
              to: {
          name: 'post-overview',
          params: {
            id: child.node.productId,
            category_id: child.node.categoryId,
            post_id: child.id,
          },
          query: {
            template_id: _ctx.templateId,
          },
        },
              class: _normalizeClass(["w-full py-3 border-b cursor-pointer flex justify-between hover:bg-gray-300 hover:text-gray-700 lessonSecondaryStyle", 
          _ctx.postId === child.id
            ? 'text-white post-highlight'
            : 'text-blue-700 postqueue-lesson'
        ]),
              style: _normalizeStyle(_ctx.postId === child.id && { backgroundColor: _ctx.highlightBackground })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (_ctx.postIndexes[child.id])
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.postIndexes[child.id]), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.isParentSubCategory ? 'px-4' : 'px-2')
                  }, _toDisplayString(child.node.title), 3)
                ]),
                (
            _ctx.postId === child.id &&
            _ctx.activePost.materials &&
            _ctx.activePost.materials.length
          )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "px-10 flex items-center justify-center",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
                      style: _normalizeStyle({ color: _ctx.highlightColor })
                    }, [
                      _createVNode(_component_BaseIcon, {
                        name: "docdownload",
                        hwClass: "h-4 w-4"
                      })
                    ], 4))
                  : _createCommentVNode("", true),
                (_ctx.nodeIsCompleted(child))
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      class: "h-5 px-6",
                      src: _ctx.checkIcon,
                      alt: "Completed"
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["to", "class", "style"]))
          : (_ctx.nodeIsPost(child.type) && !_ctx.available)
            ? (_openBlock(), _createElementBlock("div", {
                key: child.id,
                class: "w-full py-3 border-b flex justify-between text-gray-500"
              }, [
                _createElementVNode("div", null, [
                  (_ctx.postIndexes[child.id])
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.postIndexes[child.id]), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.isParentSubCategory ? 'px-4' : 'px-2')
                  }, _toDisplayString(child.node.title), 3)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: child.id,
                class: "w-full"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["px-6", 
              !child.node.available
                ? 'text-gray-500 drip-category-title'
                : 'lessonSecondaryStyle postqueue-subCategory'
            ])
                  }, [
                    _createTextVNode(_toDisplayString(child.node.title.toLocaleUpperCase()) + " ", 1),
                    (!child.node.available)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "(Coming Soon)"))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _createVNode(_component_NewYorkPostCategoryPostTree, {
                  categoryTree: child.childs,
                  "completed-posts": _ctx.completedPosts,
                  "post-indexes": _ctx.postIndexes,
                  activePost: _ctx.activePost,
                  available: child.node.available,
                  highlightBackground: _ctx.highlightBackground,
                  highlightColor: _ctx.highlightColor,
                  isParentSubCategory: true
                }, null, 8, ["categoryTree", "completed-posts", "post-indexes", "activePost", "available", "highlightBackground", "highlightColor"])
              ]))
      ], 64))
    }), 128)),
    _createVNode(_component_post_material_modal, {
      "show-modal": _ctx.showModal,
      materials: _ctx.activePost.materials,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
    }, null, 8, ["show-modal", "materials"])
  ]))
}