<template>
  <div
    v-if="fetchingSubmission || isLoading"
    class="flex flex-col justify-center items-center h-full w-full"
  >
    <UISkeleton class="w-full h-full aspect-video rounded-lg" />
  </div>
  <div v-else class="flex flex-col h-full w-full">
    <!-- Result Part -->
    <div
      v-if="lastSubmission"
      class="h-full flex-1 flex flex-col justify-evenly items-center"
    >
      <div
        id-="score-container"
        class="w-full flex flex-col justify-between items-center py-4"
      >
        <div id="svg-wrapper">
          <img class="w-32 h-32" :src="quizPassIcon" alt="quiz-pass-illus" />
        </div>

        <Title
          tag="h1"
          id="score-title"
          class="font-normal mt-8"
          :class="[
            lastSubmission.status === assessmentStatusOptions.passed
              ? 'text-green-600'
              : 'text-red-600',
          ]"
          >{{ score }}%
        </Title>
      </div>
      <div
        id-="message-container"
        class="w-4/5 mx-auto border rounded-md border-gray-300 py-12 flex flex-col justify-center items-center"
      >
        <Title
          tag="h4"
          id="message-heading"
          class="font-medium text-center m-0.5"
        >
          {{ bannerMessage }}
        </Title>
        <Title
          id="secondary-message-heading"
          tag="h4"
          class="font-medium text-center m-0.5"
        >
          {{ secondaryBannerMessage }}
        </Title>
        <p
          id="message-subheading"
          class="text-lg font-normal m-0 mt-4"
          :class="[
            lastSubmission.status === assessmentStatusOptions.passed
              ? 'text-green-600'
              : 'text-red-600',
          ]"
        >
          {{ correctCount }} / {{ questionCount }} Questions answered correctly
        </p>
      </div>
      <div
        id-="button-container"
        class="w-4/5 mx-auto flex-1 flex justify-center items-center gap-2"
      >
        <router-link
          tag="div"
          :to="{
            name: `new-${postContentType}-overview`,
            params: {
              category_id: categoryId,
              post_id: postId,
              id: productId,
            },
          }"
          style="text-decoration: none"
          class="flex justify-center no-underline"
        >
          <Button
            id="quiz-btn-retake"
            variant="secondary"
            size="medium"
            class="no-underline"
          >
            {{ buttonLabel }}
          </Button>
        </router-link>
        <router-link
          tag="span"
          :to="{
            name: `new-${postContentType}-overview`,
            params: {
              category_id: categoryId,
              post_id: postId,
              id: productId,
            },
            query: {
              review: true,
            },
          }"
          class="cursor-pointer"
        >
          <Button
            id="quiz-btn-review"
            variant="subtle"
            size="medium"
            class="no-underline"
          >
            View Result
          </Button>
        </router-link>
      </div>
    </div>
    <!-- Start Quiz Part -->
    <div v-else class="text-center flex flex-col justify-center items-center">
      <img class="w-[70%]" :src="quizStartImage" alt="Quiz " />

      <router-link
        tag="div"
        :to="{
          name: `new-${postContentType}-overview`,
          params: {
            category_id: categoryId,
            post_id: postId,
            id: productId,
          },
        }"
        style="text-decoration: none"
        class="flex justify-center no-underline"
      >
        <Button
          id="quiz-btn"
          variant="secondary"
          size="medium"
          class="no-underline"
        >
          {{ buttonLabel }}
        </Button>
      </router-link>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { AssessmentStatusService } from '@/services/'
import { UISkeleton } from '@gohighlevel/ghl-ui'
import { adminMode } from '@/helper/permission.helper'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { quizStartImage } from '@/helper/constants'
import { useRoute } from 'vue-router'
import { PostContentTypeLabel } from '@/models/Post'
import { quizPassIcon } from '@/helper/constants'

const props = defineProps({
  post: Object,
  categoryId: String,
  isLoading: {
    type: Boolean,
    default: false,
  },
  quiz: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['changeQuizStatus'])

const route = useRoute()
const lastSubmission = ref(null)
const questionCount = ref(0)
const correctCount = ref(0)
const incorrectCount = ref(0)
const fetchingSubmission = ref(false)
const assessmentStatusOptions = ref(AssessmentStatusOptions)

const contentTypeLabel = ref({
  quiz: PostContentTypeLabel.quiz,
  assignment: PostContentTypeLabel.assignment,
})

const productId = computed(() => {
  return props.post ? props.post.productId : route.params.id
})

const postId = computed(() => {
  return props.post ? props.post.id : ''
})

const isAdmin = computed(() => {
  return adminMode()
})

const postContentType = computed(() => {
  return props.post ? props.post.contentType : ''
})

const bannerMessage = computed(() => {
  return lastSubmission.value.status === assessmentStatusOptions.value.passed
    ? 'You have successfully completed this quiz. Thank You!'
    : 'You have failed to reach the minimum score of this quiz.'
})

const secondaryBannerMessage = computed(() => {
  return props.quiz.requiredPassingGrade
    ? `You need ${props.quiz.passingGrade}% or higher to pass. Please try again.`
    : 'Please try again.'
})

const buttonLabel = computed(() => {
  return `${lastSubmission.value ? 'Retake' : 'Start'} ${
    contentTypeLabel.value[postContentType.value]
  }`
})

const score = computed(() => {
  return lastSubmission.value ? lastSubmission.value.score : 0
})

async function getLastSubmission() {
  if (isAdmin.value) return // don't make api calls if it's admin
  fetchingSubmission.value = true
  try {
    const fetchedSubmission =
      await AssessmentStatusService.getLastSubmissionByPostId(postId.value)
    lastSubmission.value = fetchedSubmission.data.lastSubmission
    if (lastSubmission.value) {
      questionCount.value = lastSubmission.value.submission.length
      correctCount.value = lastSubmission.value.submission.filter(
        (e) => e.correctAnswerGiven
      ).length
      incorrectCount.value = lastSubmission.value.submission.filter(
        (e) => !e.correctAnswerGiven
      ).length
      emit('changeQuizStatus', true)
    } else {
      emit('changeQuizStatus', false)
    }
  } catch (error) {
    console.error(`Error getting last submission`, error)
  } finally {
    fetchingSubmission.value = false
  }
}

onMounted(async () => {
  await getLastSubmission()
})
</script>
