import Model from './Model'

export default class Assignment extends Model {
  constructor(payload?: {
    id?: string
    title?: string
    introduction?: string
    fileUrls?: any
    confirmMessage?: string
    locationId?: string
    productId?: string
    postId?: string
    originId?: string
    answer?: string
    ungradedAssignment?: boolean
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  get introduction(): string {
    return this._data.introduction
  }

  get fileUrls(): any {
    return this._data.fileUrls
  }

  get submissionType(): string {
    return this._data.submissionType
  }
  set submissionType(submissionType: string) {
    this._data.submissionType = submissionType
  }

  get answer(): string {
    return this._data.answer
  }
  set answer(answer: string) {
    this._data.answer = answer
  }

  get confirmMessage(): string {
    return this._data.confirmMessage
  }

  get productId(): string {
    return this._data.productId
  }

  get postId(): string {
    return this._data.postId
  }

  get originId(): string {
    return this._data.originId
  }

  get ungradedAssignment() {
    return this._data.ungradedAssignment
  }
  set ungradedAssignment(ungradedAssignment: boolean) {
    this._data.ungradedAssignment = ungradedAssignment
  }
}
