import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full sticky top-0 bg-white z-30 pt-3" }
const _hoisted_2 = ["onClick"]

import { UIDrawer, UIDrawerContent } from '@gohighlevel/ghl-ui';
import { nextTick } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerMenuV2',
  props: {
    drawerOpen: Boolean,
    drawerMenuOptions: Array<any>,
    handleDrawerMenuClick: Function,
    handleDrawerMenuToggle: Function,
    placement: String
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _directive_swipe = _resolveDirective("swipe")!

  return (_openBlock(), _createBlock(_unref(UIDrawer), {
    show: __props.drawerOpen,
    placement: __props.placement,
    id: 'drawer',
    displayDirective: 'if',
    "default-height": "23vh",
    "on-mask-click": __props.handleDrawerMenuToggle
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(UIDrawerContent), {
        id: "drawer-component",
        class: "px-4",
        closable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "flex min-h-3 w-full flex-col items-center justify-center",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (__props.handleDrawerMenuToggle && __props.handleDrawerMenuToggle(...args)))
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "h-1 w-6 rounded-md border border-solid bg-gray-200" }, null, -1)
            ]))), [
              [_directive_swipe, {
                        direction: 'down',
                        callback: () => {
                            nextTick(() => {
                                __props.handleDrawerMenuToggle();
                            });
                        },
                    }]
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.drawerMenuOptions, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.key,
              class: _normalizeClass(["w-full p-3 flex gap-4 items-center text-sm font-medium text-gray-500", option.color]),
              onClick: ($event: any) => (__props.handleDrawerMenuClick(option.key))
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(option?.icon), {
                class: "border rounded border-gray-50 bg-gray-50 p-2",
                style: { height: '40px', width: '40px' }
              })),
              _createTextVNode(" " + _toDisplayString(option.label), 1)
            ], 10, _hoisted_2))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show", "placement", "on-mask-click"]))
}
}

})