<template>
  <transition name="fade">
    <div
      tabindex="0"
      role="button"
      :aria-label="listItemData.node.title"
      class="category-item-container p-4 hover:bg-neo-classic-hover-bg relative"
      :class="{
        'border-b': showBottomBorder,
        'bg-neo-classic-active-bg': isPostActive,
        'cursor-pointer': isCategoryAvailable,
        'cursor-not-allowed': !isCategoryAvailable,
      }"
      @click="redirectToPost"
      ref="categoryItemContainer"
    >
      <div class="w-full grid grid-cols-12 gap-4">
        <div v-if="count" class="col-span-1 flex items-center justify-center">
          {{ count }}
        </div>
        <ImageLoader
          v-if="isImageLoading"
          :class="{ 'col-span-2': shrinkSize, 'col-span-3': !shrinkSize }"
        />
        <img
          v-else
          :src="imageUrl"
          class="w-full rounded-lg aspect-video"
          :class="{
            'col-span-2': shrinkSize,
            'col-span-3': !shrinkSize,
            border: isAssessment,
            'object-cover': !isAssessment,
          }"
          alt="category-item-image"
        />
        <div
          class="category-item-content"
          :class="{
            'col-span-6 xl:col-span-7': shrinkSize && count,
            'col-span-7 xl:col-span-6': !shrinkSize && count,
            'col-span-7 xl:col-span-8': shrinkSize && !count,
            'col-span-8 xl:col-span-7': !shrinkSize && !count,
            'flex w-full items-center': !showDescription,
          }"
        >
          <Title
            :tag="shrinkSize ? 'h4' : 'h5'"
            :class="{ 'mb-2': shrinkSize, 'mb-0': !showDescription }"
            class="font-medium mb-2"
          >
            {{ listItemData.node.title }}
          </Title>
          <Paragraph
            v-if="showDescription"
            class="text-sm"
            :class="{
              'blur-sm select-none': isItemLocked,
            }"
          >
            {{ limitedDescription }}
          </Paragraph>
        </div>
        <div
          class="category-status-indicator col-span-3 xl:col-span-2 flex justify-center items-center"
        >
          <Lock01Icon
            v-if="isItemLocked"
            :class="shrinkSize ? 'h-5 w-5' : 'h-6 w-6'"
          />
          <CheckCircleIcon
            class="bg-neo-classic-success text-white rounded-full"
            :class="shrinkSize ? 'h-5 w-5' : 'h-6 w-6'"
            v-else-if="isPostCompleted"
          />
          <UIProgress
            v-else-if="progress > 0"
            class="w-full"
            type="line"
            :percentage="progress"
            :status="progress === 100 ? 'success' : 'default'"
            :showProgressText="false"
            :showProgressPercentage="false"
            indicatorPlacement="outside"
            :strokeWidth="8"
          >
            <template #default></template>
          </UIProgress>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import ImageLoader from '@/components/neo-classic-theme/loaders/ImageLoader.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { getTextContentFromHTML } from '@/helper'
import {
  DESCRIPTION_LIMIT,
  defaultEmptyImage,
  defaultAssessmentImage,
} from '@/helper/constants'
import { computed, ref, PropType } from 'vue'
import { Lock01Icon, CheckCircleIcon } from '@gohighlevel/ghl-icons/24/outline'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import { UIProgress } from '@gohighlevel/ghl-ui'
import { PostContentType } from '@/models/Post'

const props = defineProps({
  showDescription: {
    type: Boolean,
    default: true,
  },
  isSubCategoryItem: {
    type: Boolean,
    default: false,
  },
  showBottomBorder: {
    type: Boolean,
    default: true,
  },
  listItemData: {
    type: Object as PropType<CategoryTreeNode>,
    default: () => ({}),
  },
  shrinkSize: {
    type: Boolean,
    default: false,
  },
  isPostActive: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: 0,
  },
  progress: {
    type: Number,
    default: 0,
  },
  isPostCompleted: {
    type: Boolean,
    default: false,
  },
  isItemLocked: {
    type: Boolean,
    default: false,
  },
  isCategoryAvailable: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()
const isImageLoading = ref(true)
const imageUrl = ref(defaultEmptyImage)
const categoryItemContainer = ref(null)

const contentType = computed(() => {
  return props.listItemData.contentType
})

const isAssessment = computed(() => {
  return (
    contentType.value === PostContentType.assignment ||
    contentType.value === PostContentType.quiz
  )
})

const limitedDescription = computed(() => {
  if (!props.listItemData.node.description) return ''

  if (props.listItemData.node.description.length > DESCRIPTION_LIMIT) {
    const limitedContent = getTextContentFromHTML(
      props.listItemData.node.description
    ).slice(0, DESCRIPTION_LIMIT)
    return `${limitedContent}...`
  }

  return props.listItemData.node.description
})

const loading = ref(false)

function redirectToPost() {
  if (
    route.query.post_id === props.listItemData.node.id ||
    !props.isCategoryAvailable
  ) {
    return
  }

  loading.value = true
  router.push({
    name: 'post-overview',
    params: {
      id: props.listItemData.node.productId,
      category_id: props.listItemData.parent || props.listItemData.categoryId,
      post_id: props.listItemData.node.id,
    },
    query: {
      ...route.query,
    },
    hash: `#${props.listItemData.node.id}`,
  })
}

onMounted(() => {
  try {
    let defaultImage = defaultEmptyImage

    if (isAssessment.value) {
      defaultImage = defaultAssessmentImage
    }

    const newImage = new Image()
    newImage.src = props.listItemData.node.posterImage
      ? replaceBucketUrlWithCdnUrl(props.listItemData.node.posterImage)
      : defaultImage
    isImageLoading.value = true
    newImage.onload = () => {
      imageUrl.value = newImage.src
      isImageLoading.value = false
    }

    newImage.onerror = (error) => {
      imageUrl.value = defaultImage
      isImageLoading.value = false
      console.error('Error while loading image: ', error)
    }

    if (props.isPostActive) {
      // focus on the container to make show active post
      categoryItemContainer.value.focus()
      categoryItemContainer.value.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  } catch (error) {
    console.error('Error while loading image: ', error)
  }
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
