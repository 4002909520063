<template>
  <div class="w-full text-center sm:text-left">
    <div
        class="w-11/12 flex items-center justify-start m-auto border-b border-[#EAECF0]"
      >
        <SearchSmIcon class="w-4 h-4 text-gray-500 m-4" />
        <input
          id="search"
        v-model.trim="searchText"
        @input="createDebounce()"
        aria-label="Search"
        name="search-for-post"
        type="text"
        maxlength="64"
        class="appearance-none rounded-none relative block w-full focus:outline-none placeholder-gray-600 text-gray-900 sm:text-base sm:leading-5 placeholder:text-base placeholder:leading-5"
        placeholder="Search products, categories and lessons"
        />
      </div>
    <div class="w-full">
      <div v-if="searchText" id="searchList">
        <div
          v-if="searchData.length || loading"
          id="optionList"
          class="block py-2"
        >
          <div
            class="searchList flex flex-col my-2 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-400 leading-5"
            v-for="option in searchData"
            :key="option.typeId"
            @click.prevent="changeRoute(option)"
          >
            <div class="p-2 text-left flex justify-between">
              <span class="text-gray-900 text-sm">{{
                option.title.length > 50
                  ? `${option.title.substring(0, 40)}...`
                  : option.title
              }}</span>
              <img
                class="text-gray-700 h-5"
                :src="searchIcons[option.type]"
                alt="product icon"
              />
            </div>
            <div
              v-if="option.description"
              v-html="option.description"
              class="px-2 text-left text-xs h-auto"
            ></div>
            <div
              v-if="option.type !== 'product'"
              class="product-title px-2 text-left font-semibold text-xs h-5"
              :class="option.description ? 'p-2' : 'pb-2'"
            >
              {{ productTitleMapping[option.productId] }}
            </div>
          </div>
          <!-- <div
            class="px-4 py-2 flex justify-center items-center text-gray-700"
            v-if="loading"
          >
            <span class="inline-flex h-6 w-6">
              <UISpinner
                size="small"
                :color="getMobileTheme().primaryHexCode"
              />
            </span>
            <span class="inline-flex items-center px-4 h-6">Loading</span>
          </div> -->
          <div
            class="px-4 py-2 flex justify-center items-center text-gray-700"
            v-if="!allDataLoaded || loading"
            ref="loadMore"
          >
            <span class="inline-flex h-6 w-6">
              <UISpinner size="small" />
            </span>
          </div>
        </div>
        <div
          v-else-if="!isSearchDataAvailable"
          class="block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700"
        >
          No results for
          <span class="font-semibold">"{{ searchText }}"</span>. Please try
          another search
        </div>
      </div>
      <div v-else-if="recentSearches.length" class="p-2 text-gray-700">
        <div class="text-left">Recent Searches</div>
        <div
          class="searchList flex flex-col my-2 pr-6 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-400 leading-5"
          v-for="(option, index) in recentSearches"
          :key="index"
          @click.prevent="
            () => {
              searchText = option
              searchContent()
            }
          "
        >
          <div class="py-1 px-2 text-left">
            {{ option }}
          </div>
        </div>
      </div>
      <div v-else class="p-2 text-gray-700">No Recent Searches</div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { debounce } from 'lodash'
import {
  productIcon,
  folderIcon,
  fileIcon,
  LIBRARY_MENU_ITEMS,
} from '../../helper/constants'
import { UniversalSearchService } from '../../services'
import { getHighlightedDescription } from '@/helper'
import UISpinner from '@/components/common/UISpinner.vue'
import { SearchSmIcon } from '@gohighlevel/ghl-icons/24/outline'

export default defineComponent({
  components: {
    UISpinner,
    SearchSmIcon
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: '',
      limit: 30,
      page: 1,
      totalSearchCount: 0,
      searchData: [],
      loading: false,
      isSearchDataAvailable: true,
      recentSearches: [],
      searchIds: [],
      productTitleMapping: {},
      uniqueProductIds: [],
      searchIcons: {
        product: productIcon,
        category: folderIcon,
        post: fileIcon,
      },
      observer: null as any,
      allDataLoaded: false,
      error: '',
    }
  },
  async mounted() {
    this.recentSearches =
      JSON.parse(window.localStorage.getItem('searchItems')) || []
    this.observer = new IntersectionObserver(this.loadMore)
    if (!this.allDataLoaded) {
      await this.$nextTick()
      if (this.$refs.loadMore) {
        this.observer.observe(this.$refs.loadMore)
      }
    }
  },
  methods: {
    createDebounce: debounce(function () {
      this.searchContent()
    }, 500),
    async searchFullText() {
      this.loading = true
      const payload = {
        searchKey: this.searchText,
        pageLimit: this.limit,
        pageNumber: this.page,
      }
      const newData = await UniversalSearchService.searchItems(payload)
      const productIds = []
      this.allDataLoaded = !newData.length
      this.page = this.page + 1
      if (newData.length) {
        for (const data of newData) {
          if (!this.searchIds.includes(data.typeId)) {
            if (!this.uniqueProductIds.includes(data.productId)) {
              this.uniqueProductIds.push(data.productId)
              productIds.push(data.productId)
            }
            if (data.description) {
              data.description = getHighlightedDescription(
                data.description,
                this.searchText.split(' '),
                0
              )
            }
            this.searchData.push(data)
            this.searchIds.push(data.typeId)
          }
        }
        if (productIds.length) {
          this.fetchProductTitle(productIds)
        }
        this.isSearchDataAvailable = true
        // this.nextSearchData()
        if (this.$refs.loadMore) {
          this.observer.observe(this.$refs.loadMore)
        }
      } else {
        this.isSearchDataAvailable = false
      }
      this.loading = false
    },
    searchContent() {
      this.resetData()
      if (!this.searchText) {
        this.isSearchDataAvailable = true
        return
      }
      // this.searchCount()
      this.searchFullText()
    },
    resetData() {
      this.page = 1
      this.searchData = []
      this.searchIds = []
      this.productTitleMapping = {}
      this.uniqueProductIds = []
    },
    async fetchProductTitle(productIds) {
      const products = await UniversalSearchService.getProductTitles({
        productIds: productIds,
      })
      this.productTitleMapping = { ...this.productTitleMapping, ...products }
    },
    changeRoute(option) {
      this.recentSearches =
        JSON.parse(window.localStorage.getItem('searchItems')) || []
      if (!this.recentSearches.includes(this.searchText.toLocaleLowerCase())) {
        if (this.recentSearches.length === 3) this.recentSearches.pop()
        this.recentSearches.splice(0, 0, this.searchText.toLocaleLowerCase())
      }
      window.localStorage.setItem(
        'searchItems',
        JSON.stringify(this.recentSearches)
      )
      this.searchText = ''
      this.searchData = []
      this.$router.push({
        name:
          option.type === 'product'
            ? 'category-list'
            : option.type === 'category'
            ? 'category-overview'
            : 'post-overview',
        params: {
          id: option.productId,
          category_id: option.categoryId,
          post_id: option.typeId,
        },
        query: this.$route.query,
      })
      this.$store.commit('UPDATE_ACTIVE_LIBRARY', LIBRARY_MENU_ITEMS.allCourses)
    },
    async searchCount() {
      const payload = {
        searchKey: this.searchText,
      }
      const { totalCount } = await UniversalSearchService.searchCount(payload)
      this.totalSearchCount = totalCount
    },
    nextSearchData() {
      const optionList = document.getElementById('optionList')
      if (optionList) {
        optionList.onscroll = () => {
          const offset = (this.page + 1) * this.limit - this.limit
          if (this.totalSearchCount - offset < 0) {
            return
          }
          const lastDiv = document.querySelector('#optionList > div:last-child')
          const optionListOffset =
            optionList.scrollTop + optionList.clientHeight
          const lastDivOffset = lastDiv.scrollTop + lastDiv.clientHeight
          if (optionListOffset > lastDivOffset - 10) {
            this.page++
            this.searchFullText()
          }
        }
      }
    },
    async loadMore([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.searchFullText()
        ul.scrollTop = scrollTop
      }
    },
  },
})
</script>
