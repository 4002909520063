import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label"]

import { ref, computed, useAttrs } from 'vue'
import { twMerge } from 'tailwind-merge'

// Define props with default values and validators

export default /*@__PURE__*/_defineComponent({
  __name: 'Badge',
  props: {
  ariaLabel: { type: String, default: '' },
  variant: {
    type: String,
    default: 'primary',
    validator: (value) =>
      ['primary', 'secondary', 'success', 'danger', 'subtle'].includes(value),
  },
  isEditing: { type: Boolean, default: false },
  badgeText: { type: String, default: 'Badge Text' },
  badgeBackground: { type: String, default: '' },
  badgeTextColor: { type: String, default: '' },
  borderColor: { type: String, default: '' },
  borderWidth: { type: String, default: '' },
  hoverBackground: { type: String, default: '' },
  hoverBorderColor: { type: String, default: '' },
  hoverTextColor: { type: String, default: '' },
  size: {
    type: String,
    default: 'medium',
    validator: (value) => ['small', 'medium', 'large'].includes(value),
  },
},
  setup(__props) {

const props = __props

const attrs = useAttrs()

// Compute badge classes based on props
const badgeClasses = computed(() => {
  const baseClasses =
    'font-sans rounded-full font-semibold focus:outline-none transition-all duration-300 ease-in-out'
  const variantClasses =
    {
      primary:
        'bg-nova-blue-850 text-white hover:bg-[#215292] focus:ring-blue-500',
      secondary:
        'bg-nova-blue-200 text-nova-blue-850 hover:bg-nova-blue-100 focus:ring-nova-blue-600',
      success:
        'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500',
      danger: 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500',
      subtle:
        'bg-hover-bg border text-gray-600 hover:bg-gray-300 focus:ring-gray-500',
    }[props.variant] ||
    'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500'

  const sizeClasses =
    {
      small: 'px-1.5 py-0.5 text-xs',
      medium: 'px-3 py-1.5 text-sm',
      large: 'px-4 py-2 text-base',
    }[props.size] || 'px-4 py-2 text-base'

  return twMerge(baseClasses, variantClasses, sizeClasses, attrs.class)
})

// Compute badge styles based on props
const badgeStyle = computed(() => ({
  backgroundColor: props.badgeBackground || undefined,
  color: props.badgeTextColor || undefined,
  borderColor: props.borderColor || undefined,
  borderWidth: props.borderWidth || undefined,
  borderStyle: props.borderWidth ? 'solid' : undefined,
  cursor: 'default',
  ...attrs.style,
  '&:hover': {
    backgroundColor: props.hoverBackground || undefined,
    color: props.hoverTextColor || undefined,
    borderColor: props.hoverBorderColor || undefined,
  },
}))

// Compute aria-label for accessibility
const computedAriaLabel = computed(() => props.ariaLabel || props.badgeText)

return (_ctx: any,_cache: any) => {
  const _directive_highlight = _resolveDirective("highlight")!

  return _withDirectives((_openBlock(), _createElementBlock("span", _mergeProps({
    class: badgeClasses.value,
    "aria-label": computedAriaLabel.value
  }, _unref(attrs), { style: badgeStyle.value }), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(__props.badgeText), 1)
    ])
  ], 16, _hoisted_1)), [
    [_directive_highlight, { isEditing: __props.isEditing, label: 'Badge' }]
  ])
}
}

})