<template>
  <div class="w-full bg-white mb-5 mt-2 pl-16">
    <OneComment
      v-for="comment in comments"
      :content="comment.content"
      :commentElem="comment"
      :key="comment.id"
      :id="comment.id"
      :instructor="instructor"
      @updateStateChildComment="updateStateChildComment"
      :commentPermission="commentPermission"
      :pendoPayload="pendoPayload"
      :parentVisibility="parentComment.visibility"
      :scrollCommentId="scrollCommentId"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import OneComment from '@/components/comments/OneComment.vue'
export default defineComponent({
  data() {
    return {
      contentText: '',
      comments: [],
    }
  },
  components: {
    OneComment,
  },
  props: {
    replies: {
      type: Array,
    },
    instructor: {
      type: Object,
    },
    commentPermission: {
      type: String,
    },
    pendoPayload: {
      type: Object,
    },
    parentComment: {
      type: Object,
    },
    scrollCommentId: {
      type: String,
      default: null,
    },
  },
  async mounted() {
    this.comments = this.replies
  },

  methods: {
    findCommentIndexById(id: string) {
      return this.comments.findIndex((elem) => String(elem.id) === String(id))
    },
    updateStateChildComment(params) {
      const indexOfChild = this.findCommentIndexById(params.id)
      this.comments[indexOfChild].content = params.content
    },
  },

  watch: {
    replies: {
      handler() {
        this.comments = this.replies
      },
    },
  },
})
</script>
<style>
#tinymce-div .tox {
  border-radius: 10px;
}
#tinymce-div .tox-toolbar__primary {
  border: none;
}
</style>
