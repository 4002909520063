<template>
  <div v-if="productTitle" class="w-full text-sm text-gray-400 font-medium">
    <router-link
      :to="{
        name: 'library-v2',
      }"
      exact
      class="text-md text-gray-400 font-semibold no-underline hover:no-underline hover:text-gray-600"
      active-class="route-link-active inline-block"
    >
      Products /
    </router-link>
    <router-link
      :to="{
        name: 'product-overview',
        params: { id: productId },
        query: { template_id: templateId },
      }"
      exact
      class="text-md text-gray-400 font-semibold no-underline hover:no-underline"
      active-class="route-link-active inline-block"
    >
      {{ productTitle }}</router-link
    >

    <span v-if="postId">
      /
      <span v-if="!postTitle">...</span>
      <router-link
        v-else
        :to="{
          name: 'post-overview',
          params: { id: productId, category_id: categoryId, post_id: postId },
        }"
        exact
        class="text-md text-blue-600 font-semibold no-underline hover:no-underline"
        active-class=""
        >{{ postTitle }}</router-link
      >
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    productId: String,
    productTitle: String,
    categoryId: String,
    categoryTitle: String,
    postId: String,
    postTitle: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
    goBackIconColor: {
      type: String,
      default: '#111827',
    },
  },
  computed: {
    templateId(): string {
      return this.$route.query.template_id
    },
  },
})
</script>
<style scoped>
.route-link-active {
  color: #4a5568;
  font-weight: bold;
  cursor: default;
  text-decoration: none;
}
</style>
