<template>
  <div :class="isSubCategory ? 'ml-14 border-l-2' : 'w-full'" class="font-sans">
    <component
      :is="isSubCategory || !paperWrapper ? 'div' : Paper"
      v-for="(treeItem, treeIndex) in categoryTree"
      :key="treeItem.id"
      class="w-full"
      :class="{ 'border-b mb-3': !isSubCategory }"
      :id="treeItem.id"
    >
      <div
        v-if="!isSubCategory"
        @click="toggleCategory(treeItem.id)"
        class="collapse-trigger p-4 border-b flex justify-between items-center"
        :class="[
          {
            'cursor-default': !treeItem.isAvailable || !hasProductAccess,
            'border border-neo-classic-primary-accent':
              highlightElement === treeItem.id,
          },
          hasProductAccess ? 'cursor-pointer' : '',
        ]"
        role="button"
        tabindex="0"
        :aria-label="treeItem.node.title"
      >
        <div class="flex items-center gap-2">
          <Title tag="h4" class="m-0 text-lg font-medium">
            {{ !isSubCategory ? treeItem.node.title : '' }}
            <span
              class="text-gray-700 font-medium text-sm ml-2"
              v-if="!treeItem.isAvailable"
            >
              Drip (Coming soon)
            </span>
          </Title>
          <Lock01Icon class="h-5 w-5" v-if="isItemLocked(treeItem)" />
        </div>
        <div class="flex items-center gap-2">
          <UITag type="error" v-if="!treeItem.isAvailable">
            Available after {{ treeItem.dripDays }} day{{
              treeItem.dripDays > 1 ? 's' : ''
            }}
          </UITag>
          <ChevronRightIcon
            class="w-5 h-5 transition-all ease-in-out"
            :class="{
              'rotate-90': openedCategories.includes(treeItem.id),
              'text-gray-300': !treeItem.isAvailable,
            }"
          />
        </div>
      </div>
      <div
        class="transition-all duration-500 ease-in-out overflow-hidden"
        :style="{
          display: 'grid',
          gridTemplateRows:
            openedCategories.includes(treeItem.id) || isSubCategory
              ? '1fr'
              : '0fr',
        }"
      >
        <div
          class="collapse-content transition-all duration-500 ease-in-out overflow-hidden"
          :style="{
            opacity:
              openedCategories.includes(treeItem.id) || isSubCategory
                ? '1'
                : '0',
            transform:
              openedCategories.includes(treeItem.id) || isSubCategory
                ? 'translateY(0)'
                : 'translateY(-100px)',
          }"
        >
          <LockedContent
            v-if="isItemLocked(treeItem)"
            :content-type="CategoryTreeNodeTypeView[treeItem.type]"
            :lockedByPostId="treeItem.lockedBy || treeItem.lockedByPost"
            :lockedByCategoryId="treeItem.node.lockedByCategory"
            :completedPostIds="completedPostIds"
            @highlightThisElement="highlightThisElement"
          />
          <CategoryListItem
            v-else-if="isSubCategory"
            :key="treeItem.id"
            :listItemData="treeItem"
            :showBottomBorder="treeIndex !== categoryTree.length - 1"
            class="pl-8"
            :shrinkSize="isSubCategory"
            :progress="postProgress.get(treeItem.node.id)"
            :is-post-completed="completedPostIds.has(treeItem.id)"
            :is-item-locked="isItemLocked(treeItem)"
            :show-description="
              getCategoryAvailability(
                treeItem.categoryId || treeItem.node.parentCategory
              )
            "
            :isCategoryAvailable="
              getCategoryAvailability(
                treeItem.categoryId || treeItem.node.parentCategory
              )
            "
          />
          <div v-else>
            <div
              v-for="(postListItem, listIndex) in treeItem.childs"
              :key="postListItem.node.id"
              :id="postListItem.node.id"
            >
              <div
                class="border-b"
                v-if="postListItem.type === CategoryTreeNodeType.Category"
              >
                <div
                  class="bg-neo-classic-sub-category-bg py-1 pl-20 flex items-center justify-between pr-4"
                  :class="{
                    'border border-neo-classic-primary-accent':
                      highlightElement === postListItem.id,
                  }"
                >
                  <Title tag="h5" class="m-0 font-medium">
                    {{ postListItem.node.title }}
                  </Title>
                  <UITag
                    type="error"
                    size="small"
                    v-if="!postListItem.isAvailable"
                  >
                    Available after {{ postListItem.dripDays }} day{{
                      postListItem.dripDays > 1 ? 's' : ''
                    }}
                  </UITag>
                </div>
                <div class="w-full">
                  <LockedContent
                    v-if="isItemLocked(postListItem)"
                    :content-type="CategoryTreeNodeTypeView[postListItem.type]"
                    :lockedByPostId="
                      postListItem.lockedBy || postListItem.lockedByPost
                    "
                    :lockedByCategoryId="postListItem.node.lockedByCategory"
                    :completedPostIds="completedPostIds"
                    @highlightThisElement="highlightThisElement"
                  />
                  <CategoryListView
                    v-else
                    :categoryTree="postListItem.childs"
                    :isSubCategory="true"
                    :completedPostIds="completedPostIds"
                    :hasProductAccess="hasProductAccess"
                    :postProgress="postProgress"
                  />
                </div>
              </div>
              <CategoryListItem
                v-else
                :listItemData="postListItem"
                :showBottomBorder="listIndex !== treeItem.childs.length - 1"
                class="pl-14"
                :progress="postProgress.get(postListItem.node.id)"
                :is-post-completed="completedPostIds.has(postListItem.id)"
                :is-item-locked="isItemLocked(postListItem)"
                :show-description="
                  getCategoryAvailability(postListItem.node.parentCategory)
                "
                :isCategoryAvailable="
                  getCategoryAvailability(postListItem.node.parentCategory)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, Ref, onMounted } from 'vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import LockedContent from '@/components/neo-classic-theme/common/post/LockedContent.vue'
import { ChevronRightIcon, Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import CategoryListItem from '@/components/neo-classic-theme/landing-page/CategoryListItem.vue'
import CategoryTreeNode, {
  CategoryTreeNodeType,
  CategoryTreeNodeTypeView,
} from '@/models/CategoryTreeNode'
import { UITag } from '@gohighlevel/ghl-ui'
import { CategoryVisibility } from '@/models/Category'
import { useRoute } from 'vue-router'

const props = defineProps({
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  isSubCategory: {
    type: Boolean,
    default: false,
  },
  paperWrapper: {
    type: Boolean,
    default: true,
  },
  postProgress: {
    type: Map<string, number>,
    default: new Map(),
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  itemIndex: {
    type: Number,
    default: 0,
  },
  hasProductAccess: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()

const openedCategories = ref([])
const categoriesAndPostsLookup = inject<Ref<Map<string, any>>>(
  'categoriesAndPostsLookup'
)

function isItemLocked(treeItem: CategoryTreeNode) {
  if (treeItem.node.visibility === CategoryVisibility.locked) {
    const lockedByPostId = treeItem.lockedBy || treeItem.lockedByPost
    const lockedByCategory = treeItem.node.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false
    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)
      if (category) {
        return (
          isLocked ||
          !category.childs?.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

function getCategoryAvailability(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (!category) return false
  return category.node.available
}

function toggleCategory(categoryId: string) {
  if (!props.hasProductAccess) return
  if (openedCategories.value.includes(categoryId)) {
    openedCategories.value = openedCategories.value.filter(
      (id) => id !== categoryId
    )
  } else {
    openedCategories.value.push(categoryId)
  }
}

const highlightElement = ref('')
function highlightThisElement({
  categoryId,
  highlightCategoryId,
}: {
  categoryId: string
  highlightCategoryId: string
}) {
  highlightElement.value = highlightCategoryId
  setTimeout(() => {
    highlightElement.value = ''
  }, 1500)

  // If the category is not opened, we open it
  if (!openedCategories.value.includes(categoryId)) {
    toggleCategory(categoryId)
  }
  // We scroll to the category
  window.history.pushState(null, null, `#${categoryId}`)
  document.getElementById(categoryId).scrollIntoView({
    behavior: 'smooth',
  })
}

onMounted(() => {
  if (route.hash) {
    const categoryId = route.hash.slice(1)
    const category = props.categoryTree.find(
      (category) => category.id === categoryId
    )

    if (category) {
      highlightThisElement({
        categoryId: categoryId,
        highlightCategoryId: categoryId,
      })
    }
  }
})
</script>
