<template>
  <div
    class="flex max-w-xs justify-between px-3 py-1 items-center p-1 border border-gray-400 rounded bg-gray-100 my-2 self-center"
  >
    <div class="flex items-center">
      <BaseIcon name="document" hwClass="h-3 w-3 text-gray-500" />
      <div class="text-xm px-2">
        {{ trimmedFileName }}
      </div>
    </div>
    <div class="flex items-center">
      <div class="px-3 font-10-px self-center">
        {{ convertFileSize(file.size) }}
      </div>
      <div
        class="flex items-center"
        @click="downloadMaterial(file.url, file.title)"
      >
        <BaseIcon
          name="download"
          class="text-blue-500 cursor-pointer"
          hwClass="h-4 w-4"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { downloadFile, humanReadableFileSize } from '@/helper/fileHandler'
import BaseIcon from '../svgicons/BaseIcon.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
export default defineComponent({
  props: {
    file: Object,
  },
  components: {
    BaseIcon,
  },
  data() {
    return {
      fileNameLimit: 20,
    }
  },
  computed: {
    trimmedFileName(): string {
      if (
        this.file &&
        this.file.title &&
        this.file.title.length > this.fileNameLimit
      ) {
        return `${this.file.title.substring(0, this.fileNameLimit)}...`
      }
      return this.file.title
    },
  },
  methods: {
    async downloadMaterial(url: string, title: string) {
      await downloadFile(replaceBucketUrlWithCdnUrl(url), title)
    },
    convertFileSize(size: number): string {
      return humanReadableFileSize(size)
    },
  },
})
</script>
<style scoped>
.font-10-px {
  font-size: 10px;
}
</style>