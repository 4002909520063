import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center h-screen"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "mx-4"
}
const _hoisted_4 = { class: "px-5 flex" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "py-2"
}
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  class: "w-full py-2"
}
const _hoisted_12 = { key: 3 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 1,
  class: "text-center p-20 flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_FileTab = _resolveComponent("FileTab")!

  return (_ctx.fetchingSubmission)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_UISpinner, { size: "large" })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.showAssignmentQuestion)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["rounded submission-container mx-auto", _ctx.newMobileScreens ? '' : 'border border-gray-300'])
            }, [
              (!_ctx.newMobileScreens || (_ctx.newMobileScreens && !_ctx.hideTitle))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["text-center font-semibold", _ctx.newMobileScreens ? 'py-2 text-xl' : 'py-5'])
                  }, _toDisplayString(_ctx.assignment.title), 3))
                : _createCommentVNode("", true),
              (!_ctx.retakingAssignment && !_ctx.newMobileScreens)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (
            _ctx.lastSubmission &&
            _ctx.lastSubmission.status !== _ctx.assessmentStatusOptions.processing
          )
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["py-5 px-2 rounded-md flex m-auto font-medium", `bg-${_ctx.resultMessageColor}-200`])
                        }, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("span", {
                              class: _normalizeClass(["pt-px", `text-${_ctx.resultMessageColor}-500`])
                            }, [
                              _createVNode(_component_BaseIcon, {
                                name: 
                  _ctx.lastSubmission.status === _ctx.assessmentStatusOptions.passed
                    ? 'checkcircle'
                    : 'crosswithcircle'
                ,
                                hwClass: "h-8 w-8"
                              }, null, 8, ["name"])
                            ], 2),
                            _createElementVNode("div", {
                              class: "px-5 self-center",
                              innerHTML: _ctx.pageTopMessage
                            }, null, 8, _hoisted_5)
                          ]),
                          (
              _ctx.nextPost &&
              _ctx.lastSubmission.status === _ctx.assessmentStatusOptions.passed &&
              !_ctx.completed
            )
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                class: _normalizeClass(["ml-auto pt-2 pr-5 cursor-pointer", `text-${_ctx.resultMessageColor}-500`]),
                                to: {
              name: 'post-overview',
              params: {
                id: _ctx.productId,
                category_id: _ctx.nextPost.categoryId,
                post_id: _ctx.nextPost.id,
              },
            }
                              }, {
                                default: _withCtx(() => _cache[0] || (_cache[0] = [
                                  _createTextVNode(" Next ")
                                ])),
                                _: 1
                              }, 8, ["class", "to"]))
                            : _createCommentVNode("", true),
                          (_ctx.lastSubmission.status === _ctx.assessmentStatusOptions.failed)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(["ml-auto pt-2 pr-5 cursor-pointer", `text-${_ctx.resultMessageColor}-500`])
                              }, [
                                _createVNode(_component_router_link, {
                                  tag: "div",
                                  to: {
                name: `${_ctx.postContentType}-overview`,
                params: {
                  category_id: _ctx.categoryId,
                  post_id: _ctx.postId,
                },
                query: {
                  retake: true,
                },
              },
                                  class: "flex justify-center pt-5"
                                }, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode(" Retake ")
                                  ])),
                                  _: 1
                                }, 8, ["to"])
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 2))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([
          _ctx.newMobileScreens ? 'py-1' : 'p-4',
          _ctx.isShowingResultInMobile ? 'px-4' : '',
        ])
              }, [
                (_ctx.lastSubmission && _ctx.assignment.confirmMessage)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["mb-2", 
            _ctx.newMobileScreens
              ? 'border border-success-new-200 rounded-lg bg-success-100 px-2'
              : ''
          ])
                    }, [
                      _createElementVNode("div", {
                        class: "font-bold cursor-pointer editor-content",
                        id: "confirmation-message",
                        innerHTML: 
              _ctx.assignment.confirmMessage?.slice(0, _ctx.currentConfirmationMsgLength)
            
                      }, null, 8, _hoisted_6),
                      (_ctx.assignment.confirmMessage?.length > _ctx.maxDisplayChars)
                        ? (_openBlock(), _createBlock(_component_UIButton, {
                            key: 0,
                            id: "toggle-confirmation-message",
                            text: true,
                            onClick: _ctx.toggleConfirmationMessage
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.assignment.confirmMessage?.length === _ctx.currentConfirmationMsgLength
                ? 'Show less'
                : 'Load More'), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ], 2))
                  : _createCommentVNode("", true),
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "font-bold" }, "Instructions:", -1)),
                _createElementVNode("div", {
                  id: "introduction-div",
                  class: "custom-word-break editor-content",
                  innerHTML: _ctx.assignment.introduction
                }, null, 8, _hoisted_7),
                (_ctx.fileList.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "my-1 font-bold" }, "Attached files:", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (file, index) => {
                        return (_openBlock(), _createBlock(_component_FileTab, {
                          file: file,
                          key: index
                        }, null, 8, ["file"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.lastSubmission)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"font-weight":"bold"} }, "Submitted Answer:", -1)),
                      _createElementVNode("span", {
                        class: "custom-word-break",
                        innerHTML: _ctx.lastSubmission.answer
                      }, null, 8, _hoisted_10),
                      (_ctx.submittedFiles.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "my-1 font-bold" }, "Submitted files:", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submittedFiles, (file, index) => {
                              return (_openBlock(), _createBlock(_component_FileTab, {
                                file: file,
                                key: index
                              }, null, 8, ["file"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.lastSubmission && _ctx.lastSubmission.feedback)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["font-semibold", _ctx.newMobileScreens ? '' : 'text-xs'])
                      }, " Feedback ", 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["border custom-word-break editor-content", 
              _ctx.newMobileScreens
                ? 'bg-primary-50 border-primary-200 px-2 text-gray-900 rounded-lg'
                : 'rounded p-5 text-xs bg-white'
            ]),
                        innerHTML: _ctx.lastSubmission.feedback
                      }, null, 10, _hoisted_13)
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ], 2))
          : (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                height: "300",
                width: "300",
                src: "https://staging.cdn.msgsndr.com/assets/membership/assessments-home.png"
              }, null, -1)
            ]))),
        (!_ctx.newMobileScreens && _ctx.showingRetakeButton)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              tag: "div",
              to: {
        name: `${_ctx.postContentType}-overview`,
        params: {
          category_id: _ctx.categoryId,
          post_id: _ctx.postId,
        },
        query: {
          retake: true,
        },
      },
              class: "flex justify-center pt-5"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UIButton, {
                  type: "primary",
                  id: "assignment-button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ]))
}