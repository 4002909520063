<template>
  <img
    class="user-avatar rounded-full"
    :style="{
      minWidth: overWriteMinLimit ? '' : '32px',
      minHeight: overWriteMinLimit ? '' : '32px',
    }"
    :src="replaceBucketUrlWithCdnUrl(src)"
    alt="user avatar"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { userAvatarImage } from '../../helper/constants'
export default defineComponent({
  props: {
    src: {
      type: String,
      default: userAvatarImage,
    },
    overWriteMinLimit: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
<style scoped>
.user-avatar {
  aspect-ratio: 1;
  object-fit: cover;
}
</style>
