import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-center items-center h-full w-full"
}
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col h-full w-full"
}
const _hoisted_3 = {
  key: 0,
  class: "h-full flex-1 flex flex-col justify-evenly items-center"
}
const _hoisted_4 = {
  "id-": "score-container",
  class: "w-full flex flex-col justify-between items-center py-4"
}
const _hoisted_5 = { id: "svg-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  "id-": "message-container",
  class: "w-4/5 mx-auto border rounded-md border-gray-300 py-12 flex flex-col justify-center items-center"
}
const _hoisted_8 = {
  "id-": "button-container",
  class: "w-4/5 mx-auto flex-1 flex justify-center items-center gap-2"
}
const _hoisted_9 = {
  key: 1,
  class: "text-center flex flex-col justify-center items-center"
}
const _hoisted_10 = ["src"]

import { computed, onMounted, ref } from 'vue'
import { AssessmentStatusService } from '@/services/'
import { UISkeleton } from '@gohighlevel/ghl-ui'
import { adminMode } from '@/helper/permission.helper'
import { AssessmentStatusOptions } from '@/models/AssessmentStatus'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { quizStartImage } from '@/helper/constants'
import { useRoute } from 'vue-router'
import { PostContentTypeLabel } from '@/models/Post'
import { quizPassIcon } from '@/helper/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoQuizStatusComponent',
  props: {
  post: Object,
  categoryId: String,
  isLoading: {
    type: Boolean,
    default: false,
  },
  quiz: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['changeQuizStatus'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const route = useRoute()
const lastSubmission = ref(null)
const questionCount = ref(0)
const correctCount = ref(0)
const incorrectCount = ref(0)
const fetchingSubmission = ref(false)
const assessmentStatusOptions = ref(AssessmentStatusOptions)

const contentTypeLabel = ref({
  quiz: PostContentTypeLabel.quiz,
  assignment: PostContentTypeLabel.assignment,
})

const productId = computed(() => {
  return props.post ? props.post.productId : route.params.id
})

const postId = computed(() => {
  return props.post ? props.post.id : ''
})

const isAdmin = computed(() => {
  return adminMode()
})

const postContentType = computed(() => {
  return props.post ? props.post.contentType : ''
})

const bannerMessage = computed(() => {
  return lastSubmission.value.status === assessmentStatusOptions.value.passed
    ? 'You have successfully completed this quiz. Thank You!'
    : 'You have failed to reach the minimum score of this quiz.'
})

const secondaryBannerMessage = computed(() => {
  return props.quiz.requiredPassingGrade
    ? `You need ${props.quiz.passingGrade}% or higher to pass. Please try again.`
    : 'Please try again.'
})

const buttonLabel = computed(() => {
  return `${lastSubmission.value ? 'Retake' : 'Start'} ${
    contentTypeLabel.value[postContentType.value]
  }`
})

const score = computed(() => {
  return lastSubmission.value ? lastSubmission.value.score : 0
})

async function getLastSubmission() {
  if (isAdmin.value) return // don't make api calls if it's admin
  fetchingSubmission.value = true
  try {
    const fetchedSubmission =
      await AssessmentStatusService.getLastSubmissionByPostId(postId.value)
    lastSubmission.value = fetchedSubmission.data.lastSubmission
    if (lastSubmission.value) {
      questionCount.value = lastSubmission.value.submission.length
      correctCount.value = lastSubmission.value.submission.filter(
        (e) => e.correctAnswerGiven
      ).length
      incorrectCount.value = lastSubmission.value.submission.filter(
        (e) => !e.correctAnswerGiven
      ).length
      emit('changeQuizStatus', true)
    } else {
      emit('changeQuizStatus', false)
    }
  } catch (error) {
    console.error(`Error getting last submission`, error)
  } finally {
    fetchingSubmission.value = false
  }
}

onMounted(async () => {
  await getLastSubmission()
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (fetchingSubmission.value || __props.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-lg" })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (lastSubmission.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    class: "w-32 h-32",
                    src: _unref(quizPassIcon),
                    alt: "quiz-pass-illus"
                  }, null, 8, _hoisted_6)
                ]),
                _createVNode(Title, {
                  tag: "h1",
                  id: "score-title",
                  class: _normalizeClass(["font-normal mt-8", [
            lastSubmission.value.status === assessmentStatusOptions.value.passed
              ? 'text-green-600'
              : 'text-red-600',
          ]])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(score.value) + "% ", 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(Title, {
                  tag: "h4",
                  id: "message-heading",
                  class: "font-medium text-center m-0.5"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(bannerMessage.value), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Title, {
                  id: "secondary-message-heading",
                  tag: "h4",
                  class: "font-medium text-center m-0.5"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(secondaryBannerMessage.value), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", {
                  id: "message-subheading",
                  class: _normalizeClass(["text-lg font-normal m-0 mt-4", [
            lastSubmission.value.status === assessmentStatusOptions.value.passed
              ? 'text-green-600'
              : 'text-red-600',
          ]])
                }, _toDisplayString(correctCount.value) + " / " + _toDisplayString(questionCount.value) + " Questions answered correctly ", 3)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  tag: "div",
                  to: {
            name: `new-${postContentType.value}-overview`,
            params: {
              category_id: __props.categoryId,
              post_id: postId.value,
              id: productId.value,
            },
          },
                  style: {"text-decoration":"none"},
                  class: "flex justify-center no-underline"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Button, {
                      id: "quiz-btn-retake",
                      variant: "secondary",
                      size: "medium",
                      class: "no-underline"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(buttonLabel.value), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createVNode(_component_router_link, {
                  tag: "span",
                  to: {
            name: `new-${postContentType.value}-overview`,
            params: {
              category_id: __props.categoryId,
              post_id: postId.value,
              id: productId.value,
            },
            query: {
              review: true,
            },
          },
                  class: "cursor-pointer"
                }, {
                  default: _withCtx(() => [
                    _createVNode(Button, {
                      id: "quiz-btn-review",
                      variant: "subtle",
                      size: "medium",
                      class: "no-underline"
                    }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode(" View Result ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                class: "w-[70%]",
                src: _unref(quizStartImage),
                alt: "Quiz "
              }, null, 8, _hoisted_10),
              _createVNode(_component_router_link, {
                tag: "div",
                to: {
          name: `new-${postContentType.value}-overview`,
          params: {
            category_id: __props.categoryId,
            post_id: postId.value,
            id: productId.value,
          },
        },
                style: {"text-decoration":"none"},
                class: "flex justify-center no-underline"
              }, {
                default: _withCtx(() => [
                  _createVNode(Button, {
                    id: "quiz-btn",
                    variant: "secondary",
                    size: "medium",
                    class: "no-underline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(buttonLabel.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ]))
      ]))
}
}

})