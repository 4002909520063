import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }

import { toRaw, PropType, computed, ref, onMounted, watch } from 'vue'
import DefaultTemplate from '@/components/product/templates/DefaultTemplate.vue'
import ClassicTemplate from '@/components/product/templates/ClassicTemplate.vue'
import NewYorkTemplate from '@/components/product/templates/NewYorkTemplate.vue'
import NeoClassicTemplate from '@/components/neo-classic-theme/NeoClassicTemplate.vue'
import {
  PostService,
  UserProductTrackingService,
  UserPurchaseService,
} from '../services'
import {
  getHeroAlignmentClass,
  getHeroSpacingClass,
  createCategoryPostTree,
  computeNextPost,
  getPost,
  isClientPortal,
} from '../helper'
import CategoryTreeNode from '../models/CategoryTreeNode'
import { defaultProductBackground } from '../helper/constants'
import UISpinner from '@/components/common/UISpinner.vue'
import { cloneDeep } from 'lodash'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsThemeController',
  props: {
  product: {
    type: Object,
  },
  instructor: {
    type: Object,
  },
  fetching: Boolean,
  productStarted: Boolean,
  logo: String,
  selectedTheme: {
    type: String,
    default: 'Default',
  },
  allCompletedPostsData: {
    type: Object as PropType<{
      completedPostIds: Set<string>
      posts: Array<object>
    }>,
    default: null,
  },
},
  setup(__props) {

const route = useRoute()
const router = useRouter()
const store = useStore()
const props = __props

const productId = computed(() => {
  return route.params.id || props.product.id
})

const productThemeComponent = computed(() => {
  switch (props.selectedTheme) {
    case 'Classic':
      return ClassicTemplate
    case 'NewYork':
      return NewYorkTemplate
    case 'Neue':
      return NeoClassicTemplate
    case 'NeoClassic':
      return NeoClassicTemplate
    default:
      return DefaultTemplate
  }
})

const templateId = computed(() => {
  return route.query.template_id || 'Default'
})

const categoryTree = computed(() => {
  return createCategoryPostTree(null, toRaw(categories.value))
})

const heroSection = computed(() => {
  const templateId = props.selectedTheme || 'Default'
  if (props.product && props.product.customizations) {
    const customization = props.product.customizations

    return {
      alignment: getHeroAlignmentClass(customization.heroTextAlignment),
      spacing: getHeroSpacingClass(customization.heroSpacing),
      background:
        customization.heroImage || defaultProductBackground[templateId],
      overlayColor: customization.heroOverlayColor || 'rgba(0, 0, 0, 0)',
    }
  }

  return {
    alignment: 'text-center' as string,
    spacing: 'py-16' as string,
    background: defaultProductBackground[templateId],
    overlayColor: 'rgba(0, 0, 0, 0)',
  }
})

const postIdsCompleted = computed(() => {
  return props.allCompletedPostsData?.completedPostIds
    ? props.allCompletedPostsData?.completedPostIds
    : new Set()
})

const hasProductAccess = computed(() => {
  return toRaw(props.product)?._data?.hasProductAccess
})

const isPortalNavbar = computed(() => {
  return (
    isClientPortal() &&
    !store.getters.newMobileScreens &&
    source.value !== 'communities'
  )
})

const source = computed(() => {
  return route.query.source
})

const fetchingProduct = ref(false)

onMounted(async () => {
  fetchingProduct.value = true
  await Promise.all([fetchCategories(), fetchCompletionData()])
  fetchingProduct.value = false
  // TODO: Need to update below function to compute correct next post
  // Currently it works based on last completed post's index
  // Ideally it should be based on first incomplete post.

  nextPost.value = computeNextPost(
    cloneDeep(categoryTree.value),
    postIdsCompleted.value as Set<string>
  )
  if (
    nextPost.value &&
    (props.selectedTheme === 'Neue' || templateId.value === 'Neue')
  ) {
    goToNextPost()
  }
})

const fetchingCategories = ref(false)
const categories = ref([])
const totalPosts = ref(0)
const completedPosts = ref(0)
const categoryIdsCompleted = ref(new Set())
const nextPost = ref({} as CategoryTreeNode)

async function fetchCategories() {
  fetchingCategories.value = true

  try {
    categories.value = await UserPurchaseService.getCategories({
      product_id: productId.value,
    })
  } catch (error) {
    console.error('Error while fetching product --> ', error)
  } finally {
    fetchingCategories.value = false
  }
}

async function fetchCompletionData() {
  return Promise.all([fetchCompletedPosts(), fetchNoOfPublishedPosts()])
}

async function fetchCompletedPosts() {
  categoryIdsCompleted.value = new Set(
    categories.value.reduce((acc, categoryElem) => {
      const { posts } = categoryElem
      const categoryPostIds = posts.map((e) => e.id)
      const completedPostLengthInCategory = categoryPostIds.filter((element) =>
        postIdsCompleted.value.has(element)
      ).length
      if (
        categoryPostIds.length > 0 &&
        completedPostLengthInCategory === categoryPostIds.length
      ) {
        acc.push(categoryElem.id)
      }
      return acc
    }, [])
  )

  completedPosts.value = postIdsCompleted.value.size
}

async function fetchNoOfPublishedPosts() {
  const publishedResp = await PostService.noOfPublishedPosts({
    product_id: productId.value,
  })

  totalPosts.value = publishedResp.count
}

function goToNextPost() {
  if (hasProductAccess.value === false) return
  if (!props.productStarted) {
    // start the product
    UserProductTrackingService.trackProductStart(productId.value)
  }
  let payload: any = null
  if (nextPost.value?.id) {
    payload = {
      name: 'post-overview',
      params: {
        post_id: nextPost.value.id,
        category_id: nextPost.value.parent,
        id: productId.value,
      },
    }
  } else if (props.selectedTheme === 'Neue') {
    const post = getPost(categoryTree.value)
    payload = {
      name: 'post-overview',
      params: {
        post_id: post.id,
        category_id: post.parent,
        id: productId.value,
      },
    }
  }
  if (route.query.template_id) {
    payload.query = {
      template_id: templateId.value,
    }
    if (route.query.builder) {
      payload.query.builder = route.query.builder
    }
  }
  if (payload) {
    trackCustomGAEvents(GAEventTypes.NEXT_LESSON, { postId: nextPost.value.id })
    router.push(payload)
  }
}

watch(
  () => props.selectedTheme,
  (val) => {
    if (val === 'Neue') {
      goToNextPost()
    }
  }
)

watch(templateId, (val) => {
  if (val === 'Neue') {
    goToNextPost()
  }
})

watch(productId, async (val) => {
  await fetchCategories()
  await fetchCompletionData()
  nextPost.value = computeNextPost(
    cloneDeep(categoryTree.value),
    postIdsCompleted.value as Set<string>
  )
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(isPortalNavbar.value ? 'pt-10' : '')
  }, [
    ((__props.fetching || fetchingProduct.value) && __props.selectedTheme !== 'NeoClassic')
      ? (_openBlock(), _createBlock(UISpinner, {
          key: 0,
          class: "flex justify-center items-center h-screen",
          size: "large"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(productThemeComponent.value), {
            heroSection: heroSection.value,
            product: __props.product,
            categoryTree: categoryTree.value,
            postIdsCompleted: postIdsCompleted.value,
            categoryIdsCompleted: categoryIdsCompleted.value,
            completedPosts: completedPosts.value,
            totalPosts: totalPosts.value,
            instructor: __props.instructor,
            nextPost: nextPost.value,
            productStarted: __props.productStarted,
            logo: __props.logo,
            hasProductAccess: hasProductAccess.value,
            allCompletedPostsData: __props.allCompletedPostsData,
            loading: __props.fetching || fetchingProduct.value,
            onGoToNextPost: goToNextPost
          }, null, 40, ["heroSection", "product", "categoryTree", "postIdsCompleted", "categoryIdsCompleted", "completedPosts", "totalPosts", "instructor", "nextPost", "productStarted", "logo", "hasProductAccess", "allCompletedPostsData", "loading"]))
        ]))
  ], 2))
}
}

})