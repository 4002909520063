import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my-4 mb-8 h-full grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center" }
const _hoisted_2 = { class: "w-full overflow-hidden h-auto relative rounded-lg" }
const _hoisted_3 = { class: "absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1" }
const _hoisted_4 = { class: "lg:mt-4 h-36" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "w-full pt-1" }
const _hoisted_7 = { class: "flex items-center justify-between" }
const _hoisted_8 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shimmer = _resolveComponent("shimmer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
      return _createElementVNode("div", {
        key: i,
        class: "border-2 shadow-sm bg-white border-slate-200 p-4 rounded-lg w-full h-full overflow-hidden cursor-pointer hover:shadow-lg hover:-translate-y-2 transform transition duration-200"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_shimmer, { class: "w-full aspect-video rounded-lg" }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_shimmer, { class: "w-16 h-4 rounded-lg" })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_shimmer, { class: "w-2/3 h-6 my-1 rounded-lg" }),
          _createVNode(_component_shimmer, { class: "w-full h-4 my-1 rounded-lg" })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_shimmer, { class: "w-full h-2 rounded-lg" })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_shimmer, { class: "rounded-lg w-12 h-12 mr-2" }),
              _createVNode(_component_shimmer, { class: "w-24 h-4 rounded-lg" })
            ]),
            _createVNode(_component_shimmer, { class: "w-20 h-8 rounded-lg" })
          ])
        ])
      ])
    }), 64))
  ]))
}