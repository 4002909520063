import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, KeepAlive as _KeepAlive, unref as _unref } from "vue"

const _hoisted_1 = { class: "w-full sticky top-0 bg-white z-30 pt-3" }
const _hoisted_2 = {
  class: "mx-auto w-full",
  style: {
        height: 'calc(100% - 95px)',
      }
}

import CategorySyllabusV2 from "@/components/mobile/CategorySyllabusV2.vue";
import PostCommentsV2 from "@/components/mobile/PostCommentsV2.vue";
import TabComponentV2 from "@/components/mobile/TabComponentV2.vue";
import { CURRICULAM_COMMENTS_TAB } from "@/helper/constants";
import Category from "@/models/Category";
import Post from "@/models/Post";
import Product from "@/models/Product";
import { UIDrawer, UIDrawerContent } from "@gohighlevel/ghl-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'CurriculamCommentsDrawerV2',
  props: {
    categoryTree: {},
    totalPosts: {},
    allCompletedPostsData: {},
    commentPermission: {},
    product: {},
    category: {},
    activePost: {},
    hasProductAccess: { type: Boolean },
    productId: {}
  },
  setup(__props: any) {

const store = useStore();
const selectedTab = ref("curriculum");
const openCurriculumComments = computed(() => store.getters.openCurriculumComments);

const props = __props;

const isCommentVisible = computed(() => {
  return props.commentPermission === "enabled";
});

const tabs = ref<{ name: string; value: string; disabled?: boolean }[]>(CURRICULAM_COMMENTS_TAB);

function handleOpenCurriculumComments() {
  store.commit("SET_CURRICULUM_COMMENTS_OPEN", !openCurriculumComments.value);
}

function handleCommentsCurriculamTabChange(tab: string) {
  if (tab === 'comments' && !isCommentVisible.value) {
    return;
  }
  selectedTab.value = tab;
}
onMounted(() => {
  if (!isCommentVisible.value) {
    tabs.value = [tabs.value[0], { name: 'Comments', value: 'comments', disabled: true }]
  }
})

return (_ctx: any,_cache: any) => {
  const _directive_swipe = _resolveDirective("swipe")!

  return (_openBlock(), _createBlock(_unref(UIDrawer), {
    id: "curriculum-comments-drawer",
    show: openCurriculumComments.value,
    placement: 'bottom',
    displayDirective: 'if',
    "default-height": "90vh",
    "on-mask-click": handleOpenCurriculumComments
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(UIDrawerContent), {
        id: "drawer-component",
        class: "p-0",
        closable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "flex min-h-3 w-full flex-col items-center justify-center",
              onClick: handleOpenCurriculumComments
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "h-1 w-6 rounded-md border border-solid bg-gray-200" }, null, -1)
            ]))), [
              [_directive_swipe, {
            direction: 'down',
            callback: () => {
              nextTick(() => {
                handleOpenCurriculumComments();
              });
            },
          }]
            ]),
            _createVNode(TabComponentV2, {
              selectedTab: selectedTab.value,
              tabOptions: tabs.value,
              onTabChange: handleCommentsCurriculamTabChange
            }, null, 8, ["selectedTab", "tabOptions"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (selectedTab.value === 'curriculum')
                    ? (_openBlock(), _createBlock(CategorySyllabusV2, {
                        key: 0,
                        categoryTree: _ctx.categoryTree,
                        totalPosts: _ctx.totalPosts || 0,
                        allCompletedPostsData: _ctx.allCompletedPostsData,
                        hasProductAccess: _ctx.hasProductAccess,
                        product: _ctx.product,
                        productId: _ctx.productId
                      }, null, 8, ["categoryTree", "totalPosts", "allCompletedPostsData", "hasProductAccess", "product", "productId"]))
                    : (_openBlock(), _createBlock(PostCommentsV2, {
                        key: 1,
                        product: _ctx.product,
                        activePost: _ctx.activePost,
                        category: _ctx.category
                      }, null, 8, ["product", "activePost", "category"]))
                ]),
                _: 1
              })
            ], 1024))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})