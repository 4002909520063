<script setup lang="ts">
import Navigation from "@/components/common/Navigation.vue";
import AllCoursesV2 from "@/components/mobile/AllCoursesV2.vue";
import MyCoursesV2 from "@/components/mobile/MyCoursesV2.vue";
import { COURSES_V2_TABS } from "@/helper/constants";
import UpsellOffer from "@/models/UpsellOffer";
import { UserPurchaseService } from "@/services";
import { computed, h, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import CoursesShimmerV2 from "../../components/shimmer/mobile/CoursesShimmerV2.vue";
import TabComponentV2 from "@/components/mobile/TabComponentV2.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const selectedTab = ref('all_courses');
const fetching = ref(false);
const products = ref([]);
const upsellOffers = ref<UpsellOffer[]>([]);
const filteredUpsellOffers = ref<UpsellOffer[]>([]);
const allCourses = ref([]);
const purchasedProductIds = ref([]);
const isUpsellOfferAvailable = ref(false);

const isLibraryPage = computed(() => {
  const libraryPathNames = ["library-v2", "library-v2-v2"];
  return libraryPathNames.includes(String(route.name));
});
const selectedTabComponent = computed(() => {
  return selectedTab.value === 'all_courses'
    ? h(AllCoursesV2, {
      allCourses: allCourses.value,
      upsellOffers: filteredUpsellOffers.value,
    })
    : h(MyCoursesV2, { myCourses: products.value });
});

onMounted(async () => {
  fetching.value = true;
  await fetchUserProducts();
  await fetchUpsellBundle();
  fetching.value = false;
});

async function fetchUserProducts() {
  try {
    products.value = await UserPurchaseService.getAllProducts({ getPostCount: true });
    purchasedProductIds.value = products.value.map((p) => p.id);
  } catch (error) {
    console.error("Error while fetching products --> ", error);
  }
}
async function fetchUpsellBundle() {
  try {
    const upsellOffersArr = await UserPurchaseService.getBundleOffers();
    const set = new Set();
    upsellOffers.value = upsellOffersArr.filter((offer) => {
      if (!set.has(offer.upsellOfferId)) {
        set.add(offer.upsellOfferId);
        return true;
      }
      return false;
    });

    if (upsellOffers.value.length > 0) {
      isUpsellOfferAvailable.value = true;
      filteredUpsellOffers.value = upsellOffers.value.filter((offer) => {
        return offer?.upsellType && offer?.offerUpSell;
      });
    }
    allCourses.value = [...upsellOffers.value, ...products.value];
  } catch (error) {
    console.error("Error while fetching upsell bundles --> ", error);
  }
}
async function backCallback() {
  router.back();
}

function handleTabChange(tab: string) {
  selectedTab.value = tab;
}
</script>
<template>
  <div class="page-container">
    <Navigation :backCallback="!isLibraryPage ? backCallback : null" />

    <div class="content-container w-dvw  relative pb-8">
      <div class="courses-tab w-11/12 mx-auto flex items-center justify-evenly gap-4">

        <TabComponentV2 :selectedTab="selectedTab" :tabOptions="COURSES_V2_TABS" @tab-change="handleTabChange" />
      </div>

      <CoursesShimmerV2 v-if="fetching" />
      <KeepAlive>
        <Transition name="fade">
          <component :is="selectedTabComponent" :fetching="fetching" class="h-full" />
        </Transition>
      </KeepAlive>
    </div>
  </div>
</template>

<style scoped>
.header {
  border-bottom: 1px solid #d9d9d9;
  height: 10vh;
}


.page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
}

.courses-tab {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
}

.max-w-2\/5 {
  max-width: 40%;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
