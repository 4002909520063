<template>
  <div class="w-full">
    <div id="header-container " class="top-0 w-full z-[10] font-sans">
      <WelcomeHeader
        :title="product.title"
        :description="product.description"
        :showButton="true"
        :buttonText="buttonText"
        :backgroundImage="product.posterImage || heroSection.background"
        @header-button-click="emit('goToNextPost')"
        :loading="loading"
        :productAccessMessage="productAccessMessage"
        :hasProductAccess="hasProductAccess"
      />
    </div>
    <div
      id="content-container"
      class="grid grid-cols-12 gap-6 content-fix-width mx-auto mt-6 w-[80vw]"
    >
      <div class="col-span-9">
        <CategoryListViewLoader v-if="loading" />

        <CategoryListView
          v-else
          :categoryTree="categoryTree"
          :postProgress="postProgress"
          :completedPostIds="postIdsCompleted"
          :hasProductAccess="hasProductAccess"
        />
      </div>
      <div class="col-span-3">
        <LessonsStats
          :completed="completedPosts"
          :total="totalPosts"
          :progress="productProgress"
          :poster-image="product.posterImage"
        />
        <InstructorCard class="mt-4" :instructor="instructor" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  provide,
  computed,
  defineProps,
  defineEmits,
  PropType,
  ComputedRef,
  watch,
} from 'vue'
import LessonsStats from '@/components/neo-classic-theme/landing-page/LessonsStats.vue'
import WelcomeHeader from '@/components/neo-classic-theme/landing-page/WelcomeHeader.vue'
import CategoryListView from '@/components/neo-classic-theme/landing-page/CategoryListView.vue'
import InstructorCard from '@/components/neo-classic-theme/common/InstructorCard.vue'
import { getProductAccessMessage } from '@/helper/offerAccess'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import { getCategoryAndPostsLookup } from '@/helper'
import CategoryListViewLoader from '@/components/neo-classic-theme/loaders/CategoryListViewLoader.vue'

const props = defineProps({
  loading: Boolean,
  heroSection: Object,
  product: Object,
  instructor: Object,
  categoryTree: Array<CategoryTreeNode>,
  nextPost: Object,
  completedPosts: Number,
  totalPosts: Number,
  postIdsCompleted: Set,
  categoryIdsCompleted: Set,
  courseCompleted: Boolean,
  productStarted: Boolean,
  hasProductAccess: Boolean,
  allCompletedPostsData: {
    type: Object as PropType<{
      completedPostIds: Set<string>
      posts: Array<object>
    }>,
    default: null,
  },
})

const emit = defineEmits(['goToNextPost'])

// Created a Map of records for each category and posts for quick lookup
const categoriesAndPostsLookup = ref(new Map())
provide('categoriesAndPostsLookup', categoriesAndPostsLookup)

function updateTreeLookup() {
  categoriesAndPostsLookup.value = getCategoryAndPostsLookup(props.categoryTree)
}

const postProgress = computed(() => {
  if (
    !props.allCompletedPostsData ||
    !props.allCompletedPostsData.posts ||
    !props.allCompletedPostsData.posts.length
  )
    return new Map()

  return props.allCompletedPostsData.posts.reduce(
    (agg: Map<string, number>, post: any): Map<string, number> => {
      agg.set(post.postId, post.percentage)
      return agg
    },
    new Map()
  )
}) as ComputedRef<Map<string, number>>

const productAccessMessage = computed(() => {
  return getProductAccessMessage(props.hasProductAccess, props.product)
})

const buttonText = computed(() => {
  if (props.productStarted) {
    if (props.totalPosts === props.completedPosts) return 'Go to'
    else return 'Resume Course'
  } else return 'Start Course'
})

const productProgress = computed(() => {
  return (props.completedPosts / props.totalPosts) * 100
})

onMounted(() => {
  updateTreeLookup()
})

watch(() => props.categoryTree, updateTreeLookup)
</script>

<style scoped></style>
