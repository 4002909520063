import BaseService from './BaseService'
import Product from '@/models/Product'
import http from '../../http-common'

export default class ProductService extends BaseService<Product> {
  protected subdomain = 'products'

  model() {
    return Product
  }

  constructor(locationId: string) {
    super(locationId)
  }

  getCustomizationSettings(productId: string, params: any) {
    return http
      .get(`${this.endpoint}/product-themes/${productId}`, {
        params,
      })
      .then((res) => res.data)
  }
}
