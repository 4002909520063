import {
  findExtensionFromFileType,
  get1DigitRandomNumber,
} from '../helper/fileHandler'

import { AxiosProgressEvent } from 'axios'
import { UploadMediaResponse } from '@/helper/interfaces'
import firebase from 'firebase/app'
import http from '../../http-common'
import { v4 as uuidv4 } from 'uuid'

export default class MediaUploadService {
  private subdomain = 'media'
  private locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  uploadFile(payload: {
    file: Blob
    type: string
    filename?: string
    folder?: string
    progressUpdate?: any
  }): Promise<UploadMediaResponse> {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('type', payload.type)

    if (payload.filename) {
      formData.append('filename', payload.filename)
    }

    if (payload.folder) {
      formData.append('folder', payload.folder)
    }

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (payload.progressUpdate) {
          payload.progressUpdate(percentCompleted)
        }
      },
    }

    return http.post(this.endpoint, formData, config).then((res) => {
      const { data } = res
      const { path: url, thumb: thumbnail } = data
      return { url, thumbnail } as UploadMediaResponse
    })
  }

  async checkIfFileExists(fileUrl: string): Promise<boolean> {
    // const bucket = firebase.storage().ref().bucket

    try {
      await firebase.storage().ref(fileUrl).getDownloadURL()
      return true
    } catch (error) {
      if (error && error.code === 'storage/object-not-found') {
        // sure that its not there
        return false
      } else {
        console.error('error while checking for file url --> ', error)
        // not sure what happened
        throw new Error(
          'Not able to check if file exists or not, please check the error'
        )
      }
    }
  }

  async getUniquePath(path: string): Promise<string> {
    try {
      if (await this.checkIfFileExists(path)) {
        return await this.getUniquePath(`${path}-${get1DigitRandomNumber()}`)
      } else {
        return path
      }
    } catch (error) {
      console.error('error while getting unique path --> ', error)
      return path
    }
  }

  async uploadFileToFirebaseStorage(payload: {
    file: Blob
    type: string
    filename?: string
    folder?: string
    progressUpdate?: any
    cancelUpload?: any
  }): Promise<{
    uploadPromise: Promise<UploadMediaResponse>
    cancelUpload
  }> {
    const filename = payload.filename || uuidv4()
    let path = `memberships/${this.locationId}/${
      payload.folder || 'other'
    }/${filename}`

    path = await this.getUniquePath(path)

    const bucket = firebase.storage().ref().bucket
    // console.log('bucket --> ', bucket)
    const fullPath = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(
      path
    )}?alt=media`

    const uploadPath = firebase.storage().ref(path)

    const extensionFromFileType = findExtensionFromFileType(payload.type) // returns "" or ".xyz"
    const extensionFromFileName = `.${filename.split('.').pop()}`
    const fileNameForUpload =
      extensionFromFileType === extensionFromFileName
        ? filename
        : `${filename}${extensionFromFileType}`

    const uploadTask = uploadPath.put(payload.file, {
      contentType: payload.type,
      contentDisposition: `inline; filename="${fileNameForUpload}"`,
      customMetadata: { name: payload.type },
    })

    const cancelUpload = () => {
      uploadTask.cancel()
    }

    const uploadPromise: Promise<UploadMediaResponse> = new Promise(
      (resolve, reject) => {
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            const uploadProgress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )

            if (payload.progressUpdate) {
              payload.progressUpdate(uploadProgress)
            }
          },
          function (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            reject(error)
          },
          async function () {
            // console.log('completed upload --> ', fullPath)

            const url = await firebase
              .storage()
              .refFromURL(fullPath)
              .getDownloadURL()

            const resp = { url } as UploadMediaResponse
            resolve(resp)
          }
        )
      }
    )

    return { uploadPromise, cancelUpload }
  }

  async uploadFileSigned(payload: {
    file: Blob
    type: string
    filename?: string
    folder?: string
    progressUpdate?: any
    cancelUpload?: any
  }): Promise<{
    uploadPromise: Promise<UploadMediaResponse>
    cancelUpload
  }> {
    const filename: string = payload.filename || uuidv4()
    const foldername: string = payload.folder || 'other'
    const signedUrl: {
      error: boolean
      url?: string
      unsignedUrl?: string
      msg?: string
    } = (await this.generateSignedUrl(foldername, filename, payload.type)).data

    const blobedFile = new File([payload.file], payload.filename)

    const xhr = new XMLHttpRequest()

    const uploadPromise: Promise<UploadMediaResponse> = new Promise(function (
      resolve,
      reject
    ) {
      const formData = new FormData()
      formData.append('file', blobedFile)
      xhr.open('PUT', signedUrl.url, true)
      xhr.onload = () => {
        const status = xhr.status
        if (status === 200) {
          resolve({ url: signedUrl.unsignedUrl })
        } else {
          reject('Something went wrong')
        }
      }
      xhr.onerror = () => {
        reject('Something went wrong')
      }
      xhr.setRequestHeader('Content-Type', payload.type)

      if (payload.progressUpdate) {
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            const percentComplete = Number(
              ((event.loaded / event.total) * 100).toFixed()
            )
            payload.progressUpdate(percentComplete)
          }
        }
      }
      xhr.send(blobedFile)
    })

    const cancelUpload = () => {
      xhr.abort()
    }

    return { uploadPromise, cancelUpload }
  }

  async generateSignedUrl(folder: string, filename: string, type: string) {
    return http.post(`${this.endpoint}/signed-url`, { filename, folder, type })
  }
}
