import http from '../../http-common'

export default class UserProductTracking {
  protected subdomain = 'user-product-tracking'
  private readonly locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  private get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  trackProductLogin(productId: string) {
    return http
      .post(`${this.endpoint}/${productId}/register-product-login`)
      .then((resp) => resp.data)
  }

  trackProductStart(productId: string) {
    return http
      .post(`${this.endpoint}/${productId}/register-product-start`)
      .then((resp) => resp.data)
  }

  checkIfProductStarted(productId: string) {
    return http
      .get(`${this.endpoint}/${productId}/check-product-started`)
      .then((resp) => resp.data)
  }
}
