import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "category-list"
}
const _hoisted_2 = { class: "w-11/12 flex items-center justify-start gap-2 mx-auto my-4" }
const _hoisted_3 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_4 = { class: "w-11/12 flex items-center justify-evenly mx-auto gap-4" }
const _hoisted_5 = { class: "w-11/12 flex flex-col items-start mx-auto gap-2 my-4" }
const _hoisted_6 = { class: "w-11/12 flex flex-col items-start mx-auto gap-2 my-4" }
const _hoisted_7 = { class: "flex items-center gap-4 my-2" }
const _hoisted_8 = { class: "flex flex-col items-start gap-2" }

import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryShimmerV2',
  setup(__props) {

const store = useStore();

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
};

return (_ctx: any,_cache: any) => {
  return (_unref(newMobileScreens))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(UISkeleton), {
          height: "33.33%",
          width: "100%",
          sharp: false
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "64px"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "16px",
            circle: ""
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "96px"
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "15px",
            class: "w-11/12 mx-auto my-4 rounded-xl"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "40px",
            class: "w-11/12 mx-auto my-4"
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "40px",
            width: "50%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "40px",
            width: "50%"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "24px",
            width: "128px"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "90%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "80%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "80%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "70%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "10px",
            width: "50%"
          })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "24px",
            width: "128px"
          }),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(UISkeleton), {
              sharp: false,
              height: "48px",
              width: "48px",
              circle: ""
            }),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(UISkeleton), {
                sharp: false,
                height: "16px",
                width: "96px"
              }),
              _createVNode(_unref(UISkeleton), {
                sharp: false,
                height: "12px",
                width: "64px"
              })
            ])
          ]),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "100%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "90%"
          }),
          _createVNode(_unref(UISkeleton), {
            sharp: false,
            height: "16px",
            width: "70%"
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})