<template>
  <div class="relative font-sans">
    <UITooltip
      v-if="isTruncated && mode === 'tooltip'"
      :placement="placement"
      :trigger="trigger"
    >
      <template #trigger>
        <p
          v-highlight="{ isEditing: isEditing, label: 'Paragraph' }"
          :style="[computedStyle, customStyle]"
          :class="['text-md m-0', computedClass]"
          class="custom-word-break"
          v-bind="$attrs"
        >
          {{ truncatedText }}
        </p>
      </template>
      <p class="max-w-[33vw] custom-word-break">{{ fullText }}</p>
    </UITooltip>
    <div v-else-if="mode === 'extend'" class="relative">
      <p
        ref="content"
        v-highlight="{ isEditing: isEditing, label: 'Paragraph' }"
        :style="[
          computedStyle,
          customStyle,
          { maxHeight: maxHeightExtend + 'px', overflowY: 'auto' },
        ]"
        :class="['text-md m-0', computedClass, 'hide-scrollbar']"
        class="custom-word-break"
        v-bind="$attrs"
      >
        <slot></slot>
      </p>
      <div
        v-if="showArrows && showUpArrow"
        @click="scrollUp"
        class="arrow-container top-0 right-[-25px]"
      >
        <span v-if="upArrow" v-html="upArrow"></span>
        <span v-else class="arrow-up">
          <ChevronUpIcon class="h-4 w-4" />
        </span>
      </div>
      <div
        v-if="showArrows && showDownArrow"
        @click="scrollDown"
        class="arrow-container bottom-0 right-[-25px]"
      >
        <span v-if="downArrow" v-html="downArrow"></span>
        <span v-else class="arrow-down">
          <ChevronDownIcon class="h-4 w-4" />
        </span>
      </div>
    </div>
    <p
      v-else
      v-highlight="{ isEditing: isEditing, label: 'Paragraph' }"
      :style="[computedStyle, customStyle]"
      :class="['text-md m-0', computedClass]"
      class="custom-word-break"
      v-bind="$attrs"
    >
      {{ truncatedText }}
    </p>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from 'vue'
import { UITooltip } from '@gohighlevel/ghl-ui'
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@gohighlevel/ghl-icons/24/outline'

export default {
  components: {
    UITooltip,
    ChevronDownIcon,
    ChevronUpIcon,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: '',
    },
    fontSize: {
      type: String,
      default: '',
    },
    fontWeight: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    customClass: {
      type: [String, Object, Array],
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    placement: {
      type: String,
      default: 'bottom-start',
      validator: (value) =>
        [
          'top-start',
          'top',
          'top-end',
          'right-start',
          'right',
          'right-end',
          'bottom-start',
          'bottom',
          'bottom-end',
          'left-start',
          'left',
          'left-end',
        ].includes(value),
    },
    trigger: {
      type: String,
      default: 'hover',
      validator: (value) =>
        ['hover', 'click', 'focus', 'manual'].includes(value),
    },
    mode: {
      type: String,
      default: 'none',
      validator: (value) => ['none', 'tooltip', 'extend'].includes(value),
    },
    maxHeightExtend: {
      type: Number,
      default: 200,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    upArrow: {
      type: String,
      default: null,
    },
    downArrow: {
      type: String,
      default: null,
    },
  },
  setup(props, { slots }) {
    const computedStyle = computed(() => ({
      color: props.textColor,
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
    }))

    function stripHTMLTags(str) {
      const doc = new DOMParser().parseFromString(str, 'text/html')
      return doc.body.textContent || ''
    }

    const computedClass = computed(() => props.customClass)

    const fullText = computed(() =>
      slots.default ? stripHTMLTags(slots.default()[0].children) : ''
    )
    const isTruncated = computed(
      () => props.maxLength && fullText.value.length > props.maxLength
    )

    const truncatedText = computed(() =>
      isTruncated.value
        ? fullText.value.slice(0, props.maxLength) + '...'
        : fullText.value
    )

    const content = ref(null)
    const showUpArrow = ref(false)
    const showDownArrow = ref(false)

    const updateArrows = () => {
      if (content.value) {
        showUpArrow.value = content.value.scrollTop > 0
        showDownArrow.value =
          content.value.scrollTop + content.value.clientHeight <
          content.value.scrollHeight
      }
    }

    const scrollUp = () => {
      if (content.value) {
        content.value.scrollTo({
          top: content.value.scrollTop - 20,
          behavior: 'smooth',
        })
        updateArrows()
      }
    }

    const scrollDown = () => {
      if (content.value) {
        content.value.scrollTo({
          top: content.value.scrollTop + 20,
          behavior: 'smooth',
        })
        updateArrows()
      }
    }

    onMounted(() => {
      if (props.mode === 'extend' && content.value) {
        updateArrows()
        content.value.addEventListener('scroll', updateArrows)
      }
    })

    return {
      computedStyle,
      computedClass,
      fullText,
      isTruncated,
      truncatedText,
      content,
      showUpArrow,
      showDownArrow,
      scrollUp,
      scrollDown,
    }
  },
}
</script>

<style scoped>
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.custom-word-break {
  word-break: break-word;
}

.arrow-container {
  position: absolute;
  right: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s;
}

.arrow-container:hover {
  background: rgba(255, 255, 255, 1);
}

.arrow-up,
.arrow-down {
  font-size: 1rem;
  color: #000;
}
.arrow-down {
  transform: rotate(90deg);
}
.arrow-up {
  transform: rotate(90deg);
}
</style>
