// import { ProductCustomizationService } from '@/services'
import Model from './Model'

export enum HeroTextAlignment {
  left = 'left',
  right = 'right',
  centered = 'centered',
}

export enum HeroSpacing {
  xs = 'xs',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface Instructor {
  heading: string
  name: string
  headshot: string
  title: string
  bio: string
}

export default class ProductCustomization extends Model {
  constructor(payload?: {
    logoImage?: string
    heroImage?: string
    heroOverlayColor?: string
    heroTextAlignment?: string
    heroSpacing?: string
    instructorHeading?: string
    instructorHeadshot?: string
    instructorName?: string
    instructorTitle?: string
    instructorBio?: string
    favicon?: string
  }) {
    super(payload || {})
  }

  get logoImage(): string {
    return this._data.logoImage
  }

  set logoImage(logoImage: string) {
    this._data.logoImage = logoImage
  }

  get heroImage(): string {
    return this._data.heroImage
  }

  set heroImage(heroImage: string) {
    this._data.heroImage = heroImage
  }

  get heroOverlayColor(): string {
    return this._data.heroOverlayColor
  }

  set heroOverlayColor(heroOverlayColor: string) {
    this._data.heroOverlayColor = heroOverlayColor
  }

  get heroTextAlignment(): string {
    return this._data.heroTextAlignment
  }

  set heroTextAlignment(heroTextAlignment: string) {
    this._data.heroTextAlignment = heroTextAlignment
  }

  get heroSpacing(): string {
    return this._data.heroSpacing
  }

  set heroSpacing(heroSpacing: string) {
    this._data.heroSpacing = heroSpacing
  }

  get favicon(): string {
    return this._data.favicon
  }

  set favicon(favicon: string) {
    this._data.favicon = favicon
  }

  get instructorHeading(): string {
    return this._data.instructorHeading
  }

  set instructorHeading(instructorHeading: string) {
    this._data.instructorHeading = instructorHeading
  }

  get instructorHeadshot(): string {
    return this._data.instructorHeadshot
  }

  set instructorHeadshot(instructorHeadshot: string) {
    this._data.instructorHeadshot = instructorHeadshot
  }

  get instructorName(): string {
    return this._data.instructorName
  }

  set instructorName(instructorName: string) {
    this._data.instructorName = instructorName
  }

  get instructorTitle(): string {
    return this._data.instructorTitle
  }

  set instructorTitle(instructorTitle: string) {
    this._data.instructorTitle = instructorTitle
  }

  get instructorBio(): string {
    return this._data.instructorBio
  }

  set instructorBio(instructorBio: string) {
    this._data.instructorBio = instructorBio
  }
}
