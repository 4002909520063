import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "category-list-container mt-4 sm:block lg:grid grid-cols-3 gap-4" }
const _hoisted_2 = { class: "count text-gray-600" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center text-center font-bold text-lg"
}
const _hoisted_4 = { class: "w-full text-center" }
const _hoisted_5 = {
  key: 1,
  class: "text-center my-4"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "lesson-stats-instructor-container col-span-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryShimmer = _resolveComponent("CategoryShimmer")!
  const _component_CategoryPostBreadcrumbs = _resolveComponent("CategoryPostBreadcrumbs")!
  const _component_UITextXsMedium = _resolveComponent("UITextXsMedium")!
  const _component_CategoryListItem = _resolveComponent("CategoryListItem")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_LessonsStats = _resolveComponent("LessonsStats")!
  const _component_InstructorCard = _resolveComponent("InstructorCard")!

  return (_ctx.fetching)
    ? (_openBlock(), _createBlock(_component_CategoryShimmer, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["max-w-xl lg:max-w-6xl w-full mx-auto my-4 px-4 lg:px-0", _ctx.isPortalNavbar ? 'pt-14' : _ctx.newMobileScreens ? 'pb-20' : 'my-4'])
      }, [
        (_ctx.device === 'desktop' || _ctx.isBuilder || !_ctx.newMobileScreens)
          ? (_openBlock(), _createBlock(_component_CategoryPostBreadcrumbs, {
              key: 0,
              "product-id": _ctx.productId,
              "product-title": _ctx.product && _ctx.product.title
            }, null, 8, ["product-id", "product-title"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["mb-4 category-list bg-white rounded pb-6 col-span-2", _ctx.newMobileScreens ? '' : 'shadow p-6'])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["category-list-title flex items-center justify-between text-2xl py-2", 
            _ctx.device === 'dekstop' || _ctx.isBuilder || !_ctx.newMobileScreens
              ? 'flex'
              : 'hidden'
          ])
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title font-semibold" }, "Categories", -1)),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.categories.length), 1)
            ], 2),
            (_ctx.newMobileScreens)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.product.title), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.newMobileScreens)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("button", {
                    class: _normalizeClass(["text-white rounded-full py-1 px-4", _ctx.getMobileTheme.primary]),
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleNextPostClick && _ctx.handleNextPostClick(...args))),
                    disabled: !_ctx.hasProductAccess
                  }, _toDisplayString(_ctx.completedPosts > 0 ? 'Resume' : 'Start'), 11, _hoisted_6),
                  (!_ctx.hasProductAccess)
                    ? (_openBlock(), _createBlock(_component_UITextXsMedium, {
                        key: 0,
                        class: "pt-1"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.productAccessMessage), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "category-list-items my-4 lg:my-0",
                key: category.id
              }, [
                (category.available)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: {
              name: 'category-overview',
              params: { id: _ctx.productId, category_id: category.id },
            }
                    }, {
                      default: _withCtx(() => [
                        (_ctx.categoryProgressCount !== null)
                          ? (_openBlock(), _createBlock(_component_CategoryListItem, {
                              key: 0,
                              "category-progress-count": _ctx.categoryProgressCount.get(category.id),
                              category: category,
                              progress: _ctx.categoryProgress[category.id]
                            }, null, 8, ["category-progress-count", "category", "progress"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createBlock(_component_CategoryListItem, {
                      key: 1,
                      category: category,
                      progress: _ctx.categoryProgress[category.id],
                      "category-progress-count": _ctx.categoryProgressCount.get(category.id),
                      "not-available": true
                    }, null, 8, ["category", "progress", "category-progress-count"]))
              ]))
            }), 128))
          ], 2),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_LessonsStats, {
              completed: _ctx.completedPosts,
              total: _ctx.totalPosts,
              "progress-color": _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : '',
              class: "mb-3"
            }, null, 8, ["completed", "total", "progress-color"]),
            _createVNode(_component_InstructorCard, { instructor: _ctx.instructor }, null, 8, ["instructor"])
          ])
        ])
      ], 2))
}