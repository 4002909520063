<template>
  <div
    class="progress rounded-full w-full bg-progress-disable lg:bg-gray-200 h-2"
  >
    <div
      id="progress-completed"
      class="h-full flex justify-center items-center rounded-full text-xs leading-none py-1 text-center"
      :class="
        progressColor && progressColor.includes('bg-')
          ? progressColor
          : 'bg-blue-500'
      "
      :style="{
        width: `${percentCompleted || 0}%`,
        backgroundColor:
          progressColor && progressColor.includes('bg-') ? '' : progressColor,
      }"
    >
      <span
        class="text-white"
        :class="newMobileScreens ? 'text-xxs' : ''"
        v-if="showPercentage"
        :style="{ lineHeight: 'normal' }"
        >{{ percentCompleted }}%</span
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    percentCompleted: {
      type: Number,
      default: 0,
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
    progressColor: {
      type: String,
    },
  },
  computed: {
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
  },
})
</script>
