import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["id", "onClick", "aria-disabled", "aria-label"]
const _hoisted_2 = {
  key: 0,
  class: "flex items-center w-full col-span-2 justify-end"
}
const _hoisted_3 = {
  key: 0,
  class: "text-gray-900"
}
const _hoisted_4 = {
  key: 2,
  class: "flex items-center col-span-2 justify-end"
}
const _hoisted_5 = {
  key: 0,
  class: "text-neo-classic-primary-accent text-xxs ml-auto"
}

import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import { downloadFileWithProgress } from '@/helper/fileHandler'
import LinkPreview from '@/components/neo-classic-theme/common/LinkPreview.vue'
import {
  ArrowDownIcon,
  CheckCircleIcon,
  Download01Icon,
  File04Icon,
  Image01Icon,
  XIcon,
} from '@gohighlevel/ghl-icons/24/outline'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { getFileSize } from '@/helper'
import { ref, computed } from 'vue'
import PostMaterial, { PostMaterialType } from '@/models/PostMaterial'


export default /*@__PURE__*/_defineComponent({
  __name: 'AttachedFiles',
  props: {
  fileList: {
    type: Array<PostMaterial>,
    required: true,
  },
  wrapInPaper: {
    type: Boolean,
    default: true,
  },
  showBorder: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props

const revisedFileList = computed(() => {
  return props.fileList.map((file) => {
    return {
      title: file.title,
      url: file.url,
      type: file.type,
      size: file.size,
      id: file.id || (file.title + file.type).replaceAll(' ', '_'),
    }
  }) as Array<PostMaterial>
})

const downloadingQueue = ref(new Map())
const downloadCompleteQueue = ref(new Map())

function getFileIcon(fileType: string) {
  if (fileType?.includes(PostMaterialType.image)) return Image01Icon
  return File04Icon
}

function updateProgress(progress: number, fileId: string) {
  downloadingQueue.value.set(fileId, {
    ...downloadingQueue.value.get(fileId),
    progress,
  })

  if (progress === 100) {
    downloadCompleteQueue.value.set(fileId, {
      ...downloadCompleteQueue.value.get(fileId),
      progress,
    })
    downloadingQueue.value.delete(fileId)
  }
}

async function initiateDownload({ fileUrl, fileTitle, fileId }) {
  try {
    if (downloadingQueue.value.has(fileId)) return
    const { downloadPromise, cancelDownload } = downloadFileWithProgress({
      url: replaceBucketUrlWithCdnUrl(fileUrl),
      givenFileName: fileTitle,
      progressUpdate: (progress) => updateProgress(progress, fileId),
      onCancel: () => {
        downloadingQueue.value.delete(fileId)
      },
    })
    downloadingQueue.value.set(fileId, {
      fileUrl,
      fileTitle,
      progress: 0,
      cancelDownload,
    })
    await downloadPromise
  } catch (error) {
    if (error.type === 'abort') {
      console.warn('Download cancelled by the user!')
    } else {
      console.error('Error initiating download: ', error)
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.wrapInPaper ? Paper : 'div'), { class: "w-full" }, {
    default: _withCtx(() => [
      (_ctx.$slots.title)
        ? _renderSlot(_ctx.$slots, "title", { key: 0 })
        : (_openBlock(), _createBlock(Title, {
            key: 1,
            tag: "h4",
            class: "font-medium m-0 p-6"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Attached Files")
            ])),
            _: 1
          })),
      _createElementVNode("div", {
        class: _normalizeClass([__props.showBorder ? 'border rounded-lg' : '', "w-full"])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(revisedFileList.value, (file, fileIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            tabindex: "0",
            role: "button",
            key: file.id,
            id: file.id,
            onClick: ($event: any) => (
          initiateDownload({
            fileUrl: file.url,
            fileTitle: file.title,
            fileId: file.id,
          })
        ),
            class: _normalizeClass(["cursor-pointer py-4 px-6 grid grid-cols-12 items-center justify-between hover:bg-neo-classic-hover-bg text-neo-classic-primary-accent group", fileIndex === revisedFileList.value.length - 1 ? '' : 'border-b']),
            "aria-disabled": downloadingQueue.value.has(file.id),
            "aria-label": 
          downloadingQueue.value.has(file.id)
            ? 'Downloading...'
            : 'Download ' + file.title
        
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex items-center w-full col-span-10", {
            'animate-pulse opacity-50': downloadingQueue.value.has(file.id),
          }])
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(getFileIcon(file.type)), { class: "w-4 h-4 mr-2" })),
              (file.type.includes(_unref(PostMaterialType).image))
                ? (_openBlock(), _createBlock(LinkPreview, {
                    key: 0,
                    alternateComponent: Title,
                    href: _unref(replaceBucketUrlWithCdnUrl)(file.url),
                    linkClass: "hover:no-underline",
                    tag: "h6",
                    class: "m-0 truncate"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(file.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["href"]))
                : (_openBlock(), _createBlock(Title, {
                    key: 1,
                    tag: "h5",
                    class: "m-0 truncate"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(file.title), 1)
                    ]),
                    _: 2
                  }, 1024))
            ], 2),
            (downloadingQueue.value.has(file.id))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_unref(ArrowDownIcon), { class: "w-4 h-4 animate-bounce mr-2" }),
                  (downloadingQueue.value.has(file.id))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(Math.round(downloadingQueue.value.get(file.id).progress) + '%'), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(XIcon), {
                    class: "w-4 h-4 ml-auto text-neo-classic-error",
                    onClick: _withModifiers(downloadingQueue.value.get(file.id).cancelDownload, ["prevent","stop"])
                  }, null, 8, ["onClick"])
                ]))
              : (downloadCompleteQueue.value.has(file.id))
                ? (_openBlock(), _createBlock(_unref(CheckCircleIcon), {
                    key: 1,
                    class: "w-4 h-4 ml-auto col-span-2 text-neo-classic-success"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (file.size)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(getFileSize)(file.size)), 1))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(Download01Icon), { class: "w-4 h-4 ml-4" })
                  ]))
          ], 10, _hoisted_1))
        }), 128))
      ], 2)
    ]),
    _: 3
  }))
}
}

})