import Model from './Model'
import Offer from './Offer'

export enum UpsellOfferType {
  INAPP = 'in_app',
  ONECLICK = 'one_click',
}
export default class UpsellOffer extends Model {
  constructor(payload?: {
    id?: string
    title?: string
    subTitle?: string
    description?: string
    videoUrl?: string
    buttonText?: string
    offerId?: string
    upsellType?: string
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get subTitle(): string {
    return this._data.subTitle
  }

  set subTitle(subTitle: string) {
    this._data.subTitle = subTitle
  }

  get description(): string {
    return this._data.description
  }

  set description(description: string) {
    this._data.description = description
  }

  get videoUrl(): string {
    return this._data.videoUrl
  }

  set videoUrl(videoUrl: string) {
    this._data.videoUrl = videoUrl
  }

  get offerId(): string {
    return this._data.offerId
  }

  set offerId(offerId: string) {
    this._data.offerId = offerId
  }

  get upsellOfferId(): string {
    return this._data.upsellOfferId
  }

  set upsellOfferId(upsellOfferId: string) {
    this._data.upsellOfferId = upsellOfferId
  }

  get buttonText(): string {
    return this._data.buttonText
  }

  set buttonText(buttonText: string) {
    this._data.buttonText = buttonText
  }

  get offer(): Offer {
    return this._data.offerUpSell
  }

  set offer(offer: Offer) {
    this._data.offerUpSell = offer
  }

  get upsellType(): string {
    return this._data.upsellType
  }

  set upsellType(upsellType: string) {
    this._data.upsellType = upsellType
  }

  get active(): boolean {
    return this._data.active
  }

  set active(active: boolean) {
    this._data.active = active
  }
}
