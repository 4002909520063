<template>
  <div
    @click="$emit('handleClick')"
    class="nova-product-card lg:m-4 rounded shadow overflow-hidden max-h-96 max-w-xs cursor-pointer hover:shadow-md transform transition duration-300 hover:scale-105"
    :class="[
      sliderCard ? '' : 'lg:m-0',
      activeProduct && activeProduct === productId
        ? 'border-blue-500'
        : 'border-gray-300',
      !upsellProduct ? 'ml-0' : '',
      newMobileScreens ? '' : 'border',
    ]"
  >
    <div
      class="product-image w-full overflow-hidden h-auto relative"
      :class="[
        sliderCard && 'product-modal-image',
        newMobileScreens ? '' : 'border-b',
      ]"
    >
      <img
        class="w-full h-full object-contain"
        :src="
          replaceBucketUrlWithCdnUrl(posterImage) ||
          replaceBucketUrlWithCdnUrl(defaultPosterImage)
        "
        alt="Product Poster Image"
      />
      <div
        v-if="newMobileScreens"
        class="absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1"
        :class="upsellProduct ? 'hidden' : 'lg:hidden'"
      >
        <span
          v-if="label && label === 'in-library'"
          class="offer-amount px-1 rounded text-white"
          :class="[
            upsellProduct ? 'text-xxs' : 'text-xs',
            newMobileScreens ? getMobileTheme.primary : '',
          ]"
        >
          In Library
        </span>
        <span
          v-if="label && label === 'locked'"
          class="offer-amount d-block lg:px-3 text-center"
          :style="{ color: '#FFBC00' }"
        >
          <BaseIcon name="lockfilled" hwClass="w-4 h-4" />
        </span>
      </div>
    </div>
    <div
      class="product-details lg:my-1 lg:py-2"
      :class="[
        newMobileScreens ? '' : 'h-40',
        {
          'h-28 md:h-32 lg:h-36 py-2': sliderCard,
          'h-24 md:h-24': upsellProduct,
        },
      ]"
    >
      <div class="p-2 lg:px-2 lg:py-1">
        <div
          class="font-semibold product-title"
          :class="
            upsellProduct || sliderCard || newMobileScreens
              ? 'text-sm'
              : 'text-lg'
          "
        >
          {{ truncateText(productTitle) }}
        </div>
      </div>
      <div v-if="!newMobileScreens" class="px-2 pb-2">
        <div
          class="text-gray-800 product-title"
          :class="upsellProduct ? 'text-xs' : 'text-sm'"
          v-if="productDescription"
        >
          {{ truncateText(productDescription, 75) }}
        </div>
      </div>
    </div>
    <div
      v-if="!newMobileScreens || upsellProduct || sliderCard"
      class="w-full absolute bottom-0 block"
      :class="['border-t pt-1', upsellProduct && 'border-gray-200']"
    >
      <div class="flex items-center justify-between py-1 px-1 lg:px-2">
        <div class="flex items-center">
          <div id="instructor-headshot">
            <img
              class="rounded-full"
              :class="[
                upsellProduct || sliderCard ? 'w-8' : 'w-12',
                instructorHeadshot && 'border',
              ]"
              :src="
                replaceBucketUrlWithCdnUrl(
                  instructorHeadshot || userAvatarImage
                )
              "
              alt="Instructor"
            />
          </div>
          <div
            id="instructor-name"
            class="font-semibold text-blue-600"
            :class="upsellProduct ? 'text-xxs px-1' : 'text-sm px-2'"
          >
            {{
              truncateText(
                instructorName || 'Instructor Name',
                instructorNameLimit
              )
            }}
          </div>
        </div>
        <span
          v-if="
            label &&
            label === 'in-library' &&
            (!upsellProduct || !newMobileScreens || sliderCard)
          "
          class="offer-amount py-1 rounded text-white"
          :class="[
            upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            newMobileScreens ? getMobileTheme.primary : 'bg-in-library',
          ]"
        >
          In Library
        </span>
        <span
          v-if="
            label && label === 'in-library' && upsellProduct && newMobileScreens
          "
          class="offer-amount py-1 rounded text-white inline-flex items-center"
          :class="[
            upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            newMobileScreens ? getMobileTheme.primaryText : 'bg-in-library',
          ]"
        >
          <BaseIcon name="book" hwClass="w-4 h-4" />
        </span>
        <span
          v-if="label && label === 'locked'"
          class="offer-amount lg:px-3 text-center inline-flex items-center"
          :style="{ color: '#FFBC00' }"
        >
          <BaseIcon name="lockfilled" hwClass="w-4 h-4" />
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import {
  defaultPosterImage,
  userAvatarImage,
  INSTRUCTOR_NAME_MAX_LENGTH,
} from '../../helper/constants'
import { getMobileTheme } from '../../helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    productId: String,
    productTitle: String,
    posterImage: String,
    productDescription: String,
    instructorName: String,
    instructorHeadshot: String,
    activeProduct: String,
    upsellProduct: {
      type: Boolean,
      default: false,
    },
    sliderCard: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userAvatarImage,
      defaultPosterImage,
      instructorNameLimit: INSTRUCTOR_NAME_MAX_LENGTH,
    }
  },
  computed: {
    newMobileScreens() {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
})
</script>
<style scoped>
.product-title {
  word-break: break-word;
}

.product-image > img {
  aspect-ratio: 16/9;
  object-fit: contain;
}

#instructor-headshot > img {
  aspect-ratio: 1;
}
</style>
