import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center mt-2" }
const _hoisted_2 = { id: "instructor-headshot" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "instructor-card",
    class: _normalizeClass(["bg-white rounded py-5 px-5", [
      _ctx.newYorkTheme && !_ctx.newMobileScreens && 'px-10',
      _ctx.newMobileScreens
        ? 'border'
        : `shadow ${_ctx.getThemeClasses(_ctx.instructorCardClass)}`,
    ]])
  }, [
    _createElementVNode("span", {
      id: "instructor-heading",
      class: _normalizeClass(["font-semibold text-xl", [
        _ctx.newYorkTheme && !_ctx.newMobileScreens && 'flex justify-center',
        _ctx.newMobileScreens ? '' : _ctx.getThemeClasses(_ctx.instructorHeadingClass),
      ]])
    }, _toDisplayString((_ctx.instructor && _ctx.instructor.heading) || _ctx.defaultValues.heading), 3),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "h-16 w-16 rounded-full",
          src: 
            _ctx.replaceBucketUrlWithCdnUrl(
              (_ctx.instructor && _ctx.instructor.headshot) || _ctx.defaultValues.headshot
            )
          ,
          alt: "Instructor"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["ml-3 flex-1", _ctx.newYorkTheme && !_ctx.newMobileScreens && 'ml-16'])
      }, [
        _createElementVNode("div", {
          id: "instructor-name",
          class: _normalizeClass(
            _ctx.newMobileScreens
              ? _ctx.getMobileTheme.primaryText
              : _ctx.getThemeClasses(_ctx.instructorNameClass)
          )
        }, _toDisplayString((_ctx.instructor && _ctx.instructor.name) || _ctx.defaultValues.name), 3),
        _createElementVNode("div", {
          id: "instructor-title",
          class: _normalizeClass(["lg:text-blue-400", _ctx.newMobileScreens ? '' : _ctx.getThemeClasses(_ctx.instructorTitleClass)])
        }, _toDisplayString((_ctx.instructor && _ctx.instructor.title) || _ctx.defaultValues.title), 3)
      ], 2)
    ]),
    _createElementVNode("div", {
      id: "instructor-bio",
      class: _normalizeClass(["mb-2 font-normal text-md whitespace-pre-line", [
        _ctx.newYorkTheme && !_ctx.newMobileScreens && 'ml-[76px]',
        _ctx.newMobileScreens ? '' : _ctx.getThemeClasses(_ctx.instructorBioClass),
      ]])
    }, _toDisplayString(_ctx.instructor && _ctx.instructor.bio), 3)
  ], 2))
}