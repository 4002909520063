import Model from './Model'

export default class Question extends Model {
  constructor(payload?: {
    id?: string
    quizId?: string
    title?: string
    questionType?: string
    sequenceNumber?: number
    originId?: string
    options?: any[] | null
    explanation?: string
    createdAt?: Date
  }) {
    super(payload || {})
  }

  get id(): string {
    return this._data.id
  }

  get title(): string {
    return this._data.title
  }
  set title(title: string) {
    this._data.title = title
  }

  get explanation(): string {
    return this._data.explanation
  }
  set explanation(explanation: string) {
    this._data.explanation = explanation
  }

  get quizId(): string {
    return this._data.quizId
  }
  set quizId(quizId: string) {
    this._data.quizId = quizId
  }

  get questionType(): string {
    return this._data.questionType
  }
  set questionType(questionType: string) {
    this._data.questionType = questionType
  }

  get sequenceNumber(): number {
    return this._data.sequenceNumber
  }
  set sequenceNumber(sequenceNumber: number) {
    this._data.sequenceNumber = sequenceNumber
  }

  get originId(): string {
    return this._data.originId
  }
  set originId(originId: string) {
    this._data.originId = originId
  }

  get options(): any | null {
    return this._data.options
  }
  set options(options: any | null) {
    this._data.options = options
  }

  get createdAt(): string {
    return this._data.createdAt
  }
  set createdAt(createdAt: string) {
    this._data.createdAt = createdAt
  }
}
