import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OneComment = _resolveComponent("OneComment")!
  const _component_ReplyList = _resolveComponent("ReplyList")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_OneComment, {
      content: _ctx.comment.content,
      commentElem: _ctx.comment,
      id: _ctx.comment.id,
      instructor: _ctx.instructor,
      onUpdateStateParentComment: _ctx.updateStateParentComment,
      commentPermission: _ctx.commentPermission,
      onHideReplyList: _ctx.hideReplyList,
      pendoPayload: _ctx.pendoPayload,
      productCommentPrivacy: _ctx.productCommentPrivacy
    }, null, 8, ["content", "commentElem", "id", "instructor", "onUpdateStateParentComment", "commentPermission", "onHideReplyList", "pendoPayload", "productCommentPrivacy"]),
    (_ctx.showReplyList)
      ? (_openBlock(), _createBlock(_component_ReplyList, {
          key: 0,
          replies: _ctx.comment.replies,
          commentPermission: _ctx.commentPermission,
          pendoPayload: _ctx.pendoPayload,
          instructor: _ctx.instructor,
          parentComment: _ctx.comment,
          scrollCommentId: _ctx.scrollCommentId
        }, null, 8, ["replies", "commentPermission", "pendoPayload", "instructor", "parentComment", "scrollCommentId"]))
      : _createCommentVNode("", true)
  ]))
}