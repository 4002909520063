<script lang="ts" setup>
import { extractTextFromHTML } from "@/helper";
import { defaultCourseThumbnail } from "@/helper/constants";
import { replaceBucketUrlWithCdnUrl, truncateText } from "@/helper/filter";
import { computed } from "vue";

const { course } = defineProps(["course"]);

const courseDescription = computed(() => {
  return truncateText(extractTextFromHTML(course?._data.description), 75)
})
</script>

<template>
  <div v-if="!course.upsellType"
    class="course-card w-full flex items-center justify-between gap-4 my-4 cursor-pointer clickable overflow-x-scroll">
    <div class="w-3/5 min-w-36  h-28 xs:h-32 sm:h-48 md:h-60 rounded-md border border-gray-300 aspect-video">
      <img :src="replaceBucketUrlWithCdnUrl(course?._data?.posterImage) || defaultCourseThumbnail
        " alt="course-thumbnail" class="w-full h-full rounded-md object-contain" :class="{
          'object-cover': !replaceBucketUrlWithCdnUrl(course?._data?.posterImage),
        }" />
    </div>
    <div class="h-full w-3/4 flex flex-col items-start justify-around gap-2">
      <div v-show="course?._data?.title" class="text-gray-900 font-bold break-all line-clamp-2 sm:text-lg">
        {{ truncateText(course._data.title, 50) }}
      </div>
      <div v-show="course?._data?.description"
        class="line-clamp-3 font-normal text-xs text-gray-500 sm:text-sm sm:line-clamp-4 break-all">
        {{ courseDescription }}
      </div>
      <div class="w-full text-start text-gray-400 font-medium  sm:text-md">
        {{ course._data?.noOfPostCompleted || 0 }}/{{ course._data?.totalPosts || 0 }}
      </div>
    </div>
  </div>
</template>

<style scoped></style>
