import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 1,
  class: "ml-10"
}

import { ref } from 'vue'
import SingleCommentBlock from '@/components/neo-classic-theme/comments/SingleCommentBlock.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { ReverseLeftIcon } from '@gohighlevel/ghl-icons/24/outline'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoCommentList',
  props: {
  commentsList: {
    type: Array<any>,
    default: () => [],
  },
  editableCommentId: {
    type: String,
    default: '',
  },
  instructor: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['onEdit', 'onDelete'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const showReplyToComment = ref([])

function toggleReplyComment(parentCommentId: string) {
  if (showReplyToComment.value.includes(parentCommentId)) {
    showReplyToComment.value = showReplyToComment.value.filter(
      (id) => id !== parentCommentId
    )
  } else {
    showReplyToComment.value.push(parentCommentId)
  }
}

function onEdit(commentId: string) {
  emit('onEdit', commentId)
}
function onDelete(commentId: string) {
  emit('onDelete', commentId)
}

return (_ctx: any,_cache: any) => {
  const _component_NeoCommentList = _resolveComponent("NeoCommentList", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.commentsList, (comment) => {
      return (_openBlock(), _createElementBlock("div", {
        key: comment.id
      }, [
        _createVNode(SingleCommentBlock, {
          comment: comment,
          editableCommentId: __props.editableCommentId,
          instructor: __props.instructor,
          onOnEdit: onEdit,
          onOnDelete: onDelete
        }, {
          "at-end": _withCtx((slotProps) => [
            _renderSlot(_ctx.$slots, "for-comment-reply", _mergeProps({ ref_for: true }, slotProps))
          ]),
          "edit-section": _withCtx(() => [
            _renderSlot(_ctx.$slots, "for-comment-edit")
          ]),
          _: 2
        }, 1032, ["comment", "editableCommentId", "instructor"]),
        (comment.replies && comment.replies.length > 0)
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              class: "text-neo-classic-primary-accent focus:outline-none outline-none ml-10 my-4 flex items-center",
              variant: "text",
              onClick: ($event: any) => (toggleReplyComment(comment.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(ReverseLeftIcon), { class: "w-4 h-4 mr-1" }),
                _createTextVNode(" " + _toDisplayString(showReplyToComment.value.includes(comment.id)
            ? 'Hide replies'
            : `View ${comment.replies.length} replies`), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          : _createCommentVNode("", true),
        (showReplyToComment.value.includes(comment.id))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_NeoCommentList, {
                commentsList: comment.replies,
                editableCommentId: __props.editableCommentId,
                instructor: __props.instructor,
                onOnEdit: onEdit,
                onOnDelete: onDelete
              }, {
                "for-comment-reply": _withCtx((slotProps) => [
                  _renderSlot(_ctx.$slots, "for-comment-reply", _mergeProps({ ref_for: true }, slotProps))
                ]),
                "for-comment-edit": _withCtx(() => [
                  _renderSlot(_ctx.$slots, "for-comment-edit")
                ]),
                _: 2
              }, 1032, ["commentsList", "editableCommentId", "instructor"])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})