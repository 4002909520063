import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-image w-full image-container border-b rounded" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "px-2 py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPost())),
    class: _normalizeClass(["relative bg-white product-card max-w-xs rounded-lg overflow-hidden shadow-lg px-4", _ctx.available && 'cursor-pointer'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "w-full h-full",
        src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.posterImage),
        alt: "Product Poster Image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["text-center font-semibold text-xl post-title custom-word-break", [
          !_ctx.available ? 'text-gray-500' : 'text-blue-500',
          _ctx.$route.name.includes('product-overview') && _ctx.available
            ? 'primaryStyle lesson-title'
            : '',
        ]])
      }, _toDisplayString(_ctx.post.title), 3)
    ])
  ], 2))
}