import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "user-library",
  class: "w-full"
}
const _hoisted_2 = { class: "max-w-xl mx-auto bg-white pb-16 flex flex-col overflow-y-auto" }
const _hoisted_3 = {
  key: 1,
  class: "w-full mt-3 mb-2"
}
const _hoisted_4 = {
  id: "library-container",
  class: "lg:w-11/12 flex-1 flex flex-col xl:w-9/12 mx-4 px-2 lg:p-4"
}
const _hoisted_5 = {
  key: 0,
  id: "library-title",
  class: "relative left-0 w-full text-left px-0 lg:hidden"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "w-full text-center col-span-2 lg:col-span-3"
}
const _hoisted_9 = {
  key: 0,
  class: "w-full text-center col-span-2 lg:col-span-3"
}
const _hoisted_10 = {
  key: 4,
  id: "search-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_CarouselShimmer = _resolveComponent("CarouselShimmer")!
  const _component_UpsellBundle = _resolveComponent("UpsellBundle")!
  const _component_slide = _resolveComponent("slide")!
  const _component_carousel = _resolveComponent("carousel")!
  const _component_LibraryShimmer = _resolveComponent("LibraryShimmer")!
  const _component_NewProductCard = _resolveComponent("NewProductCard")!
  const _component_UniversalSearch = _resolveComponent("UniversalSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navigation, {
      backCallback: _ctx.active === _ctx.LIBRARY_MENU_ITEMS.search ? _ctx.backCallback : null
    }, null, 8, ["backCallback"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.fetching)
        ? (_openBlock(), _createBlock(_component_CarouselShimmer, { key: 0 }))
        : _createCommentVNode("", true),
      (
          _ctx.active === _ctx.LIBRARY_MENU_ITEMS.allCourses &&
          !_ctx.fetching &&
          _ctx.isUpsellOfferAvailable
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mx-6 mt-1 mb-3 text-lg font-bold" }, "Featured", -1)),
            _createVNode(_component_carousel, {
              "items-to-show": 1.25,
              "wrap-around": true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upsellBundles, (course) => {
                  return (_openBlock(), _createBlock(_component_slide, {
                    key: course.id
                  }, {
                    default: _withCtx(() => [
                      (course.upsellType && course.offerUpSell)
                        ? (_openBlock(), _createBlock(_component_UpsellBundle, {
                            key: 0,
                            class: "w-full inline-block px-3",
                            offer: course.offerUpSell,
                            upsellId: course.id,
                            purchasedProductIds: _ctx.purchasedProductIds
                          }, null, 8, ["offer", "upsellId", "purchasedProductIds"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.isPortalActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.active === _ctx.LIBRARY_MENU_ITEMS.allCourses)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_6, "All Courses"))
                : (_ctx.active === _ctx.LIBRARY_MENU_ITEMS.myCourses)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_7, " My Courses "))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.fetching)
          ? (_openBlock(), _createBlock(_component_LibraryShimmer, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.active === _ctx.LIBRARY_MENU_ITEMS.myCourses && !_ctx.fetching)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              id: "product-list",
              class: _normalizeClass(["mb-8 lg:my-4 lg:mb-0 grid grid-cols-2 lg:grid-cols-3 justify-center gap-4 lg:gap-5", _ctx.isPortalActive && 'mt-3'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createBlock(_component_NewProductCard, {
                  key: product.id,
                  "product-id": product.id,
                  "product-title": product.title,
                  "poster-image": product.posterImage,
                  "product-description": product.description,
                  "instructor-name": product.customizations?.instructorName,
                  "instructor-headshot": product.customizations?.instructorHeadshot,
                  showProgress: false,
                  onHandleClick: ($event: any) => (_ctx.openProduct(product.id))
                }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "onHandleClick"]))
              }), 128)),
              (_ctx.products.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " No courses found! "))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.active === _ctx.LIBRARY_MENU_ITEMS.allCourses && !_ctx.fetching)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              id: "product-list",
              class: _normalizeClass(["lg:my-4 mb-8 grid grid-cols-2 lg:grid-cols-3 justify-center gap-4 lg:gap-8", _ctx.isPortalActive && 'mt-3'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCourses.slice(_ctx.sliderLimit, _ctx.allCourses.length), (course) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: course.id
                }, [
                  (course.upsellType && course.offerUpSell)
                    ? (_openBlock(), _createBlock(_component_UpsellBundle, {
                        key: 0,
                        offer: course.offerUpSell,
                        upsellId: course.id,
                        purchasedProductIds: _ctx.purchasedProductIds,
                        "not-in-carousel": true
                      }, null, 8, ["offer", "upsellId", "purchasedProductIds"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allCourses, (course) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: course.id
                }, [
                  (!course.upsellType)
                    ? (_openBlock(), _createBlock(_component_NewProductCard, {
                        key: 0,
                        "product-id": course.id,
                        "product-title": course.title,
                        "poster-image": course.posterImage,
                        "product-description": course.description,
                        "instructor-name": 
                course.customizations && course.customizations.instructorName
              ,
                        "instructor-headshot": 
                course.customizations &&
                course.customizations.instructorHeadshot
              ,
                        "show-in-library": true,
                        label: "in-library",
                        showProgress: false,
                        onHandleClick: ($event: any) => (_ctx.openProduct(course.id))
                      }, null, 8, ["product-id", "product-title", "poster-image", "product-description", "instructor-name", "instructor-headshot", "onHandleClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              (_ctx.allCourses.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, " No courses and offers are available! "))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.active === _ctx.LIBRARY_MENU_ITEMS.search && !_ctx.fetching)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_UniversalSearch, { device: "mobile" })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}