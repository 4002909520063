import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "offer-amount d-block lg:px-3 text-center",
  style: { color: '#FFBC00' }
}
const _hoisted_3 = { class: "p-2 lg:px-2 lg:py-1" }
const _hoisted_4 = {
  key: 0,
  class: "px-2 pb-2"
}
const _hoisted_5 = { class: "flex items-center justify-between py-1 px-1 lg:px-2" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { id: "instructor-headshot" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "offer-amount lg:px-3 text-center inline-flex items-center",
  style: { color: '#FFBC00' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('handleClick'))),
    class: _normalizeClass(["nova-product-card lg:m-4 rounded shadow overflow-hidden max-h-96 max-w-xs cursor-pointer hover:shadow-md transform transition duration-300 hover:scale-105", [
      _ctx.sliderCard ? '' : 'lg:m-0',
      _ctx.activeProduct && _ctx.activeProduct === _ctx.productId
        ? 'border-blue-500'
        : 'border-gray-300',
      !_ctx.upsellProduct ? 'ml-0' : '',
      _ctx.newMobileScreens ? '' : 'border',
    ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["product-image w-full overflow-hidden h-auto relative", [
        _ctx.sliderCard && 'product-modal-image',
        _ctx.newMobileScreens ? '' : 'border-b',
      ]])
    }, [
      _createElementVNode("img", {
        class: "w-full h-full object-contain",
        src: 
          _ctx.replaceBucketUrlWithCdnUrl(_ctx.posterImage) ||
          _ctx.replaceBucketUrlWithCdnUrl(_ctx.defaultPosterImage)
        ,
        alt: "Product Poster Image"
      }, null, 8, _hoisted_1),
      (_ctx.newMobileScreens)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1", _ctx.upsellProduct ? 'hidden' : 'lg:hidden'])
          }, [
            (_ctx.label && _ctx.label === 'in-library')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["offer-amount px-1 rounded text-white", [
            _ctx.upsellProduct ? 'text-xxs' : 'text-xs',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : '',
          ]])
                }, " In Library ", 2))
              : _createCommentVNode("", true),
            (_ctx.label && _ctx.label === 'locked')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_BaseIcon, {
                    name: "lockfilled",
                    hwClass: "w-4 h-4"
                  })
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["product-details lg:my-1 lg:py-2", [
        _ctx.newMobileScreens ? '' : 'h-40',
        {
          'h-28 md:h-32 lg:h-36 py-2': _ctx.sliderCard,
          'h-24 md:h-24': _ctx.upsellProduct,
        },
      ]])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["font-semibold product-title", 
            _ctx.upsellProduct || _ctx.sliderCard || _ctx.newMobileScreens
              ? 'text-sm'
              : 'text-lg'
          ])
        }, _toDisplayString(_ctx.truncateText(_ctx.productTitle)), 3)
      ]),
      (!_ctx.newMobileScreens)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.productDescription)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["text-gray-800 product-title", _ctx.upsellProduct ? 'text-xs' : 'text-sm'])
                }, _toDisplayString(_ctx.truncateText(_ctx.productDescription, 75)), 3))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.newMobileScreens || _ctx.upsellProduct || _ctx.sliderCard)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["w-full absolute bottom-0 block", ['border-t pt-1', _ctx.upsellProduct && 'border-gray-200']])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  class: _normalizeClass(["rounded-full", [
                _ctx.upsellProduct || _ctx.sliderCard ? 'w-8' : 'w-12',
                _ctx.instructorHeadshot && 'border',
              ]]),
                  src: 
                _ctx.replaceBucketUrlWithCdnUrl(
                  _ctx.instructorHeadshot || _ctx.userAvatarImage
                )
              ,
                  alt: "Instructor"
                }, null, 10, _hoisted_8)
              ]),
              _createElementVNode("div", {
                id: "instructor-name",
                class: _normalizeClass(["font-semibold text-blue-600", _ctx.upsellProduct ? 'text-xxs px-1' : 'text-sm px-2'])
              }, _toDisplayString(_ctx.truncateText(
                _ctx.instructorName || 'Instructor Name',
                _ctx.instructorNameLimit
              )), 3)
            ]),
            (
            _ctx.label &&
            _ctx.label === 'in-library' &&
            (!_ctx.upsellProduct || !_ctx.newMobileScreens || _ctx.sliderCard)
          )
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(["offer-amount py-1 rounded text-white", [
            _ctx.upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primary : 'bg-in-library',
          ]])
                }, " In Library ", 2))
              : _createCommentVNode("", true),
            (
            _ctx.label && _ctx.label === 'in-library' && _ctx.upsellProduct && _ctx.newMobileScreens
          )
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(["offer-amount py-1 rounded text-white inline-flex items-center", [
            _ctx.upsellProduct ? 'text-xxs px-1' : 'text-sm px-2',
            _ctx.newMobileScreens ? _ctx.getMobileTheme.primaryText : 'bg-in-library',
          ]])
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: "book",
                    hwClass: "w-4 h-4"
                  })
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.label && _ctx.label === 'locked')
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  _createVNode(_component_BaseIcon, {
                    name: "lockfilled",
                    hwClass: "w-4 h-4"
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}