<script lang="ts" setup>
import BaseIcon from "@/components/svgicons/BaseIcon.vue";
import { SearchSmIcon } from "@gohighlevel/ghl-icons/24/outline";
import { UIDrawer, UIDrawerContent } from "@gohighlevel/ghl-ui";
import { debounce } from "lodash";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import GlobalSearchShimmerV2 from "../../components/shimmer/mobile/GlobalSearchShimmerV2.vue";
import { getHighlightedDescription } from "@/helper";
import { nextTick, onMounted } from "vue";
import {
  fileIcon,
  folderIcon,
  LEVELUP_LOCATION_IDS,
  LIBRARY_MENU_ITEMS,
  productIcon,
} from "../../helper/constants";
import { UniversalSearchService } from "../../services";

const store = useStore();
const route = useRoute();
const router = useRouter();
const openSearchDrawer = computed(() => store.getters.openSearchDrawer);
const searchText = ref<string>("");

const limit = ref(30);
const page = ref(1);
const totalSearchCount = ref(0);
const searchData = ref([]);
const loading = ref(false);
const isSearchDataAvailable = ref(true);
const recentSearches = ref([]);
const searchIds = ref([]);
const productTitleMapping = ref({});
const uniqueProductIds = ref([]);
const allDataLoaded = ref(false);
const error = ref("");

const searchIcons = {
  product: productIcon,
  category: folderIcon,
  post: fileIcon,
};
const isLevelUpItem = computed(() => true)
// return LEVELUP_LOCATION_IDS.includes(store.getters.locationId))
const observer = ref<IntersectionObserver | null>(null);

const createDebounce = debounce(() => {

  searchContent();
}, 500);

onMounted(async () => {
  recentSearches.value = JSON.parse(window.localStorage.getItem("searchItems") || "[]");
  observer.value = new IntersectionObserver(loadMore);
  if (!allDataLoaded.value) {
    await nextTick();
    const loadMoreRef = document.querySelector("#loadMore");
    if (loadMoreRef) {
      observer.value.observe(loadMoreRef);
    }
  }
});

async function searchFullText() {
  loading.value = true;
  const payload = {
    searchKey: searchText.value,
    pageLimit: limit.value,
    pageNumber: page.value,
  };
  const newData = await UniversalSearchService.searchItems(payload);
  const productIds = [];

  allDataLoaded.value = !newData.length;
  page.value = page.value + 1;
  if (newData.length) {
    for (const data of newData) {
      if (!searchIds.value.includes(data.typeId)) {
        if (!uniqueProductIds.value.includes(data.productId)) {
          uniqueProductIds.value.push(data.productId);
          productIds.push(data.productId);
        }
        if (data.description) {
          data.description = getHighlightedDescription(
            data.description,
            searchText.value.split(" "),
            0
          );
        }
        searchData.value.push(data);
        searchIds.value.push(data.typeId);
      }
    }
    if (productIds.length) {
      await fetchProductTitle(productIds);
    }
    isSearchDataAvailable.value = true;
    const loadMoreRef = document.querySelector("#loadMore");
    if (loadMoreRef) {
      observer.value.observe(loadMoreRef);
    }
  } else {
    isSearchDataAvailable.value = false;
  }
  loading.value = false;
}

function searchContent() {
  resetData();
  if (!searchText.value) {
    isSearchDataAvailable.value = true;
    return;
  }
  searchFullText();
}

function resetData() {
  page.value = 1;
  searchData.value = [];
  searchIds.value = [];
  productTitleMapping.value = {};
  uniqueProductIds.value = [];
}

async function fetchProductTitle(productIds: string[]) {
  const products = await UniversalSearchService.getProductTitles({
    productIds: productIds,
  });
  productTitleMapping.value = { ...productTitleMapping.value, ...products };
}

function handleOpenSearchDrawer() {
  store.commit("SET_SEARCH_DRAWER_OPEN", !openSearchDrawer.value);
}

function changeRoute(option: any) {
  recentSearches.value = JSON.parse(window.localStorage.getItem("searchItems") || "[]");
  if (!recentSearches.value.includes(searchText.value.toLowerCase())) {
    if (recentSearches.value.length === 3) recentSearches.value.pop();
    recentSearches.value.splice(0, 0, searchText.value.toLowerCase());
  }
  window.localStorage.setItem("searchItems", JSON.stringify(recentSearches.value));
  searchText.value = "";
  searchData.value = [];
  router.push({
    name:
      option.type === "product" || option.type === "category"
        ? isLevelUpItem.value ? "category-list-levelup" : "category-list"
        : "post-overview",
    params: {
      id: option.productId,
      category_id: option.categoryId,
      post_id: option.typeId,
    },
    query: { ...route.query, category_id: option.categoryId, selected_tab: 'syllabus', from_search: 'true' },
  });
  handleOpenSearchDrawer();
  store.commit("UPDATE_ACTIVE_LIBRARY", LIBRARY_MENU_ITEMS.allCourses);
}

async function searchCount() {
  const payload = {
    searchKey: searchText.value,
  };
  const { totalCount } = await UniversalSearchService.searchCount(payload);
  totalSearchCount.value = totalCount;
}

async function loadMore([{ isIntersecting, target }]: IntersectionObserverEntry[]) {
  if (isIntersecting) {
    const ul = target.offsetParent;
    const scrollTop = target.offsetParent.scrollTop;
    await searchFullText();
    if (ul) {
      ul.scrollTop = scrollTop;
    }
  }
}
</script>

<template>
  <UIDrawer :show="openSearchDrawer" :placement="'right'" :id="'drawer'" :displayDirective="'if'"
    :on-mask-click="handleOpenSearchDrawer" :style="{ width: '100dvw' }">
    <UIDrawerContent id="drawer-component" :closable="false">
      <div class="w-full border-b border-[#E4E4E4] px-2 gap-4 flex items-center search-navigation">
        <BaseIcon name="chevronleft" hwClass="w-6 h-6" @click="handleOpenSearchDrawer" class="flex-shrink-0" />

        <div class="flex-grow flex items-center justify-start mx-auto my-4 border rounded-full border-[#EAECF0]">
          <SearchSmIcon class="w-4 h-4 text-gray-500 mx-3 my-3" />
          <input class="outline-none border-none w-11/12 placeholder:text-sm text-sm placeholder:text-start"
            placeholder="Search products, categories and lessons" v-model.trim="searchText" @input="createDebounce()"
            aria-label="Search" type="text" maxlength="64" id="search" />
        </div>
      </div>
      <div class="w-11/12 mx-auto my-4">
        <div v-if="searchText" id="searchList">
          <div class="py-2 flex justify-center items-center text-gray-700" v-if="loading && !isSearchDataAvailable"
            ref="loadMore">
            <GlobalSearchShimmerV2 />
          </div>
          <div v-else-if="!loading && searchData.length" id="optionList" class="block py-2">
            <div
              class="searchList flex flex-col my-2 p-2 text-gray-800 cursor-pointer border-gray-50 rounded-lg bg-gray-50 hover:bg-gray-100 leading-5 gap-4"
              v-for="option in searchData" :key="option.typeId" @click.prevent="changeRoute(option)">
              <div class="p-2 text-left flex justify-between">
                <span class="text-gray-900 text-sm">{{
                  option.title.length > 50
                    ? `${option.title.substring(0, 40)}...`
                    : option.title
                }}</span>
                <img class="text-gray-700 h-5" :src="searchIcons[option.type]" alt="product icon" />
              </div>
              <div v-if="option.description" v-html="option.description" class="px-2 text-left text-xs h-auto"></div>
              <div v-if="option.type !== 'product'" class="product-title px-2 text-left font-semibold text-xs"
                :class="option.description ? 'p-2' : 'pb-2'">
                {{ productTitleMapping[option.productId] }}
              </div>
            </div>
          </div>
          <div v-else-if="!searchText || !loading" class="block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700">
            No results for
            <span class="font-semibold">"{{ searchText }}"</span>. Please try another
            search
          </div>
        </div>
        <div v-else-if="recentSearches.length" class="p-2 text-gray-700">
          <div class="text-left text-[#101828]">Recent Searches</div>
          <div
            class="searchList flex flex-col my-2 pr-6 p-2 text-[#909090] cursor-pointer border-gray-200 border-b leading-5"
            v-for="(option, index) in recentSearches" :key="index" @click.prevent="() => {
              searchText = option;
              searchContent();
            }
              ">
            <div class="py-1 px-2 text-left">
              {{ option }}
            </div>
          </div>
        </div>
        <div v-else class="p-2 text-gray-700">No Recent Searches</div>
      </div>
    </UIDrawerContent>
  </UIDrawer>
</template>

<style>
@media (max-width: 1024px) {
  .n-drawer-body-content-wrapper {
    box-sizing: border-box;
    padding-inline: 0px !important;
    padding-block: 0px !important;
  }
}
</style>
