<template>
  <div class="flex items-center h-full">
    <div
      class="ld"
      :class="customCss"
      :style="{ 'border-left': `1.1em solid ${color}` }"
    ></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'UISpinner',
  props: {
    color: {
      type: String,
      default: '#38a0db',
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    customCss(): string {
      if (this.size === 'extra-small') return 'ld-xs'
      if (this.size === 'small') return 'ld-sm'
      if (this.size === 'large') return 'ld-lg'
      if (this.size === 'extra-large') return 'ld-xl'
      return 'ld-md'
    },
  },
})
</script>
<style scoped>
.ld,
.ld:after {
  border-radius: 50%;
}
.ld-xs,
.ld-xs:after {
  width: 6em;
  height: 6em;
}
.ld-sm,
.ld-sm:after {
  width: 7em;
  height: 7em;
}
.ld-md,
.ld-md:after {
  width: 9em;
  height: 9em;
}
.ld-lg,
.ld-lg:after {
  width: 11em;
  height: 11em;
}
.ld-xl,
.ld-xl:after {
  width: 13em;
  height: 13em;
}
.ld {
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(127, 209, 222, 0.2);
  border-right: 1.1em solid rgba(127, 209, 222, 0.2);
  border-bottom: 1.1em solid rgba(127, 209, 222, 0.2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
