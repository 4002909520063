import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "progress-bar-container",
  class: "w-full"
}
const _hoisted_2 = {
  key: 0,
  id: "progress-bar-percentage",
  class: "text-right mt-2 text-sm font-normal text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "progress-bar-background",
      class: _normalizeClass(["relative w-full bg-gray-200 rounded-full overflow-hidden", _ctx.heightClass])
    }, [
      _createElementVNode("div", {
        id: "progress-bar-fill",
        class: _normalizeClass(["h-full transition-all duration-300 ease-out", _ctx.colorClass]),
        style: _normalizeStyle({ width: _ctx.progressWidth })
      }, null, 6)
    ], 2),
    (_ctx.showPercentage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(Math.round(_ctx.progress)) + "% ", 1))
      : _createCommentVNode("", true)
  ]))
}