import http from '../../http-common'

interface LoginPayload {
  email: string
  password: string
  deviceId: string
  deviceName: string
  deviceType: string
  hostName: string
  captchaToken?: string
}

interface PasswordRecoveryPayload {
  email: string
  hostName: string
}

class LoginService {
  login(payload: LoginPayload) {
    return http.post(`user/login`, payload)
  }
  recovery(payload: PasswordRecoveryPayload) {
    return http.post(`user/recovery`, payload)
  }
  brand(params: any) {
    return http.get(`brand`, { params })
  }
  updatePassword(payload: any) {
    return http.post('user/update-password', payload)
  }
  updateContact(payload: any) {
    return http.put('user/contact', payload)
  }
  getAvatar(): Promise<string> {
    return http.get('user/avatar').then((res) => res.data.avatar)
  }
  setAvatar(avatar: string) {
    return http.post('user/avatar', { avatar })
  }
  setPassword(payload: any) {
    return http.post('user/password-set', payload)
  }
  checkIfPasswordSet(payload: any) {
    return http.post(`user/check-password-set`, payload)
  }
  checkIfPasswordSessionValid(token: string) {
    return http.get(`user/check-session-valid?token=${token}`)
  }
  forgetPassword(payload: PasswordRecoveryPayload) {
    return http.post(`user/forgot-password`, payload)
  }
}

export default new LoginService()
