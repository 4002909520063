<template>
  <div
    class="py-4 px-4 w-full flex bg-white justify-between items-center border-gray-200"
    :class="activePost.video && 'lg:px-10'"
  >
    <button
      :disabled="!previousPostData"
      @click="gotoPreviousPost"
      class="flex justify-center items-center"
      :class="!previousPostData ? 'text-gray-300' : getMobileTheme.primaryText"
    >
      <BaseIcon name="previousarrow" />
    </button>
    <div
      class="mobile-post-status-fetch-message md:w-3/12 lg:w-4/12 flex justify-center md:justify-end items-center"
      v-if="fetchingPostCompletionStatus"
    >
      Fetching post status..
    </div>
    <div
      v-else
      class="mx-2 lg:pt-0 bg-transparent lg:w-3/12 lg:w-4/12 flex justify-center lg:justify-end items-center"
      :disabled="disablePostCompletionButton || isAdmin"
    >
      <button
        @click="togglePostCompletion"
        :disabled="disablePostCompletionButton || isAdmin"
        class="cursor-pointer rounded flex justify-center items-center"
        :class="markCompletedBtnCss"
      >
        <BaseIcon name="correct" hwClass="inline-block w-5" />
        <span class="px-2">{{ postCompletionButtonText }}</span>
      </button>
    </div>
    <button
      :disabled="!nextPostData"
      @click="goToNextPost"
      class="flex justify-center items-center"
      :class="!nextPostData ? 'text-gray-300' : getMobileTheme.primaryText"
    >
      <BaseIcon name="nextarrow" />
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getMobileTheme } from '@/helper'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'

export default defineComponent({
  components: {
    BaseIcon,
  },
  props: {
    activePost: {
      type: Object,
    },
    previousPostData: {
      type: Object,
    },
    fetchingPostCompletionStatus: {
      type: Boolean,
    },
    disablePostCompletionButton: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
    },
    postCompletionButtonText: {
      type: String,
    },
    postMarkedAsCompleted: {
      type: Boolean,
    },
    nextPostData: {
      type: Object,
    },
  },
  methods: {
    gotoPreviousPost() {
      this.$emit('gotoPreviousPost')
    },
    goToNextPost() {
      this.$emit('goToNextPost')
    },
    togglePostCompletion() {
      this.$emit('togglePostCompletion')
    },
  },
  computed: {
    markCompletedBtnCss() {
      if (this.disablePostCompletionButton || this.isAdmin) {
        return 'cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'text-green-700'
      }
      return getMobileTheme().primaryText
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
})
</script>
