import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductsThemeController = _resolveComponent("ProductsThemeController")!
  const _component_UISpinner = _resolveComponent("UISpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.enableNeoTheme)
      ? (_openBlock(), _createBlock(_component_ProductsThemeController, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$props)), null, 16))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.isPortalNavbar ? 'pt-10' : '')
        }, [
          (_ctx.fetching || _ctx.fetchingProduct)
            ? (_openBlock(), _createBlock(_component_UISpinner, {
                key: 0,
                class: "flex justify-center items-center h-screen",
                size: "large"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(`${_ctx.selectedTheme}Template`), {
                  heroSection: _ctx.heroSection,
                  product: _ctx.product,
                  categoryTree: _ctx.categoryTree,
                  postIdsCompleted: _ctx.postIdsCompleted,
                  categoryIdsCompleted: _ctx.categoryIdsCompleted,
                  completedPosts: _ctx.completedPosts,
                  totalPosts: _ctx.totalPosts,
                  instructor: _ctx.instructor,
                  nextPost: _ctx.nextPost,
                  productStarted: _ctx.productStarted,
                  logo: _ctx.logo,
                  hasProductAccess: _ctx.hasProductAccess,
                  onGoToNextPost: _ctx.goToNextPost
                }, null, 40, ["heroSection", "product", "categoryTree", "postIdsCompleted", "categoryIdsCompleted", "completedPosts", "totalPosts", "instructor", "nextPost", "productStarted", "logo", "hasProductAccess", "onGoToNextPost"]))
              ]))
        ], 2))
  ]))
}