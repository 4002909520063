import 'firebase/auth'

import {
  DashBoard,
  NotificationMobileView,
  ProfileContainer,
} from '@gohighlevel/clientportal-core'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { deviceId, isMobileTablet } from '../helper/device_info'
import { fetchAdminCat, fetchCat } from '@/helper/permission.helper'
import { getQueryString, isClientPortal, redirect, redirectTo } from '@/helper'
import {
  initialize,
  saveContactId,
  saveFeatureFlag,
  saveUserId,
} from '@/services'

import CategoryDetail from '@/views/CategoryDetail.vue'
import CategoryList from '@/views/CategoryList.vue'
import CategoryListV2 from '@/views/mobile/CategoryListV2.vue'
import LoginService from '@/services/LoginService'
import MobileUpsellBundlePage from '@/views/MobileUpsellBundlePage.vue'
import NewLibrary from '@/views/NewLibrary.vue'
import Post from '@/views/Post.vue'
import ProductContainer from '@/views/ProductContainer.vue'
import Products from '@/views/Products.vue'
import SecureRoutes from '@/views/SecureRoutes.vue'
import axios from 'axios'
import config from '../config'
import firebase from 'firebase/app'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('../views/PasswordRecovery.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/set-password',
    name: 'set-password',
    component: () => import('../views/SetPassword.vue'),
    meta: {
      requiresGuest: true,
    },
    props: (route) => ({
      contactId: route.query.contact_id,
      locationId: route.query.location_id,
      type: route.query.type,
    }),
  },
  {
    path: '/',
    component: SecureRoutes,
    children: [
      {
        path: 'library',
        name: 'library',
        component: () => import('../views/Library.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2',
        name: 'library-v2',
        component: NewLibrary,
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
        props: (route) => ({ courses: route.query.courses }),
      },
      {
        path: 'library-v2/my-courses',
        name: 'my-courses-library',
        component: () => import('../views/NewLibrary.vue'),
        props: {
          loadMyCourses: true,
        },
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle/:offerId/:upsellId',
        name: 'upsell-bundle',
        component: () => import('../views/MobileUpsellBundlePage.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle-v2/:offerId/:upsellId',
        name: 'upsell-bundle-levelup',
        component: () => import('../views/mobile/MobileUpsellBundleV2.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/settings/index.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'profile-settings',
            component: () => import('../views/settings/Profile.vue'),
          },
          {
            path: 'password',
            name: 'password-settings',
            component: () => import('../views/settings/Password.vue'),
          },
          {
            path: 'purchase-history',
            name: 'purchase-history',
            component: () => import('../views/settings/PurchaseHistory.vue'),
          },
        ],
      },
      {
        path: 'products',
        component: ProductContainer,
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
        children: [
          {
            path: ':id',
            name: 'product-overview',
            component: () => import('../views/ProductsThemeController.vue'),
          },
          {
            path: ':id/categories',
            name: 'category-list',
            component: CategoryList,
          },
          {
            path: ':id/categories-v2',
            name: 'category-list-levelup',
            component: CategoryListV2,
          },
          {
            path: ':id/categories/:category_id',
            name: 'category-overview',
            component: CategoryDetail,
          },
          {
            path: ':id/categories/:category_id/posts/:post_id',
            name: 'post-overview',
            component: Post,
            children: [
              {
                path: 'comments',
                name: 'post-comments',
                component: () =>
                  import('../components/comments/MobileComments.vue'),
              },
              {
                path: 'mobile-quiz-overview',
                name: 'mobile-quiz-overview',
                component: () =>
                  import(
                    '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-quiz-overview/:quiz_id',
                name: 'mobile-quiz-questions',
                component: () =>
                  import('../components/assessments/MobileQuestions.vue'),
              },
              {
                path: 'quiz-result/:quiz_id/',
                name: 'mobile-quiz-result',
                component: () =>
                  import('../components/assessments/MobileQuizResult.vue'),
              },
              {
                path: 'mobile-quiz-overview-v2/:quiz_id',
                name: 'mobile-quiz-questions-levelup',
                component: () => import('../views/mobile/MobileQuizV2.vue'),
              },
              {
                path: 'mobile-assignment',
                name: 'mobile-assignment',
                component: () =>
                  import(
                    '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-assignment-overview',
                name: 'mobile-assignment-overview',
                component: () => import('../views/Assignment.vue'),
              },
              {
                path: 'mobile-assignment-overview-v2',
                name: 'mobile-assignment-overview-levelup',
                component: () => import('../views/Assignment.vue'),
              },
              {
                path: 'mobile-assignment-result',
                name: 'mobile-assignment-result',
                component: () =>
                  import(
                    '../components/assessments/MobileAssignmentResult.vue'
                  ),
              },
              {
                path: 'v2/assignment',
                name: 'new-assignment-overview',
                component: () =>
                  import(
                    '../components/neo-classic-theme/assignment/AssignmentAttempt.vue'
                  ),
              },
              {
                path: 'v2/quiz',
                name: 'new-quiz-overview',
                component: () =>
                  import('@/components/neo-classic-theme/quiz/QuizAttempt.vue'),
              },
            ],
          },
          {
            path: ':id/categories/:category_id/posts/:post_id/materials',
            name: 'post-materials',
            component: () => import('../views/MobilePostMaterials.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/quiz',
            name: 'quiz-overview',
            component: () => import('../views/Quiz.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/assignment',
            name: 'assignment-overview',
            component: () => import('../views/Assignment.vue'),
          },
        ],
      },
      {
        path: '/upsell-offers/in-app/:id',
        name: 'in-app-upsell',
        component: () => import('../views/UpsellCheckout.vue'),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
      },
    ],
  },
  {
    path: '/offers/:id',
    name: 'offer',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/upsell-offers/one-click/:id',
    name: 'one-click-upsell',
    component: () => import('../views/UpsellCheckout.vue'),
  },
  {
    path: '/courses/profile',
    name: 'ProfileContainer',
    component: ProfileContainer,
  },
  {
    path: '/courses/notifications',
    name: 'NotificationMobileView',
    component: NotificationMobileView,
  },
  {
    path: '/courses/home',
    name: 'LandingPage',
    component: DashBoard,
  },
  { path: '/404', component: () => import('../views/NotFound.vue') },
  { path: '/:catchAll(.*)', redirect: '/404' },
]

const routesV2: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login-v2',
    component: () => import('../views/Login.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/courses/recovery',
    name: 'recovery-v2',
    component: () => import('../views/PasswordRecovery.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/courses/',
    component: SecureRoutes,
    children: [
      {
        path: 'library',
        name: 'library-old-v2',
        component: () => import('../views/Library.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2',
        name: 'library-v2-v2',
        component: NewLibrary,
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'library-v2/my-courses',
        name: 'my-courses-library-v2',
        component: () => import('../views/NewLibrary.vue'),
        props: {
          loadMyCourses: true,
        },
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle/:offerId/:upsellId',
        name: 'upsell-bundle-v2',
        component: MobileUpsellBundlePage,
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'upsell-bundle-v2/:offerId/:upsellId',
        name: 'upsell-bundle-levelup-v2',
        component: () => import('../views/mobile/MobileUpsellBundleV2.vue'),
        meta: {
          requiresAuth: true,
          clientAuth: true,
        },
      },
      {
        path: 'settings',
        name: 'settings-v2',
        component: () => import('../views/settings/index.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'profile-settings-v2',
            component: () => import('../views/settings/Profile.vue'),
          },
          {
            path: 'password',
            name: 'password-settings-v2',
            component: () => import('../views/settings/Password.vue'),
          },
          {
            path: 'purchase-history',
            name: 'purchase-history-v2',
            component: () => import('../views/settings/PurchaseHistory.vue'),
          },
        ],
      },
      {
        path: 'products',
        component: ProductContainer,
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
        children: [
          {
            path: ':id',
            name: 'product-overview-v2',
            component: Products,
          },
          {
            path: ':id/categories',
            name: 'category-list-v2',
            component: CategoryList,
          },
          {
            path: ':id/categories-v2',
            name: 'category-list-levelup-v2',
            component: CategoryListV2,
          },
          {
            path: ':id/categories/:category_id',
            name: 'category-overview-v2',
            component: CategoryDetail,
          },
          {
            path: ':id/categories/:category_id/posts/:post_id',
            name: 'post-overview-v2',
            component: Post,
            meta: {
              breadcrumb: [
                { name: 'Home', link: '/courses/products/:id' },
                {
                  name: 'post',
                  link: '/courses/products/:id/categories/:category_id',
                },
              ],
            },
            children: [
              {
                path: 'comments',
                name: 'post-comments-v2',
                component: () =>
                  import('../components/comments/MobileComments.vue'),
              },

              {
                path: 'mobile-quiz-overview',
                name: 'mobile-quiz-overview-v2',
                component: () =>
                  import(
                    '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },

              {
                path: 'mobile-quiz-overview/:quiz_id',
                name: 'mobile-quiz-questions-v2',
                component: () =>
                  import('../components/assessments/MobileQuestions.vue'),
              },
              {
                path: 'quiz-result/:quiz_id/',
                name: 'mobile-quiz-result-v2',
                component: () =>
                  import('../components/assessments/MobileQuizResult.vue'),
              },
              {
                path: 'mobile-quiz-overview-v2/:quiz_id',
                name: 'mobile-quiz-questions-levelup-v2',
                component: () => import('../views/mobile/MobileQuizV2.vue'),
              },
              // {
              //   path: 'quiz-result-v2/:quiz_id/',
              //   name: 'mobile-quiz-result-v2-levelup-v2',
              //   component: () =>
              //     import('../views/mobile/MobileQuizResultV2.vue'),
              // },
              {
                path: 'mobile-assignment',
                name: 'mobile-assignment-v2',
                component: () =>
                  import(
                    '../components/assessments/MobileAssessmentInitiate.vue'
                  ),
              },
              {
                path: 'mobile-assignment-overview',
                name: 'mobile-assignment-overview-v2',
                component: () => import('../views/Assignment.vue'),
              },
              {
                path: 'mobile-assignment-overview-v2',
                name: 'mobile-assignment-overview-levelup-v2',
                component: () => import('../views/Assignment.vue'),
              },
              {
                path: 'mobile-assignment-result',
                name: 'mobile-assignment-result-v2',
                component: () =>
                  import(
                    '../components/assessments/MobileAssignmentResult.vue'
                  ),
              },
              {
                path: 'v2/assignment',
                name: 'new-assignment-overview-v2',
                component: () =>
                  import(
                    '../components/neo-classic-theme/assignment/AssignmentAttempt.vue'
                  ),
              },
              {
                path: 'v2/quiz',
                name: 'new-quiz-overview-v2',
                component: () =>
                  import('@/components/neo-classic-theme/quiz/QuizAttempt.vue'),
              },
            ],
          },
          {
            path: ':id/categories/:category_id/posts/:post_id/materials',
            name: 'post-materials-v2',
            component: () => import('../views/MobilePostMaterials.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/quiz',
            name: 'quiz-overview-v2',
            component: () => import('../views/Quiz.vue'),
          },
          {
            path: ':id/categories/:category_id/post/:post_id/assignment',
            name: 'assignment-overview-v2',
            component: () => import('../views/Assignment.vue'),
          },
        ],
      },
      {
        path: 'upsell-offers/in-app/:id',
        name: 'in-app-upsell-v2',
        component: () => import('../views/UpsellCheckout.vue'),
        meta: {
          requiresAuth: true,
          adminUser: true,
        },
      },
    ],
  },
  {
    path: '/courses/offers/:id',
    name: 'offer-v2',
    component: () => import('../views/Checkout.vue'),
  },
  {
    path: '/courses/upsell-offers/one-click/:id',
    name: 'one-click-upsell-v2',
    component: () => import('../views/UpsellCheckout.vue'),
  },
  {
    path: '/courses/profile',
    name: 'ProfileContainer-v2',
    component: ProfileContainer,
  },
  {
    path: '/courses/notifications',
    name: 'NotificationMobileView-v2',
    component: NotificationMobileView,
  },
  {
    path: '/courses/home',
    name: 'LandingPage-v2',
    component: DashBoard,
  },
  { path: '/courses/404', component: () => import('../views/NotFound.vue') },
  { path: '/courses/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...routes, ...routesV2],
})

router.beforeEach(async (to, from, next) => {
  let cat
  let acat
  try {
    cat = await fetchCat()
    acat = await fetchAdminCat()
  } catch (error) {
    cat = undefined
  }
  const externalUserId =
    (cat ? cat.external_user_id : null) || (cat ? cat.externalUserId : null)
  const enableClientPortal = isClientPortal() && externalUserId

  if (to.matched.some((record) => record.meta.requiresGuest)) {
    if ((cat && cat.userId) || enableClientPortal) {
      return next({
        name: 'library-v2',
      })
    } else if (
      to.query.loginCode &&
      (to.query.user || to.query.email) &&
      !(to.query.type || to.query.session)
    ) {
      try {
        let user, type
        const setPasswordPayload: any = {
          hostName: to.query.domain || window.location.hostname,
        }
        if (to.query.user) {
          user = to.query.user
          type = 'id'
          setPasswordPayload.userId = user
        }
        if (to.query.email) {
          user = getQueryString(window.location.search, 'email')
          type = 'email'
          setPasswordPayload.email = user
        }
        const { data: passwordData } = await LoginService.checkIfPasswordSet(
          setPasswordPayload
        )
        const passwordSet = passwordData.passwordSet
        const userId = passwordData.userId
        const email = passwordData.email
        if (!passwordSet) {
          return redirectTo(
            'set-password',
            `email=${email.replace(/\+/g, '%2B')}&loginCode=${
              to.query.loginCode
            }&userId=${userId || user}&type=new`
          )
        }
        const { data } = await axios.post(
          `${config.newBaseUrl}/user/login-code/${to.query.loginCode}`,
          {
            user: user,
            type: type,
            deviceName: navigator.userAgent,
            deviceId: deviceId,
            deviceType: isMobileTablet() ? 'mobile' : 'desktop',
            hostName: to.query.domain || window.location.hostname,
          }
        )
        if (data.token) {
          const payload = btoa(JSON.stringify(data))
          if (localStorage) {
            localStorage.setItem('cat', payload)
          }

          initialize(data.locationId)
          saveUserId(data.userId)
          saveContactId(data.contactId)
          saveFeatureFlag(data.featureFlag)
          const redirectUrl = getQueryString(
            window.location.search,
            'redirectUrl'
          )
          if (redirectUrl) {
            return window.open(redirectUrl, '_self')
          } else {
            return next({
              name: 'library-v2',
            })
          }
        }
      } catch (e) {
        console.error('Error ---->', e)
        if (isClientPortal()) return redirectTo('login')
        return next({
          name: 'login',
        })
      }
      return redirect(to, from, next)
    }
  } else if (to.matched.some((record) => record.meta.adminUser)) {
    const { location_id: locationId, token } = to.query
    // need to handle for checkouts from client portal side
    if (
      !token &&
      !window.location.href.includes('source=communities') &&
      !acat &&
      !cat
    ) {
      return redirectTo('login')
    }
    if (token) {
      const { data } = await axios.post(
        config.newBaseUrl + '/user/login-token',
        {
          locationId,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      await firebase.auth().signInWithCustomToken(data.token)

      const payload = btoa(JSON.stringify(data))
      if (localStorage) {
        localStorage.setItem('acat', payload)
      }
      window.$cookies.set('acat', payload)
    }
    return redirect(to, from, next)
  } else if (
    to.matched.some((record) => record.meta.clientAuth) &&
    to.query.token
  ) {
    const { location_id: locationId, token } = to.query

    // need to handle for library and upsell bundle page in client portal
    if (token) {
      await axios
        .post(config.newBaseUrl + '/user/login-ctoken', {
          locationId,
          ct: token,
        })
        .then(async ({ data }) => {
          await firebase.auth().signInWithCustomToken(data.token)

          const payload = btoa(JSON.stringify(data))
          if (localStorage) {
            localStorage.setItem('cat', payload)
          }
          window.$cookies.set('cat', payload)
        })
        .catch(() => {
          if (isClientPortal()) return redirectTo('login')
          next({
            name: 'login',
          })
        })
    }
    return redirect(to, from, next)
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!cat && !acat) {
      if (isClientPortal()) return redirectTo('login')
      return next({
        name: 'login',
      })
    }
    return redirect(to, from, next)
  }
  if (isClientPortal()) return redirect(to, from, next)
  else next()
})

export default router
