import Model from './Model'
import Offer from './Offer'
import ProductCustomization from './ProductCustomization'

export enum CommentPrivacy {
  public = 'public',
  instructorOnly = 'instructorOnly',
  publicAndInstructor = 'publicAndInstructor',
}

export default class Product extends Model {
  constructor(payload?: {
    title?: string
    description?: string
    posterImage?: string
    customizations?: { [key: string]: any }
    libraryOrder?: number
  }) {
    super(payload || {})

    if (payload && payload.customizations) {
      payload.customizations = new ProductCustomization(payload.customizations)
    }
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get description(): string {
    return this._data.description
  }

  set description(description: string) {
    this._data.description = description
  }

  get posterImage(): string {
    return this._data.posterImage
  }

  set posterImage(posterImage: string) {
    this._data.posterImage = posterImage
  }

  get offers(): Array<Offer> {
    return this._data.offers
  }

  set offers(offers: Array<Offer>) {
    this._data.offers = offers
  }

  get customizations(): ProductCustomization {
    return this._data.customizations
  }

  set customizations(customizations: ProductCustomization) {
    this._data.customizations = customizations
  }

  get customJs(): string {
    return this._data.customJs
  }

  set customJs(customJs: string) {
    this._data.customJs = customJs
  }

  get customCss(): string {
    return this._data.customCss
  }

  set customCss(customCss: string) {
    this._data.customCss = customCss
  }

  get libraryOrder(): number {
    return this._data.libraryOrder
  }

  set libraryOrder(libraryOrder: number) {
    this._data.libraryOrder = libraryOrder
  }

  get customHeader(): string {
    return this._data.customHeader
  }

  set customHeader(customHeader: string) {
    this._data.customHeader = customHeader
  }

  get customFooter(): string {
    return this._data.customFooter
  }

  set customFooter(customFooter: string) {
    this._data.customFooter = customFooter
  }

  get commentPrivacy(): string {
    return this._data.commentPrivacy
  }

  set commentPrivacy(commentPrivacy: string) {
    this._data.commentPrivacy = commentPrivacy
  }
}
