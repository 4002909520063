<template>
  <div class="editor-content rich-text-viewer" v-html="processedHtml"></div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

// DO NOT REMOVE BELOW IMPORTS
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Image from '@/components/neo-classic-theme/common/Image.vue'
import LinkPreview from '@/components/neo-classic-theme/common/LinkPreview.vue'

interface Props {
  html: string
  paragraphProps?: Record<string, any>
  imageProps?: Record<string, any>
  linkProps?: Record<string, any>
  replaceLinks?: boolean
  replaceAnchors?: boolean
}

const props = defineProps({
  html: {
    type: String,
    default: '',
  },
  paragraphProps: {
    type: Object,
    default: () => ({}),
  },
  imageProps: {
    type: Object,
    default: () => ({}),
  },
  linkProps: {
    type: Object,
    default: () => ({}),
  },
  replaceLinks: {
    type: Boolean,
    default: true,
  },
  replaceImages: {
    type: Boolean,
    default: true,
  },
})

const processedHtml = computed(() => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(props.html, 'text/html')
  // Modifying paragraph component will break the styling defined by the user

  // Replace <p> tags with Paragraph component
  // doc.querySelectorAll('p').forEach((p) => {
  //   const paragraphComponent = document.createElement('Paragraph')
  //   paragraphComponent.innerHTML = p.innerHTML
  //   Object.keys(props.paragraphProps || {}).forEach((key) => {
  //     paragraphComponent.setAttribute(key, props.paragraphProps[key])
  //   })
  //   p.replaceWith(paragraphComponent)
  // })

  // Replace <a> tags with LinkPreview component
  if (props.replaceLinks) {
    doc.querySelectorAll('a').forEach((a) => {
      const linkComponent = document.createElement('LinkPreview')
      linkComponent.innerHTML = a.innerHTML
      linkComponent.setAttribute('href', a.getAttribute('href') || '')
      Object.keys(props.linkProps || {}).forEach((key) => {
        linkComponent.setAttribute(key, props.linkProps[key])
      })
      a.replaceWith(linkComponent)
    })
  }

  // Replace <img> tags with Image component

  if (props.replaceImages) {
    doc.querySelectorAll('img').forEach((img) => {
      const imageComponent = document.createElement('Image')
      imageComponent.setAttribute('url', img.getAttribute('src') || '')
      Object.keys(props.imageProps || {}).forEach((key) => {
        imageComponent.setAttribute(key, props.imageProps[key])
      })
      img.replaceWith(imageComponent)
    })
  }

  return doc.body.innerHTML
})
</script>

<style scoped>
.rich-text-viewer {
  word-break: break-word;
}
</style>
