import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pr-2 flex" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex-1 pl-5" }
const _hoisted_5 = { class: "flex py-px" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = {
  key: 0,
  class: "text-black font-semibold"
}
const _hoisted_8 = {
  key: 1,
  class: "text-black font-semibold"
}
const _hoisted_9 = {
  key: 2,
  class: "text-black font-semibold"
}
const _hoisted_10 = {
  key: 0,
  class: "border-0 text-xs cursor-default rounded bg-blue-200 text-blue-700 py-px px-1"
}
const _hoisted_11 = { class: "font-xs px-2" }
const _hoisted_12 = {
  key: 3,
  class: "inline-flex items-center py-1 bg-gray-300 border rounded-full pr-1 pl-2"
}
const _hoisted_13 = { class: "px-1 text-xs privacy-label leading-3" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "cursor-pointer"
}
const _hoisted_16 = ["id", "innerHTML"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "bg-white cursor-pointer pt-4" }
const _hoisted_19 = { class: "flex text-sm pt-4" }
const _hoisted_20 = { class: "text-gray-500 text-sm p-1 flex-1" }
const _hoisted_21 = {
  key: 0,
  class: "privacy-dropdown px-2 flex items-center"
}
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = { class: "mx-1" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "transform rotate-90 inline-block" }
const _hoisted_26 = {
  key: 1,
  class: "flex py-px cursor-pointer text-gray-500"
}
const _hoisted_27 = { class: "pl-1" }
const _hoisted_28 = {
  key: 0,
  class: "cursor-pointer hover:text-blue-600 text-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UIDropdown = _resolveComponent("UIDropdown")!
  const _component_editor = _resolveComponent("editor")!
  const _component_ReplyCommentModal = _resolveComponent("ReplyCommentModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.commentElem)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              (_ctx.commentElem.userdetails && _ctx.commentElem.userdetails.avatar)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["bg-gray-500 bg-cover rounded-full", _ctx.isParentComment ? 'h-10 w-10' : 'h-8 w-8']),
                    style: _normalizeStyle({
              backgroundImage: `url(${_ctx.commentElem.userdetails.avatar})`,
            })
                  }, null, 6))
                : (
              !_ctx.commentElem.userdetails && _ctx.instructor && _ctx.instructor.headshot
            )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["bg-gray-500 bg-cover rounded-full", _ctx.isParentComment ? 'h-10 w-10' : 'h-8 w-8']),
                      style: _normalizeStyle({ backgroundImage: `url(${_ctx.instructor.headshot})` })
                    }, null, 6))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(["bg-gray-300 inline-flex items-center bg-cover border rounded-full", _ctx.isParentComment ? 'p-2' : 'p-1'])
                    }, [
                      _createVNode(_component_BaseIcon, {
                        name: "user",
                        hwClass: "h-6 w-6"
                      })
                    ], 2))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                (_ctx.commentElem && _ctx.commentElem.userdetails)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.commentElem &&
                    _ctx.commentElem.userdetails &&
                    _ctx.commentElem.userdetails.name.substring(0, 15)), 1))
                  : (
                    _ctx.commentElem &&
                    _ctx.commentElem.userId &&
                    !_ctx.commentElem.userdetails
                  )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Deleted User "))
                    : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                        _createTextVNode(_toDisplayString((_ctx.instructor && _ctx.instructor.name) || 'Instructor') + " ", 1),
                        (_ctx.instructor && _ctx.instructor.name)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Instructor"))
                          : _createCommentVNode("", true)
                      ])),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.timeSince), 1),
                (_ctx.isParentComment)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                      _createVNode(_component_BaseIcon, {
                        name: _ctx.commentPrivacy.svgicon,
                        hwClass: "h-3 w-3"
                      }, null, 8, ["name"]),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.commentPrivacy.label), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isParentComment)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "bg-blue-100 border-0 rounded-full inline-block px-2 mt-2 cursor-default custom-list-styling",
                    innerHTML: _ctx.repliedToText
                  }, null, 8, _hoisted_14))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.editingComment && _ctx.commentPermission === 'enabled')
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  (_ctx.showEditDeleteDropdown)
                    ? (_openBlock(), _createBlock(_component_UIDropdown, {
                        key: 0,
                        id: "comment-edit-dropdown",
                        options: _ctx.commentEditOptions,
                        onSelect: _ctx.selectCommentEditOption
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BaseIcon, { name: "dotsvertical" })
                        ]),
                        _: 1
                      }, 8, ["options", "onSelect"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.editingComment)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                id: _ctx.id,
                class: "text-black py-1 custom-list-styling",
                style: _normalizeStyle({ fontWeight: _ctx.computedFontWeight }),
                innerHTML: _ctx.formattedCommentText
              }, null, 12, _hoisted_16))
            : _createCommentVNode("", true)
        ]),
        (_ctx.editingComment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_editor, {
                  editorText: _ctx.contentText,
                  "onUpdate:editorText": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentText) = $event))
                }, null, 8, ["editorText"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.replyTextLength) + " / " + _toDisplayString(_ctx.commentCharacterLength), 1),
                (_ctx.isParentComment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createVNode(_component_UIDropdown, {
                        id: "is-parent-privacy-dropdown",
                        options: _ctx.filteredPrivacyOptions,
                        onSelect: _ctx.setCurrentPrivacyOption
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_BaseIcon, {
                              name: _ctx.currentPrivacyOption.svgicon,
                              hwClass: "w-4 h-4"
                            }, null, 8, ["name"]),
                            _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.currentPrivacyOption.label), 1),
                            (_ctx.productCommentPrivacy === 'publicAndInstructor')
                              ? (_openBlock(), _createBlock(_component_BaseIcon, {
                                  key: 0,
                                  name: "chevrondown",
                                  hwClass: "h-3 w-3"
                                }))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }, 8, ["options", "onSelect"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  class: "border rounded ml-2 p-1 px-3 cursor-pointer bg-gray-300 text-gray-700 inline-block text-center justify-center",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelUpdate && _ctx.cancelUpdate(...args)))
                }, " Cancel "),
                _createElementVNode("button", {
                  disabled: _ctx.postButtonDisabled,
                  class: _normalizeClass(["border rounded ml-2 p-1 cursor-pointer w-28 inline-flex items-center text-center justify-center", 
                _ctx.postButtonDisabled
                  ? 'bg-gray-100 text-gray-500'
                  : 'bg-blue-100 text-blue-500'
              ]),
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateComment && _ctx.updateComment(...args)))
                }, [
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_BaseIcon, { name: "paperairplane" })
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "inline-flex items-center pl-2" }, "Update", -1))
                ], 10, _hoisted_24)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.commentPermission === 'enabled')
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              _createElementVNode("div", {
                class: "flex cursor-pointer pb-2 hover:text-blue-600 font-bold text-xs",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.replyComment = !_ctx.replyComment))
              }, [
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_BaseIcon, {
                    name: "reply",
                    hwClass: "h-4 w-4"
                  })
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mt-px pr-2 pl-px cursor-pointer" }, "Reply", -1))
              ]),
              (_ctx.isParentComment && _ctx.hasReplies)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createElementVNode("div", {
                      class: "mt-px pr-2 pl-5 cursor-pointer h-5",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.hideReplyList && _ctx.hideReplyList(...args)))
                    }, _toDisplayString(_ctx.showReplies ? 'Hide' : 'Show') + " Replies ", 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.replyComment && _ctx.commentPermission === 'enabled')
          ? (_openBlock(), _createBlock(_component_ReplyCommentModal, {
              key: 2,
              parentCommentId: _ctx.parentCommentId,
              repliedToComment: _ctx.commentElem.content,
              repliedToUserId: _ctx.commentElem.userId,
              onCloseReplyModal: _ctx.closeReplyModal,
              pendoPayload: _ctx.pendoPayload,
              parentVisibility: _ctx.visibilityOfReplyComment
            }, null, 8, ["parentCommentId", "repliedToComment", "repliedToUserId", "onCloseReplyModal", "pendoPayload", "parentVisibility"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}