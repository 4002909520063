<template>
  <div
    class="my-4 mb-8 h-full grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center"
  >
    <div
      v-for="i of 6"
      :key="i"
      class="border-2 shadow-sm bg-white border-slate-200 p-4 rounded-lg w-full h-full overflow-hidden cursor-pointer hover:shadow-lg hover:-translate-y-2 transform transition duration-200"
    >
      <div class="w-full overflow-hidden h-auto relative rounded-lg">
        <shimmer class="w-full aspect-video rounded-lg" />
        <div
          class="absolute text-xs rounded text-white right-0 bottom-0 mr-2 mb-2 z-1"
        >
          <shimmer class="w-16 h-4 rounded-lg" />
        </div>
      </div>
      <div class="lg:mt-4 h-36">
        <shimmer class="w-2/3 h-6 my-1 rounded-lg" />
        <shimmer class="w-full h-4 my-1 rounded-lg" />
      </div>

      <div class="mb-4">
        <shimmer class="w-full h-2 rounded-lg" />
      </div>

      <div class="w-full pt-1">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <shimmer class="rounded-lg w-12 h-12 mr-2" />
            <shimmer class="w-24 h-4 rounded-lg" />
          </div>
          <shimmer class="w-20 h-8 rounded-lg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Shimmer from './Shimmer.vue'

export default defineComponent({
  components: {
    Shimmer,
  },
})
</script>
