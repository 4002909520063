import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center courses-font-family font-semibold"
}
const _hoisted_2 = {
  key: 1,
  class: "w-11/12 h-full flex flex-col items-center justify-start mx-auto py-2 gap-4"
}
const _hoisted_3 = { class: "w-full h-full" }
const _hoisted_4 = {
  key: 0,
  class: "w-full"
}
const _hoisted_5 = { class: "w-full my-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "relative w-full min-h-40 aspect-video" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "absolute inset-0 flex justify-center items-center" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "course-tag absolute rounded-3xl py-0.5 px-2 bg-[--accent-color]",
  style: {"top":"1rem","right":"1rem"}
}
const _hoisted_12 = { class: "text-gray-900 font-semibold line-clamp-1" }
const _hoisted_13 = {
  key: 0,
  class: "text-[--accent-color] font-semibold w-full text-start break-all line-clamp-1"
}
const _hoisted_14 = {
  key: 1,
  class: "text-[--accent-color] font-semibold text-start"
}

import { defaultCourseThumbnail, lockedPostIcon } from "@/helper/constants";
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
  truncateText,
} from "@/helper/filter";
import { UIEmpty } from "@gohighlevel/ghl-ui";
import { useRouter } from "vue-router";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import CourseCardV2 from "./CourseCardV2.vue";
import UpsellBundleV2 from "./UpsellBundleV2.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AllCoursesV2',
  props: ["allCourses", "upsellOffers", "fetching"],
  setup(__props) {

const props = __props;

const router = useRouter();

const computedUpsellOffers = computed(() => {
  return props.upsellOffers?.filter(course => course.upsellType && course.offerUpSell)
})
function openProduct(productId) {
  router.push({ name: "category-list-levelup", params: { id: productId } });
}
function openUpsell(offerId, upsellId) {
  router.push({
    name: "upsell-bundle-levelup",
    params: {
      offerId,
      upsellId,
    },
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!props.fetching && !props.allCourses.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(UIEmpty), {
            id: "empty-product-message",
            title: "No courses found",
            description: "You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey."
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (props.upsellOffers.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(Carousel), {
                      "items-to-show": 1.5,
                      "wrap-around": true
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.upsellOffers, (course) => {
                          return (_openBlock(), _createBlock(_unref(Slide), {
                            key: course.id
                          }, {
                            default: _withCtx(() => [
                              (course.upsellType && course.offerUpSell)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "w-11/12 flex flex-col items-start gap-2 justify-around clickable",
                                    onClick: ($event: any) => (openUpsell(course.offerUpSell.id, course.id))
                                  }, [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("img", {
                                        src: _unref(replaceBucketUrlWithCdnUrl)(course.offerUpSell?.posterImage) ||
                      _unref(defaultCourseThumbnail)
                      ,
                                        alt: "bundle-image",
                                        class: _normalizeClass(["w-full h-full relative rounded-md brightness-75", {
                        'object-cover': !_unref(replaceBucketUrlWithCdnUrl)(
                          course.offerUpSell?.posterImage
                        ),
                      }])
                                      }, null, 10, _hoisted_8),
                                      _createElementVNode("div", _hoisted_9, [
                                        _createElementVNode("img", {
                                          src: _unref(lockedPostIcon),
                                          alt: "lock-icon",
                                          class: "h-6 w-6 text-[--accent-color] object-cover"
                                        }, null, 8, _hoisted_10)
                                      ]),
                                      (
                        course?.offerUpSell?.products &&
                        course?.offerUpSell?.products?.length > 1
                      )
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[0] || (_cache[0] = [
                                            _createElementVNode("span", { class: "text-white text-xs font-medium" }, "Bundle", -1)
                                          ])))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(truncateText)(course.offerUpSell?.title)), 1),
                                    (course.offerUpSell?.priceDisplayOverride)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(truncateText)(course.offerUpSell?.priceDisplayOverride, 50)), 1))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_unref(fetchCurrencySymbol)(course.offerUpSell?.currency)) + " " + _toDisplayString(course.offerUpSell?.amount), 1))
                                  ], 8, _hoisted_6))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.allCourses, (course) => {
              return (_openBlock(), _createBlock(CourseCardV2, {
                key: course.id,
                onClick: ($event: any) => (openProduct(course.id)),
                course: course
              }, null, 8, ["onClick", "course"]))
            }), 128)),
            _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedUpsellOffers.value, (course) => {
              return (_openBlock(), _createBlock(UpsellBundleV2, {
                class: "w-full clickable overflow-x-scroll",
                key: course.id,
                offer: course.offerUpSell,
                upsellId: course.id,
                onClick: ($event: any) => (openUpsell(course.offerUpSell.id, course.id))
              }, null, 8, ["offer", "upsellId", "onClick"]))
            }), 128))
          ])
        ]))
  ]))
}
}

})