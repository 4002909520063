import BaseService from './BaseService'
import Assignment from '@/models/Assignment'

export default class AssignmentService extends BaseService<Assignment> {
  protected subdomain = 'assessments/assignment'

  model() {
    return Assignment
  }
}
