<template>
  <div class="w-full">
    <div v-if="!isSubCategory" class="border-b w-full h-full">
      <div
        v-if="isPlaylistLoading"
        class="flex items-center justify-between h-20 px-6"
      >
        <UISkeleton class="w-28 h-8 rounded-md" />
        <UISkeleton class="w-16 h-8 rounded-md" />
      </div>
      <div v-else class="flex items-center justify-between h-20 px-6">
        <Title tag="h4" class="m-0 font-medium">
          {{ category.title }}
        </Title>
        <Paragraph class="m-0"> {{ totalPostsInCategory }} Lessons </Paragraph>
      </div>
    </div>
    <CategoryPlaylistLoader v-if="isPlaylistLoading" />
    <div v-else class="max-h-96 overflow-y-auto">
      <div
        v-for="(postListItem, listIndex) in playlist"
        :key="postListItem.node.id"
        :id="postListItem.node.id"
      >
        <div
          class="border-b-2"
          v-if="
            !isSubCategory &&
            postListItem.type === CategoryTreeNodeType.Category
          "
        >
          <div
            class="bg-neo-classic-sub-category-bg py-1 flex items-center justify-between pr-2"
          >
            <Title tag="h6" class="m-0 px-4 font-medium">
              {{ postListItem.node.title }}
            </Title>
            <UITag v-if="!postListItem.isAvailable" type="error" size="small">
              Available after {{ postListItem.dripDays }} day{{
                postListItem.dripDays > 1 ? 's' : ''
              }}
            </UITag>
          </div>
          <LockedContent
            v-if="isItemLocked(postListItem)"
            :lockedByPostId="postListItem.lockedBy || postListItem.lockedByPost"
            :content-type="
              CategoryTreeNodeTypeView[CategoryTreeNodeType.Category]
            "
            :lockedByCategoryId="postListItem.node.lockedByCategory"
            :completedPostIds="completedPostIds"
          />
          <CategoryPlaylist
            v-else
            :parentCategoryIndex="listIndex"
            :playlist="postListItem.childs"
            :isSubCategory="true"
            :category="category"
            :activePost="activePost"
            :completedPostIds="completedPostIds"
            :categoryTree="categoryTree"
          />
        </div>
        <CategoryListItem
          v-else
          :count="getCount(listIndex)"
          :listItemData="postListItem"
          :showBottomBorder="listIndex !== playlist.length - 1"
          :show-description="false"
          :isPostActive="activePostId === postListItem.node.id"
          :is-post-completed="completedPostIds.has(postListItem.node.id)"
          :is-item-locked="isItemLocked(postListItem)"
          :progress="getCompletionPercentage(postListItem.node.id)"
          :itemIndex="listIndex"
          :is-category-available="postListItem.isAvailable"
        />
      </div>
    </div>
    <div
      v-if="!isSubCategory"
      class="w-full p-4 text-center flex items-center justify-center border-t"
    >
      <Button
        :disabled="!previousCategory"
        variant="secondary"
        @click="goToCategory(previousCategory)"
        aria-label="Previous Category"
        class="mr-2 w-full"
      >
        Previous Category
      </Button>
      <Button
        :disabled="!nextCategory"
        @click="goToCategory(nextCategory)"
        aria-label="Next Category"
        class="w-full"
      >
        Next Category
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import CategoryListItem from '@/components/neo-classic-theme/landing-page/CategoryListItem.vue'
import Category from '@/models/Category'
import CategoryTreeNode, {
  CategoryTreeNodeType,
  CategoryTreeNodeTypeView,
} from '@/models/CategoryTreeNode'
import Post from '@/models/Post'
import { computed, PropType, watch, inject, Ref, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import LockedContent from '@/components/neo-classic-theme/common/post/LockedContent.vue'
import { CategoryVisibility } from '@/models/Category'
import { UITag } from '@gohighlevel/ghl-ui'
import CategoryPlaylistLoader from '@/components/neo-classic-theme/loaders/CategoryPlaylistLoader.vue'
import { UISkeleton } from '@gohighlevel/ghl-ui'

const router = useRouter()
const route = useRoute()

const props = defineProps({
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  playlist: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  isSubCategory: {
    type: Boolean,
    default: false,
  },
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
  activePost: {
    type: Object as PropType<Post>,
    default: () => ({}),
  },
  totalPostsInCategory: {
    type: Number,
    default: 0,
  },
  parentCategoryIndex: {
    type: Number,
    default: 0,
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  allCompletedPosts: {
    type: Array<{ postId: string; percentage: number }>,
    default: () => [],
  },
  refresh: {
    type: Boolean,
    default: false,
  },
})

const activePostId = computed(() => {
  return props.activePost.id
})

const categoriesAndPostsLookup =
  inject<Ref<Map<string, any>>>('categoriesAndPostsLookup') || ref(new Map())

const isLoading = inject<Ref<boolean>>('isLoading') || ref(false)

const isPlaylistLoading = computed(() => {
  if (props.playlist.length === 0) {
    return isLoading.value
  }
  return false
})

const getCount = (listIndex: number) => {
  if (props.isSubCategory) {
    return (10 * props.parentCategoryIndex + listIndex + 11) / 10
    // Simplification of the above expression
    // props.parentCategoryIndex + 1 + (listIndex + 1) / 10
  }
  return listIndex + 1
}

function getCompletionPercentage(postId: string) {
  if (props.allCompletedPosts.length) {
    const post = props.allCompletedPosts.find((post) => post.postId === postId)

    return post?.percentage || 0
  }

  return 0
}

function isItemLocked(treeItem: CategoryTreeNode) {
  if (treeItem.node.visibility === CategoryVisibility.locked) {
    const lockedByPostId = treeItem.lockedBy || treeItem.lockedByPost
    const lockedByCategory = treeItem.node.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false
    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)
      if (category) {
        return (
          isLocked ||
          !category.childs?.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

// Find the next category in the tree no need to fetch from backend
const nextCategory = computed(() => {
  const currentCategorySequenceNo = props.category.sequenceNo
  const nextCategory = props.categoryTree.find(
    (node) => node.node.sequenceNo === currentCategorySequenceNo + 1
  )
  return nextCategory || null
})

const previousCategory = computed(() => {
  const currentCategorySequenceNo = props.category.sequenceNo
  const previousCategory = props.categoryTree.find(
    (node) => node.node.sequenceNo === currentCategorySequenceNo - 1
  )
  return previousCategory || null
})

// Compute total posts in the category including sub categories
const totalPostsInCategory = computed(() => {
  return props.playlist.reduce((acc, curr) => {
    if (curr.type === CategoryTreeNodeType.Category) {
      return acc + curr.childs.length
    }
    return acc + 1
  }, 0)
})

const goToCategory = (category: CategoryTreeNode) => {
  // Check if the next category is locked or dripped
  if (isItemLocked(category) || !category.isAvailable) {
    // redirect to products page
    return router.push({
      name: 'product-overview-v2',
      params: {
        id: route.params.id,
      },
      hash: `#${category.node.id}`,
      query: {
        ...route.query,
      },
    })
  }

  const firstPostInCategory = category.childs[0]
  router.push({
    name: 'post-overview',
    params: {
      id: category.node.productId,
      category_id: category.node.id,
      post_id: firstPostInCategory.id,
    },
    hash: `#${firstPostInCategory.id}`,
    query: {
      ...route.query,
    },
  })
}
</script>
