import http from '../../http-common'
import BaseService from './BaseService'
import Comment from '@/models/Comment'

export default class CommentService extends BaseService<Comment> {
  protected subdomain = 'comments'

  model() {
    return Comment
  }

  constructor(locationId: string) {
    super(locationId)
  }

  createComment(payload: {
    productId: string
    postId: string
    content: string
    parentCommentId?: string | undefined
    repliedToComment?: string | undefined
    repliedToUserId?: string | undefined
    pendoPayload?: any
    visibility?: string
  }) {
    return http.post(`${this.endpoint}/`, payload)
  }

  updateComment(
    id: string,
    payload: {
      content: string
      pendoPayload: any
      visibility?: string
    }
  ) {
    return http.put(`${this.endpoint}/${id}`, payload)
  }

  findComments(payload: {
    productId: string
    postId: string
    offset?: number
    limit?: number
    commentsAfter?: string
    order?: number
    commentId?: string
    scrollSearch?: boolean
  }) {
    return http.post(`${this.endpoint}/filtered`, payload)
  }

  deleteComment(commentId: string) {
    return http.delete(`${this.endpoint}/${commentId}`)
  }

  getTotalCommentsByPostIds(payload: { postIds: string[] }) {
    return http.post(`${this.endpoint}/posts/count`, payload)
  }

  getTotalCommentsByUserIdForPost(payload: {
    postId: string
    userId: string
    adminMode: boolean
  }) {
    return http.post(`${this.endpoint}/post/comment_count`, payload)
  }
}
