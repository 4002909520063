<template>
  <div :id="comment.id" class="mb-4 grid grid-cols-2 single-comment-block-wrapper">
    <img
      :src="commentAvatar"
      class="w-8 h-8 rounded-full user-avatar"
      :alt="'comment-avatar' + userName"
    />
    <div class="ml-2 w-full single-comment-wrapper">
      <slot v-if="editableCommentId === comment.id" name="edit-section"></slot>
      <div
        v-else
        class="bg-neo-classic-hover-bg p-4 rounded-lg grid grid-cols-12 gap-1 w-full"
      >
        <div
          class="user-comment-wrapper"
          :class="isCommentBelongToCurrentUser ? 'col-span-11' : 'col-span-12'"
        >
          <div class="w-full font-medium mb-2">
            {{ userName }}
            <span class="text-neo-classic-secondary-text ml-2">
              {{ commentCreatedAt(comment.createdAt) }}
            </span>
          </div>
          <RichTextViewer
            :html="comment.content"
            :replaceImages="false"
            :replaceLinks="false"
          />
        </div>
        <div
          v-if="isCommentBelongToCurrentUser"
          class="col-span-1 flex justify-end"
        >
          <UIDropdown
            id="comment-actions-dropdown"
            :options="commentActions"
            placement="bottom"
            @select="handleCommentAction"
          >
            <DotsHorizontalIcon class="w-5 h-5 cursor-pointer" />
          </UIDropdown>
        </div>
      </div>
      <div class="flex justify-between items-center mt-2">
        <Button
          class="text-neo-classic-secondary-text focus:outline-none outline-none"
          :id="`comment-${comment.id}-reply-btn`"
          variant="text"
          @click="() => toggleReplyComment(comment.id)"
        >
          Reply
        </Button>
        <div class="flex items-center text-neo-classic-primary-accent">
          <component class="mr-1" :is="getPrivacy(comment.visibility)?.icon" />
          {{ getPrivacy(comment.visibility)?.label }}
        </div>
      </div>
      <div v-if="replyToComment === comment.id" class="mt-4">
        <slot
          name="at-end"
          v-bind="{
            onCancel: () => toggleReplyComment(comment.id),
            isReplying: true,
            replyToComment,
            parentCommentId: comment.parentCommentId || comment.id,
            repliedToComment: comment.content,
            repliedToUserId: comment.userId,
            repliedToCommentPrivacy: comment.visibility,
          }"
        ></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import RichTextViewer from '@/components/neo-classic-theme/common/RichTextViewer.vue'
import { userAvatarImage } from '@/helper/constants'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import {
  DotsHorizontalIcon,
  Pencil01Icon,
  Trash01Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { UIDropdown, renderIcon } from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { inject, ref, h, computed } from 'vue'
import { CommentPrivacy } from '@/models/Product'
import { CommentVisibility } from '@/models/Comment'
import { getUserId } from '@/services'
import { useStore } from 'vuex'

const props = defineProps({
  comment: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  editableCommentId: {
    type: String,
    default: '',
  },
  instructor: {
    type: Object,
    default: () => ({}),
  },
})

const commentActions = [
  {
    label: 'Edit',
    key: 'edit',
    icon() {
      return h(renderIcon(Pencil01Icon), null, {})
    },
  },
  {
    type: 'divider',
    key: 'd1',
  },
  {
    label: 'Delete',
    key: 'delete',
    icon() {
      return h(renderIcon(Trash01Icon), { class: 'text-neo-classic-error' }, {})
    },
  },
]

const commentPrivacy: Array<{
  label: string
  key: CommentPrivacy
  visibility: CommentVisibility
  icon: any
}> = inject('commentPrivacy') || []

const replyToComment = ref('')
const store = useStore()

const emit = defineEmits(['onEdit', 'onDelete'])

const isCommentBelongToCurrentUser = computed(() => {
  return getUserId() === props.comment.userdetails?.id
})

const clientPortalUserData = computed(() => store.getters.clientPortalUserData)

const commentAvatar = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId)
    return userAvatarImage

  if (!props.comment?.userdetails)
    return props.instructor?.headshot || userAvatarImage

  if (props.comment.userdetails?.id === getUserId()) {
    return clientPortalUserData.value?.avatar || store.getters.userAvatar || userAvatarImage
  }

  return props.comment?.userdetails?.avatar || userAvatarImage
})

const userName = computed(() => {
  if (!props.comment?.userdetails && props.comment?.userId)
    return 'Deleted User'
  return props.comment?.userdetails && props.comment?.userdetails?.name
    ? props.comment?.userdetails?.name
    : props.instructor && props.instructor.name
    ? props.instructor.name
    : 'Instructor'
})

function toggleReplyComment(commentId: string) {
  if (replyToComment.value === commentId) {
    replyToComment.value = ''
  } else {
    replyToComment.value = commentId
  }
}

function handleCommentAction(key: string) {
  switch (key) {
    case 'edit': {
      emit('onEdit', props.comment.id)
      break
    }
    case 'delete':
      emit('onDelete', props.comment.id)
      break
    default:
      break
  }
}

function getPrivacy(value: CommentVisibility) {
  return commentPrivacy.find((cp) => cp.visibility === value)
}

function commentCreatedAt(time: string) {
  return moment(time).fromNow()
}
</script>
<style scoped>
.single-comment-block-wrapper {
  grid-template-columns: auto 1fr;
}
</style>
