import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col gap-3" }

import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import { UISkeleton } from '@gohighlevel/ghl-ui'

export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryListViewLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Paper, { class: "p-2 h-16" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-md" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-2 h-16" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-md" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-2 h-16" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-md" })
      ]),
      _: 1
    }),
    _createVNode(Paper, { class: "p-2 h-16" }, {
      default: _withCtx(() => [
        _createVNode(_unref(UISkeleton), { class: "w-full h-full aspect-video rounded-md" })
      ]),
      _: 1
    })
  ]))
}
}

})