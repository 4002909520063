import Audio from './Audio'
import Model from './Model'
import PostMaterial from './PostMaterial'
import Video from './Video'

export enum PostVisibility {
  draft = 'draft',
  published = 'published',
  locked = 'locked',
  drip = 'drip',
}

export enum PostCommentStatus {
  visible = 'visible',
  hidden = 'hidden',
  locked = 'locked',
  enabled = 'enabled',
}

export enum PostContentType {
  video = 'video',
  assessment = 'assessment',
  assignment = 'assignment',
  quiz = 'quiz',
  audio = 'audio',
  lesson = 'lesson',
}

export enum PostContentTypeLabel {
  quiz = 'Quiz',
  assignment = 'Assignment',
}

export default class Post extends Model {
  constructor(payload?: {
    title?: string
    description?: string
    visibility?: string
    categoryId?: string
    productId?: string
    posterImage?: string
    commentStatus?: string
    contentId?: string
    contentType?: string
    sequenceNo?: number
    post_materials?: Array<any>
    asset_urls?: object
    video?: any
    commentPermission?: string
    lockedByPost?: string
    audio?: any
  }) {
    if (payload && payload.post_materials) {
      payload.post_materials = payload.post_materials
        .map((p) => new PostMaterial(p))
        .sort((a, b) => a.sequenceNo - b.sequenceNo)
    }

    if (payload && payload.video) {
      payload.video = new Video(payload.video)
    }
    if (payload && payload.audio) {
      payload.audio = new Audio(payload.audio)
    }

    super(payload || {})
  }

  get commentPermission(): string {
    return this._data.commentPermission
  }

  set commentPermission(commentPermission: string) {
    this._data.commentPermission = commentPermission
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get description(): string {
    return this._data.description
  }

  set description(description: string) {
    this._data.description = description
  }

  get visibility(): string {
    return this._data.visibility
  }

  set visibility(visibility: string) {
    this._data.visibility = visibility
  }

  get parentCategory(): string {
    return this._data.categoryId
  }

  set parentCategory(categoryId: string) {
    this._data.categoryId = categoryId
  }

  get categoryId(): string {
    return this._data.categoryId
  }

  set categoryId(categoryId: string) {
    this._data.categoryId = categoryId
  }

  get productId(): string {
    return this._data.productId
  }

  set productId(productId: string) {
    this._data.productId = productId
  }

  get posterImage(): string {
    return this._data.posterImage
  }

  set posterImage(posterImage: string) {
    this._data.posterImage = posterImage
  }

  get commentStatus(): string {
    return this._data.commentStatus
  }

  set commentStatus(commentStatus: string) {
    this._data.commentStatus = commentStatus
  }

  get contentId(): string {
    return this._data.contentId
  }

  set contentId(contentId: string) {
    this._data.contentId = contentId
  }

  get contentType(): string {
    return this._data.contentType
  }

  set contentType(contentType: string) {
    this._data.contentType = contentType
  }

  get sequenceNo(): number {
    return this._data.sequenceNo
  }

  set sequenceNo(sequenceNo: number) {
    this._data.sequenceNo = sequenceNo
  }

  get materials(): Array<PostMaterial> {
    return this._data.post_materials
  }

  set materials(postMaterials: Array<PostMaterial>) {
    this._data.post_materials = postMaterials
  }

  get video(): Video | null {
    return this._data.video
  }

  set video(video: Video | null) {
    this._data.video = video
  }

  get assetUrls(): object | null {
    return this._data.asset_urls
  }

  set assetUrls(assetUrls: object | null) {
    this._data.asset_urls = assetUrls
  }

  get audio(): Audio | null {
    return this._data.audio
  }

  set audio(audio: Audio | null) {
    this._data.audio = audio
  }

  get postCompletedRef(): string | null {
    return this._data._postCompletedRef
  }

  set postCompletedRef(_postCompletedRef: string | null) {
    this._data._postCompletedRef = _postCompletedRef
  }

  // locked by post
  get lockedByPost(): string | null {
    return this._data.lockedByPost
  }

  // locked by category
  get lockedByCategory(): string | null {
    return this._data.lockedByCategory
  }

  get isLocked(): boolean {
    return this._data.isLocked || false
  }

  set isLocked(isLocked: boolean) {
    this._data.isLocked = isLocked
  }
}
