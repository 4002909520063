<template>
  <div
    @click="openPost()"
    class="relative bg-white product-card max-w-xs rounded-lg overflow-hidden shadow-lg px-4"
    :class="available && 'cursor-pointer'"
  >
    <div class="product-image w-full image-container border-b rounded">
      <img
        class="w-full h-full"
        :src="replaceBucketUrlWithCdnUrl(posterImage)"
        alt="Product Poster Image"
      />
    </div>
    <div class="px-2 py-4">
      <div
        class="text-center font-semibold text-xl post-title custom-word-break"
        :class="[
          !available ? 'text-gray-500' : 'text-blue-500',
          $route.name.includes('product-overview') && available
            ? 'primaryStyle lesson-title'
            : '',
        ]"
      >
        {{ post.title }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { checkIcon } from '../../helper/constants'
import { getDefaultPosterImage } from '@/helper'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'

export default defineComponent({
  props: {
    post: {
      type: Object,
    },
    categoryId: {
      type: String,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    templateId: String,
    hasProductAccess: {
      type: Boolean
    }
  },
  computed: {
    posterImage(): string {
      return (
        this.post.posterImage || getDefaultPosterImage(this.post.contentType)
      )
    },
    productId(): string {
      return this.post.productId
    },
  },
  data() {
    return {
      checkIcon,
    }
  },
  methods: {
    openPost() {
      if (this.hasProductAccess === false) {
        return
      }
      if (this.available) {
        this.$router.push({
          name: 'post-overview',
          params: {
            id: this.productId,
            category_id: this.categoryId,
            post_id: this.post.id,
          },
          query: {
            template_id: this.templateId,
            ...this.$route.query,
          },
        })
      }
    },
    replaceBucketUrlWithCdnUrl,
  },
})
</script>
<style scoped>
.image-container {
  height: 10rem;
}
.custom-word-break {
  word-break: break-word;
}
</style>