import { computed, h } from 'vue'
import { NavigationItemType, isPwa } from '@gohighlevel/clientportal-core'
import {
  Home05Icon,
  User02Icon,
  GraduationHat02Icon,
  BookClosedIcon,
  Bell02Icon,
} from '@gohighlevel/ghl-icons/24/outline'
import { LIBRARY_MENU_ITEMS } from '@/helper/constants'
import { isClientPortal } from '@/helper'
import { isMobileTablet } from '@/helper/device_info'

function useNavigation(isLevelUpItem: boolean) {
  const clientPortalNavigationItems = [
    {
      name: LIBRARY_MENU_ITEMS.home,
      type: NavigationItemType.APP_ROUTE,
      routeName: ['LandingPage', 'LandingPage-v2'],
      showOnMobile: true,
      showOnWeb: false,
      icon: () => h(Home05Icon),
    },
    {
      name: LIBRARY_MENU_ITEMS.allCourses,
      routeName: ['library-v2', 'library-v2-v2'],
      current: true,
      type: NavigationItemType.APP_ROUTE,
      showOnMobile: true,
      showOnWeb: false,
      icon: () => h(GraduationHat02Icon),
      selectedIcon: () => h(GraduationHat02Icon),
    },
    ...(!isLevelUpItem
      ? [
          {
            name: LIBRARY_MENU_ITEMS.myCourses,
            routeName: ['my-courses-library', 'my-courses-library-v2'],
            type: NavigationItemType.APP_ROUTE,
            showOnMobile: true,
            showOnWeb: false,
            icon: () => h(BookClosedIcon),
            selectedIcon: () => h(BookClosedIcon),
          },
        ]
      : []),
    ...(!isLevelUpItem
      ? [
          {
            name: 'Notifications',
            routeName: ['NotificationMobileView', 'NotificationMobileView-v2'],
            type: NavigationItemType.APP_ROUTE,
            showOnMobile: true,
            showOnWeb: false,
            icon: () => h(Bell02Icon),
          },
        ]
      : []),
    {
      name: LIBRARY_MENU_ITEMS.profile,
      routeName: ['ProfileContainer', 'ProfileContainer-v2'],
      type:
        isPwa() || isMobileTablet()
          ? NavigationItemType.APP_ROUTE
          : NavigationItemType.REDIRECTION,
      redirectionUrl: `${window.location.origin}/account?activeTab=Profile`,
      showOnMobile: true,
      showOnWeb: false,
      icon: () => h(User02Icon),
    },
  ]

  const generalNavigationItems = [
    {
      name: LIBRARY_MENU_ITEMS.allCourses,
      routeName: ['library-v2', 'library-v2-v2'],
      current: true,
      type: NavigationItemType.APP_ROUTE,
      showOnMobile: true,
      showOnWeb: false,
      icon: () => h(GraduationHat02Icon),
      selectedIcon: () => h(GraduationHat02Icon),
    },
    {
      name: LIBRARY_MENU_ITEMS.profile,
      routeName: ['profile-settings', 'profile-settings-v2'],
      current: true,
      type: NavigationItemType.REDIRECTION,
      redirectionUrl: `${window.location.origin}/settings/profile`,
      showOnMobile: true,
      showOnWeb: false,
      icon: () => h(User02Icon),
      selectedIcon: () => h(User02Icon),
    },
  ]

  const navigationItems = computed(() => {
    return isClientPortal()
      ? clientPortalNavigationItems
      : generalNavigationItems
  })

  return {
    navigationItems,
  }
}

export default useNavigation
