import http from '../../http-common'

export default class UniversalSearchService {
  protected subdomain = 'universal-search'
  private readonly locationId: string

  constructor(locationId: string) {
    this.locationId = locationId
  }

  private get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  searchCount(params?: any) {
    return http
      .get(`${this.endpoint}/search-count`, { params })
      .then((resp) => resp.data)
  }

  searchItems(params?: any) {
    return http
      .get(`${this.endpoint}/full-text-search-v2`, { params })
      .then((resp) => resp.data)
  }

  getProductTitles(params: any) {
    return http
      .post(`${this.endpoint}/product-title`, params)
      .then((resp) => resp.data)
  }
}
