import BaseService from './BaseService'
import Video from '@/models/Video'
import http from '../../http-common'

export default class VideoService extends BaseService<Video> {
  protected subdomain = 'videos'

  model() {
    return Video
  }

  constructor(locationId: string) {
    super(locationId)
  }

  checkTranscodingError(postId: string, transcodingErrorPayload: any) {
    console.log('Video Error, Calling transcoding Error API')
    return http
      .put(
        `${this.endpoint}/transcoding-error/${postId}`,
        transcodingErrorPayload
      )
      .then((res) => res.data)
  }

  reTranscodeVideo(videoId: string, transcodingErrorPayload: any) {
    return http
      .put(`${this.endpoint}/retranscode/${videoId}`, transcodingErrorPayload)
      .then((res) => res.data)
  }

  sendWatchtimeLogs(payload: any) {
    return http
      .post(`${this.endpoint}/watchtime-logs`, payload)
      .then((res) => res.data)
  }
}
