<template>
  <CategoryShimmer v-if="fetching" />
  <div
    class="max-w-xl lg:max-w-6xl w-full mx-auto my-4 px-4 lg:px-0"
    :class="isPortalNavbar ? 'pt-14' : newMobileScreens ? 'pb-20' : 'my-4'"
    v-else
  >
    <CategoryPostBreadcrumbs
      v-if="device === 'desktop' || isBuilder || !newMobileScreens"
      :product-id="productId"
      :product-title="product && product.title"
    />
    <div
      class="category-list-container mt-4 sm:block lg:grid grid-cols-3 gap-4"
    >
      <div
        class="mb-4 category-list bg-white rounded pb-6 col-span-2"
        :class="newMobileScreens ? '' : 'shadow p-6'"
      >
        <div
          class="category-list-title flex items-center justify-between text-2xl py-2"
          :class="
            device === 'dekstop' || isBuilder || !newMobileScreens
              ? 'flex'
              : 'hidden'
          "
        >
          <div class="title font-semibold">Categories</div>
          <div class="count text-gray-600">{{ categories.length }}</div>
        </div>
        <div
          class="flex items-center text-center font-bold text-lg"
          v-if="newMobileScreens"
        >
          <div class="w-full text-center">{{ product.title }}</div>
        </div>
        <div v-if="newMobileScreens" class="text-center my-4">
          <button
            class="text-white rounded-full py-1 px-4"
            :class="getMobileTheme.primary"
            @click="handleNextPostClick"
            :disabled="!hasProductAccess"
          >
            {{ completedPosts > 0 ? 'Resume' : 'Start' }}
          </button>
          <UITextXsMedium v-if="!hasProductAccess" class="pt-1">
            {{ productAccessMessage }}
          </UITextXsMedium>
        </div>
        <div
          class="category-list-items my-4 lg:my-0"
          v-for="category in categories"
          :key="category.id"
        >
          <router-link
            v-if="category.available"
            :to="{
              name: 'category-overview',
              params: { id: productId, category_id: category.id },
            }"
          >
            <CategoryListItem
              v-if="categoryProgressCount !== null"
              :category-progress-count="categoryProgressCount.get(category.id)"
              :category="category"
              :progress="categoryProgress[category.id]"
            />
          </router-link>
          <CategoryListItem
            v-else
            :category="category"
            :progress="categoryProgress[category.id]"
            :category-progress-count="categoryProgressCount.get(category.id)"
            :not-available="true"
          />
        </div>
      </div>
      <div class="lesson-stats-instructor-container col-span-1">
        <LessonsStats
          :completed="completedPosts"
          :total="totalPosts"
          :progress-color="newMobileScreens ? getMobileTheme.primary : ''"
          class="mb-3"
        />
        <InstructorCard :instructor="instructor" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import CategoryPostBreadcrumbs from '@/components/common/CategoryPostBreadcrumbs.vue'
import LessonsStats from '@/components/product/LessonsStats.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import CategoryListItem from '@/components/product/CategoryListItem.vue'
import Category from '../models/Category'
import { CategoryService, UserPurchaseService, PostService } from '../services'
import {
  createCategoryPostTree,
  computeNextPost,
  isClientPortal,
} from '../helper'
import {
  defaultPosterImage,
  LEVELUP_LOCATION_IDS,
  SOURCES,
} from '../helper/constants'
import Post from '../models/Post'
import CategoryShimmer from '@/components/common/CategoryShimmer.vue'
import { cloneDeep } from 'lodash'
import { getMobileTheme } from '@/helper'
import { UITextXsMedium } from '@gohighlevel/ghl-ui'
import { getProductAccessMessage } from '../helper/offerAccess'

export default defineComponent({
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    allCompletedPostsData: {
      type: Object,
      default: null,
    },
  },
  components: {
    CategoryPostBreadcrumbs,
    LessonsStats,
    InstructorCard,
    // Progress,
    CategoryShimmer,
    CategoryListItem,
    UITextXsMedium,
  },
  computed: {
    hasProductAccess(): boolean {
      return toRaw(this.product)?._data?.hasProductAccess
    },
    productId(): string {
      return this.$route.params.id || this.product.id
    },
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    },
    postIdsCompleted(): Set<string> {
      return this.allCompletedPostsData?.completedPostIds
        ? this.allCompletedPostsData?.completedPostIds
        : new Set()
    },
    communitiesLearningUrl(): string {
      const communitiesBasePath = window.location.origin.endsWith(
        'gokollab.com'
      )
        ? '/'
        : '/communities/groups/'
      return `${window.location.origin}${communitiesBasePath}${this.groupSlug}/learning`
    },
    productAccessMessage() {
      return getProductAccessMessage(this.hasProductAccess, this.product)
    },
    isPortalActive() {
      return isClientPortal()
    },
    isPortalNavbar(): boolean {
      return (
        isClientPortal() &&
        !this.$store.getters.newMobileScreens &&
        this.source !== SOURCES.communities
      )
    },
    getMobileTheme() {
      return getMobileTheme()
    },
    isOpenedFromCommunities() {
      return this.$store.getters.communitiesSource
    },
    locationId(): string {
      return this.$store.getters.locationId
    },
    isLevelUpItem(): boolean {
      return true
      // return LEVELUP_LOCATION_IDS.includes(this.locationId);
    },
  },
  data() {
    return {
      categories: [] as Category[],
      totalPosts: 0,
      completedPosts: 0,
      categoryIdsCompleted: new Set() as Set<string>,
      defaultPosterImage,
      categoryProgress: {},
      fetching: false,
      allCategories: [] as Category[],
      categoryProgressCount: new Map(),
      nextPostData: {},
      source: this.$route.query.source,
      groupSlug: this.$route.query.group_slug,
    }
  },
  async mounted() {
    // IF LEVEL UP ITEM, REDIRECT TO LEVEL UP ITEM
    if (this.isLevelUpItem && this.newMobileScreens) {
      return this.$router.push({
        name: 'category-list-levelup',
        params: { id: this.productId },
        query: { selected_tab: 'syllabus' },
      })
    }

    this.fetching = true
    await this.fetchCompletionData()
    await this.fetchCategories()
    await this.fetchCategoryProgress()
    this.fetching = false

    // await this.fetchPublishedPosts()
    this.updateCategoryProgressCount()
    const categoryTree = createCategoryPostTree(null, this.allCategories)
    this.nextPostData = computeNextPost(
      cloneDeep(categoryTree),
      this.postIdsCompleted
    )

    if (
      this.newMobileScreens &&
      (this.source === SOURCES.communities || this.isOpenedFromCommunities)
    ) {
      this.$emit('updateBackData', {
        callback: () => {
          window.location.href = this.communitiesLearningUrl
          this.$store.commit('SET_COMMUNITIES_FLAG', '')
        },
      })
    } else {
      this.$emit('updateBackData', {
        name: 'library-v2',
      })
    }

    // Move to top
    window.scrollTo(0, 0)
  },
  methods: {
    async fetchCategories() {
      // const allCategories = await CategoryService.findAll({
      //   product_id: this.productId,
      //   visibility: CategoryVisibility.published
      // })

      const allCategories = await UserPurchaseService.getCategories({
        product_id: this.productId,
      })

      // show only root categories
      this.categories = allCategories
        .filter((c: Category) => c.parentCategory === null)
        .sort((a, b) => a.sequenceNo - b.sequenceNo)

      this.allCategories = allCategories
    },
    // async fetchPublishedPosts() {
    //   let noOfPublishedPosts = 0

    //   const fetchingPublishedPosts = this.categories.map(
    //     async (c: Category) => {
    //       const posts = await PostService.findAll({
    //         visibility: PostVisibility.published,
    //         category_id: c.id
    //       })

    //       noOfPublishedPosts += posts.length

    //       return { categoryId: c.id, posts }
    //     }
    //   )

    //   const publishedPosts = await Promise.all(fetchingPublishedPosts)
    //   this.totalPosts = noOfPublishedPosts
    //   console.log('published posts data --> ', publishedPosts)

    //   const categoryProgress = publishedPosts.reduce(
    //     (agg: any, { categoryId, posts }) => {
    //       const totalPostsInCategory = posts.length
    //       const noOfPostsCompleted = posts.filter((post: Post) =>
    //         this.postIdsCompleted.has(post.id)
    //       ).length

    //       const completionPercentage = Math.round(
    //         (noOfPostsCompleted / totalPostsInCategory) * 100
    //       )

    //       const newAgg = {}
    //       newAgg[categoryId] = completionPercentage

    //       return { ...agg, ...newAgg }
    //     },
    //     {}
    //   )

    //   this.categoryProgress = categoryProgress
    // },
    async fetchCompletionData() {
      this.fetchCompletedPosts()
      await this.fetchNoOfPublishedPosts()
    },
    fetchCompletedPosts() {
      this.categoryIdsCompleted = new Set(
        this.categories.reduce((acc, categoryElem) => {
          const { posts } = categoryElem
          const categoryPostIds = posts.map((e) => e.id)
          const completedPostLengthInCategory = categoryPostIds.filter(
            (element) => this.postIdsCompleted?.has(element)
          ).length
          if (
            categoryPostIds.length > 0 &&
            completedPostLengthInCategory === categoryPostIds.length
          ) {
            acc.push(categoryElem.id)
          }
          return acc
        }, [])
      )

      this.completedPosts = this.postIdsCompleted?.size
    },
    async fetchNoOfPublishedPosts() {
      const publishedResp = await PostService.noOfPublishedPosts({
        product_id: this.productId,
      })

      this.totalPosts = publishedResp.count
    },
    async fetchCategoryProgress() {
      const categories = this.categories.map((c: Category) => c.id)
      const resp = await CategoryService.fetchCategoriesProgress(
        this.productId,
        categories
      )

      this.categoryProgress = resp.reduce((agg, data) => {
        const { categoryId, progress } = data
        const newProgress = {}
        newProgress[categoryId] = progress

        return { ...agg, ...newProgress }
      }, {})
    },
    getTotalCompletedPosts(c: Category) {
      let count = 0
      c.posts.forEach((p: Post) => {
        if (this.postIdsCompleted?.has(p.id)) {
          count += 1
        }
      })
      return count
    },
    updateCategoryProgressCount() {
      this.allCategories.forEach((c: Category) => {
        if (c.parentCategory === null) {
          this.categoryProgressCount.set(c.id, {
            ...this.categoryProgressCount.get(c.id),
            totalPosts: c.posts.length,
            completedPosts: this.getTotalCompletedPosts(c),
          })
        } else {
          const categoryProgress = this.categoryProgressCount.get(
            c.parentCategory
          )
          const prevTotalPosts =
            categoryProgress && categoryProgress.totalPosts
              ? categoryProgress.totalPosts
              : 0
          const prevCompletedPosts =
            categoryProgress && categoryProgress.completedPosts
              ? categoryProgress.completedPosts
              : 0
          this.categoryProgressCount.set(c.parentCategory, {
            ...categoryProgress,
            totalPosts: c.posts.length + prevTotalPosts,
            completedPosts: this.getTotalCompletedPosts(c) + prevCompletedPosts,
          })
        }
      })
    },
    handleNextPostClick() {
      if (this.nextPostData.id) {
        this.$router.push({
          name: 'post-overview',
          params: {
            id: this.product.id,
            category_id: this.nextPostData.parent,
            post_id: this.nextPostData.id,
          },
          query: this.$route.query,
        })
      }
    },
  },
})
</script>
