<template>
  <component :is="wrapper ? Paper : 'div'" :class="wrapper ? 'p-4' : 'py-4'">
    <div class="flex justify-center">
      <Button
        size="large"
        :variant="isPostCompleted ? 'success' : 'primary'"
        class="w-full"
        :disabled="isAdmin || disablePostCompletionButton"
        @click="emit('togglePostCompletion')"
      >
        {{ isPostCompleted ? 'Completed' : buttonText }}
      </Button>
    </div>
    <div v-if="isAdmin" class="flex justify-center">
      <p class="text-sm text-gray-500 mt-2">
        {{
          isAdmin
            ? 'You are an admin, you can not mark this post as completed'
            : ''
        }}
      </p>
    </div>
    <div v-if="isPostCompleted && nextPost">
      <div
        class="grid grid-cols-12 gap-4 p-4 border bg-neo-classic-hover-bg rounded-lg mt-3"
      >
        <div class="col-span-2">
          <img
            :src="
              replaceBucketUrlWithCdnUrl(nextPost.posterImage) ||
              defaultEmptyImage
            "
            :alt="nextPost.title"
            class="w-full object-cover rounded-lg aspect-video"
          />
        </div>
        <div class="col-span-8">
          <Title tag="h3" class="font-medium !m-0 !mb-2">
            Good job! Keep going!
          </Title>
          <Paragraph class="m-0">
            {{ nextPost.title }}
          </Paragraph>
        </div>
        <div class="col-span-2 flex items-center">
          <Button
            size="large"
            variant="text"
            class="text-neo-classic-primary-accent flex items-center"
            @click="goToNextPost"
          >
            Next Lesson
            <ChevronRightIcon class="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  </component>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue' 
import Button from '@/components/neo-classic-theme/common/Button.vue'
import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import { adminMode } from '@/helper/permission.helper'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'
import { ChevronRightIcon } from '@gohighlevel/ghl-icons/24/outline'
import { defaultEmptyImage } from '@/helper/constants'
import { useRouter } from 'vue-router'

const props = defineProps({
  wrapper: {
    type: Boolean,
    default: true,
  },
  activePost: {
    type: Object,
  },
  isPostCompleted: {
    type: Boolean,
  },
  buttonText: {
    type: String,
    default: 'Mark as completed',
  },
  nextPost: {
    type: Object,
    default: () => ({}),
  },
  disablePostCompletionButton: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['togglePostCompletion'])
const isAdmin = adminMode()
const router = useRouter()

function goToNextPost() {
  router.push({
    name: 'post-overview',
    params: {
      id: props.nextPost.productId,
      category_id: props.nextPost.categoryId,
      post_id: props.nextPost.id,
    },
  })
}
</script>
