<script setup lang="ts">
import PaymentWarningIcon from '@/assets/payment-warning.svg'
import { UI_PAYMENT_ELEMENT_EVENT } from '@/helper/constants'
import { extractAPIErrorMsg } from '@/helper/index'
import { base64ToUtf8, getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/helper/storage.helper'
import { UIPaymentElement } from '@gohighlevel/ghl-payment-element'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import config from '../../config'
import UISpinner from '../common/UISpinner.vue'
import Recaptcha from './captcha/Recaptcha.vue'

const route = useRoute()
const props = defineProps({
  size: String,
  locationId: String,
  contactId: String,
  contact: Object,
  sourceId: String, // offer id
  priceIds: String,
  purchaseV2: Function,
  isLivePayment: Boolean,
  offer: Object,
  closeModal: Function,
  togglePaymentFooter: Function,
  couponData: {
    type: Object,
    default: () => ({}),
  },
})

const isProcessingOrder = ref(false)
const isProcessingPayment = ref(false)
const isPaymentElementReady = ref(false)
const errorMsg = ref()
const paymentElementOptions = ref()
const paymentElementRef = ref()
const isLivePayment = ref(props.isLivePayment)
const showRecaptcha = ref(false)
const reCaptchaToken = ref(undefined)
const reCaptchaError = ref('')
const orderData = ref<any>(null)
const defaultProvider = ref('')

const paymentMode = computed(() => {
  return process.env.NODE_ENV == 'production' ? 'production' : 'staging'
})
const stripeOptions = ref(null)

const squarePaymentMode = computed(() => {
  return props?.offer?.type === 'subscription' ? 'subscription' : ''
})
const customProviderInUse = computed(() => {
  return defaultProvider.value === 'custom-provider'
})

onMounted(() => {
  stripeOptions.value = getStripeOptions()
})

function getStripeOptions() {
  const { amount, currency, type, trialDays, setupFee } = props.offer
  const currencyLower = currency.toLowerCase()
  if (type === 'subscription') {
    return {
        mode: 'subscription',
        amount: (trialDays && Number(trialDays)) > 0 ? Number(setupFee ? setupFee : 0) : Number(amount) + Number(setupFee ? setupFee : 0),
        currency: currencyLower,
      }
  }
  const { couponData } = props
  const totalAmount = couponData?.isValidCode ? couponData.total : amount
  const options: any = {
    mode: totalAmount ? 'payment' : 'setup',
    currency: currencyLower,
  }
  if (totalAmount) options.amount = Number(totalAmount)
  return options
}


const customProviderOptions = computed(() => {
  const { amount, currency, type, paymentPriceId, paymentProductId } = props.offer

  if (type === 'subscription') {
    return {
      mode: 'subscription',
      amount: Number(amount),
      currency: currency.toLowerCase(),
      contact: {
        id: props.contact?.id,
        full_name: props.contact?.full_name_lower_case,
        email: props.contact?.email,
        phone: props.contact?.phone,
      },
      productDetails: [
        { productId: paymentProductId, priceId: paymentPriceId }
      ]
    }
  } else {
    return {
      mode: 'payment',
      amount: amount,
      currency: currency.toLowerCase(),
      contact: {
        id: props.contact?.id || '',
        full_name: props.contact?.full_name || '',
        email: props.contact?.email || '',
        phone: props.contact?.phone || ''
      }
    }
  }
  
})

const setErrorMsg = (msg: string) => {
  errorMsg.value = msg
}

const onPaymentCallback = (event) => {
  defaultProvider.value = event.provider
  if (event.type === UI_PAYMENT_ELEMENT_EVENT.READY) {
    isPaymentElementReady.value = true
    if (defaultProvider.value === 'custom-provider') {
      props.togglePaymentFooter(true)
      processPayment()
    }
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.PROCESSING) {
    setErrorMsg('')
    isProcessingPayment.value = event.value
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.ERROR) {
    removeLocalStorageItem(`p_inp_${route.query.sourceId}`)
    if (event.message === 'Request failed with status code 429') {
      showRecaptcha.value = true
    } else {
      setErrorMsg(event.message)
    }
    isProcessingPayment.value = false
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.SUCCESS) {
    removeLocalStorageItem(`p_inp_${route.query.sourceId}`)
    setErrorMsg('')
    isProcessingPayment.value = false
    paymentElementOptions.value = undefined
    isPaymentElementReady.value = false
    props.purchaseV2({
      ...event.data,
      orderId: orderData.value ? orderData.value?._id : event.data.orderId,
    })
  }
}

const onVerify = (response: any) => {
  if (!response) {
    reCaptchaError.value = 'Verification failed! Please try again'
    return
  }
  reCaptchaError.value = ''
  reCaptchaToken.value = response
  if (defaultProvider.value === 'custom-provider') {
    processPayment()
  }
}

const closeModal = () => {
  props.closeModal()
}

defineExpose({
  processPayment,
})

async function placeOrder() {
  if (isProcessingOrder.value) return
  isProcessingOrder.value = true
  setErrorMsg('')
  try {

    const requestURL = `${config.paymentBaseUrl}/orders`
    const { locationId, contactId, sourceId, priceIds, offer, couponData } =
      props

    const requestBody = {
      altId: locationId,
      altType: 'location',
      contactId: contactId,
      source: {
        type: 'membership',
        id: sourceId, // offer id
        name: `${offer?.title ? offer?.title : `${locationId}:${contactId}`
          } - Payment`,
      },
      products: priceIds
        .split(',')
        .map((id) => ({
          id: id.trim(),
          qty: 1,
        }))
        .filter((product) => product.id),
      fingerprint: uuidv4(),
      trackingId: uuidv4(),
      captchaToken: reCaptchaToken.value,
    }

    if (couponData?.isValidCode) {
      requestBody.couponCode = couponData?.code
      requestBody.couponSessionId = uuidv4()
    }

    const { data } = await axios.post(requestURL, requestBody)
    orderData.value = data && data.order ? data.order : {}

    return data
  } catch (error) {
    if (error?.response?.status === 429) {
      return { reprocess: true }
    }
    const errorMsg = extractAPIErrorMsg(error)
    setErrorMsg(errorMsg)
  } finally {
    isProcessingOrder.value = false
  }
}

async function processPayment() {
  try {
    const isValid = await paymentElementRef.value.validatePayment()
    if (!isValid) return
    const { order, reprocess } = await placeOrder()
    reCaptchaToken.value = undefined
    if (reprocess) {
      showRecaptcha.value = true
      isProcessingOrder.value = false
      return
    }
    if (order._id) {
      const sourceParams = {
        source: 'order',
        sourceId: order._id,
        traceId: order.traceId,
        captchaToken: reCaptchaToken.value,
      }
      // Convert props object to JSON string
      const parentProps = JSON.parse(base64ToUtf8(getLocalStorageItem(`p_inp`)))

      const propsJson = JSON.stringify({
        parentProps,
        sourceParams,
      })
      setLocalStorageItem(`p_inp_${sourceParams.sourceId}`, propsJson, 5)
      await paymentElementRef.value.confirmPayment(sourceParams)
    }
  } catch (error) {
    console.error('Error processing payment', error)
  }
}

const onCallBack = () => {
  return `${window.location.origin}${route.fullPath}`
}

</script>
<template>
  <div>
    <div>
      <div
        class="card"
        :class="customProviderInUse ? 'razorpay-card-min-height' : 'default-card-min-height'">
        <div>
          <UISpinner v-if="!isPaymentElementReady" />
          <div :class="isPaymentElementReady ? '' : 'hidden'">
            <UIPaymentElement
              ref="paymentElementRef"
              :env="paymentMode"
              id="payment-element"
              :altId="locationId"
              altType="location"
              :liveMode="isLivePayment"
              :debug="false"
              :showModeBadge="true"
              :stripeOptions="stripeOptions"
              :customProviderProps="customProviderOptions"
              @payment-callback="onPaymentCallback"
              :mode="squarePaymentMode"
              :callback-url="onCallBack()"
            />
          </div>
        </div>
      </div>
      <div class="text-center" v-if="showRecaptcha">
        <Recaptcha @expired="() => (reCaptchaToken = undefined)" @verify="onVerify" />
        <p v-if="reCaptchaError" class="text-red-600">{{ reCaptchaError }}</p>
      </div>
      <div v-if="errorMsg" class="flex p-2 text-red-700">
        <img :src="PaymentWarningIcon" alt="alert" /><span class="pl-2">{{
          errorMsg
          }}</span>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.container {
  display: grid;
  gap: 10px;
}

.form-input-label {
  display: inline-block;
  font-size: 13px;
  color: #32325d;
  margin-bottom: 5px;
}

.form-input {
  border-radius: 5px;
  background-position: 97%;
  border: 1px solid rgb(209, 213, 219);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) !important;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) !important;
  line-height: 24px;
  padding: 8px 12px;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  text-align: left;
  background: #fff;
  font-size: 12px;
}

.card {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px 24px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-card-min-height {
  min-height: 122px;
}

.razorpay-card-min-height {
  min-height: 500px;
}

.w-100 {
  width: 100%;
}

.loader {
  width: 35px;
  margin: auto;
}

.card-el-error-msg svg {
  font-size: 12px;
  color: rgba(248, 113, 113, 1);
}

.card-el-error-msg {
  font-size: 13px;
  text-align: center;
  padding: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(248, 113, 113, 1);
}

.card-el-error-msg svg .base {
  fill: rgb(226, 89, 80);
}

.card-el-error-msg img {
  margin-right: 4px;
}

.primary-btn {
  background-color: #37ca37;
  color: #fff;
}

.secondary-btn {
  background-color: #188bf6;
  color: #fff;
}

.mt-10 {
  margin-top: 10px;
}

.amount-style {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
}

::v-deep .ghl-payment-element input::placeholder {
  text-align: left !important;
  font-weight: normal !important;
  font-size: 0.875rem !important;
}
</style>
