<script lang="ts">

import PaymentModal from './PaymentModal.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, toRaw } from 'vue'
import { isClientPortal } from '@/helper'
import { getLocalStorageItem } from '@/helper/storage.helper'
import { defineComponent } from 'vue'
import PaymentFailedModal from './PaymentFailedModal.vue'


export default defineComponent({
  components: {
    PaymentModal,
    PaymentFailedModal
  },

  mounted() {
    if (this.$route.query.redirect_status === 'succeeded') {
      this.paymentProps.showThankYouMsg = true
      this.handlePaymentRedirection()
    } else if (this.$route.query.redirect_status === 'failed') {
      this.showFailedModal = true      
    }
  },
  computed: {
    redirectionStatus() {
      return this.$route?.query?.redirect_status
    }
  },
  data() {
    return {
      paymentProps: {},
      showPaymentModal: false,
      showFailedModal: false,
      cToken: '',
      url: '',
      featureFlag: null
    }
  },
  methods: {
    handlePaymentRedirection() {
      try {
        if (this.$route?.query?.payment_intent) {
          const cookieName = `p_inp_${this.$route?.query?.sourceId}`
          const cookieValue = getLocalStorageItem(cookieName)
          if (cookieValue) {
            this.paymentProps = JSON.parse(cookieValue).parentProps
            this.showPaymentModal = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    orderConfirmation(payload) {
      const { ct, previewUrl, upsellOfferId, featureFlag, closeModal } = payload

      this.paymentProps.showThankYouMsg = true
      this.cToken = ct
      this.url = previewUrl
      this.featureFlag = featureFlag
      if (closeModal) {
        setTimeout(() => {
          window.close()
        }, 5000)
      }
    },
    goToPreview() {
      if (location.hostname === 'localhost') {
        window.open(
          `http://localhost:4040/library-v2/?domain=${this.url}&token=${this.cToken}&location_id=${this.paymentProps.locationId}`
        )
      }
      if (isClientPortal()) {
        window.open(`https://${window.location.hostname}/courses/library-v2`)
      } else if (this.cToken && this.paymentProps) {
        window.open(
          `${this.url}/library-v2?&token=${this.cToken}&location_id=${this.paymentProps.locationId}`
        )
      }
    },

    redirectToCurrent() {
      this.showFailedModal = false
      this.$router.push({ path: this.$router.currentRoute.value.path })
    },

    changeCloseModal() {
      this.showFailedModal = !this.showFailedModal
    }
  },
  watch: {
    redirectionStatus(val) {
      if (val === 'succeeded') {
        this.handlePaymentRedirection()
      } else if (val === 'failed') {
        this.showFailedModal = true
      }
    }
  }
})


</script>
<template>
  <div>
    <PaymentModal
      v-if="showPaymentModal"
      :contact="paymentProps.contact"
      :showThankYouMsg="paymentProps.showThankYouMsg"
      :freeProduct="paymentProps.freeProduct"
      :show-modal="showPaymentModal"
      :couponData="paymentProps.couponData"
      @close="showPaymentModal = false"
      :location="paymentProps.location"
      :locationId="paymentProps.locationId"
      :offerId="paymentProps.offerId"
      :offer="paymentProps?.offer?._data"
      :checkoutSource="paymentProps.checkoutSource"
      @order-successful="orderConfirmation"
      @go-to-preview="goToPreview"
    />
    <PaymentFailedModal :show="showFailedModal" @redirect-to-current="redirectToCurrent"/>
  </div>
</template>
<style scoped>
.n-button {
  height: 44px !important;
}
</style>
