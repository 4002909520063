import Model from './Model'

export enum AssessmentStatusOptions {
  processing = 'processing',
  passed = 'passed',
  failed = 'failed',
}
export default class AssessmentStatus extends Model {
  constructor(payload?: {
    id?: string
    locationId?: string
    postId?: string
    productId?: string
    quizId?: string
    userId?: string
    attempt?: number
    submission?: any | null
    score?: number
    status?: string
    answer?: string
    feedback?: string
  }) {
    super(payload || {})
  }

  get id(): string {
    return this._data.id
  }

  get postId(): string {
    return this._data.postId
  }
  set postId(postId: string) {
    this._data.postId = postId
  }

  get locationId(): string {
    return this._data.locationId
  }
  set locationId(locationId: string) {
    this._data.locationId = locationId
  }

  get productId(): string {
    return this._data.productId
  }
  set productId(productId: string) {
    this._data.productId = productId
  }

  get quizId(): string {
    return this._data.quizId
  }
  set quizId(quizId: string) {
    this._data.quizId = quizId
  }

  get assignmentId(): string {
    return this._data.assignmentId
  }
  set assignmentId(assignmentId: string) {
    this._data.assignmentId = assignmentId
  }

  get userId(): string {
    return this._data.userId
  }

  get attempt(): number {
    return this._data.attempt
  }

  get submission(): any {
    return this._data.submission
  }
  set submission(submission: any) {
    this._data.submission = submission
  }

  get status(): string {
    return this._data.status
  }

  get score(): number {
    return this._data.score
  }

  get answer(): string {
    return this._data.answer
  }

  get feedback(): string {
    return this._data.feedback
  }
}
