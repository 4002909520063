<template>
  <div
    v-if="openModal"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-900 opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="ui-modal inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-40 my-8 lg:align-middle md:align-middle w-11/12 lg:w-2/5 md:w-2/3"
      >
        <div class="flex flex-col bg-white py-2 sm:p-6 sm:pb-4">
          <div class="flex justify-between items-center">
            <div class="pt-2">Files</div>
            <div
              class="h-1.5 p-2 md:px-0 lg:px-0 cursor-pointer"
              @click="closeModal"
            >
              <BaseIcon name="cross" hwClass="w-3 h-3" />
            </div>
          </div>

          <div class="sm:flex sm:items-start data-list overflow-y-auto">
            <div class="w-full text-center sm:text-left h-16">
              <div class="w-full">
                <div>
                  <div
                    class="flex flex-col my-2 rounded py-4 px-2 text-gray-700 cursor-pointer border bg-gray-50 hover:bg-gray-100 leading-5"
                    v-for="material in postMaterials"
                    :key="material.id"
                  >
                    <button
                      class="text-blue-500 flex items-center justify-between post-material-link"
                      @click="downloadFileFromURL(material.id, material.url, material.title)"
                    >
                      <div class="flex items-center">
                        <img
                          :src="getIcon(material.type)"
                          class="post-material-icon mr-2 h-6"
                        />
                        <a
                          :href="replaceBucketUrlWithCdnUrl(material.url)"
                          @click.prevent
                          class="post-material-title custom-word-break !no-underline"
                        >
                          {{ material.title }}
                        </a>
                      </div>
                      <UISpinner
                        size="small"
                        v-if="downloadingQueue.has(material.id)"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PostMaterial, { PostMaterialType } from '@/models/PostMaterial'
import { pdfIcon, imageIcon, docIcon } from '../../helper/constants'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import UISpinner from '@/components/common/UISpinner.vue'
import { downloadFile } from '@/helper/fileHandler'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'

export default defineComponent({
  components: {
    BaseIcon,
    UISpinner,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    materials: {
      type: Array,
    },
  },
  computed: {
    postMaterials(): PostMaterial[] {
      if (!this.materials) return [] as PostMaterial[]

      return this.materials
        .slice()
        .sort((a: any, b: any) => a.sequenceNo - b.sequenceNo) as PostMaterial[]
    },
  },
  data() {
    return {
      openModal: false,
      downloadingQueue: new Set(),
    }
  },
  watch: {
    showModal(val: boolean) {
      this.openModal = val
    },
  },
  methods: {
    async downloadFileFromURL(id: string, url: string, title: string) {
      try {
        this.downloadingQueue.add(id)
        await downloadFile(url, title)
      } catch (error) {
        console.error('Error while downloading file --> ', error)
      } finally {
        this.downloadingQueue.delete(id)
      }
    },
    closeModal() {
      this.$emit('close')
    },
    getIcon(materialType: string) {
      switch (materialType) {
        case PostMaterialType.pdf: {
          return pdfIcon
        }
        case PostMaterialType.image: {
          return imageIcon
        }
        default: {
          return docIcon
        }
      }
    },
    replaceBucketUrlWithCdnUrl,
  },
})
</script>
<style scoped>
.ui-modal {
  height: 30rem;
}
.data-list {
  height: 26rem;
}
@media (max-width: 450px) {
  .ui-modal {
    height: 25rem;
  }
  .data-list {
    height: 23rem;
  }
}
</style>
