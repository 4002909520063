<template>
  <div class="w-full flex flex-col justify-center items-center">
    <img class="h-20 py-4" :src="lockIcon" alt="Lock Category" />
    <div class="text-4xl extrabold text-center">This Lesson is Locked!</div>
    <div class="text-xl text-center lg:text-left py-4 extrabold">
      To gain access to this {{ lockedOverlayContentText }} please complete.
    </div>
    <div v-if="lockedPost">
      <button
        @click="redirectToPost(lockedPost.categoryId, lockedPost.id)"
        class="my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
        v-if="showLockedButton && lockedPost && lockedPost.title"
      >
        {{ `Post Name` && `Post: ${lockedPost.title}` }}
      </button>
    </div>
    <div v-if="lockedCategory">
      <button
        @click="redirectToPost(lockedCategory.id)"
        class="my-4 rounded text-white lg:px-8 lg:py-4 md:px-6 md:py-3 px-4 py-2 bg-gray-800 cursor-pointer"
        v-if="showLockedButton && lockedCategory && lockedCategory.title"
      >
        {{ `Category Name` && `Category: ${lockedCategory.title}` }}
      </button>
    </div>
    <div v-if="!showLockedButton">
      <UISpinner size="small" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps<{
  isCategoryLocked: boolean
  parentCategoryLocked: boolean
  isActivePostLocked: boolean
  lockedOverlayContentText: string
  lockedPost: any
  lockedCategory: any
  showLockedButton: boolean
  lockIcon: string
  productId: string
}>()

function redirectToPost(categoryId: string, postId?: string) {
  const params: { [key: string]: string } = {
    id: props.productId,
    category_id: categoryId,
  }

  if (postId) {
    params.post_id = postId
  }

  router.push({
    name: postId ? 'post-overview' : 'category-overview',
    params: params,
  })
}
</script>
