<template>
  <span
    :class="getHwClass"
    class="inline-flex icon-style items-center"
    v-html="svgCode"
  ></span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { CLOUD_ASSETS_URL, iconPaths } from '@/helper/constants'

export default defineComponent({
  name: 'BaseIcon',
  props: {
    hwClass: {
      type: String || Array<string>,
      default: 'h-5 w-5',
    },
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(iconPaths, value)
      },
    },
  },

  computed: {
    iconUrl() {
      return iconPaths[this.name]
    },
    getHwClass() {
      return this.hwClass
    },
  },
  data() {
    return {
      svgCode: '',
    }
  },
  async mounted() {
    await this.getIcon()
  },
  methods: {
    async getIcon() {
      if (!this.iconUrl) return
      await fetch(CLOUD_ASSETS_URL + this.iconUrl)
        .then((r) => r.text())
        .then((text) => {
          this.svgCode = text
        })
    },
  },
  watch: {
    async name() {
      await this.getIcon()
    },
  },
})
</script>
<style>
.icon-style svg {
  width: 100%;
}
</style>
