<template>
  <div id="progress-bar-container" class="w-full">
    <div
      id="progress-bar-background"
      class="relative w-full bg-gray-200 rounded-full overflow-hidden"
      :class="heightClass"
    >
      <div
        id="progress-bar-fill"
        class="h-full transition-all duration-300 ease-out"
        :class="colorClass"
        :style="{ width: progressWidth }"
      ></div>
    </div>
    <div
      id="progress-bar-percentage"
      v-if="showPercentage"
      class="text-right mt-2 text-sm font-normal text-gray-700"
    >
      {{ Math.round(progress) }}%
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue'

export default defineComponent({
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value: number) => value >= 0 && value <= 100,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md', // Default size
      validator: (value: string) =>
        ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
    },
    color: {
      type: String,
      default: 'blue', // Default color
      validator: (value: string) =>
        ['blue', 'red', 'green', 'yellow', 'purple'].includes(value),
    },
  },
  setup(props) {
    const progressWidth = ref('0%')

    const heightClass = computed(() => {
      switch (props.size) {
        case 'xs':
          return 'h-1'
        case 'sm':
          return 'h-2'
        case 'md':
          return 'h-3'
        case 'lg':
          return 'h-4'
        case 'xl':
          return 'h-5'
        default:
          return 'h-3'
      }
    })

    const colorClass = computed(() => {
      switch (props.color) {
        case 'blue':
          return 'bg-blue-500'
        case 'red':
          return 'bg-red-500'
        case 'green':
          return 'bg-green-500'
        case 'yellow':
          return 'bg-yellow-500'
        case 'purple':
          return 'bg-purple-500'
        default:
          return 'bg-blue-500'
      }
    })

    const animateProgress = (start: number, end: number, duration: number) => {
      const startTime = performance.now()

      const animate = (currentTime: number) => {
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / duration, 1)
        const currentWidth = start + (end - start) * progress
        progressWidth.value = `${currentWidth}%`

        if (progress < 1) {
          requestAnimationFrame(animate)
        }
      }

      requestAnimationFrame(animate)
    }

    watch(
      () => props.progress,
      (newProgress) => {
        animateProgress(0, newProgress, 500) // 500ms duration
      },
      { immediate: true }
    )

    return {
      progressWidth,
      heightClass,
      colorClass,
    }
  },
})
</script>

<style scoped>
/* No additional styles needed as Tailwind CSS is used */
</style>
