import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full p-4 flex flex-col gap-2" }

import { UISkeleton } from '@gohighlevel/ghl-ui'

export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryPlaylistLoader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(UISkeleton), { class: "w-full h-20 rounded-md" }),
    _createVNode(_unref(UISkeleton), { class: "w-full h-20 rounded-md" }),
    _createVNode(_unref(UISkeleton), { class: "w-full h-20 rounded-md" })
  ]))
}
}

})