<template>
  <div
    id="post-details-container"
    class="neue-template flex flex-col justify-center my-2 mx-auto"
    :class="
      disableActions && disableActions.pointerEvents === 'none' ? 'px-2' : ''
    "
  >
    <div>
      <div
        class="text-nova-blue-600 text-base font-semibold mt-4 lessonPrimaryStyle breadCrumb__nav"
      >
        {{ product.title }}
      </div>
      <div
        class="progress rounded-full bg-gray-300 h-1 mb-1"
        :class="
          activePost.video || activePost.audio
            ? 'lesson-video-progress'
            : 'lesson-progress'
        "
      >
        <div
          id="progress-completed"
          class="bg-nova-blue-600 lesson-progress__color h-full w-full flex justify-center items-center rounded-full text-xs leading-none text-center"
          :style="{
            width: `${percentCompleted || 0}%`,
          }"
        ></div>
      </div>
    </div>
    <div class="my-2 grid grid-cols-12 w-full gap-4">
      <div
        class="col-span-4 side-panel bg-white postqueue-background border br-1 md:h-10/12-screen overflow-y-auto my-2 md:my-0"
        id="category-list"
        style="scrollbar-width: thin"
      >
        <div>
          <NeueCategoryPostTree
            :level="0"
            :category-tree="categoryTree"
            :completed-posts="completedPostIds"
            :isBackground="false"
            :active-post="activePost"
            :active="toggleCategory"
            :highlightBackground="highlightBackground"
            :highlightColor="highlightColor"
            :post-indexes="postIndexes"
            :hasProductAccess="hasProductAccess"
          />
        </div>
      </div>
      <div class="main-section w-full col-span-8">
        <div class="post-details">
          <div class="video-post-body-container">
            <div
              v-if="
                (activePost.video && hasProductAccess && videoOptions) ||
                (activePost.audio && hasProductAccess)
              "
            >
              <video-player
                id="post-video"
                v-if="activePost.video && hasProductAccess && videoOptions"
                class="items-center mb-5"
                :loading="fetchingPost"
                :video="activePost && activePost.video"
                :assetUrl="activePost && activePost.assetUrls"
                :options="videoOptions"
                :trackTime="true"
                :videoCompletePercentage="videoCompletePercentage"
                :video-thumbnail="activePost.posterImage"
                @getVideoTime="getVideoTime"
                @onPause="storeVideoProgress"
                @onEnded="storeVideoProgress"
                @onPlay="updateVideoPlay"
              />
              <audio-player
                v-if="activePost.audio"
                class="player-height mb-4"
                :audio="activePost.audio"
                :thumbnail="activePost.posterImage"
                :post="activePost"
                :product="product"
                @getAudioTime="getAudioTime"
                @onPause="storeAudioProgress"
                @onEnded="storeAudioProgress"
                @onPlay="updateAudioPlay"
              />
            </div>
            <div
              v-else
              class="relative post-description-container post-body bg-white p-5 shadow rounded lesson-body-background overflow-y-auto custom-list-styling"
              :class="[
                expandPostData ? 'expanded-post-body' : 'post-body-description',
                !hasProductAccess ? 'flex items-center justify-center' : '',
              ]"
              @mouseenter="showExpandIcon = true"
              @mouseleave="showExpandIcon = false"
            >
              <div v-if="hasProductAccess">
                <div
                  v-if="currentPostContentType === postContentType.quiz"
                  class="h-full flex flex-col justify-center"
                >
                  <QuizStatusComponent
                    :post="activePost"
                    :categoryId="categoryId"
                    @changeQuizStatus="changeQuizStatus"
                  />
                </div>
                <div
                  v-else-if="
                    currentPostContentType === postContentType.assignment
                  "
                >
                  <AssignmentStatus
                    :post="activePost"
                    :categoryId="categoryId"
                    :showRetakeButton="true"
                    :nextPost="nextPostData"
                    @goToNextPost="goToNextPost"
                  />
                </div>
                <div
                  v-else
                  id="post-description"
                  class="lessonSecondaryStyle lesson-body-description mb-5 custom-word-break custom-list-styling"
                  v-html="activePost.description"
                ></div>
                <div
                  v-if="showExpandIcon && isExpandable"
                  class="z-20 bottom-0 right-0 cursor-pointer expand-icon sticky float-right"
                  @click="expandPostDetails"
                >
                  <BaseIcon name="fullwindow" hwClass="w-5 h-5" />
                </div>
              </div>
              <div
                v-else
                class="text-center flex flex-col items-center justify-center"
              >
                <Lock01Icon class="h-20 w-20" />
                <UITextLgMedium>
                  {{ productAccessMessage }}
                </UITextLgMedium>
              </div>
            </div>
          </div>
          <div class="mt-5 flex justify-between items-center">
            <div>
              <button
                @click="goToNextPost()"
                class="border rounded py-2"
                :disabled="!hasProductAccess || !isNextPostButtonEnabled"
                :class="
                  hasProductAccess && isNextPostButtonEnabled
                    ? 'border-nova-blue-800'
                    : 'border-gray-400 cursor-not-allowed'
                "
              >
                <span
                  class="lessonPrimaryStyle navigation-btn px-6 py-2"
                  :class="
                    hasProductAccess && isNextPostButtonEnabled
                      ? 'text-nova-blue-800'
                      : 'text-gray-400'
                  "
                  >Next Lesson</span
                >
              </button>
            </div>
            <div>
              <div
                class="post-status-fetch-message flex justify-center md:justify-end items-center"
                v-if="fetchingPostCompletionStatus"
              >
                Fetching post status..
              </div>
              <div
                v-else
                class="pt-2 md:pt-0"
                :disabled="disablePostCompletionButton || isAdmin"
              >
                <button
                  @click="togglePostCompletion()"
                  :disabled="
                    disablePostCompletionButton ||
                    (isAdmin && !hasProductAccess)
                  "
                  class="py-2 px-2 rounded border flex justify-center items-center"
                  :class="markCompletedBtnCss"
                >
                  <span :style="{ color: postCompletedIcon }">
                    <BaseIcon name="correct" hwClass="w-4 h-4" />
                  </span>
                  <span class="px-2">{{ postCompletionButtonText }}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="
              (activePost.video || activePost.audio) && activePost.description
            "
            class="post-body my-2 bg-white shadow rounded lesson-body-background overflow-y-auto"
          >
            <div
              id="post-description"
              class="course-body-height p-4 custom-list-styling lessonSecondaryStyle lesson-body-description custom-word-break"
            >
              <span v-if="expandPost">
                <span v-html="activePost.description"></span>
                <span
                  class="text-blue-500 cursor-pointer pb-2"
                  @click="expandPostDescription"
                  >Show Less</span
                >
              </span>
              <span v-else v-html="postDescription"> </span>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <InstructorCard :instructor="instructor" :newYorkTheme="true" />
        </div>
        <div class="mt-4" v-if="activePost.commentPermission !== 'hidden'">
          <CommentList
            :productCommentPrivacy="product.commentPrivacy"
            :postId="postId"
            :productId="productId"
            :instructor="instructor"
            :commentPermission="activePost.commentPermission"
            :pendoPayload="{ post: activePost, product, category }"
            :commentIdToScroll="commentIdToScroll"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRaw } from 'vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Category from '@/models/Category'
import NeueCategoryPostTree from '@/components/common/NeueCategoryPostTree.vue'
import InstructorCard from '@/components/product/InstructorCard.vue'
import { findNextPostId } from '@/helper'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '@/models/CategoryTreeNode'
import CommentList from '@/components/comments/CommentList.vue'
import { MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE } from '@/helper/constants'
import { showCollapsableDescription } from '@/helper'
import QuizStatusComponent from '@/components/assessments/QuizStatusComponent.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import AssignmentStatus from '@/components/assessments/AssignmentStatus.vue'
import { Lock01Icon } from '@gohighlevel/ghl-icons/24/outline'
import { UITextLgMedium } from '@gohighlevel/ghl-ui'
import { getProductAccessMessage } from '@/helper/offerAccess'
import { PostContentType } from '@/models/Post'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'

export default defineComponent({
  components: {
    VideoPlayer,
    NeueCategoryPostTree,
    InstructorCard,
    CommentList,
    QuizStatusComponent,
    AssignmentStatus,
    BaseIcon,
    Lock01Icon,
    UITextLgMedium,
    AudioPlayer,
  },
  props: {
    product: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    activePost: {
      type: Object,
    },
    category: {
      type: Object,
    },
    videoOptions: {
      type: Object,
    },
    playlist: {
      type: Array,
    },
    disableActions: {
      type: Object,
    },
    nextPost: {
      type: Object,
    },
    totalPosts: {
      type: Number,
      default: 0,
    },
    completedPosts: {
      type: Number,
      default: 0,
    },
    hasProductAccess: {
      type: Boolean,
    },
    videoCompletePercentage: Number,
    postCompletionButtonText: String,
    postMarkedAsCompleted: Boolean,
    lessonButton: Object,
    isAdmin: Boolean,
    categoryTree: Array,
    fetchingPost: Boolean,
    fetchingPostCompletionStatus: Boolean,
    disablePostCompletionButton: Boolean,
    completedPostIds: {
      type: Set,
      default: new Set(),
    },
  },
  created() {
    this.emitter.on('updateActiveCategory', this.updateActiveCategory)
  },
  beforeDestroy() {
    this.emitter.off('updateActiveCategory', this.updateActiveCategory)
    this.storeVideoProgress()
  },
  mounted() {
    document
      .querySelector('#read-more-post')
      ?.addEventListener('click', this.expandPostDescription)
    this.createActiveCategory()
    this.isVideoProgressUpdated = false
    this.loadStylesheetTags()
  },
  computed: {
    productAccessMessage() {
      return getProductAccessMessage(this.hasProductAccess, this.product)
    },
    currentPostContentType(): string {
      return this.activePost && this.activePost.contentType
    },
    productId(): string {
      return this.$route.params.id
    },
    categoryId(): string {
      return this.$route.params.category_id
    },
    postId(): string {
      return this.$route.params.post_id
    },
    themeSettings(): any {
      return this.product.customizations?.lessonSettings?.themeSettings
    },
    sections(): any {
      return this.product.customizations?.lessonSettings?.sections
    },
    postCompletedIcon(): string {
      if (this.sections?.lessonBody?.button?.color) {
        return this.sections?.lessonBody?.button?.color
      } else if (
        this.themeSettings?.primaryStyle?.color &&
        !this.completedPostIds.has(this.postId)
      ) {
        return this.themeSettings?.primaryStyle.color
      }
      return this.postMarkedAsCompleted
        ? '#2f855a'
        : this.disablePostCompletionButton || this.isAdmin
        ? '#6b7280'
        : '#ffffff'
    },
    markCompletedBtnCss(): string {
      if (
        this.disablePostCompletionButton ||
        this.isAdmin ||
        !this.hasProductAccess
      ) {
        return 'lessonPrimaryStyle lesson-body__btn bg-white text-gray-600 cursor-not-allowed'
      } else if (this.postMarkedAsCompleted) {
        return 'cursor-pointer text-green-700'
      }
      return 'cursor-pointer bg-nova-blue-800 text-white lessonPrimaryStyle lesson-body__btn'
    },
    availableCategoryTree(): any {
      return this.categoryTree.filter((node: any) => node.node.available)
    },
    availablePlayList(): Array<CategoryTreeNode> {
      return this.playlist.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
    },
    nextPostData(): any {
      return findNextPostId(
        this.availableCategoryTree,
        this.postId,
        this.category,
        this.availablePlayList
      )
    },
    postIndexes(): Map<string, number> {
      const postIndexes = new Map()
      for (let i = 0; i < this.categoryTree.length; i++) {
        let index = 1
        this.categoryTree[i]?.childs?.forEach((node: CategoryTreeNode) => {
          if (node.type === CategoryTreeNodeType.Post) {
            postIndexes.set(node.id, index++)
          } else {
            for (let i = 0; i < node.childs.length; i++) {
              postIndexes.set(node.childs[i].id, index++)
            }
          }
        })
      }
      return postIndexes
    },
    postDescription(): string {
      return this.activePost.description &&
        this.activePost.description.length >
          MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE
        ? this.derivePostDescription() +
            '.... ' +
            `<span class="text-blue-500 cursor-pointer" id="read-more-post">Read More</span>`
        : this.activePost.description
    },
    isExpandable(): boolean {
      return this.activePost?.description?.length > 5700 ? true : false
    },
    navigationButtonColor(): string {
      if (this.sections?.lessonBody?.navigationButton?.color) {
        return this.sections.lessonBody.navigationButton.color
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle.color
      }
      return '#3B5998'
    },
    highlightBackground(): string {
      if (this.sections?.courseNavigation?.highlight?.backgroundColor) {
        return this.sections?.courseNavigation?.highlight?.backgroundColor
      } else if (this.themeSettings?.primaryStyle?.color) {
        return this.themeSettings?.primaryStyle?.color
      }
      return '#709be0'
    },
    highlightColor(): string {
      if (this.sections?.courseNavigation?.highlight?.color) {
        return this.sections.courseNavigation.highlight.color
      }
      return '#202DAB'
    },
    percentCompleted(): number {
      if (this.completedPosts && this.totalPosts) {
        return Math.round((this.completedPosts * 100) / this.totalPosts)
      }

      return 0
    },
    featureFlags() {
      return this.$store.state.featureFlag
    },
    isNextPostButtonEnabled() {
      return this.nextPostData && this.nextPostData.postId
    },
  },
  data() {
    return {
      parentCategory: null as Category,
      expandPost: false,
      readMorePost: null,
      showExpandIcon: false,
      expandPostData: false,
      post: {},
      toggleCategory: {},
      canRetakeQuiz: false,
      commentIdToScroll: this.$route.query.commentId,
      postContentType: PostContentType,
    }
  },
  methods: {
    loadStylesheetTags() {
      const customStyle = document.createElement('link')
      customStyle.setAttribute('rel', 'stylesheet')
      customStyle.setAttribute('vmid', 'newYorkFonts')
      customStyle.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=Lato'
      )
      document.head.appendChild(customStyle)
    },
    updateVideoPlay() {
      this.$emit('updateVideoPlay')
    },
    storeVideoProgress() {
      this.$emit('storeVideoProgress')
    },
    getVideoTime(currentTime: Array<number>) {
      this.$emit('updateVideoTime', currentTime)
    },
    updateAudioPlay() {
      this.$emit('updateAudioPlay')
    },
    storeAudioProgress() {
      this.$emit('storeAudioProgress')
    },
    getAudioTime(currentTime: Array<number>) {
      this.$emit('updateAudioTime', currentTime)
    },
    changeQuizStatus(quizRetake: boolean) {
      this.canRetakeQuiz = quizRetake
    },
    goToNextPost() {
      if (!this.hasProductAccess) return
      trackCustomGAEvents(GAEventTypes.NEXT_LESSON, {
        postId: this.nextPostData.postId,
      })
      this.$router.push({
        name: 'post-overview',
        params: {
          id: this.productId,
          category_id: this.nextPostData.categoryId,
          post_id: this.nextPostData.postId,
        },
        query: {
          template_id: this.templateId,
          ...this.$route.query,
        },
      })
    },
    expandPostDescription() {
      if (!this.expandPost) {
        document
          .querySelector('#read-more-post')
          .removeEventListener('click', this.expandPostDescription)
      }
      this.expandPost = !this.expandPost
      if (!this.expandPost) {
        setTimeout(() => {
          document
            .querySelector('#read-more-post')
            .addEventListener('click', this.expandPostDescription)
        }, 500)
      }
    },
    updateActiveCategory(categoryId: string) {
      this.toggleCategory[categoryId] = !this.toggleCategory[categoryId]
    },
    derivePostDescription(): string {
      return showCollapsableDescription(this.activePost)
    },
    expandPostDetails() {
      this.expandPostData = !this.expandPostData
    },
    togglePostCompletion() {
      if (!this.hasProductAccess) return
      this.$emit('togglePostCompletion')
    },
    createActiveCategory() {
      const obj = {}
      for (let i = 0; i < this.categoryTree.length; i++) {
        obj[this.categoryTree[i].id] = false
      }
      if (this.category.id && !this.category.parentCategory) {
        obj[this.category.id] = true
      } else if (this.category.parentCategory) {
        obj[this.category.parentCategory] = true
      }
      this.toggleCategory = Object.assign({ ...obj })
    },
  },
  watch: {
    categoryTree: {
      handler(value) {
        this.createActiveCategory()
      },
    },
    'category.id'() {
      this.createActiveCategory()
    },
  },
})
</script>
<style scoped>
.neue-template {
  font-family: Lato;
}
.custom-word-break {
  word-break: break-word;
}

.post-body-description {
  height: 600px;
}
.expanded-post-body {
  height: 1000px;
}

.post-description-container {
  min-width: 65%;
}

@media screen and (max-width: 769px) {
  .post-body-description {
    height: 250px;
  }
  .expanded-post-body {
    height: 400px;
  }
}

.course-body-height {
  max-height: 30rem;
}
.assignment-button {
  background-color: #3b5998;
  font-weight: bold;
  color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
}
</style>
