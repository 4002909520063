<template>
  <div id="lesson-container" class="h-full w-full flex flex-col gap-2">
    <div
      id="breadcrumb-container"
      class="w-full xl:w-11/12 mx-auto content-fix-width mt-4"
    >
      <NeoClassicBreadcrumbs
        :product-id="productId"
        :product-title="product && product.title"
        :category-id="categoryId"
        :category-title="category && category.title"
        :post-id="postId"
        :post-title="activePost && activePost.title"
      />
    </div>

    <div
      id="content-container"
      class="grid grid-cols-12 gap-4 xl:w-11/12 mx-auto content-fix-width"
    >
      <LessonPageLoader class="col-span-8" v-if="isLoading" />
      <Paper
        class="p-4 w-full col-span-8 max-h-fit"
        v-else-if="isPostLocked(activePost)"
      >
        <LockedContent
          :content-type="CategoryTreeNodeTypeView.Post"
          :lockedByPostId="activePost.lockedByPost"
          :lockedByCategoryId="activePost.lockedByCategory"
          :completedPostIds="completedPostIds"
        />
      </Paper>
      <Paper
        v-else-if="isCategoryInDrip(activePost.parentCategory || categoryId)"
        class="p-4 w-full col-span-8 h-5/6"
      >
        <DripContent
          :remainingTime="
            categoriesAndPostsLookup.get(activePost.categoryId).node.dripDays
          "
        />
      </Paper>
      <div
        v-else
        id="content-container "
        class="col-span-8 flex flex-col gap-2 mb-4"
      >
        <!-- Below theme colors will be moved to token -->
        <Paper class="p-4" v-if="activePost.video && videoOptions">
          <VideoPlayer
            id="post-video"
            ref="mediaContainer"
            class="player-height"
            :loading="fetchingPost"
            :video="activePost && activePost.video"
            :options="videoOptions"
            :trackTime="true"
            :videoCompletePercentage="videoCompletePercentage"
            :video-thumbnail="activePost.posterImage"
            :assetUrl="assetUrls"
            controls-background="#1D2939"
            theme-color="#ffffff99"
            radius="0.375rem"
            menuColor="#1D2939"
            defaultIconColors="#ffffff"
            textColor="#ffffff"
            @getVideoTime="getVideoTime"
            @onPause="storeVideoProgress"
            @onEnded="storeVideoProgress"
            @onPlay="updateVideoPlay"
          />
        </Paper>
        <Paper class="p-4" v-else-if="activePost.audio">
          <AudioPlayer
            id="post-audio"
            ref="mediaContainer"
            class="rounded-lg !mb-0 !max-w-full"
            :loading="fetchingPost"
            :trackTime="true"
            controls-background="#1D2939"
            theme-color="#ffffff99"
            :audio="activePost.audio"
            :thumbnail="activePost.posterImage"
            :post="activePost"
            :product="product"
            :audioCompletePercentage="audioCompletePercentage"
            @getAudioTime="updateAudioTime"
            @onPause="storeAudioProgress"
            @onEnded="storeAudioProgress"
            @onPlay="updateAudioPlay"
          />
        </Paper>

        <Paper
          v-if="currentPostContentType === PostContentType.quiz"
          class="flex justify-center p-4 player-height"
        >
          <NeoQuizStatusComponent
            :loading="!!quiz"
            :quiz="quiz"
            :post="activePost"
            :categoryId="categoryId"
            @changeQuizStatus="changeQuizStatus"
          />
        </Paper>

        <div v-if="activePost.contentType === PostContentType.assignment">
          <AssignmentLaunch
            :assignment="assignment"
            :assessmentStatus="assessmentStatus"
          />
        </div>
        <div v-else id="about-lesson-wrapper">
          <AboutLesson
            v-if="activePost.description"
            id="post-description"
            class="lesson-body-description"
            :description="activePost.description"
          >
            <template #at-end>
              <MarkPostComplete
                :wrapper="false"
                :activePost="activePost"
                :nextPost="nextPost"
                @togglePostCompletion="togglePostCompletion"
                :isPostCompleted="isPostCompleted"
                :buttonText="postCompletionButtonText"
                :disablePostCompletionButton="disablePostCompletionButton"
              />
            </template>
          </AboutLesson>
        </div>
        <div v-if="!activePost.description" id="post-completion-wrapper">
          <MarkPostComplete
            :activePost="activePost"
            :nextPost="nextPost"
            @togglePostCompletion="togglePostCompletion"
            :isPostCompleted="isPostCompleted"
            :buttonText="postCompletionButtonText"
            :disablePostCompletionButton="disablePostCompletionButton"
          />
        </div>
        <div
          v-if="activePost.commentPermission === PostCommentStatus.enabled"
          id="comments-wrapper"
        >
          <Paper class="w-full p-6 mb-4">
            <NeoComments
              :productCommentPrivacy="product.commentPrivacy"
              :postId="postId"
              :productId="productId"
              :instructor="instructor"
              :pendoPayload="{ post: activePost, product, category }"
              :commentIdToScroll="commentIdToScroll"
            />
          </Paper>
        </div>
      </div>
      <div id="playlist-container" class="col-span-4 flex flex-col gap-2">
        <Paper id="playlist-wrapper" class="w-full">
          <CategoryPlaylist
            :paperWrapper="false"
            :playlist="playlist"
            :categoryTree="categoryTree"
            :category="category"
            :active-post="activePost"
            :nextCategoryId="nextCategoryId"
            :completedPostIds="completedPostIds"
            :allCompletedPosts="allCompletedPosts"
            :refresh="isLoading"
          />
        </Paper>
        <div
          v-if="activePost.materials && activePost.materials.length > 0"
          id="attached-files-wrapper"
        >
          <AttachedFiles :fileList="activePost.materials" />
        </div>
        <div id="instructor-detail-wrapper">
          <InstructorCard :instructor="instructor" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  PropType,
  computed,
  ref,
  onBeforeUnmount,
  onMounted,
  watch,
  provide,
  ComputedRef,
} from 'vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Post, { PostContentType, PostCommentStatus } from '@/models/Post'
import { CategoryTreeNodeTypeView } from '@/models/CategoryTreeNode'
import AboutLesson from '@/components/neo-classic-theme/lesson-page/AboutLesson.vue'
import InstructorCard from '@/components/neo-classic-theme/common/InstructorCard.vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import CategoryPlaylist from '@/components/neo-classic-theme/lesson-page/CategoryPlaylist.vue'
import AttachedFiles from '@/components/neo-classic-theme/common/AttachedFiles.vue'
import MarkPostComplete from '@/components/neo-classic-theme/lesson-page/MarkPostComplete.vue'
import AssignmentLaunch from '@/components/neo-classic-theme/assignment/AssignmentLaunch.vue'
import LessonPageLoader from '@/components/neo-classic-theme/loaders/LessonPageLoader.vue'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import Category from '@/models/Category'
import NeoQuizStatusComponent from '@/components/neo-classic-theme/quiz/NeoQuizStatusComponent.vue'
import AssessmentStatus from '@/models/AssessmentStatus'
import { useRoute } from 'vue-router'
import LockedContent from './common/post/LockedContent.vue'
import { getCategoryAndPostsLookup } from '@/helper'
import Assignment from '@/models/Assignment'
import { CategoryVisibility } from '@/models/Category'
import NeoComments from '@/components/neo-classic-theme/comments/NeoComments.vue'
import NeoClassicBreadcrumbs from '@/components/neo-classic-theme/common/NeoClassicBreadcrumbs.vue'
import DripContent from '@/components/neo-classic-theme/common/DripContent.vue'

const route = useRoute()
const props = defineProps({
  product: {
    type: Object,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  instructor: {
    type: Object,
  },
  activePost: {
    type: Object as PropType<Post>,
    default: () => ({}),
  },
  assignment: {
    type: Object as PropType<Assignment>,
    default: () => ({}),
  },
  assessmentStatus: {
    type: Object as PropType<AssessmentStatus>,
    default: () => ({}),
  },
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
  videoOptions: {
    type: Object,
  },
  nextPost: {
    type: Object,
  },
  playlist: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [], 
  },
  disableActions: {
    type: Object,
  },
  videoCompletePercentage: {
    type: Number,
    default: 0,
  },
  audioCompletePercentage: {
    type: Number,
    default: 0,
  },
  nextCategoryId: {
    type: String,
  },
  markCompletedBtnCss: {
    type: String,
  },
  postCompletionButtonText: {
    type: String,
    default: 'Mark as complete',
  },
  lessonButton: {
    type: Object,
  },
  isAdmin: {
    type: Boolean,
  },
  nextLesson: {
    type: Object,
  },
  fetchingPost: {
    type: Boolean,
  },
  fetchingPostCompletionStatus: {
    type: Boolean,
  },
  disablePostCompletionButton: {
    type: Boolean,
    default: false,
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  allCompletedPosts: {
    type: Array<{ postId: string; percentage: number }>,
    default: () => [],
  },
  quiz: {
    type: Object,
    default: () => null,
  },
})

const emit = defineEmits([
  'togglePostCompletion',
  'updateVideoTime',
  'storeVideoProgress',
  'updateVideoPlay',
  'updateAudioTime',
  'storeAudioProgress',
  'updateAudioPlay',
])

// Map to store records for each category and posts for quick lookup
const categoriesAndPostsLookup = ref(new Map())
provide('categoriesAndPostsLookup', categoriesAndPostsLookup)

const computedLoadingFlag = computed(() => props.isLoading)
provide('isLoading', computedLoadingFlag)

const canRetakeQuiz = ref(false)
const commentIdToScroll = ref(null)
const maxHeightOfPlaylist = ref(450)
const mediaContainer = ref(null)
const updatingLookup = ref(false)

const productId = computed(() => route.params.id || props.product.id)
const categoryId = computed(
  () => (route.params.category_id as string) || props.category.id
)
const postId = computed(
  () => route.params.post_id || props.activePost.id
) as ComputedRef<string>

const assetUrls = computed(() => {
  return props.activePost && props.activePost.assetUrls
})

const currentPostContentType = computed(() => props.activePost.contentType)
const isPostCompleted = computed(() => props.completedPostIds.has(postId.value))

function isPostLocked(post: Post) {
  if (post.visibility === CategoryVisibility.locked) {
    const lockedByPostId = post.lockedByPost
    const lockedByCategory = post.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false

    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)

      if (category) {
        return (
          isLocked ||
          !category.childs.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

function isCategoryInDrip(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (category) {
    if (category.node.available) return false
    return category.node.dripDays > 0
  }
  return false
}

const updatePlaylistHeight = () => {
  if (mediaContainer.value)
    maxHeightOfPlaylist.value = mediaContainer.value.offsetHeight
      ? mediaContainer.value.offsetHeight
      : maxHeightOfPlaylist.value
}

const togglePostCompletion = () => {
  emit('togglePostCompletion')
}

const changeQuizStatus = (quizRetake: boolean) => {
  canRetakeQuiz.value = quizRetake
}

const updateVideoPlay = () => {
  emit('updateVideoPlay')
}

const storeVideoProgress = () => {
  emit('storeVideoProgress')
}

const getVideoTime = (currentTime: Array<number>) => {
  emit('updateVideoTime', currentTime)
}

const storeAudioProgress = () => {
  emit('storeAudioProgress')
}

const updateAudioPlay = () => {
  emit('updateAudioPlay')
}

const updateAudioTime = (currentTime: Array<number>) => {
  emit('updateAudioTime', currentTime)
}

onMounted(() => {
  updatingLookup.value = true
  try {
    categoriesAndPostsLookup.value = getCategoryAndPostsLookup(
      props.categoryTree
    )
    updatePlaylistHeight()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error) {
    console.error('Error while updating lookup: ', error)
  } finally {
    updatingLookup.value = false
  }
})

onBeforeUnmount(() => {
  storeVideoProgress()
})

watch(
  () => props.activePost,
  () => {
    updatePlaylistHeight()
  }
)

watch(
  () => props.categoryTree,
  () => {
    categoriesAndPostsLookup.value = getCategoryAndPostsLookup(
      props.categoryTree
    )
  }
)
</script>
<style scoped>
.player-height {
  height: 100%;
  aspect-ratio: 16/9;
}
</style>
