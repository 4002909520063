import { toRaw } from 'vue'
import moment from 'moment'

export function getProductAccessMessage(hasProductAccess, product) {
  if (hasProductAccess === true) return
  const purchaseData = toRaw(product)?._data?.userPurchase
  if (
    purchaseData &&
    purchaseData?.validityStart &&
    moment().isBefore(purchaseData?.validityStart)
  ) {
    return `Access will be available from ${moment(
      purchaseData?.validityStart
    ).format('MMMM Do, YYYY')}`
  } else if (
    purchaseData &&
    purchaseData?.validityEnd &&
    moment().isAfter(purchaseData?.validityEnd)
  ) {
    return `Access to the course has expired on ${moment(
      purchaseData?.validityEnd
    ).format('MMMM Do, YYYY')}`
  }
  return "You haven't receive access to the Course yet"
}
