import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  id: "header-container ",
  class: "top-0 w-full z-[10] font-sans"
}
const _hoisted_3 = {
  id: "content-container",
  class: "grid grid-cols-12 gap-6 content-fix-width mx-auto mt-6 w-[80vw]"
}
const _hoisted_4 = { class: "col-span-9" }
const _hoisted_5 = { class: "col-span-3" }

import {
  onMounted,
  ref,
  provide,
  computed,
  PropType,
  ComputedRef,
  watch,
} from 'vue'
import LessonsStats from '@/components/neo-classic-theme/landing-page/LessonsStats.vue'
import WelcomeHeader from '@/components/neo-classic-theme/landing-page/WelcomeHeader.vue'
import CategoryListView from '@/components/neo-classic-theme/landing-page/CategoryListView.vue'
import InstructorCard from '@/components/neo-classic-theme/common/InstructorCard.vue'
import { getProductAccessMessage } from '@/helper/offerAccess'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import { getCategoryAndPostsLookup } from '@/helper'
import CategoryListViewLoader from '@/components/neo-classic-theme/loaders/CategoryListViewLoader.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoClassicTemplate',
  props: {
  loading: Boolean,
  heroSection: Object,
  product: Object,
  instructor: Object,
  categoryTree: Array<CategoryTreeNode>,
  nextPost: Object,
  completedPosts: Number,
  totalPosts: Number,
  postIdsCompleted: Set,
  categoryIdsCompleted: Set,
  courseCompleted: Boolean,
  productStarted: Boolean,
  hasProductAccess: Boolean,
  allCompletedPostsData: {
    type: Object as PropType<{
      completedPostIds: Set<string>
      posts: Array<object>
    }>,
    default: null,
  },
},
  emits: ['goToNextPost'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit

// Created a Map of records for each category and posts for quick lookup
const categoriesAndPostsLookup = ref(new Map())
provide('categoriesAndPostsLookup', categoriesAndPostsLookup)

function updateTreeLookup() {
  categoriesAndPostsLookup.value = getCategoryAndPostsLookup(props.categoryTree)
}

const postProgress = computed(() => {
  if (
    !props.allCompletedPostsData ||
    !props.allCompletedPostsData.posts ||
    !props.allCompletedPostsData.posts.length
  )
    return new Map()

  return props.allCompletedPostsData.posts.reduce(
    (agg: Map<string, number>, post: any): Map<string, number> => {
      agg.set(post.postId, post.percentage)
      return agg
    },
    new Map()
  )
}) as ComputedRef<Map<string, number>>

const productAccessMessage = computed(() => {
  return getProductAccessMessage(props.hasProductAccess, props.product)
})

const buttonText = computed(() => {
  if (props.productStarted) {
    if (props.totalPosts === props.completedPosts) return 'Go to'
    else return 'Resume Course'
  } else return 'Start Course'
})

const productProgress = computed(() => {
  return (props.completedPosts / props.totalPosts) * 100
})

onMounted(() => {
  updateTreeLookup()
})

watch(() => props.categoryTree, updateTreeLookup)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(WelcomeHeader, {
        title: __props.product.title,
        description: __props.product.description,
        showButton: true,
        buttonText: buttonText.value,
        backgroundImage: __props.product.posterImage || __props.heroSection.background,
        onHeaderButtonClick: _cache[0] || (_cache[0] = ($event: any) => (emit('goToNextPost'))),
        loading: __props.loading,
        productAccessMessage: productAccessMessage.value,
        hasProductAccess: __props.hasProductAccess
      }, null, 8, ["title", "description", "buttonText", "backgroundImage", "loading", "productAccessMessage", "hasProductAccess"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (__props.loading)
          ? (_openBlock(), _createBlock(CategoryListViewLoader, { key: 0 }))
          : (_openBlock(), _createBlock(CategoryListView, {
              key: 1,
              categoryTree: __props.categoryTree,
              postProgress: postProgress.value,
              completedPostIds: __props.postIdsCompleted,
              hasProductAccess: __props.hasProductAccess
            }, null, 8, ["categoryTree", "postProgress", "completedPostIds", "hasProductAccess"]))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(LessonsStats, {
          completed: __props.completedPosts,
          total: __props.totalPosts,
          progress: productProgress.value,
          "poster-image": __props.product.posterImage
        }, null, 8, ["completed", "total", "progress", "poster-image"]),
        _createVNode(InstructorCard, {
          class: "mt-4",
          instructor: __props.instructor
        }, null, 8, ["instructor"])
      ])
    ])
  ]))
}
}

})