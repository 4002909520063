function getCookie() {
  const cookie = window.$cookies.get('cat') || localStorage.getItem('cat')
  return cookie ? JSON.parse(atob(cookie)) : null
}

function getAdminCookie() {
  const cookie = window.$cookies.get('acat') || localStorage.getItem('acat')
  return cookie ? JSON.parse(atob(cookie)) : null
}

export const adminMode = () => {
  const cookie = getAdminCookie()
  if (cookie) {
    return cookie.role === 'admin'
  }
  return null
}

export const fetchCat = () => {
  return getCookie()
}

export const fetchAdminCat = () => {
  return getAdminCookie()
}
