<template>
  <div
    class="playlist-queue-container h-full bg-gray-800 mt-3 md:mt-0 postqueue-container"
  >
    <div
      class="playlist-info flex justify-between text-white p-5 w-full"
      :class="
        $route.name.includes('post-overview')
          ? 'lessonPrimaryStyle postqueue-category'
          : ''
      "
    >
      <span class="playlist-title font-semibold text-sm">{{
        categoryTitle
      }}</span>
      <span class="playlist-length text-sm"
        >{{ playlist.length }} Lesson{{ playlist.length > 1 ? 's' : '' }}</span
      >
    </div>
    <div ref="postQueue" class="playlist-queue overflow-auto flex-1">
      <div
        class="w-full"
        ref="postQueueItem"
        v-for="(categoryTreeNode, index) in playlist"
        :key="categoryTreeNode.id"
        :id="categoryTreeNode.id"
      >
        <router-link
          tag="div"
          class="playlist-item p-2 w-full flex items-center cursor-pointer"
          :class="
            activePost === categoryTreeNode.id
              ? 'bg-gray-700 post-highlight'
              : ''
          "
          :to="{
            name: 'post-overview',
            params: getRouteParams(categoryTreeNode),
          }"
        >
          <span
            class="playlist-item-count mr-2 text-sm postqueue-lesson"
            :class="
              activePost === categoryTreeNode.id
                ? 'text-white post-highlight'
                : 'text-white'
            "
            >{{ index + 1 }}</span
          >
          <img
            class="playlist-item-image object-contain h-16 w-24"
            :src="replaceBucketUrlWithCdnUrl(getPosterImage(categoryTreeNode))"
            alt="Post"
          />
          <span
            class="playlist-item-title flex-1 text-white text-sm pl-2 postqueue-lesson"
            :class="
              activePost === categoryTreeNode.id
                ? 'text-white post-highlight'
                : 'text-white'
            "
            >{{ categoryTreeNode.node.title }}</span
          >
          <img
            v-if="nodeIsCompleted(categoryTreeNode)"
            class="h-5"
            :src="checkIcon"
            alt="Completed"
          />
        </router-link>
      </div>
    </div>
    <div
      class="next-category-button-container bg-gray-800 p-3 justify-center flex h-16"
      v-if="nextCategory"
    >
      <router-link
        id="next-category-button"
        :to="{
          name: 'category-overview',
          params: { id: productId, category_id: nextCategory },
        }"
        tag="button"
        class="rounded px-4 md:px-5 xl:px-4 py-3 md:py-4 xl:py-3 bg-gray-700 hover:bg-gray-600 text-sm text-white font-semibold leading-tight shadow-md next-category__btn"
        >Next Category</router-link
      >
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { checkIcon } from '@/helper/constants'
import { getDefaultPosterImage } from '@/helper'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '../../models/CategoryTreeNode'
import Category from '../../models/Category'
import Post from '../../models/Post'

export default defineComponent({
  props: {
    productId: {
      type: String,
    },
    categoryId: {
      type: String,
    },
    categoryTitle: {
      type: String,
    },
    playlist: {
      type: Array,
    },
    activePost: {
      type: String,
    },
    completedPosts: {
      type: Set,
      default: new Set(),
    },
    nextCategory: {
      type: String,
    },
  },
  data() {
    return {
      checkIcon,
    }
  },
  computed: {
    postQueueItemRef() {
      return this.$refs['postQueueItem']
    },
    postQueueRef() {
      return this.$refs['postQueue']
    },
  },
  mounted() {
    this.scrollToActivePostItem()
  },
  methods: {
    getPosterImage(categoryTreeNode): string {
      return (
        categoryTreeNode.node.posterImage ||
        getDefaultPosterImage(categoryTreeNode.node.contentType)
      )
    },
    nodeIsCompleted(entity: CategoryTreeNode): boolean {
      if (entity.type === CategoryTreeNodeType.Post) {
        return this.completedPosts.has(entity.id)
      } else {
        const category = entity.node as Category

        if (!category.posts) return false

        const completedPosts = category.posts.filter((p: Post) =>
          this.completedPosts.has(p.id)
        )

        return completedPosts.length === category.posts.length
      }
    },
    getRouteParams(categoryTreeNode: CategoryTreeNode): any {
      if (categoryTreeNode.type === CategoryTreeNodeType.Post) {
        return {
          id: this.productId,
          category_id: this.categoryId,
          post_id: categoryTreeNode.id,
        }
      } else {
        const category = categoryTreeNode.node as Category
        const firstPost = category.posts[0]
        return {
          id: this.productId,
          category_id: categoryTreeNode.id,
          post_id: firstPost.id,
        }
      }
    },
    scrollToActivePostItem() {
      this.$nextTick(() => {
        if (!this.postQueueItemRef) return
        this.postQueueItemRef.forEach((link: any) => {
          if (this.activePost === link.id) {
            this.postQueueRef.scrollTo({
              top: link.offsetTop - link.scrollHeight - 2 * link.offsetHeight,
            })
          }
        })
      })
    },
  },
})
</script>
<style scoped>
.playlist-queue-container {
  contain: size;
}
</style>
