import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "progress rounded-full w-full bg-progress-disable lg:bg-gray-200 h-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "progress-completed",
      class: _normalizeClass(["h-full flex justify-center items-center rounded-full text-xs leading-none py-1 text-center", 
        _ctx.progressColor && _ctx.progressColor.includes('bg-')
          ? _ctx.progressColor
          : 'bg-blue-500'
      ]),
      style: _normalizeStyle({
        width: `${_ctx.percentCompleted || 0}%`,
        backgroundColor:
          _ctx.progressColor && _ctx.progressColor.includes('bg-') ? '' : _ctx.progressColor,
      })
    }, [
      (_ctx.showPercentage)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["text-white", _ctx.newMobileScreens ? 'text-xxs' : '']),
            style: { lineHeight: 'normal' }
          }, _toDisplayString(_ctx.percentCompleted) + "%", 3))
        : _createCommentVNode("", true)
    ], 6)
  ]))
}