<template>
  <div class="category-post-list">
    <template :key="child.id" v-for="child in categoryTree">
      <PostListItem
        v-if="nodeIsPost(child.type)"
        :post="child.node"
        :is-locked="isLocked(child.parent)"
        :category-id="child.parent"
        :completed="completedPosts.has(child.id)"
        :available="available"
        :hasProductAccess="hasProductAccess"
      />
      <div
        :class="[
          level === 0 ? 'mb-4 rounded-sm' : 'lg:ml-6 my-12 lg:my-0',
          level === 0 && isBackground
            ? `bg-white ${!newMobileScreens ? 'shadow' : ''}`
            : '',
          device === 'desktop' || isBuilder || !newMobileScreens ? 'p-5' : '',
          !child.node.available ? 'drip-category' : '',
        ]"
        class="category-contents"
        v-else
        :key="child.id"
      >
        <div class="flex items-center">
          <component
            :is="hasProductAccess ? 'router-link' : 'span'"
            v-if="child.node.available"
            :to="{
              name: 'category-overview',
              params: { id: child.node.productId, category_id: child.id },
            }"
            class="category-title cursor-pointer font-bold"
            :class="[
              level === 0 ? 'text-xl lg:text-2xl' : 'text-xl',
              device === 'desktop' &&
              $route.name.includes('product-overview') &&
              level === 0
                ? 'primaryStyle course-category-title'
                : device === 'desktop' || isBuilder || !newMobileScreens
                ? 'primaryStyle subcategory-title'
                : 'text-gray-700 mb-2',
            ]"
            tag="div"
          >
            {{ child.node.title }}
          </component>
          <div
            v-else
            class="category-title font-bold text-gray-500 drip-category-title"
            :class="level === 0 ? 'text-xl lg:text-2xl' : 'text-xl'"
          >
            {{ child.node.title }}<span class="float-right">(Coming Soon)</span>
          </div>
        </div>
        <div
          v-if="child.node.description"
          :class="[
            level !== 0 ? 'truncate' : '',
            $route.name.includes('product-overview')
              ? 'secondaryStyle category-desc'
              : '',
            newMobileScreens ? 'ml-0' : '',
            'whitespace-pre-line',
          ]"
        >
          {{ child.node.description }}
        </div>
        <!-- <div class="font-bold text-xl">{{ child.node.title }}</div> -->
        <CategoryPostTree
          :available="child.node.available"
          :level="level + 1"
          v-if="child.childs && child.childs.length"
          :category-tree="child.childs"
          :completed-posts="completedPosts"
          :complete-categories="completedCategories"
          :isBackground="isBackground"
          :category-lock-data="categoryLockData"
          :hasProductAccess="hasProductAccess"
        />
        <div class="font-semibold my-1 ml-2" v-else>No content available!</div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import PostListItem from './PostListItem.vue'
import { CategoryTreeNodeType } from '../../models/CategoryTreeNode'

export default defineComponent({
  name: 'CategoryPostTree',
  components: {
    PostListItem,
  },
  props: {
    categoryTree: {
      type: Array,
    },
    completedPosts: {
      type: Set,
      default: () => new Set(),
    },
    completedCategories: {
      type: Set,
      default: () => new Set(),
    },
    level: {
      type: Number,
      default: 0,
    },
    available: {
      type: Boolean,
      default: true,
    },
    isBackground: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
    },
    categoryLockData: {
      type: Array,
    },
    hasProductAccess: Boolean
  },
  computed: {
    device(): string {
      return this.$store.getters.device
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    isBuilder(): boolean {
      return this.$route.query.builder === 'true'
    }
  },
  methods: {
    nodeIsPost(type: CategoryTreeNodeType) {
      return type === CategoryTreeNodeType.Post
    },
    isLocked(categoryId: string) {
      if (!this.categoryLockData) {
        return false
      }
      const lockData = this.categoryLockData.find(
        (c) => c.categoryId === categoryId
      )
      if (lockData) {
        return lockData.isLocked
      }
      return false
    },
  },
})
</script>
<!-- <style scoped>
@media screen and (max-width: 1024px) {
  .subcategory-title {
    color: black;
  }
}
</style> -->
