import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "block px-10 py-10 grid grid-cols-1 gap-3 lg:grid lg:grid-cols-3 lg:gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shimmer = _resolveComponent("shimmer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (i) => {
      return _createElementVNode("div", {
        class: "w-full flex flex-col items-center lg:w-full rounded mb-3 md:mb-0",
        key: i
      }, [
        _createVNode(_component_shimmer, { class: "w-full mb-2 h-32 rounded-lg" }),
        _createVNode(_component_shimmer, { class: "w-1/2 h-5 rounded-lg" })
      ])
    }), 64))
  ]))
}