<template>
  <div
    id="instructor-card"
    class="bg-white rounded py-5 px-5"
    :class="[
      newYorkTheme && !newMobileScreens && 'px-10',
      newMobileScreens
        ? 'border'
        : `shadow ${getThemeClasses(instructorCardClass)}`,
    ]"
  >
    <span
      id="instructor-heading"
      class="font-semibold text-xl"
      :class="[
        newYorkTheme && !newMobileScreens && 'flex justify-center',
        newMobileScreens ? '' : getThemeClasses(instructorHeadingClass),
      ]"
      >{{ (instructor && instructor.heading) || defaultValues.heading }}</span
    >
    <div class="flex items-center mt-2">
      <div id="instructor-headshot">
        <img
          class="h-16 w-16 rounded-full"
          :src="
            replaceBucketUrlWithCdnUrl(
              (instructor && instructor.headshot) || defaultValues.headshot
            )
          "
          alt="Instructor"
        />
      </div>
      <div
        class="ml-3 flex-1"
        :class="newYorkTheme && !newMobileScreens && 'ml-16'"
      >
        <div
          id="instructor-name"
          :class="
            newMobileScreens
              ? getMobileTheme.primaryText
              : getThemeClasses(instructorNameClass)
          "
        >
          {{ (instructor && instructor.name) || defaultValues.name }}
        </div>
        <div
          id="instructor-title"
          class="lg:text-blue-400"
          :class="newMobileScreens ? '' : getThemeClasses(instructorTitleClass)"
        >
          {{ (instructor && instructor.title) || defaultValues.title }}
        </div>
      </div>
    </div>
    <div
      id="instructor-bio"
      class="mb-2 font-normal text-md whitespace-pre-line"
      :class="[
        newYorkTheme && !newMobileScreens && 'ml-[76px]',
        newMobileScreens ? '' : getThemeClasses(instructorBioClass),
      ]"
    >
      {{ instructor && instructor.bio }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { userAvatarImage } from '../../helper/constants'
import { getThemeClasses, getMobileTheme } from '../../helper'
export default defineComponent({
  props: {
    instructor: {
      type: Object,
    },
    progressColor: {
      type: String,
      default: '',
    },
    newYorkTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultValues: {
        heading: 'Instructor',
        name: 'Instructor Name',
        title: 'Instructor',
        headshot: userAvatarImage,
        bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed sapien quam. Sed dapibus est id enim facilisis, at posuere turpis adipiscing. Quisque sit amet dui dui.',
      },
      instructorTitleClass: {
        'product-overview': 'primaryStyle instructor-title',
        'post-overview': 'lessonPrimaryStyle lesson-instructor-title',
        'in-app-upsell': 'upsell-instructor-title',
      },
      instructorBioClass: {
        'product-overview': 'secondaryStyle instructor-bio',
        'post-overview': 'lessonSecondaryStyle lesson-instructor-bio',
        'in-app-upsell': 'upsell-instructor-bio',
      },
      instructorNameClass: {
        'product-overview': 'secondaryStyle instructor-name',
        'post-overview': 'lessonSecondaryStyle lesson-instructor-name',
        'in-app-upsell': 'upsell-instructor-name',
      },
      instructorHeadingClass: {
        'product-overview': 'instructor-heading',
        'post-overview': 'lesson-instructor-heading',
        'in-app-upsell': 'upsell-instructor-heading',
      },
      instructorCardClass: {
        'product-overview': 'instructor-card',
        'post-overview': 'lesson-instructor-card',
        'in-app-upsell': 'upsell-instructor-card',
      },
    }
  },
  computed: {
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
    getThemeClasses() {
      return getThemeClasses
    },
  },
})
</script>
<style scoped>
#instructor-bio {
  word-break: break-word;
}
</style>
