export function hideTinyMceWarning(s: number) {
  const domElements: any = document.getElementsByClassName(
    'tox-notifications-container'
  )
  for (const htmlEl of domElements) {
    const innerElems = htmlEl.getElementsByClassName(
      'tox-notification tox-notification--in tox-notification--warning'
    )
    for (const innerElem of innerElems) {
      if (innerElem && innerElem.style) innerElem.style.display = 'none'
    }
  }
}
