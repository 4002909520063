<script lang="ts" setup>
import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";

const store = useStore();

const { newMobileScreens } = {
  newMobileScreens: store.getters.newMobileScreens,
};
</script>

<template>
  <div v-if="newMobileScreens"
    class="w-11/12 library-container flex flex-col items-center justify-start mx-auto my-5 gap-4">
    <div class="w-full">
      <div class="w-full my-4 flex gap-4">
        <div v-for="n in 3" :key="n" class="w-64 h-60 flex flex-col flex-shrink-0 items-start gap-1 justify-around">
          <UISkeleton height="66.67%" width="100%" :sharp="false" />
          <UISkeleton height="16px" width="20%" :sharp="false" />
          <UISkeleton height="16px" width="35%" :sharp="false" />
        </div>
      </div>
    </div>
    <div class="w-full overflow-y-scroll scroll-smooth">
      <div v-for="n in 5" :key="n" class="w-full">
        <div class="w-full flex items-center justify-between gap-4 my-4 hover:bg-slate-50 cursor-pointer">
          <div class="w-3/5 min-w-36  h-28 xs:h-32 sm:h-48 md:h-60 rounded-md">
            <UISkeleton height="100%" width="100%" :sharp="false" />
          </div>
          <div class="h-full w-3/4 flex flex-col items-start justify-around gap-1">
            <UISkeleton height="16px" width="75%" :sharp="false" />
            <UISkeleton height="12px" width="100% " :sharp="false" />
            <UISkeleton height="12px" width="50%" :sharp="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
