import Model from './Model'

export enum CommentVisibility {
  private = 'private',
  public = 'public',
}
export default class Comment extends Model {
  constructor(payload?: {
    id?: string
    createdAt?: string
    postId?: string
    productId?: string
    content?: string
    visibility?: string
    parentCommentId?: string | null
    repliedToComment?: string | null
    repliedToUserId?: string | null
    userdetails?: object
    userId?: string
    replies?: Comment[]
  }) {
    super(payload || {})
  }

  get id(): string {
    return this._data.id
  }

  set title(title: string) {
    this._data.title = title
  }

  get createdAt(): string {
    return this._data.createdAt
  }
  set createdAt(createdAt: string) {
    this._data.createdAt = createdAt
  }

  get postId(): string {
    return this._data.postId
  }
  set postId(postId: string) {
    this._data.postId = postId
  }

  get productId(): string {
    return this._data.productId
  }
  set productId(productId: string) {
    this._data.productId = productId
  }

  get content(): string {
    return this._data.content
  }
  set content(content: string) {
    this._data.content = content
  }

  get visibility(): string {
    return this._data.visibility
  }
  set visibility(visibility: string) {
    this._data.visibility = visibility
  }

  get parentCommentId(): string | null {
    return this._data.parentCommentId
  }
  set parentCommentId(parentCommentId: string | null) {
    this._data.parentCommentId = parentCommentId
  }

  get repliedToComment(): string | null {
    return this._data.repliedToComment
  }
  set repliedToCommentId(repliedToComment: string | null) {
    this._data.repliedToComment = repliedToComment
  }

  get repliedToUserId(): string | null {
    return this._data.repliedToUserId
  }

  set repliedToUserId(repliedToUserId: string | null) {
    this._data.repliedToUserId = repliedToUserId
  }
  get userdetails(): object {
    return this._data.userdetails
  }
  set userdetails(userdetails: object) {
    this._data.userdetails = userdetails
  }

  get replies(): Comment[] {
    return this._data.replies
  }
  set replies(replies: Comment[]) {
    this._data.replies = replies
  }
}
