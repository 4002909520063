import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center h-screen"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "height-fifty-percent" }
const _hoisted_4 = { class: "ml-1" }
const _hoisted_5 = { class: "height-fifty-percent p-5 w-full text-center" }
const _hoisted_6 = { class: "height-twenty-five-percent text-lg" }
const _hoisted_7 = {
  key: 0,
  class: "height-fifty-percent text-lg"
}
const _hoisted_8 = {
  key: 1,
  class: "height-fifty-percent text-lg"
}
const _hoisted_9 = { class: "text-right pt-4 text-blue-600" }
const _hoisted_10 = {
  key: 1,
  class: "text-center p-20 flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_ctx.fetchingSubmission)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_UISpinner, { size: "large" })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.lastSubmission)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["border rounded-md submission-container mx-auto", `border-${_ctx.submissionColor}-600`])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(["capitalize text-2xl p-5 height-fifty-percent flex items-center justify-center w-full", `text-${_ctx.submissionColor}-600`])
                }, [
                  _createVNode(_component_BaseIcon, {
                    name: 
              _ctx.lastSubmission.status === _ctx.assessmentStatusOptions.passed
                ? 'checkcircle'
                : 'crosswithcircle'
            ,
                    hwClass: "h-8 w-8"
                  }, null, 8, ["name"]),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(this.lastSubmission.status), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["text-center text-4xl height-fifty-percent", `text-${_ctx.submissionColor}-600`])
                }, _toDisplayString(this.lastSubmission.score) + "% ", 3)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.correctCount) + " / " + _toDisplayString(_ctx.questionCount) + " Questions answered correctly ", 1),
                (_ctx.lastSubmission.status === _ctx.assessmentStatusOptions.passed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, " You have succesfully passed this quiz. Thank you. "))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, " You have failed to reach the minimum score of this quiz. Please try again. ")),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    tag: "span",
                    to: {
              name: 'quiz-overview',
              params: {
                category_id: _ctx.categoryId,
                post_id: _ctx.postId,
              },
              query: {
                review: true,
              },
            },
                    class: "cursor-pointer"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Review ")
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ])
            ], 2))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                height: "300",
                width: "300",
                src: "https://staging.cdn.msgsndr.com/assets/membership/assessments-home.png"
              }, null, -1)
            ]))),
        _createVNode(_component_router_link, {
          tag: "div",
          to: {
        name: `${_ctx.postContentType}-overview`,
        params: {
          category_id: _ctx.categoryId,
          post_id: _ctx.postId,
        },
      },
          class: "flex justify-center mt-10"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UIButton, {
              type: "primary",
              id: "quiz-status-component-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]))
}