import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "comment-editor-container" }
const _hoisted_2 = {
  class: "relative",
  id: "comment-editor-wrapper"
}
const _hoisted_3 = { class: "flex items-center justify-between w-full" }
const _hoisted_4 = { class: "text-gray-500 flex-1 text-xs m-2 h-full" }
const _hoisted_5 = {
  id: "comment-controls",
  class: "flex gap-2 justify-end mt-2"
}

import { UIDropdown } from '@gohighlevel/ghl-ui'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import { COMMENT_MAX_LENGTH } from '@/helper/constants'
import { computed, ref, inject, watch, onMounted } from 'vue'
import { ChevronDownIcon } from '@gohighlevel/ghl-icons/24/outline'
import { CommentService } from '@/services'
import { CommentVisibility } from '@/models/Comment'
import { CommentPrivacy } from '@/models/Product'
import Editor from '@/components/common/Editor.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoCommentInput',
  props: {
  editorText: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Type your comment here...',
  },
  commentCharacterLength: {
    type: Number,
    default: COMMENT_MAX_LENGTH || 500,
  },
  postId: {
    type: String,
    default: '',
  },
  productId: {
    type: String,
    default: '',
  },
  pendoPayload: {
    type: Object,
    default: () => ({}),
  },
  isReplying: {
    type: Boolean,
    default: false,
  },
  replyToComment: {
    type: String,
    default: '',
  },
  parentCommentId: {
    type: String,
    default: '',
  },
  repliedToComment: {
    type: String,
    default: '',
  },
  repliedToUserId: {
    type: String,
    default: '',
  },
  repliedToCommentPrivacy: {
    type: String,
    default: '',
  },
  onCancel: {
    type: Function,
    default: () => null,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
  editThisComment: {
    type: Object,
    default: () => ({}),
  },
  productCommentPrivacy: {
    type: String,
    default: CommentPrivacy.instructorOnly,
  },
},
  emits: [
  'update:editorText',
  'addNewComment',
  'updateEditedComment',
],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit

const commentPrivacy: Array<{ label: string; key: CommentPrivacy; icon: any }> =
  inject('commentPrivacy') || []

const commentInputRef = ref(null)
const contentText = ref('')
const editorInstance = ref(null)
const posting = ref(false)
const selectedCommentPrivacy = ref<string>(CommentPrivacy.instructorOnly)

const selectedPrivacyOption = computed(() => {
  if (
    !selectedCommentPrivacy.value ||
    selectedCommentPrivacy.value === CommentPrivacy.publicAndInstructor
  )
    return commentPrivacy[0]
  return commentPrivacy.find((cp) => cp.key === selectedCommentPrivacy.value)
})

const buttonDisable = computed(() => {
  return (
    posting.value ||
    contentText.value.length === 0 ||
    replyTextLength.value > props.commentCharacterLength
  )
})

const replyTextLength = computed(
  () =>
    contentText.value.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ')
      .length
)

function setCommentPrivacy(value: CommentPrivacy) {
  selectedCommentPrivacy.value = value
}

function updateEditorInstance(instance: any) {
  editorInstance.value = instance
}

function clearContent() {
  if (props.enableEdit) {
    emit('updateEditedComment', null)
  }
  contentText.value = ''
  editorInstance.value.commands?.setContent('')
  setDefaultCommentPrivacy()
  props.onCancel()
}

function updateEditorText(updatedContent: string) {
  contentText.value = updatedContent
  emit('update:editorText', updatedContent)
}

function getCommentVisibility(privacy: string) {
  return privacy === CommentPrivacy.instructorOnly
    ? CommentVisibility.private
    : CommentVisibility.public
}

function getCommentPrivacy(visibility: string) {
  return visibility === CommentVisibility.private
    ? CommentPrivacy.instructorOnly
    : CommentPrivacy.public
}

async function createComment() {
  try {
    let visibility = getCommentVisibility(selectedCommentPrivacy.value)

    if (props.isReplying) {
      visibility = props.repliedToCommentPrivacy as CommentVisibility
    }

    posting.value = true

    let payload: any = {
      postId: props.postId,
      productId: props.productId,
      content: contentText.value,
      visibility,
      pendoPayload: {
        postTitle: props.pendoPayload.post.title,
        productTitle: props.pendoPayload.product.title,
        categoryTitle: props.pendoPayload.category.title,
      },
    }

    if (props.isReplying) {
      payload = {
        ...payload,
        parentCommentId: props.parentCommentId,
        repliedToComment: props.repliedToComment,
        repliedToUserId: props.repliedToUserId,
      }
    }
    const { data: createdComment } = await CommentService.createComment(payload)

    if (createdComment.error) {
      throw createdComment.error
    }
    posting.value = false
    emit('addNewComment', createdComment.comment)
    clearContent()
  } catch (error) {
    console.error('Something went wrong', error)
  }
}

async function updateComment() {
  try {
    posting.value = true
    await CommentService.updateComment(props.editThisComment.id, {
      content: contentText.value,
      visibility: getCommentVisibility(selectedCommentPrivacy.value),
      pendoPayload: {
        postTitle: props.pendoPayload.post.title,
        productTitle: props.pendoPayload.product.title,
        categoryTitle: props.pendoPayload.category.title,
      },
    })
    emit('updateEditedComment', {
      ...props.editThisComment,
      content: contentText.value,
      visibility: getCommentVisibility(selectedCommentPrivacy.value),
    })
    clearContent()
  } catch (error) {
    console.error('Error while updating comment: ', error)
  } finally {
    posting.value = false
  }
}

function updateCommentContent() {
  if (props.editThisComment && props.editThisComment.content) {
    contentText.value = props.editThisComment.content || ''
    selectedCommentPrivacy.value =
      getCommentPrivacy(props.editThisComment.visibility) ||
      CommentPrivacy.instructorOnly

    if (commentInputRef.value && commentInputRef.value.editorContainerElement) {
      commentInputRef.value.editorContainerElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })

      commentInputRef.value.editorContainerElement?.focus()
    }
  }
}

function setDefaultCommentPrivacy() {
  if (props.productCommentPrivacy) {
    selectedCommentPrivacy.value =
      props.productCommentPrivacy === CommentPrivacy.publicAndInstructor
        ? CommentPrivacy.instructorOnly
        : props.productCommentPrivacy
  }
}

onMounted(() => {
  updateCommentContent()
  setDefaultCommentPrivacy()
})

watch(() => props.editThisComment, updateCommentContent, { deep: true })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Editor, {
        ref_key: "commentInputRef",
        ref: commentInputRef,
        editorText: contentText.value,
        class: _normalizeClass(["custom-editor-styling border p-2", {
          'border-neo-classic-error': replyTextLength.value > __props.commentCharacterLength,
        }]),
        "onUpdate:editorText": updateEditorText,
        onGetInstance: updateEditorInstance
      }, null, 8, ["editorText", "class"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(replyTextLength.value) + " / " + _toDisplayString(__props.commentCharacterLength), 1),
      _createElementVNode("div", _hoisted_5, [
        (!__props.isReplying)
          ? (_openBlock(), _createBlock(_unref(UIDropdown), {
              key: 0,
              id: "comment-privacy-dropdown",
              options: 
            __props.enableEdit && __props.editThisComment.parentCommentId ? [] : _unref(commentPrivacy)
          ,
              placement: "bottom",
              onSelect: setCommentPrivacy
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center gap-1 font-medium text-neo-classic-primary-accent cursor-pointer", {
              'text-neo-classic-secondary-text':
                __props.enableEdit && __props.editThisComment.parentCommentId,
            }])
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(selectedPrivacyOption.value.icon))),
                  _createTextVNode(" " + _toDisplayString(selectedPrivacyOption.value.label) + " ", 1),
                  _createVNode(_unref(ChevronDownIcon), { class: "w-4 h-4" })
                ], 2)
              ]),
              _: 1
            }, 8, ["options"]))
          : _createCommentVNode("", true),
        _createVNode(Button, {
          onClick: clearContent,
          disabled: buttonDisable.value && !__props.isReplying && !__props.enableEdit,
          variant: "subtle"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Cancel ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(Button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.enableEdit ? updateComment() : createComment())),
          disabled: buttonDisable.value,
          variant: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.enableEdit ? 'Update Comment' : 'Comment'), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ])
  ]))
}
}

})