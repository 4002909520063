import http from '../../http-common'
import Product from '@/models/Product'
import Category from '@/models/Category'
import Offer from '@/models/Offer'
import Post from '@/models/Post'
import PostMaterial from '@/models/PostMaterial'
import UpsellOffer from '@/models/UpsellOffer'
import Video from '@/models/Video'
import Comment from '@/models/Comment'
import OfferCustomization from '@/models/OfferCustomization'
import Model from '@/models/Model'
import ProductCustomization from '@/models/ProductCustomization'
import Question from '@/models/Question'
import Quiz from '@/models/Quiz'
import AssessmentStatus from '@/models/AssessmentStatus'
import Assignment from '@/models/Assignment'
import Audio from '@/models/Audio'

export type TypesModels =
  | OfferCustomization
  | Category
  | Offer
  | Post
  | PostMaterial
  | Product
  | UpsellOffer
  | Video
  | ProductCustomization
  | Comment
  | Question
  | Quiz
  | AssessmentStatus
  | Assignment
  | Audio

export default abstract class BaseService<K extends TypesModels> {
  protected subdomain = ''
  readonly locationId: string

  abstract model(): typeof Model

  constructor(locationId: string) {
    this.locationId = locationId
  }

  get endpoint() {
    return `/locations/${this.locationId}/${this.subdomain}`
  }

  findAll(params?: any): Promise<K[]> {
    return http
      .get(this.endpoint, {
        params,
      })
      .then((res) => {
        return res.data.map((e: any) => {
          return new (this.model())(e)
        })
      })
  }

  findById(id: string, params?: any): Promise<K> {
    return http.get(`${this.endpoint}/${id}`, { params }).then((res) => {
      return new (this.model())(res.data) as K
    })
  }
}
