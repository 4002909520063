import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full flex items-center justify-center courses-font-family font-semibold"
}
const _hoisted_2 = {
  key: 1,
  class: "w-11/12 h-full flex flex-col items-center justify-start mx-auto my-5 gap-4"
}
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = ["onClick"]

import { UIEmpty } from "@gohighlevel/ghl-ui";
import { useRouter } from "vue-router";
import CourseCardV2 from "./CourseCardV2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyCoursesV2',
  props: ["myCourses", "fetching"],
  setup(__props) {

const router = useRouter();
const props = __props;

function openProduct(productId) {
  router.push({ name: "category-list-levelup", params: { id: productId } });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!props.fetching && !props.myCourses.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(UIEmpty), {
            id: "empty-product-message",
            title: "No courses found",
            description: "You have yet to enroll in any courses. Please reach out to your instructor to initiate your educational journey."
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.myCourses, (course) => {
              return (_openBlock(), _createElementBlock("div", {
                key: course.id,
                class: "w-full clickable",
                onClick: ($event: any) => (openProduct(course.id))
              }, [
                _createVNode(CourseCardV2, { course: course }, null, 8, ["course"])
              ], 8, _hoisted_4))
            }), 128))
          ])
        ]))
  ]))
}
}

})