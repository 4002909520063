<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div>
    <div class="pr-2 flex">
      <div class="text-center">
        <span v-if="commentElem">
          <div
            v-if="commentElem.userdetails && commentElem.userdetails.avatar"
            class="bg-gray-500 bg-cover rounded-full"
            :style="{
              backgroundImage: `url(${commentElem.userdetails.avatar})`,
            }"
            :class="isParentComment ? 'h-10 w-10' : 'h-8 w-8'"
          ></div>
          <div
            v-else-if="
              !commentElem.userdetails && instructor && instructor.headshot
            "
            class="bg-gray-500 bg-cover rounded-full"
            :style="{ backgroundImage: `url(${instructor.headshot})` }"
            :class="isParentComment ? 'h-10 w-10' : 'h-8 w-8'"
          ></div>
          <div
            v-else
            class="bg-gray-300 inline-flex items-center bg-cover border rounded-full"
            :class="isParentComment ? 'p-2' : 'p-1'"
          >
            <BaseIcon name="user" hwClass="h-6 w-6" />
          </div>
        </span>
      </div>
      <div class="flex-1 pl-5">
        <div>
          <div class="flex py-px">
            <div class="flex-1">
              <div>
                <span
                  v-if="commentElem && commentElem.userdetails"
                  class="text-black font-semibold"
                >
                  {{
                    commentElem &&
                    commentElem.userdetails &&
                    commentElem.userdetails.name.substring(0, 15)
                  }}
                </span>
                <span
                  v-else-if="
                    commentElem &&
                    commentElem.userId &&
                    !commentElem.userdetails
                  "
                  class="text-black font-semibold"
                >
                  Deleted User
                </span>
                <span v-else class="text-black font-semibold">
                  {{ (instructor && instructor.name) || 'Instructor' }}
                  <span
                    class="border-0 text-xs cursor-default rounded bg-blue-200 text-blue-700 py-px px-1"
                    v-if="instructor && instructor.name"
                    >Instructor</span
                  >
                </span>
                <span class="font-xs px-2">
                  {{ timeSince }}
                </span>
                <span
                  v-if="isParentComment"
                  class="inline-flex items-center py-1 bg-gray-300 border rounded-full pr-1 pl-2"
                >
                  <BaseIcon :name="commentPrivacy.svgicon" hwClass="h-3 w-3" />
                  <span class="px-1 text-xs privacy-label leading-3">
                    {{ commentPrivacy.label }}
                  </span>
                </span>
              </div>
              <div
                v-if="!isParentComment"
                class="bg-blue-100 border-0 rounded-full inline-block px-2 mt-2 cursor-default custom-list-styling"
                v-html="repliedToText"
              ></div>
            </div>
            <div
              class="cursor-pointer"
              v-if="!editingComment && commentPermission === 'enabled'"
            >
              <UIDropdown
                v-if="showEditDeleteDropdown"
                id="comment-edit-dropdown"
                :options="commentEditOptions"
                @select="selectCommentEditOption"
              >
                <BaseIcon name="dotsvertical" />
              </UIDropdown>
            </div>
          </div>
          <div
            :id="id"
            class="text-black py-1 custom-list-styling"
            :style="{ fontWeight: computedFontWeight }"
            v-if="!editingComment"
            v-html="formattedCommentText"
          ></div>
        </div>

        <!-- Edit section -->
        <div v-if="editingComment">
          <div class="bg-white cursor-pointer pt-4">
            <editor v-model:editorText="contentText" />
          </div>
          <div class="flex text-sm pt-4">
            <div class="text-gray-500 text-sm p-1 flex-1">
              {{ replyTextLength }} / {{ commentCharacterLength }}
            </div>
            <div
              v-if="isParentComment"
              class="privacy-dropdown px-2 flex items-center"
            >
              <UIDropdown
                id="is-parent-privacy-dropdown"
                :options="filteredPrivacyOptions"
                @select="setCurrentPrivacyOption"
              >
                <div class="flex items-center">
                  <BaseIcon
                    :name="currentPrivacyOption.svgicon"
                    hwClass="w-4 h-4"
                  />
                  <span class="mx-1">{{ currentPrivacyOption.label }}</span>
                  <BaseIcon
                    v-if="productCommentPrivacy === 'publicAndInstructor'"
                    name="chevrondown"
                    hwClass="h-3 w-3"
                  />
                </div>
              </UIDropdown>
            </div>
            <button
              class="border rounded ml-2 p-1 px-3 cursor-pointer bg-gray-300 text-gray-700 inline-block text-center justify-center"
              @click="cancelUpdate"
            >
              Cancel
            </button>
            <button
              :disabled="postButtonDisabled"
              class="border rounded ml-2 p-1 cursor-pointer w-28 inline-flex items-center text-center justify-center"
              :class="
                postButtonDisabled
                  ? 'bg-gray-100 text-gray-500'
                  : 'bg-blue-100 text-blue-500'
              "
              @click="updateComment"
            >
              <div class="transform rotate-90 inline-block">
                <BaseIcon name="paperairplane" />
              </div>
              <span class="inline-flex items-center pl-2">Update</span>
            </button>
          </div>
        </div>

        <div
          class="flex py-px cursor-pointer text-gray-500"
          v-if="commentPermission === 'enabled'"
        >
          <div
            class="flex cursor-pointer pb-2 hover:text-blue-600 font-bold text-xs"
            @click="replyComment = !replyComment"
          >
            <div class="pl-1">
              <BaseIcon name="reply" hwClass="h-4 w-4" />
            </div>
            <div class="mt-px pr-2 pl-px cursor-pointer">Reply</div>
          </div>
          <div
            v-if="isParentComment && hasReplies"
            class="cursor-pointer hover:text-blue-600 text-xs"
          >
            <div
              class="mt-px pr-2 pl-5 cursor-pointer h-5"
              @click="hideReplyList"
            >
              {{ showReplies ? 'Hide' : 'Show' }} Replies
            </div>
          </div>
        </div>
        <ReplyCommentModal
          v-if="replyComment && commentPermission === 'enabled'"
          :parentCommentId="parentCommentId"
          :repliedToComment="commentElem.content"
          :repliedToUserId="commentElem.userId"
          @closeReplyModal="closeReplyModal"
          :pendoPayload="pendoPayload"
          :parentVisibility="visibilityOfReplyComment"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, h } from 'vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import ReplyCommentModal from './ReplyCommentModal.vue'
import { CommentService } from '@/services/'
import { editorConfig } from '@/helper/constants'
import { getUserId } from '@/services/'
import { COMMENT_MAX_LENGTH } from '@/helper/constants'
import { UIDropdown } from '@gohighlevel/ghl-ui'
import moment from 'moment'
import { hideTinyMceWarning } from '@/helper/editorHelper'
import Editor from '../common/Editor.vue'
import { addNewLineToLongString } from '../../helper/index'

export default defineComponent({
  name: 'OneComment',
  data() {
    return {
      editingComment: false,
      contentText: '',
      replyComment: false,
      privacyOptionList: [
        {
          label: 'Instructor only',
          value: 'private',
          key: 'instructorOnly',
          svgicon: 'lockclosed',
          icon() {
            return h(BaseIcon, {
              name: 'lockclosed',
              hwClass: 'h-5 w-5',
            })
          },
        },
        {
          label: 'Public',
          key: 'public',
          value: 'public',
          svgicon: 'globealt',
          icon() {
            return h(BaseIcon, {
              name: 'globealt',
              hwClass: 'h-5 w-5',
            })
          },
        },
      ],
      commentEditOptions: [
        {
          label: 'Edit',
          key: 'edit',
        },
        {
          label: 'Delete',
          key: 'delete',
        },
      ],
      currentPrivacyOption: {
        label: 'Instructor Only',
        value: 'private',
        svgicon: 'lockclosed',
      },
      showReplies: true,
    }
  },
  mounted() {
    this.setCurrentPrivacyOption()
    setTimeout(function () {
      const urlWithoutQueryParams = window.location.href.split('?')[0]
      window.history.replaceState({}, document.title, urlWithoutQueryParams)
    }, 5000)
  },
  components: {
    BaseIcon,
    ReplyCommentModal,
    editor: Editor,
    UIDropdown,
  },
  props: {
    content: {
      type: String,
    },
    id: {
      type: String,
    },
    commentElem: {
      type: Object,
    },
    instructor: {
      type: Object,
    },
    commentPermission: {
      type: String,
    },
    pendoPayload: {
      type: Object,
    },
    parentVisibility: String,
    productCommentPrivacy: {
      type: String,
      default: 'private',
    },
    scrollCommentId: {
      type: String,
      default: null,
    },
  },
  methods: {
    hideReplyList() {
      this.showReplies = !this.showReplies
      this.$emit('hideReplyList', this.showReplies)
    },
    closeReplyModal() {
      this.replyComment = false
    },
    selectCommentEditOption(option: string) {
      if (option === 'edit') {
        this.startEditComment()
      } else if (option === 'delete') {
        this.deleteComment()
      }
    },
    startEditComment() {
      this.contentText = this.content
      this.editingComment = true
    },
    cancelUpdate() {
      this.editingComment = false
      this.contentText = this.content
    },
    async deleteComment() {
      await CommentService.deleteComment(this.id)
      if (this.isParentComment === true) {
        this.emitter.emit('deleteStateParentComment', { id: this.id })
      } else {
        this.emitter.emit('deleteStateChildComment', {
          id: this.id,
          parentCommentId: this.commentElem.parentCommentId,
        })
      }
    },
    updateComment() {
      this.editingComment = false
      this.emitter.emit('updateComment', {
        id: this.id,
        content: this.contentText,
        visibility: this.currentPrivacyOption.value,
      })
      if (this.isParentComment === true) {
        this.$emit('updateStateParentComment', {
          id: this.id,
          content: this.contentText,
          visibility: this.currentPrivacyOption.value,
        })
      } else {
        this.$emit('updateStateChildComment', {
          id: this.id,
          content: this.contentText,
        })
      }
    },
    setCurrentPrivacyOption(value) {
      const selectedKey =
        value === undefined
          ? null
          : value === 'instructorOnly'
          ? 'private'
          : 'public'
      const option = this.privacyOptionList.find((elem) => {
        return (
          elem.value ===
          (selectedKey ? selectedKey : this.productCommentPrivacy)
        )
      })
      if (option !== undefined) {
        this.currentPrivacyOption = option
      }
    },
  },
  computed: {
    visibilityOfReplyComment(): string {
      if (this.isParentComment === true) return this.commentElem?.visibility
      return this.parentVisibility
    },
    showEditDeleteDropdown() {
      return (
        this.currentUserId && this.commentElem.userId === this.currentUserId
      )
    },
    currentUserId() {
      return getUserId()
    },
    hasReplies() {
      return (
        this.commentElem &&
        this.commentElem.replies &&
        this.commentElem.replies.length > 0
      )
    },
    commentCharacterLength() {
      return COMMENT_MAX_LENGTH || 140
    },
    postButtonDisabled() {
      return (
        this.replyTextLength > this.commentCharacterLength ||
        this.replyTextLength === 0
      )
    },
    replyTextLength() {
      return this.contentText
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/g, ' ').length
    },
    editorConfig() {
      return {
        ...editorConfig,
        setup: function (editor: any) {
          editor.on('init', () => {
            hideTinyMceWarning(0)
            for (const timeS of [100, 500, 1000, 2000, 5000]) {
              setTimeout(() => hideTinyMceWarning(timeS), timeS)
            }
          })
        },
      }
    },
    timeSince() {
      return moment(this.commentElem.createdAt).fromNow()
    },
    isParentComment() {
      return (
        this.commentElem.parentCommentId === null ||
        this.commentElem.parentCommentId === undefined
      )
    },
    parentCommentId() {
      if (this.isParentComment === true) {
        return this.id
      } else {
        return this.commentElem.parentCommentId
      }
    },
    repliedToText() {
      const currentCommenterUserDetails = this.commentElem.replyUserDetails
      return `Replied to: ${
        currentCommenterUserDetails
          ? currentCommenterUserDetails.name
          : 'Instructor'
      }: <span class="cursor-default text-blue-600">${this.commentElem.repliedToComment
        ?.replace(/<\/?[^>]+(>|$)/g, '')
        .substring(0, 50)}</span>`
    },
    commentPrivacy() {
      return this.privacyOptionList.filter(
        (option: any) => option.value === this.commentElem.visibility
      )[0]
    },
    filteredPrivacyOptions() {
      if (this.productCommentPrivacy !== 'publicAndInstructor') return []
      if (!this.productCommentPrivacy) return [this.currentPrivacyOption]
      if (this.productCommentPrivacy === 'publicAndInstructor')
        return this.privacyOptionList
      return this.privacyOptionList.filter(
        (option: any) => option.value === this.productCommentPrivacy
      )
    },
    computedFontWeight() {
      return this.scrollCommentId === this.id ? 'bold' : 'normal'
    },
    formattedCommentText() {
      return addNewLineToLongString(this.content)
    },
  },
})
</script>
<style>
.dot {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
}
.small-dot {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
}
.comment-item-actions-dropdown ul {
  margin: 0;
  padding: 0;
}
.privacy-label {
  margin-left: 2px;
}
.align-everything-center {
  display: inline-flex;
  align-items: end;
}
</style>
