import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["aria-label", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "loader ml-2"
}

import { ref, computed, useAttrs } from 'vue'
import { twMerge } from 'tailwind-merge'

// Define props with default values and validators

export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
  type: { type: String, default: 'button' },
  ariaLabel: { type: String, default: '' },
  variant: {
    type: String,
    default: 'primary',
    validator: (value: string) =>
      ['primary', 'secondary', 'success', 'danger', 'subtle', 'text'].includes(
        value
      ),
  },
  isEditing: { type: Boolean, default: false },
  buttonText: { type: String, default: 'Button Text' },
  buttonBackground: { type: String, default: '' },
  buttonTextColor: { type: String, default: '' },
  borderColor: { type: String, default: '' },
  borderWidth: { type: String, default: '' },
  hoverBackground: { type: String, default: '' },
  hoverBorderColor: { type: String, default: '' },
  hoverTextColor: { type: String, default: '' },
  size: {
    type: String,
    default: 'medium',
    validator: (value: string) => ['small', 'medium', 'large'].includes(value),
  },
  isLoading: { type: Boolean, default: false }, // New prop for loading state
},
  setup(__props) {

const props = __props

const attrs = useAttrs()

// Compute button classes based on props
const buttonClasses = computed(() => {
  const baseClasses =
    'font-sans rounded-lg font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all duration-300 ease-in-out hover:shadow-xs hover:translate-y-[-1px] cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed'
  const variantClasses =
    {
      primary:
        'bg-nova-blue-850 text-white hover:bg-[#215292] focus:ring-blue-500',
      secondary:
        'bg-nova-blue-200 text-nova-blue-850 hover:bg-nova-blue-100 focus:ring-nova-blue-600',
      success:
        'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500',
      danger: 'bg-red-500 text-white hover:bg-red-600 focus:ring-red-500',
      subtle:
        'bg-hover-bg border text-gray-600 hover:bg-gray-300 focus:ring-gray-500',
      text: 'bg-transparent text-gray-nova-blue-850 focus:ring-blue-500',
    }[props.variant] ||
    'bg-blue-500 text-white hover:bg-blue-600 focus:outline-none'

  const sizeClasses =
    {
      small: 'px-2 py-1 text-xs text-medium rounded',
      medium: 'px-3.5 py-2 text-sm rounded',
      large: 'px-5 py-3 text-base rounded',
    }[props.size] || 'px-4 py-2 text-base '

  const loadingClasses = props.isLoading ? 'opacity-50 cursor-not-allowed' : ''

  return twMerge(
    baseClasses,
    variantClasses,
    props.variant !== 'text' ? sizeClasses : '',
    attrs.class as string,
    loadingClasses
  )
})

// Compute button styles based on props
const buttonStyle = computed(() => ({
  backgroundColor: props.buttonBackground || undefined,
  color: props.buttonTextColor || undefined,
  borderColor: props.borderColor || undefined,
  borderWidth: props.borderWidth || undefined,
  borderStyle: props.borderWidth ? 'solid' : undefined,
  ...(attrs.style as Record<string, string>),
  '&:hover': {
    backgroundColor: props.hoverBackground || undefined,
    color: props.hoverTextColor || undefined,
    borderColor: props.hoverBorderColor || undefined,
  },
}))

// Compute aria-label for accessibility
const computedAriaLabel = computed(() => props.ariaLabel || props.buttonText)

return (_ctx: any,_cache: any) => {
  const _directive_highlight = _resolveDirective("highlight")!

  return _withDirectives((_openBlock(), _createElementBlock("button", _mergeProps({
    class: buttonClasses.value,
    "aria-label": computedAriaLabel.value
  }, _unref(attrs), {
    style: buttonStyle.value,
    disabled: __props.isLoading
  }), [
    _renderSlot(_ctx.$slots, "default"),
    (__props.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true)
  ], 16, _hoisted_1)), [
    [_directive_highlight, { isEditing: __props.isEditing, label: 'Button' }]
  ])
}
}

})