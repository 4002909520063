import Model from './Model'

export enum TRANSCODING_STATUS {
  COMPLETED = 'completed',
  PROCESSING = 'processing',
  REMAINING = 'remaining',
  ERROR = 'error',
}

export default class Audio extends Model {
  constructor(payload?: {
    title?: string
    url?: string
    postId?: string
    transcodingStatus?: string
    audioFormats?: Array<string>
  }) {
    super(payload || {})
  }

  get title(): string {
    return this._data.title
  }

  set title(title: string) {
    this._data.title = title
  }

  get url(): string {
    return this._data.url
  }

  set url(url: string) {
    this._data.url = url
  }

  get postId(): string {
    return this._data.postId
  }

  set postId(postId: string) {
    this._data.postId = postId
  }

  get transcodingStatus(): string {
    return this._data.transcodingStatus
  }

  set transcodingStatus(transcodingStatus: string) {
    this._data.transcodingStatus = transcodingStatus
  }

  get audioFormats(): Array<string> {
    return this._data.videoFormats
  }

  set audioFormats(audioFormats: Array<string>) {
    this._data.audioFormats = audioFormats
  }
}
