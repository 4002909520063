import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "border-b w-full h-full"
}
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-between h-20 px-6"
}
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-between h-20 px-6"
}
const _hoisted_5 = {
  key: 2,
  class: "max-h-96 overflow-y-auto"
}
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 0,
  class: "border-b-2"
}
const _hoisted_8 = { class: "bg-neo-classic-sub-category-bg py-1 flex items-center justify-between pr-2" }
const _hoisted_9 = {
  key: 3,
  class: "w-full p-4 text-center flex items-center justify-center border-t"
}

import Title from '@/components/neo-classic-theme/common/Title.vue'
import Paragraph from '@/components/neo-classic-theme/common/Paragraph.vue'
import Button from '@/components/neo-classic-theme/common/Button.vue'
import CategoryListItem from '@/components/neo-classic-theme/landing-page/CategoryListItem.vue'
import Category from '@/models/Category'
import CategoryTreeNode, {
  CategoryTreeNodeType,
  CategoryTreeNodeTypeView,
} from '@/models/CategoryTreeNode'
import Post from '@/models/Post'
import { computed, PropType, watch, inject, Ref, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import LockedContent from '@/components/neo-classic-theme/common/post/LockedContent.vue'
import { CategoryVisibility } from '@/models/Category'
import { UITag } from '@gohighlevel/ghl-ui'
import CategoryPlaylistLoader from '@/components/neo-classic-theme/loaders/CategoryPlaylistLoader.vue'
import { UISkeleton } from '@gohighlevel/ghl-ui'


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryPlaylist',
  props: {
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  playlist: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  isSubCategory: {
    type: Boolean,
    default: false,
  },
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
  activePost: {
    type: Object as PropType<Post>,
    default: () => ({}),
  },
  totalPostsInCategory: {
    type: Number,
    default: 0,
  },
  parentCategoryIndex: {
    type: Number,
    default: 0,
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  allCompletedPosts: {
    type: Array<{ postId: string; percentage: number }>,
    default: () => [],
  },
  refresh: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const router = useRouter()
const route = useRoute()

const props = __props

const activePostId = computed(() => {
  return props.activePost.id
})

const categoriesAndPostsLookup =
  inject<Ref<Map<string, any>>>('categoriesAndPostsLookup') || ref(new Map())

const isLoading = inject<Ref<boolean>>('isLoading') || ref(false)

const isPlaylistLoading = computed(() => {
  if (props.playlist.length === 0) {
    return isLoading.value
  }
  return false
})

const getCount = (listIndex: number) => {
  if (props.isSubCategory) {
    return (10 * props.parentCategoryIndex + listIndex + 11) / 10
    // Simplification of the above expression
    // props.parentCategoryIndex + 1 + (listIndex + 1) / 10
  }
  return listIndex + 1
}

function getCompletionPercentage(postId: string) {
  if (props.allCompletedPosts.length) {
    const post = props.allCompletedPosts.find((post) => post.postId === postId)

    return post?.percentage || 0
  }

  return 0
}

function isItemLocked(treeItem: CategoryTreeNode) {
  if (treeItem.node.visibility === CategoryVisibility.locked) {
    const lockedByPostId = treeItem.lockedBy || treeItem.lockedByPost
    const lockedByCategory = treeItem.node.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false
    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)
      if (category) {
        return (
          isLocked ||
          !category.childs?.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

// Find the next category in the tree no need to fetch from backend
const nextCategory = computed(() => {
  const currentCategorySequenceNo = props.category.sequenceNo
  const nextCategory = props.categoryTree.find(
    (node) => node.node.sequenceNo === currentCategorySequenceNo + 1
  )
  return nextCategory || null
})

const previousCategory = computed(() => {
  const currentCategorySequenceNo = props.category.sequenceNo
  const previousCategory = props.categoryTree.find(
    (node) => node.node.sequenceNo === currentCategorySequenceNo - 1
  )
  return previousCategory || null
})

// Compute total posts in the category including sub categories
const totalPostsInCategory = computed(() => {
  return props.playlist.reduce((acc, curr) => {
    if (curr.type === CategoryTreeNodeType.Category) {
      return acc + curr.childs.length
    }
    return acc + 1
  }, 0)
})

const goToCategory = (category: CategoryTreeNode) => {
  // Check if the next category is locked or dripped
  if (isItemLocked(category) || !category.isAvailable) {
    // redirect to products page
    return router.push({
      name: 'product-overview-v2',
      params: {
        id: route.params.id,
      },
      hash: `#${category.node.id}`,
      query: {
        ...route.query,
      },
    })
  }

  const firstPostInCategory = category.childs[0]
  router.push({
    name: 'post-overview',
    params: {
      id: category.node.productId,
      category_id: category.node.id,
      post_id: firstPostInCategory.id,
    },
    hash: `#${firstPostInCategory.id}`,
    query: {
      ...route.query,
    },
  })
}

return (_ctx: any,_cache: any) => {
  const _component_CategoryPlaylist = _resolveComponent("CategoryPlaylist", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.isSubCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (isPlaylistLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(UISkeleton), { class: "w-28 h-8 rounded-md" }),
                _createVNode(_unref(UISkeleton), { class: "w-16 h-8 rounded-md" })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(Title, {
                  tag: "h4",
                  class: "m-0 font-medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.category.title), 1)
                  ]),
                  _: 1
                }),
                _createVNode(Paragraph, { class: "m-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(totalPostsInCategory.value) + " Lessons ", 1)
                  ]),
                  _: 1
                })
              ]))
        ]))
      : _createCommentVNode("", true),
    (isPlaylistLoading.value)
      ? (_openBlock(), _createBlock(CategoryPlaylistLoader, { key: 1 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.playlist, (postListItem, listIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: postListItem.node.id,
              id: postListItem.node.id
            }, [
              (
            !__props.isSubCategory &&
            postListItem.type === _unref(CategoryTreeNodeType).Category
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(Title, {
                        tag: "h6",
                        class: "m-0 px-4 font-medium"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(postListItem.node.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      (!postListItem.isAvailable)
                        ? (_openBlock(), _createBlock(_unref(UITag), {
                            key: 0,
                            type: "error",
                            size: "small"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Available after " + _toDisplayString(postListItem.dripDays) + " day" + _toDisplayString(postListItem.dripDays > 1 ? 's' : ''), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    (isItemLocked(postListItem))
                      ? (_openBlock(), _createBlock(LockedContent, {
                          key: 0,
                          lockedByPostId: postListItem.lockedBy || postListItem.lockedByPost,
                          "content-type": 
              _unref(CategoryTreeNodeTypeView)[_unref(CategoryTreeNodeType).Category]
            ,
                          lockedByCategoryId: postListItem.node.lockedByCategory,
                          completedPostIds: __props.completedPostIds
                        }, null, 8, ["lockedByPostId", "content-type", "lockedByCategoryId", "completedPostIds"]))
                      : (_openBlock(), _createBlock(_component_CategoryPlaylist, {
                          key: 1,
                          parentCategoryIndex: listIndex,
                          playlist: postListItem.childs,
                          isSubCategory: true,
                          category: __props.category,
                          activePost: __props.activePost,
                          completedPostIds: __props.completedPostIds,
                          categoryTree: __props.categoryTree
                        }, null, 8, ["parentCategoryIndex", "playlist", "category", "activePost", "completedPostIds", "categoryTree"]))
                  ]))
                : (_openBlock(), _createBlock(CategoryListItem, {
                    key: 1,
                    count: getCount(listIndex),
                    listItemData: postListItem,
                    showBottomBorder: listIndex !== __props.playlist.length - 1,
                    "show-description": false,
                    isPostActive: activePostId.value === postListItem.node.id,
                    "is-post-completed": __props.completedPostIds.has(postListItem.node.id),
                    "is-item-locked": isItemLocked(postListItem),
                    progress: getCompletionPercentage(postListItem.node.id),
                    itemIndex: listIndex,
                    "is-category-available": postListItem.isAvailable
                  }, null, 8, ["count", "listItemData", "showBottomBorder", "isPostActive", "is-post-completed", "is-item-locked", "progress", "itemIndex", "is-category-available"]))
            ], 8, _hoisted_6))
          }), 128))
        ])),
    (!__props.isSubCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(Button, {
            disabled: !previousCategory.value,
            variant: "secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (goToCategory(previousCategory.value))),
            "aria-label": "Previous Category",
            class: "mr-2 w-full"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Previous Category ")
            ])),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(Button, {
            disabled: !nextCategory.value,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (goToCategory(nextCategory.value))),
            "aria-label": "Next Category",
            class: "w-full"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Next Category ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})