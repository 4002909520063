<script lang="ts" setup>
import { ArrowNarrowRightIcon, Lock01Icon } from "@gohighlevel/ghl-icons/24/outline";
import UISpinner from "../common/UISpinner.vue";
import { useRouter } from "vue-router";
import { PostContentType } from "@/models/Post";
import { UISkeleton } from "@gohighlevel/ghl-ui";
import { useStore } from "vuex";
import { computed } from "vue";
import { LEVELUP_LOCATION_IDS } from "@/helper/constants";

const router = useRouter();
const store = useStore();
const isLevelUpItem = computed(() => true)
// return LEVELUP_LOCATION_IDS.includes(store.getters.locationId))
defineProps([
    "lockedOverlayContentText",
    "lockedPost",
    "lockedCategory",
    "hasProductAccess",
    "showLockedButton",
]);

const newMobileScreens = computed(() => {
    return store.getters.newMobileScreens;
})

function navigateToPost(lockedPost) {
    let routeName;

    if (lockedPost?.contentType === PostContentType.assignment) {
        routeName = "mobile-assignment";
    } else if (lockedPost?.contentType === PostContentType.quiz) {
        routeName = "mobile-quiz-overview";
    } else {
        routeName = "post-overview";
    }

    router.push({
        name: routeName,
        params: {
            id: lockedPost?.productId,
            category_id: lockedPost?.categoryId,
            post_id: lockedPost?.id,
        },
    });
}

function routeToRespectivePostOrCategory(lockedPost, lockedCategory) {
    if (lockedPost && lockedPost?.id) {
        // Determine the type of lesson content and navigate accordingly.
        navigateToPost(lockedPost);
    }
    if (lockedCategory && lockedCategory?.id) {
        if (isLevelUpItem.value) {
            router.push({
                name: "category-list-levelup",
                params: { id: lockedCategory?.productId },
                query: {
                    selected_tab: 'syllabus',
                    category_id: lockedCategory?.parentCategory || lockedCategory?.id,
                },
            });
        } else {
            router.push({
                name: "category-list",
                params: { id: lockedCategory?.productId },
            });
        }
    }
}


</script>

<template>
    <div class="w-11/12 mx-auto my-4 flex flex-col justify-center items-center shadow-sm rounded-lg border border-[#E4E4E4]"
        :style="{ padding: '94px 10px 208px 10px' }">
        <Lock01Icon class="w-24 h-24 text-[--primary-color] my-4" />
        <span class="text-[#1D2939] text-2xl font-normal my-4">This {{ lockedOverlayContentText }} Is Locked
        </span>
        <span class="text-[#1D2939] text-base font-normal my-4 text-center">To gain access to this {{
            lockedOverlayContentText }}, please complete</span>
        <div v-if="showLockedButton" class="w-full flex flex-col justify-center items-center">


            <div v-if="lockedCategory?.id"
                class="w-3/4 rounded-lg flex items-center justify-center py-0 px-4 text-sm font-semibold text-white h-9 cursor-pointer bg-[--primary-color] my-4"
                :class="{ clickable: hasProductAccess }" @click="routeToRespectivePostOrCategory(null, lockedCategory)">
                <span class="line-clamp-1">{{ lockedCategory?.title }}</span>
                <ArrowNarrowRightIcon class="min-w-5 h-5 mx-2 flex-shrink-0" />
            </div>

            <div v-if="lockedPost?.id"
                class="w-3/4 rounded-lg flex items-center justify-center py-0 px-4 text-sm font-semibold text-white h-9 cursor-pointer bg-[--primary-color] my-4"
                :class="{ clickable: hasProductAccess }" @click="routeToRespectivePostOrCategory(lockedPost, null)">
                <span class="line-clamp-1 ">{{ lockedPost?.title }}</span>
                <ArrowNarrowRightIcon class="min-w-5 h-5 mx-2 flex-shrink-0" />
            </div>

        </div>
        <div v-else class="w-11/12 mx-auto flex items-center justify-center">
            <UISkeleton v-if="newMobileScreens" height="36px" class="w-3/4 my-2 mx-auto" :sharp="false" />
            <UISpinner v-else size="small" />
        </div>
    </div>
</template>

<style scoped></style>
