import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "lesson-container",
  class: "h-full w-full flex flex-col gap-2"
}
const _hoisted_2 = {
  id: "breadcrumb-container",
  class: "w-full xl:w-11/12 mx-auto content-fix-width mt-4"
}
const _hoisted_3 = {
  id: "content-container",
  class: "grid grid-cols-12 gap-4 xl:w-11/12 mx-auto content-fix-width"
}
const _hoisted_4 = {
  key: 3,
  id: "content-container ",
  class: "col-span-8 flex flex-col gap-2 mb-4"
}
const _hoisted_5 = { key: 3 }
const _hoisted_6 = {
  key: 4,
  id: "about-lesson-wrapper"
}
const _hoisted_7 = {
  key: 5,
  id: "post-completion-wrapper"
}
const _hoisted_8 = {
  key: 6,
  id: "comments-wrapper"
}
const _hoisted_9 = {
  id: "playlist-container",
  class: "col-span-4 flex flex-col gap-2"
}
const _hoisted_10 = {
  key: 0,
  id: "attached-files-wrapper"
}
const _hoisted_11 = { id: "instructor-detail-wrapper" }

import {
  PropType,
  computed,
  ref,
  onBeforeUnmount,
  onMounted,
  watch,
  provide,
  ComputedRef,
} from 'vue'
import VideoPlayer from '@/components/product/VideoPlayer.vue'
import AudioPlayer from '@/components/product/AudioPlayer.vue'
import Post, { PostContentType, PostCommentStatus } from '@/models/Post'
import { CategoryTreeNodeTypeView } from '@/models/CategoryTreeNode'
import AboutLesson from '@/components/neo-classic-theme/lesson-page/AboutLesson.vue'
import InstructorCard from '@/components/neo-classic-theme/common/InstructorCard.vue'
import Paper from '@/components/neo-classic-theme/common/Paper.vue'
import CategoryPlaylist from '@/components/neo-classic-theme/lesson-page/CategoryPlaylist.vue'
import AttachedFiles from '@/components/neo-classic-theme/common/AttachedFiles.vue'
import MarkPostComplete from '@/components/neo-classic-theme/lesson-page/MarkPostComplete.vue'
import AssignmentLaunch from '@/components/neo-classic-theme/assignment/AssignmentLaunch.vue'
import LessonPageLoader from '@/components/neo-classic-theme/loaders/LessonPageLoader.vue'
import CategoryTreeNode from '@/models/CategoryTreeNode'
import Category from '@/models/Category'
import NeoQuizStatusComponent from '@/components/neo-classic-theme/quiz/NeoQuizStatusComponent.vue'
import AssessmentStatus from '@/models/AssessmentStatus'
import { useRoute } from 'vue-router'
import LockedContent from './common/post/LockedContent.vue'
import { getCategoryAndPostsLookup } from '@/helper'
import Assignment from '@/models/Assignment'
import { CategoryVisibility } from '@/models/Category'
import NeoComments from '@/components/neo-classic-theme/comments/NeoComments.vue'
import NeoClassicBreadcrumbs from '@/components/neo-classic-theme/common/NeoClassicBreadcrumbs.vue'
import DripContent from '@/components/neo-classic-theme/common/DripContent.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NeoClassicLessonTheme',
  props: {
  product: {
    type: Object,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  instructor: {
    type: Object,
  },
  activePost: {
    type: Object as PropType<Post>,
    default: () => ({}),
  },
  assignment: {
    type: Object as PropType<Assignment>,
    default: () => ({}),
  },
  assessmentStatus: {
    type: Object as PropType<AssessmentStatus>,
    default: () => ({}),
  },
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
  videoOptions: {
    type: Object,
  },
  nextPost: {
    type: Object,
  },
  playlist: {
    type: Array<CategoryTreeNode>,
    default: () => [],
  },
  categoryTree: {
    type: Array<CategoryTreeNode>,
    default: () => [], 
  },
  disableActions: {
    type: Object,
  },
  videoCompletePercentage: {
    type: Number,
    default: 0,
  },
  audioCompletePercentage: {
    type: Number,
    default: 0,
  },
  nextCategoryId: {
    type: String,
  },
  markCompletedBtnCss: {
    type: String,
  },
  postCompletionButtonText: {
    type: String,
    default: 'Mark as complete',
  },
  lessonButton: {
    type: Object,
  },
  isAdmin: {
    type: Boolean,
  },
  nextLesson: {
    type: Object,
  },
  fetchingPost: {
    type: Boolean,
  },
  fetchingPostCompletionStatus: {
    type: Boolean,
  },
  disablePostCompletionButton: {
    type: Boolean,
    default: false,
  },
  completedPostIds: {
    type: Set,
    default: new Set(),
  },
  allCompletedPosts: {
    type: Array<{ postId: string; percentage: number }>,
    default: () => [],
  },
  quiz: {
    type: Object,
    default: () => null,
  },
},
  emits: [
  'togglePostCompletion',
  'updateVideoTime',
  'storeVideoProgress',
  'updateVideoPlay',
  'updateAudioTime',
  'storeAudioProgress',
  'updateAudioPlay',
],
  setup(__props, { emit: __emit }) {

const route = useRoute()
const props = __props

const emit = __emit

// Map to store records for each category and posts for quick lookup
const categoriesAndPostsLookup = ref(new Map())
provide('categoriesAndPostsLookup', categoriesAndPostsLookup)

const computedLoadingFlag = computed(() => props.isLoading)
provide('isLoading', computedLoadingFlag)

const canRetakeQuiz = ref(false)
const commentIdToScroll = ref(null)
const maxHeightOfPlaylist = ref(450)
const mediaContainer = ref(null)
const updatingLookup = ref(false)

const productId = computed(() => route.params.id || props.product.id)
const categoryId = computed(
  () => (route.params.category_id as string) || props.category.id
)
const postId = computed(
  () => route.params.post_id || props.activePost.id
) as ComputedRef<string>

const assetUrls = computed(() => {
  return props.activePost && props.activePost.assetUrls
})

const currentPostContentType = computed(() => props.activePost.contentType)
const isPostCompleted = computed(() => props.completedPostIds.has(postId.value))

function isPostLocked(post: Post) {
  if (post.visibility === CategoryVisibility.locked) {
    const lockedByPostId = post.lockedByPost
    const lockedByCategory = post.lockedByCategory
    const isLocked = lockedByPostId
      ? !props.completedPostIds.has(lockedByPostId)
      : false

    if (lockedByCategory) {
      // Check if all the posts in the category are complete
      const category = categoriesAndPostsLookup.value.get(lockedByCategory)

      if (category) {
        return (
          isLocked ||
          !category.childs.every((post) => props.completedPostIds.has(post.id))
        )
      }
    }
    return isLocked
  }
  return false
}

function isCategoryInDrip(categoryId: string) {
  const category = categoriesAndPostsLookup.value.get(categoryId)
  if (category) {
    if (category.node.available) return false
    return category.node.dripDays > 0
  }
  return false
}

const updatePlaylistHeight = () => {
  if (mediaContainer.value)
    maxHeightOfPlaylist.value = mediaContainer.value.offsetHeight
      ? mediaContainer.value.offsetHeight
      : maxHeightOfPlaylist.value
}

const togglePostCompletion = () => {
  emit('togglePostCompletion')
}

const changeQuizStatus = (quizRetake: boolean) => {
  canRetakeQuiz.value = quizRetake
}

const updateVideoPlay = () => {
  emit('updateVideoPlay')
}

const storeVideoProgress = () => {
  emit('storeVideoProgress')
}

const getVideoTime = (currentTime: Array<number>) => {
  emit('updateVideoTime', currentTime)
}

const storeAudioProgress = () => {
  emit('storeAudioProgress')
}

const updateAudioPlay = () => {
  emit('updateAudioPlay')
}

const updateAudioTime = (currentTime: Array<number>) => {
  emit('updateAudioTime', currentTime)
}

onMounted(() => {
  updatingLookup.value = true
  try {
    categoriesAndPostsLookup.value = getCategoryAndPostsLookup(
      props.categoryTree
    )
    updatePlaylistHeight()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  } catch (error) {
    console.error('Error while updating lookup: ', error)
  } finally {
    updatingLookup.value = false
  }
})

onBeforeUnmount(() => {
  storeVideoProgress()
})

watch(
  () => props.activePost,
  () => {
    updatePlaylistHeight()
  }
)

watch(
  () => props.categoryTree,
  () => {
    categoriesAndPostsLookup.value = getCategoryAndPostsLookup(
      props.categoryTree
    )
  }
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(NeoClassicBreadcrumbs, {
        "product-id": productId.value,
        "product-title": __props.product && __props.product.title,
        "category-id": categoryId.value,
        "category-title": __props.category && __props.category.title,
        "post-id": postId.value,
        "post-title": __props.activePost && __props.activePost.title
      }, null, 8, ["product-id", "product-title", "category-id", "category-title", "post-id", "post-title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (__props.isLoading)
        ? (_openBlock(), _createBlock(LessonPageLoader, {
            key: 0,
            class: "col-span-8"
          }))
        : (isPostLocked(__props.activePost))
          ? (_openBlock(), _createBlock(Paper, {
              key: 1,
              class: "p-4 w-full col-span-8 max-h-fit"
            }, {
              default: _withCtx(() => [
                _createVNode(LockedContent, {
                  "content-type": _unref(CategoryTreeNodeTypeView).Post,
                  lockedByPostId: __props.activePost.lockedByPost,
                  lockedByCategoryId: __props.activePost.lockedByCategory,
                  completedPostIds: __props.completedPostIds
                }, null, 8, ["content-type", "lockedByPostId", "lockedByCategoryId", "completedPostIds"])
              ]),
              _: 1
            }))
          : (isCategoryInDrip(__props.activePost.parentCategory || categoryId.value))
            ? (_openBlock(), _createBlock(Paper, {
                key: 2,
                class: "p-4 w-full col-span-8 h-5/6"
              }, {
                default: _withCtx(() => [
                  _createVNode(DripContent, {
                    remainingTime: 
            categoriesAndPostsLookup.value.get(__props.activePost.categoryId).node.dripDays
          
                  }, null, 8, ["remainingTime"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (__props.activePost.video && __props.videoOptions)
                  ? (_openBlock(), _createBlock(Paper, {
                      key: 0,
                      class: "p-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(VideoPlayer, {
                          id: "post-video",
                          ref_key: "mediaContainer",
                          ref: mediaContainer,
                          class: "player-height",
                          loading: __props.fetchingPost,
                          video: __props.activePost && __props.activePost.video,
                          options: __props.videoOptions,
                          trackTime: true,
                          videoCompletePercentage: __props.videoCompletePercentage,
                          "video-thumbnail": __props.activePost.posterImage,
                          assetUrl: assetUrls.value,
                          "controls-background": "#1D2939",
                          "theme-color": "#ffffff99",
                          radius: "0.375rem",
                          menuColor: "#1D2939",
                          defaultIconColors: "#ffffff",
                          textColor: "#ffffff",
                          onGetVideoTime: getVideoTime,
                          onOnPause: storeVideoProgress,
                          onOnEnded: storeVideoProgress,
                          onOnPlay: updateVideoPlay
                        }, null, 8, ["loading", "video", "options", "videoCompletePercentage", "video-thumbnail", "assetUrl"])
                      ]),
                      _: 1
                    }))
                  : (__props.activePost.audio)
                    ? (_openBlock(), _createBlock(Paper, {
                        key: 1,
                        class: "p-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(AudioPlayer, {
                            id: "post-audio",
                            ref_key: "mediaContainer",
                            ref: mediaContainer,
                            class: "rounded-lg !mb-0 !max-w-full",
                            loading: __props.fetchingPost,
                            trackTime: true,
                            "controls-background": "#1D2939",
                            "theme-color": "#ffffff99",
                            audio: __props.activePost.audio,
                            thumbnail: __props.activePost.posterImage,
                            post: __props.activePost,
                            product: __props.product,
                            audioCompletePercentage: __props.audioCompletePercentage,
                            onGetAudioTime: updateAudioTime,
                            onOnPause: storeAudioProgress,
                            onOnEnded: storeAudioProgress,
                            onOnPlay: updateAudioPlay
                          }, null, 8, ["loading", "audio", "thumbnail", "post", "product", "audioCompletePercentage"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                (currentPostContentType.value === _unref(PostContentType).quiz)
                  ? (_openBlock(), _createBlock(Paper, {
                      key: 2,
                      class: "flex justify-center p-4 player-height"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(NeoQuizStatusComponent, {
                          loading: !!__props.quiz,
                          quiz: __props.quiz,
                          post: __props.activePost,
                          categoryId: categoryId.value,
                          onChangeQuizStatus: changeQuizStatus
                        }, null, 8, ["loading", "quiz", "post", "categoryId"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (__props.activePost.contentType === _unref(PostContentType).assignment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(AssignmentLaunch, {
                        assignment: __props.assignment,
                        assessmentStatus: __props.assessmentStatus
                      }, null, 8, ["assignment", "assessmentStatus"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (__props.activePost.description)
                        ? (_openBlock(), _createBlock(AboutLesson, {
                            key: 0,
                            id: "post-description",
                            class: "lesson-body-description",
                            description: __props.activePost.description
                          }, {
                            "at-end": _withCtx(() => [
                              _createVNode(MarkPostComplete, {
                                wrapper: false,
                                activePost: __props.activePost,
                                nextPost: __props.nextPost,
                                onTogglePostCompletion: togglePostCompletion,
                                isPostCompleted: isPostCompleted.value,
                                buttonText: __props.postCompletionButtonText,
                                disablePostCompletionButton: __props.disablePostCompletionButton
                              }, null, 8, ["activePost", "nextPost", "isPostCompleted", "buttonText", "disablePostCompletionButton"])
                            ]),
                            _: 1
                          }, 8, ["description"]))
                        : _createCommentVNode("", true)
                    ])),
                (!__props.activePost.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(MarkPostComplete, {
                        activePost: __props.activePost,
                        nextPost: __props.nextPost,
                        onTogglePostCompletion: togglePostCompletion,
                        isPostCompleted: isPostCompleted.value,
                        buttonText: __props.postCompletionButtonText,
                        disablePostCompletionButton: __props.disablePostCompletionButton
                      }, null, 8, ["activePost", "nextPost", "isPostCompleted", "buttonText", "disablePostCompletionButton"])
                    ]))
                  : _createCommentVNode("", true),
                (__props.activePost.commentPermission === _unref(PostCommentStatus).enabled)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(Paper, { class: "w-full p-6 mb-4" }, {
                        default: _withCtx(() => [
                          _createVNode(NeoComments, {
                            productCommentPrivacy: __props.product.commentPrivacy,
                            postId: postId.value,
                            productId: productId.value,
                            instructor: __props.instructor,
                            pendoPayload: { post: __props.activePost, product: __props.product, category: __props.category },
                            commentIdToScroll: commentIdToScroll.value
                          }, null, 8, ["productCommentPrivacy", "postId", "productId", "instructor", "pendoPayload", "commentIdToScroll"])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(Paper, {
          id: "playlist-wrapper",
          class: "w-full"
        }, {
          default: _withCtx(() => [
            _createVNode(CategoryPlaylist, {
              paperWrapper: false,
              playlist: __props.playlist,
              categoryTree: __props.categoryTree,
              category: __props.category,
              "active-post": __props.activePost,
              nextCategoryId: __props.nextCategoryId,
              completedPostIds: __props.completedPostIds,
              allCompletedPosts: __props.allCompletedPosts,
              refresh: __props.isLoading
            }, null, 8, ["playlist", "categoryTree", "category", "active-post", "nextCategoryId", "completedPostIds", "allCompletedPosts", "refresh"])
          ]),
          _: 1
        }),
        (__props.activePost.materials && __props.activePost.materials.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(AttachedFiles, {
                fileList: __props.activePost.materials
              }, null, 8, ["fileList"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(InstructorCard, { instructor: __props.instructor }, null, 8, ["instructor"])
        ])
      ])
    ])
  ]))
}
}

})