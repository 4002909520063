import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full bg-white mb-5 mt-2 pl-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OneComment = _resolveComponent("OneComment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
      return (_openBlock(), _createBlock(_component_OneComment, {
        content: comment.content,
        commentElem: comment,
        key: comment.id,
        id: comment.id,
        instructor: _ctx.instructor,
        onUpdateStateChildComment: _ctx.updateStateChildComment,
        commentPermission: _ctx.commentPermission,
        pendoPayload: _ctx.pendoPayload,
        parentVisibility: _ctx.parentComment.visibility,
        scrollCommentId: _ctx.scrollCommentId
      }, null, 8, ["content", "commentElem", "id", "instructor", "onUpdateStateChildComment", "commentPermission", "pendoPayload", "parentVisibility", "scrollCommentId"]))
    }), 128))
  ]))
}