import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { VueRecaptcha } from 'vue-recaptcha'
  import {
    onLoad
  } from '../../../helper/captcha.helper'
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'Recaptcha',
  emits: ['verify', 'expired'],
  setup(__props, { emit: __emit }) {

  const emit = __emit
  
  const loadScript = () => {
    if (process.client) {
      onLoad(() => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "text/javascript";
        scriptTag.async = true
        scriptTag.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
        document.head.appendChild(scriptTag)
      })
    }
  }
  
  const onVerify = (event) => {
    emit('verify', event)
  }
  const onExpired = (event) => {
    emit('expired', event)
  }
  
return (_ctx: any,_cache: any) => {
  const _directive_visibility_observer = _resolveDirective("visibility-observer")!

  return _withDirectives((_openBlock(), _createBlock(_unref(VueRecaptcha), {
    onExpired: onExpired,
    onVerify: onVerify,
    sitekey: "6LfcbMseAAAAAI-EJoB-lUh7_TJaYloLbcbmnhEO",
    class: "flex justify-center g-recaptcha mb-2"
  }, null, 512)), [
    [_directive_visibility_observer, loadScript]
  ])
}
}

})