import http from '../../http-common'
import BaseService from './BaseService'
import Question from '@/models/Question'

export default class QuestionService extends BaseService<Question> {
  protected subdomain = 'assessments/quiz/questions'

  model() {
    return Question
  }

  findQuestionsByQuizId(quizId: string) {
    return http.get(`${this.endpoint}/${quizId}`)
  }
}
