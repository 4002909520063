<template>
  <router-link
    id="go-to-next-post"
    :class="[
      'w-full rounded p-2 next-lesson__bg-color hover:no-underline max-h-fit',
      'cursor-pointer',
    ]"
    tag="div"
    @click="trackEvent"
    :to="{
      name: 'post-overview',
      params: {
        id: post.productId,
        category_id: post.categoryId,
        post_id: post.id,
      },
    }"
    v-if="!completed"
  >
    <div
      class="no-underline flex flex-col md:flex-row lg:flex-row md:items-center lg:items-center md:justify-between lg:justify-between"
    >
      <div>
        <img
          class="max-h-28"
          :src="replaceBucketUrlWithCdnUrl(posterImage)"
          alt="Post Image"
        />
      </div>
      <UIContentWrap
        class="post-title-container flex-1 text-xs md:text-base lg:text-base"
      >
        <UITextSmNormal>
          <p class="my-0 completion-message next-lesson__completionMsg">
            {{ nextLessonContent }}
          </p>
          <h3 class="my-0 post-title next-lesson__post-title">
            {{ post.title }}
          </h3>
        </UITextSmNormal>
      </UIContentWrap>
      <UIContentWrap v-if="!completed"> Next lesson &gt; </UIContentWrap>
    </div>
  </router-link>

  <div
    v-else
    class="bg-green-100 border border-green-400 text-green-800 px-6 py-4 rounded-lg shadow-md relative flex flex-col justify-between items-start transition-transform transform duration-300 ease-in-out"
    role="alert"
  >
    <strong class="font-bold text-lg">Congratulations!</strong>
    <p class="mt-2 mb-0 text-md">
      You have successfully completed the course.
      <br />
      Thank you for being a part of our learning community!
    </p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { getDefaultPosterImage } from '@/helper'
import { UIContentWrap, UITextSmNormal } from '@gohighlevel/ghl-ui'
import { PostContentType } from '@/models/Post'
import {
  trackCustomGAEvents,
  GAEventTypes,
} from '@gohighlevel/clientportal-core'

export default defineComponent({
  props: {
    post: {
      type: Object,
      required: true,
    },
    nextLesson: {
      type: Object,
    },
    completed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UIContentWrap,
    UITextSmNormal,
  },
  data() {
    return {
      postContentType: PostContentType,
    }
  },
  computed: {
    nextLessonContent(): string {
      return this?.nextLesson?.content || 'Awesome! Continue Pushing!'
    },
    posterImage(): string {
      return (
        this.post.posterImage || getDefaultPosterImage(this.post.contentType)
      )
    },
  },
  methods: {
    trackEvent() {
      trackCustomGAEvents(GAEventTypes.NEXT_LESSON, {
        postId: this.post.postId,
      })
    },
  },
})
</script>
<style scoped>
.card-content {
  height: 100%;
  width: 100%;
}

.card-content > * {
  height: 100%;
}

@media screen and (max-width: 767px) {
  .image-title-container {
    height: 80%;
    width: 100%;
  }

  .next-lesson-message {
    height: 20%;
    width: 100%;
    padding-left: 55px;
  }
}
</style>
