<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    selectedTab: {
        type: String,
        required: true
    },
    tabOptions: {
        type: Object,
        required: true
    }
});

const emit = defineEmits(['tab-change']);


const handleTabChange = (tab) => {
    emit('tab-change', tab);
};

const getSelectedTabStyles = (tab) => {
    return {
        borderBottomColor: props.selectedTab === tab ? '#1D4981' : '#e2e8f0',
        color: props.selectedTab === tab ? '#1D4981' : '#909090'
    };
};
</script>

<template>
    <div class="w-full flex items-center justify-evenly mx-auto gap-4">
        <span v-for="tab in tabOptions" :key="tab.value"
            class="py-3 border-b-2 hover:border-[#1D4981] text-[#909090] w-1/2 text-center clickable cursor-pointer font-semibold"
            :class="{ 'opacity-50': tab.disabled }" :style="getSelectedTabStyles(tab.value)"
            @click="handleTabChange(tab.value)">{{
                tab.name }}</span>
    </div>
</template>



<style scoped></style>